import { defineMessages } from 'react-intl';
const messages = defineMessages({
  itemBatchStart: {
    id: 'Item batch start',
    defaultMessage: 'Item batch start',
  },
});

export const QSSchema = (props) => {
  const { intl, schema } = props;
  return {
    ...schema,
    fieldsets: [
      {
        ...schema.fieldsets[0],
        fields: [...schema.fieldsets[0].fields, 'b_start'],
      },
    ],
    properties: {
      ...schema.properties,
      b_start: {
        title: intl.formatMessage(messages.itemBatchStart),
        type: 'number',
      },
    },
  };
};
