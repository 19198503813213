import {
  GET_MODIFICATION,
  GET_USERMODIFICATIONS,
  GET_ALLUSERMODIFICATIONS,
  ADD_USERMODIFICATIONS,
  REMOVE_USERMODIFICATION,
} from '../../actions';
// import { map } from 'lodash';
const getInitialState = {
  get: { error: null, loaded: false, loading: false },
  getall: { error: null, loaded: false, loading: false },
  add: { error: null, loaded: false, loading: false },
  remove: { error: null, loaded: false, loading: false },
  modifications: [],
};

/**
 * user modifications reducer.
 * @function user modifications
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const userModificationsReducer = (
  state = getInitialState,
  action = {},
) => {
  switch (action.type) {
    case `${GET_USERMODIFICATIONS}_PENDING`:
    case `${GET_MODIFICATION}_PENDING`:
      return {
        ...state,
        get: {
          ...state.get,
          error: null,
          loaded: false,
          loading: true,
        },
        modifications: [],
      };
    case `${GET_USERMODIFICATIONS}_FAIL`:
    case `${GET_MODIFICATION}_FAIL`:
      return {
        ...state,
        get: {
          ...state.get,
          error: true,
          loaded: false,
          loading: false,
        },
        modifications: [],
      };
    case `${GET_USERMODIFICATIONS}_SUCCESS`:
    case `${GET_MODIFICATION}_SUCCESS`:
      return {
        ...state,
        get: {
          ...state.get,
          error: null,
          loaded: true,
          loading: false,
        },
        modifications: action.result,
      };
    case `${GET_ALLUSERMODIFICATIONS}_PENDING`:
      return {
        ...state,
        getall: {
          ...state.getall,
          error: null,
          loaded: false,
          loading: true,
        },
        modifications: [],
      };
    case `${GET_ALLUSERMODIFICATIONS}_FAIL`:
      return {
        ...state,
        getall: {
          ...state.getall,
          error: true,
          loaded: false,
          loading: false,
        },
        modifications: [],
      };
    case `${GET_ALLUSERMODIFICATIONS}_SUCCESS`:
      return {
        ...state,
        getall: {
          ...state.getall,
          error: null,
          loaded: true,
          loading: false,
        },
        modifications: action.result,
      };
    case `${ADD_USERMODIFICATIONS}_PENDING`:
      return {
        ...state,
        add: {
          ...state.add,
          error: null,
          loaded: false,
          loading: true,
        },
      };
    case `${ADD_USERMODIFICATIONS}_FAIL`:
      return {
        ...state,
        add: {
          ...state.add,
          error: true,
          loaded: false,
          loading: false,
          message: action?.error?.response?.body?.error,
        },
      };
    case `${ADD_USERMODIFICATIONS}_SUCCESS`:
      return {
        ...state,
        add: {
          ...state.add,
          error: null,
          loaded: true,
          loading: false,
        },
        title: action.result?.title,
        message: action.result?.message,
      };
    case `${REMOVE_USERMODIFICATION}_PENDING`:
      return {
        ...state,
        remove: {
          ...state.remove,
          error: null,
          loaded: false,
          loading: true,
        },
      };
    case `${REMOVE_USERMODIFICATION}_FAIL`:
      return {
        ...state,
        remove: {
          ...state.remove,
          error: true,
          loaded: false,
          loading: false,
        },
      };
    case `${REMOVE_USERMODIFICATION}_SUCCESS`:
      return {
        ...state,
        remove: {
          ...state.remove,
          error: null,
          loaded: true,
          loading: false,
        },
        modifications: action.result,
      };
    default:
      return state;
  }
};
