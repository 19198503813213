import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Header, Segment } from 'semantic-ui-react';

import { getUser } from '@plone/volto/actions/users/users';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';

import jwtDecode from 'jwt-decode';

const NireBazkidetzaView = ({ content, location, staticContext }) => {
  /** state selectors **/
  const token = useSelector((state) => state.userSession.token);
  const user = useSelector((state) => state.users.user);

  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(userId));
  }, [dispatch, userId]);
  return (
    <>
      <Helmet title={'Nire Datuak'} />
      <div className="ui container">
        {!isLoggedIn && (
          <>
            {token ? (
              <Forbidden
                pathname={location.pathname}
                staticContext={staticContext}
              />
            ) : (
              <Unauthorized
                pathname={location.pathname}
                staticContext={staticContext}
              />
            )}
          </>
        )}
        {isLoggedIn && (
          <>
            <Container className="view-wrapper">
              <Header as="h1" className="no-breadcrumb">
                Nire bazkidetza
              </Header>
              {user.bazkide_id ? (
                <Segment basic className="bazkidetza-segment">
                  <h2>{user.bazkide_id} zenbakidun bazkidea zara.</h2>
                  <p>
                    UEUko bazkide izateagatik dituzun abantailak ondorengoak
                    dira:
                  </p>
                  <ul>
                    <li>
                      <UniversalLink
                        className="ui button ueu primary"
                        href={`/komunitatea/bazkideak`}
                      >
                        UEUko bazkidetza
                      </UniversalLink>
                    </li>
                  </ul>
                </Segment>
              ) : (
                <Segment basic className="bazkidetza-segment">
                  <h2>Oraindik ez zara UEUko bazkide.</h2>
                  <p>
                    Bazkide egin nahi baduzu joan helbide honetara: (Helbidea)
                  </p>
                </Segment>
              )}
            </Container>
          </>
        )}
      </div>
    </>
  );
};

export default NireBazkidetzaView;
