import React from 'react';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { Segment } from 'semantic-ui-react';

const PageCard = ({ item, isEditMode, showDescription = true }) => {
  return (
    <Segment>
      <h3>
        <ConditionalLink item={item} condition={!isEditMode}>
          {item.title}
        </ConditionalLink>
      </h3>
      {item.description}
    </Segment>
  );
};

export default PageCard;
