import React from 'react';
import { Grid, List, Segment } from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import inguma_logo from 'volto-ueu/theme/images/logo-mark_inguma.png';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import './pertsona.css';

const GeneralInfoCard = ({ props }) => {
  // const knowledge_areas = props?.knowledge_areas?.map(
  //   (knowledge_area) => knowledge_area.title,
  // );
  return (
    <>
      <Segment className="generalinfo-card">
        <Grid>
          <Grid.Column>
            <List bulleted>
              {props?.name && (
                <List.Item>
                  <strong>{'Izena: '}</strong>
                  {props.name}
                </List.Item>
              )}
              {(props?.surname1 || props.surname2) && (
                <List.Item>
                  <strong>{'Abizenak: '}</strong>
                  {props.surname1} {props.surname2}
                </List.Item>
              )}
              {props?.titulation && (
                <List.Item>
                  <strong>{'Ikasketak: '}</strong>
                  {props.titulation}
                </List.Item>
              )}
              {/* {knowledge_areas && (
                <List.Item>
                  <strong>{'Jakintza arloa(k): '}</strong>
                  {`${knowledge_areas.join(', ')}`}
                </List.Item>
              )} */}
              {(props?.birthplace || props.birthdate) && (
                <List.Item>
                  <strong>{'Jaioterria eta urtea: '}</strong>
                  {props.birthplace && props.birthplace}
                  {props.birthplace && props.birthdate && ', '}
                  {props.birthdate && props.birthdate.slice(0, 4)}
                </List.Item>
              )}
            </List>
            {props?.description && (
              <div className="pertsona-description">{props.description}</div>
            )}
            {props?.text?.data && <StringToHTML string={props.text.data} />}
          </Grid.Column>
        </Grid>
        <br />
        {props.link && (
          <UniversalLink
            href={`${props.link}`}
            target="_blank"
            className="inguma-button"
          >
            <img width="25px" alt="" src={inguma_logo} />
            <span>Inguma</span>
          </UniversalLink>
        )}
      </Segment>
    </>
  );
};

export default GeneralInfoCard;
