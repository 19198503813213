import HomeTitleElement from './HomeTitlePlugin/HomeTitleElement';
import { HOMETITLE } from './constants';

import React from 'react';
import { BlockButton } from '@plone/volto-slate/editor/ui';
import IzIcon from 'volto-ueu/theme/icons/iz_icon.svg';
import ArrosaIcon from 'volto-ueu/theme/icons/arrosa_icon.svg';
import GrisaIcon from 'volto-ueu/theme/icons/grisa_icon.svg';
import Icon from '@plone/volto/components/theme/Icon/Icon';

export function installHomeTitlePlugin(config) {
  const { slate } = config.settings;

  slate.buttons[HOMETITLE] = (props) => (
    <BlockButton
      format={HOMETITLE}
      icon={IzIcon}
      title="Portadako titulua"
      {...props}
    />
  );
  slate.elements[HOMETITLE] = HomeTitleElement;
  slate.toolbarButtons = [...(slate.toolbarButtons || []), HOMETITLE];

  return config;
}

export function installStyledSlate(config) {
  const { slate } = config.settings;
  slate.styleMenu.inlineStyles = [
    {
      cssClass: 'grey-background-block-text',
      label: 'Banda grisa',
      icon: (props) => <Icon name={GrisaIcon} size="24px" />,
    },
    {
      cssClass: 'pink-background-block-text',
      label: 'Banda arrosa',
      icon: (props) => <Icon name={ArrosaIcon} size="24px" />,
    },
  ];
  return config;
}
