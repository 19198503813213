/**
 * Get courses.
 * @module actions/course
 */
import config from '@plone/volto/registry';
export const GET_COURSES = 'GET_COURSES';
/**
 * Get tokens.
 * @function getCourses
 * @returns {Object} Get tokens action.
 */
export function getCourses(limit = 4, unesco = []) {
  // const unesco_filter =
  //   unesco.length > 0
  //     ? `&taxonomy_unesco_categorization=${unesco.join(
  //         '&taxonomy_unesco_categorization=',
  //       )}`
  //     : '';
  const { settings } = config;
  const query_data = {
    b_size: limit,
    query: [
      {
        i: 'taxonomy_unesco_categorization',
        o: 'plone.app.querystring.operation.list.contains',
        v: unesco,
      },
      {
        i: 'portal_type',
        o: 'plone.app.querystring.operation.selection.any',
        v: ['Course'],
      },
    ],
    sort_on: 'date_to_sort',
    sort_order: 'ascending',
  };
  return {
    type: GET_COURSES,
    request: {
      op: settings.querystringSearchGet ? 'get' : 'post',
      path: `@querystring-search${
        settings.querystringSearchGet
          ? `?query=${encodeURIComponent(JSON.stringify(query_data))}`
          : ''
      }`,
    },
  };
  // return {
  //   type: GET_COURSES,
  //   request: {
  //     op: 'get',
  //     path: `@search?portal_type=Course&sort_on=start_date&sort_order=descending&sort_limit=${limit}${unesco_filter}`,
  //   },
  // };
}

export function getCoursesByTeacher(teachers = [], limit = 4) {
  const teachers_filter =
    teachers.length > 0 ? `&teachers=${teachers.join('&teachers=')}` : '';
  return {
    type: GET_COURSES,
    request: {
      op: 'get',
      path: `@search?portal_type=Course&sort_on=start_date&sort_order=descending&sort_limit=${limit}${teachers_filter}`,
    },
  };
}
export const UPDATE_COURSE = 'UPDATE_COURSE';
/**
 * Get tokens.
 * @function updateCourse
 * @returns Updates course with data from Kudeu.
 */
export function updateCourse(path) {
  return {
    type: UPDATE_COURSE,
    request: {
      op: 'post',
      path: `${path}/@updatecourse`,
    },
  };
}

export const GET_MY_COURSES = 'GET_MY_COURSES';
/**
 * Get my courses.
 * @function getMyCourses
 * @returns Returns current user enrolled courses.
 */
export function getMyCourses() {
  return {
    type: GET_MY_COURSES,
    request: {
      op: 'get',
      path: `@nireikastaroak`,
    },
  };
}
