import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Segment, Grid, Header, Label } from 'semantic-ui-react';

import { searchContent } from '@plone/volto/actions/search/search';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';

import { getErosketak } from 'volto-ueu/actions';
import { ContentData } from './ContentData';

import jwtDecode from 'jwt-decode';

/**
 * ShopOkView view component.
 * @module components/Views/OrdainketaView/ShopOkView
 */
/**
 * ShopOkView view.
 * @function ShopOkView
 * @returns {string} Markup of the component.
 */

const ShopOkView = ({ location, staticContext }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const erosketak = useSelector((state) => state.erosketak.erosketak);
  const [erosketa, setErosketa] = useState();
  const subrequests = useSelector((state) => state.search?.subrequests);
  const token = useSelector((state) => state.userSession.token);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  useEffect(() => {
    dispatch(getErosketak());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErosketa(
      erosketak.filter((erosketa) => erosketa.UID === params.erosketa)[0],
    );
  }, [params.erosketa, erosketak]);

  useEffect(() => {
    erosketa?.saskia?.content &&
      Object.keys(erosketa?.saskia?.content).forEach((item) => {
        dispatch(
          searchContent(
            '',
            {
              UID: erosketa.saskia?.content?.[item]?.publication,
            },
            erosketa.saskia?.content?.[item]?.publication,
          ),
        );
      });
  }, [erosketa, dispatch]);
  const title_text = 'Ordainketa ongi burutu da!';
  return !isLoggedIn ? (
    <>
      {token ? (
        <Forbidden pathname={location.pathname} staticContext={staticContext} />
      ) : (
        <Unauthorized
          pathname={location.pathname}
          staticContext={staticContext}
        />
      )}
    </>
  ) : (
    <ContentData
      h1_text={title_text}
      Body={() => (
        <>
          <Segment basic>
            <p>Zure erosketaren ordainketa ongi burutu da.</p>
          </Segment>
          <Segment basic>Mila esker!</Segment>
          {erosketa ? (
            <>
              <Grid className="saski-taula">
                <Grid.Row columns={3}>
                  <Grid.Column width={6}>
                    <strong>Argitalpena</strong>
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <strong>Prezioa</strong>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <strong>Kopurua</strong>
                  </Grid.Column>
                </Grid.Row>
                {erosketa?.saskia?.content &&
                  Object.keys(erosketa?.saskia?.content)?.map((item, key) => (
                    <Grid.Row key={key} columns={3}>
                      <Grid.Column width={6}>
                        {subrequests?.[
                          erosketa?.saskia?.content?.[item]?.publication
                        ]?.items?.[0]?.['@id'] &&
                          `${erosketa?.saskia?.content?.[item]?.title} (${erosketa?.saskia?.content?.[item]?.format})`}
                      </Grid.Column>
                      <Grid.Column width={1}>
                        {`${erosketa?.saskia?.content?.[item]?.unit_price} €`}
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Label basic color="black" className="saskia-count">
                          {erosketa?.saskia?.content?.[item]?.count}
                        </Label>
                      </Grid.Column>
                    </Grid.Row>
                  ))}
              </Grid>
              <Grid className="saski-prezioak">
                <Grid.Row columns={3}>
                  <Grid.Column width={6}></Grid.Column>
                  <Grid.Column width={2}>Subtotala</Grid.Column>
                  <Grid.Column width={3}>
                    {`${erosketa?.saskia.subTotal} €`}
                  </Grid.Column>
                </Grid.Row>
                {erosketa?.saskia.discounts?.length > 0 &&
                  erosketa?.saskia.discounts.map((discount, key) => (
                    <Grid.Row key={key} columns={3}>
                      <Grid.Column width={6}></Grid.Column>
                      <Grid.Column width={2}>{discount.title}</Grid.Column>
                      <Grid.Column
                        width={3}
                      >{`-${discount.value} €`}</Grid.Column>
                    </Grid.Row>
                  ))}
                <Grid.Row columns={3}>
                  <Grid.Column width={6}></Grid.Column>
                  <Grid.Column width={2}>Bidalketa gastuak</Grid.Column>
                  <Grid.Column width={3}>
                    {`${erosketa?.saskia.shipping} €`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column width={6}></Grid.Column>
                  <Grid.Column width={2}>
                    <strong>Guztira </strong>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <strong>{`${erosketa?.saskia.total} €`}</strong>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          ) : (
            <Header as="h2">
              Erosketaren helbidea ez dagokio zure erosketa bati
            </Header>
          )}
        </>
      )}
    />
  );
};

export default ShopOkView;
