import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Table, Divider, Segment } from 'semantic-ui-react';

import { getUser } from '@plone/volto/actions/users/users';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';

import { getUserSchema, getMyCourses } from 'volto-ueu/actions';
import {
  isEntryInTime,
  enrollStatus,
} from 'volto-ueu/components/Views/CourseView/utils';
import { IKASTAROA_PAY_ROUTE } from 'volto-ueu/constants';

// import { UeuListing } from 'volto-ueu/components/Variations';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { formatLL } from '../../utils';

const NireIkastaroakView = ({ content, location, staticContext }) => {
  /** state selectors **/
  const token = useSelector((state) => state.userSession.token);
  let lang = useSelector((state) => state.intl.locale);
  const myCourses = useSelector((state) => state.myCourses);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(userId));
    dispatch(getUserSchema());
    dispatch(getMyCourses());
  }, [dispatch, userId]);
  moment.locale(lang);

  return (
    <div className="ui container">
      <Helmet title={'Nire Ikastaroak'} />
      <div className="ui container">
        {!isLoggedIn && (
          <>
            {token ? (
              <Forbidden
                pathname={location.pathname}
                staticContext={staticContext}
              />
            ) : (
              <Unauthorized
                pathname={location.pathname}
                staticContext={staticContext}
              />
            )}
          </>
        )}
        {isLoggedIn && (
          <>
            <Header as="h1" className="no-breadcrumb">
              <FormattedMessage
                id="my_courses"
                defaultMessage="Nire Ikastaroak"
              />
            </Header>
            <Segment basic>
              OHARRA: Hemen agertzen diren ikastaroak aurtengo ikasturtean
              matrikulatu zaren ikastaroak dira.
            </Segment>
            <Table unstackable className="resume-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="enrollment_date_table_header"
                      defaultMessage="Matrikulazio data"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage
                      id="enrollment_data_table_header"
                      defaultMessage="Matrikulako datuak"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {myCourses?.courses.length > 0 ? (
                  myCourses?.courses
                    .sort(function (a, b) {
                      return b.matrikulazio_data.localeCompare(
                        a.matrikulazio_data,
                      );
                    })
                    .map((enrollment, key) => (
                      <Table.Row key={key}>
                        <Table.Cell>
                          {formatLL(enrollment.matrikulazio_data)}
                        </Table.Cell>
                        <Table.Cell>
                          <strong>{enrollment.title}</strong>
                          <Divider />
                          <ul>
                            {Object.entries(enrollment?.data).map((elem) => (
                              <li key={elem}>
                                <strong>{elem[1].title}:</strong>{' '}
                                {elem[1].format === 'datetime'
                                  ? elem[1].data
                                    ? formatLL(elem[1].data)
                                    : '-'
                                  : ''}
                                {elem[1].format === 'vocabulary' &&
                                  elem[1].vocabulary[elem[1].data]}
                              </li>
                            ))}
                          </ul>
                        </Table.Cell>
                        <Table.Cell>
                          <UniversalLink
                            className="ui button ueu"
                            href={enrollment.absolute_url}
                          >
                            Ikusi ikastaroaren fitxa
                          </UniversalLink>
                          {isEntryInTime(enrollment) &&
                            enrollStatus(
                              enrollment.ikastaro_id,
                              myCourses?.courses,
                            ) !== 'Onartuta' && (
                              <UniversalLink
                                className="ui button ueu primary"
                                href={`${enrollment.absolute_url}${IKASTAROA_PAY_ROUTE}`}
                              >
                                Ordaindu ikastaroa
                              </UniversalLink>
                            )}
                        </Table.Cell>
                      </Table.Row>
                    ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="3">
                      <FormattedMessage
                        id="no_courses_table_text"
                        defaultMessage="Oraindik ez daukazu ikastarorik"
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </>
        )}
      </div>
    </div>
  );
};

export default NireIkastaroakView;
