import { GET_BOOKS, GET_BOOKS_BY_AUTHORS } from 'volto-ueu/actions';
const initialState = {
  books: {
    data: [],
    error: null,
    loaded: false,
    loading: false,
  },
  books_by_authors: {
    data: [],
    error: null,
    loaded: false,
    loading: false,
  },
};

/**
 * Books reducer.
 * @function books
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const getBooksReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_BOOKS}_PENDING`:
      return {
        ...state,
        books: {
          ...state.books,
          loaded: false,
          loading: true,
          data: [],
        },
      };
    case `${GET_BOOKS}_SUCCESS`:
      return {
        ...state,
        books: {
          ...state.books,
          loaded: true,
          loading: false,
          data: action.result.items,
        },
      };
    case `${GET_BOOKS}_FAIL`:
      return {
        ...state,
        books: {
          ...state.books,
          error: true,
          loaded: false,
          loading: false,
          data: [],
        },
      };
    case `${GET_BOOKS_BY_AUTHORS}_PENDING`:
      return {
        ...state,
        books_by_authors: {
          ...state.books_by_authors,
          loaded: false,
          loading: true,
          data: [],
        },
      };
    case `${GET_BOOKS_BY_AUTHORS}_SUCCESS`:
      return {
        ...state,
        books_by_authors: {
          ...state.books_by_authors,
          loaded: true,
          loading: false,
          data: action.result.items,
        },
      };
    case `${GET_BOOKS_BY_AUTHORS}_FAIL`:
      return {
        ...state,
        books_by_authors: {
          ...state.books_by_authors,
          error: true,
          loaded: false,
          loading: false,
          data: [],
        },
      };
    default:
      return state;
  }
};
