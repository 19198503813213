import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { Grid, Segment, Divider } from 'semantic-ui-react';

import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { withQueryString } from '../SearchBlock/withQueryString';

import moment from 'moment';
import PropTypes from 'prop-types';

const TxiotesiListing = ({
  items,
  isEditMode,
  block,
  showUnconditionally = false,
  doubling = false,
}) => {
  const lang = useSelector((state) => state.intl.locale);
  const subrequest = useSelector(
    (state) => state.querystringsearch.subrequests?.[block],
  );
  moment.locale(lang);

  let mapItems = showUnconditionally || subrequest ? [...items] : [];

  return (
    <Grid columns={4} centered stackable>
      {mapItems.map((item, key) => {
        return (
          <Grid.Column id={key} key={key}>
            <Segment
              circular
              textAlign="center"
              style={{
                width: 180,
                height: 180,
              }}
            >
              <h3 style={{ fontSize: '2em' }}>
                <ConditionalLink
                  item={item}
                  condition={!isEditMode}
                  style={{ color: 'white' }}
                >
                  {moment(item?.effective).year()}
                </ConditionalLink>
              </h3>
              <Divider style={{ border: '1px solid #A7A7A7' }} />
              <h4>
                <ConditionalLink item={item} condition={!isEditMode}>
                  {item?.title}
                </ConditionalLink>
              </h4>
            </Segment>
          </Grid.Column>
        );
      })}
    </Grid>
  );
};
TxiotesiListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default compose(withQueryString)(TxiotesiListing);
