export const LOCK_CHAPTERS = 'LOCK_CHAPTERS';
/**
 * Lock chapters.
 * @function lockChapters
 * @returns Locks chapters.
 */
export function lockChapters(path) {
  return {
    type: LOCK_CHAPTERS,
    request: {
      op: 'post',
      path: `${path}/@lock-chapters`,
    },
  };
}
