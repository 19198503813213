import { ADD_IRAGARKIA } from 'volto-ueu/actions';
// import { map } from 'lodash';
const getInitialState = {
  error: null,
  loaded: false,
  loading: false,
  response: '',
};

/**
 * add_iragarkia reducer.
 * @function add_iragarkia
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const addIragarkiaReducer = (state = getInitialState, action = {}) => {
  switch (action.type) {
    case `${ADD_IRAGARKIA}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        response: '',
      };
    case `${ADD_IRAGARKIA}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
        response: 'Arazo bat gertatu da!',
      };
    case `${ADD_IRAGARKIA}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        response: action.result,
      };
    default:
      return state;
  }
};
