import React from 'react';

import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';
import FooterResidenceBlockView from './FooterResidenceBlockView';
import Schema from './schema';

const FooterResidenceBlockEdit = (props) => {
  const { block, data, onChangeBlock, selected, intl } = props;
  return (
    <>
      <FooterResidenceBlockView
        isEditMode={true}
        {...props}
      ></FooterResidenceBlockView>

      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={Schema(intl)}
          title="Button block"
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
          block={block}
        />
      </SidebarPortal>
    </>
  );
};

export default FooterResidenceBlockEdit;
