import React from 'react';
import { Grid, Image, List, Header } from 'semantic-ui-react';

import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import { Picture } from 'volto-ueu/components';
import { getRandomSrc } from 'volto-ueu/components/RandomImage/RandomImage';
import { dynamicSort } from 'volto-ueu/components/utils';

const CourseCard = ({ item, isEditMode }) => {
  return (
    <Grid width={16} className="course-card" textAlign="left">
      <Grid.Row>
        <Grid.Column width={4}>
          <ConditionalLink item={item} condition={!isEditMode}>
            {!item.image_field && (
              <Image
                src={getRandomSrc(item.UID, 'courses')}
                alt="This content has no image, this is a default placeholder."
                size="small"
              />
            )}
            {item.image_field && (
              <Picture
                imageBase={`${item['@id']}/@@images/${item.image_field}`}
                alt={item.title}
              ></Picture>
            )}
          </ConditionalLink>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header size="small" color="pink">
            {item.course_status}
            {/* {getStateText(item, formatDate(date))} */}
          </Header>
          <h3>
            <ConditionalLink item={item} condition={!isEditMode}>
              {item.title}
            </ConditionalLink>
          </h3>
          <List>
            {item?.start_date && (
              <List.Item>
                <List.Icon name="calendar outline" />
                <List.Content>
                  <p>{item.start_date}</p>
                </List.Content>
              </List.Item>
            )}
            {item?.course_location && (
              <List.Item>
                <List.Icon name="map marker alternate" />
                <List.Content>
                  <p>{item.course_location}</p>
                </List.Content>
              </List.Item>
            )}
            {item?.ikastaroko_irakasleak?.length > 0 &&
              item?.ikastaroko_irakasleak?.length < 4 &&
              item?.ikastaroko_irakasleak
                .sort(dynamicSort('name'))
                .map((irakaslea, index) => {
                  return (
                    <List.Item key={index}>
                      <List.Icon name="address card outline" />
                      <List.Content>
                        <p>
                          <span>
                            {irakaslea.review_state !== 'published' ? (
                              <> {irakaslea.name}</>
                            ) : (
                              <ConditionalLink
                                to={`/${irakaslea.path}`}
                                condition={!isEditMode}
                              >
                                {irakaslea.name}
                              </ConditionalLink>
                            )}
                          </span>
                        </p>
                      </List.Content>
                    </List.Item>
                  );
                })}
          </List>
          {item?.sub_knowledge_fields && (
            <p>{'ikastaroa / ' + item.sub_knowledge_fields}</p>
          )}
          <UniversalLink className="ui button ueu line large" item={item}>
            {'Informazio gehiago'}
          </UniversalLink>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CourseCard;
