import { useEffect, useRef } from 'react';
import moment from 'moment';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Slugify a string: remove whitespaces, special chars and replace with _
 * @param {string} string String to be slugified
 * @returns {string} Slugified string
 */
export const slugify = (string) => {
  return string
    .toLowerCase()
    .replace(/[\s-]+/g, '_')
    .replace(/[^\w]+/g, '');
};

export const formatLL = (datetime) => {
  return moment(datetime)
    .format('LL')
    .replace('11a', '11')
    .replace('31a', '31')
    .replace('2025ko', '2025eko')
    .replace('2030ko', '2030eko');
};

export const removeDefaultFields = (schema, fields) => {
  return {
    ...schema,
    fieldsets: [
      {
        ...schema.fieldsets[0],
        fields: schema.fieldsets[0].fields.filter(
          (field) => !fields.includes(field),
        ),
      },
    ],
    required: schema.required.filter((field) => !fields.includes(field)),
  };
};

export const removeFields = (schema, fieldsets, fields) => {
  let fieldsets_obj = [];
  schema.fieldsets.forEach((fieldset, key) => {
    const new_fieldset = fieldsets.includes(fieldset.id)
      ? {
          ...fieldset,
          fields: fieldset.fields.filter((field) => !fields.includes(field)),
        }
      : { ...fieldset };
    new_fieldset.fields.length > 0 && fieldsets_obj.push(new_fieldset);
  });
  return {
    ...schema,
    fieldsets: [...fieldsets_obj],
  };
};

export const extendDependencies = (schema) => {
  const ikasketa_lana1 = (formData) => {
    return formData['ikasketa_lana'] === '1';
  };
  const ikasketa_lana2 = (formData) => {
    return formData['ikasketa_lana'] === '2';
  };
  const dependencies = [
    {
      id: 'zer_ikasi',
      dependency: ikasketa_lana1,
    },
    {
      id: 'non_ikasi',
      dependency: ikasketa_lana1,
    },
    {
      id: 'ikasketa_mota',
      dependency: ikasketa_lana1,
    },
    {
      id: 'lan_mota',
      dependency: ikasketa_lana2,
    },
    {
      id: 'non_lan',
      dependency: ikasketa_lana2,
    },
  ];
  dependencies.forEach((d) => {
    if (schema.properties[d.id]) {
      schema.properties[d.id]['dependency'] = d.dependency;
    }
  });
  schema.properties.email = {
    ...schema.properties.email,
    title: 'Helbide elektronikoa',
    description:
      'Helbide elektroniko hau erabiliko dugu pasahitza berrezartzeko beharrik izango bazenu',
  };
  schema.properties.jaiotze_data = {
    ...schema.properties.jaiotze_data,
    widget: null,
    type: 'react_datepicker',
  };
  return schema;
};

export const dynamicSort = (property) => {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    if (a[property] && b[property]) {
      var result =
        a[property].toLowerCase() < b[property].toLowerCase()
          ? -1
          : a[property].toLowerCase() > b[property].toLowerCase()
            ? 1
            : 0;
      return result * sortOrder;
    } else return 0;
  };
};

export const sanitizedHTML = (strToSanitize) => {
  if (strToSanitize === undefined) {
    return '';
  }
  let myHTML = strToSanitize.replace(/(<([^>]+)>)/gi, '');
  return myHTML || '';
};
