/**
 * Send iragarki form.
 * @module actions/iragarki_form
 */
export const SEND_IRAGARKI_FORM = 'SEND_IRAGARKI_FORM';

/**
 * Send Iragarki form.
 * @function sendIragarkiForm
 * @returns {Object} Send Iragarki form action.
 */
export function sendIragarkiForm(path, data, iragarki) {
  return {
    type: SEND_IRAGARKI_FORM,
    request: {
      op: 'post',
      path: `${path}/@iragarkiform`,
      data: { data: data, iragarki: iragarki },
    },
  };
}
