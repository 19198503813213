export const SET_MATRIKULATU = 'SET_MATRIKULATU';

/**
 * Enroll.
 * @function setMatrikulatu
 * @returns {Object} Enroll.
 */
export function setMatrikulatu(path, data) {
  return {
    type: SET_MATRIKULATU,
    request: {
      op: 'post',
      path: `${path}/@matrikulatu`,
      data: data,
    },
  };
}

export const POST_MATRIKULA_ORDAINDU = 'POST_MATRIKULA_ORDAINDU';

/**
 * postMatrikulaOrdaindu.
 * @function postMatrikulaOrdaindu
 * @returns {Object} postMatrikulaOrdaindu.
 */
export function postMatrikulaOrdaindu(data) {
  return {
    type: POST_MATRIKULA_ORDAINDU,
    request: {
      op: 'post',
      path: `/@matrikula-ordaindu`,
      data: data,
    },
  };
}

export const SEND_TRANSFER_MAIL = 'SEND_TRANSFER_MAIL';

/**
 * sendTransferMail.
 * @function sendTransferMail
 * @returns {Object} sendTransferMail.
 */
export function sendTransferMail(course, prezioa) {
  return {
    type: SEND_TRANSFER_MAIL,
    request: {
      op: 'post',
      path: `${course}/@transfermail`,
      data: { prezioa },
    },
  };
}
