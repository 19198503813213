import { defineMessages } from 'react-intl';
const messages = defineMessages({
  menuSectionTitle: {
    id: 'menuSectionTitle',
    defaultMessage: 'Title',
  },
  menuSectionPhone: {
    id: 'menuSectionPhone',
    defaultMessage: 'Telefonoa',
  },
  menuSectionMail: {
    id: 'menuSectionMail',
    defaultMessage: 'Helbide elektronikoa',
  },
  menuSectionMap: {
    id: 'menuSectionMap',
    defaultMessage: 'Mapako esteka',
  },
});

const Schema = (intl) => {
  return {
    title: 'Menu Section',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'phone', 'mail', 'map'],
      },
    ],
    properties: {
      title: {
        title: intl.formatMessage(messages.menuSectionTitle),
        type: 'string',
      },
      phone: {
        title: intl.formatMessage(messages.menuSectionPhone),
        type: 'string',
      },
      mail: {
        title: intl.formatMessage(messages.menuSectionMail),
        type: 'string',
      },
      map: {
        title: intl.formatMessage(messages.menuSectionMap),
        type: 'string',
      },
    },
    required: [],
  };
};

export default Schema;
