import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Container, Header, Segment, Divider, Grid } from 'semantic-ui-react';

import { getUser } from '@plone/volto/actions/users/users';
import { getContent } from '@plone/volto/actions/content/content';

import Login from '@plone/volto/components/theme/Login/Login';
import NotFound from '@plone/volto/components/theme/NotFound/NotFound';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';

import {
  postMatrikulaOrdaindu,
  getOrdainduDaiteke,
  getMyCourses,
} from 'volto-ueu/actions';
import { PaymentModes } from 'volto-ueu/components';
import { PostForm } from 'volto-ueu/components/PostForm/PostForm';
import { IKASTAROA_PAY_ROUTE } from 'volto-ueu/constants';
import { FORM_STATE_ENROLL } from 'volto-ueu/reducers/forms/forms';
import { sendTransferMail } from '../../../actions';
import { PAYMENT_MODE_TRANSFERENCE } from '../../../constants';
import {
  isEntryInTime,
  enrolled,
  enrollStatus,
  enrollPrice,
  getEnrolledCourse,
} from '../CourseView/utils';

import jwtDecode from 'jwt-decode';

const PayEnrollView = (props) => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userSession.token);
  const content = useSelector((state) => state.content);
  const users = useSelector((state) => state.users);
  const transferMail = useSelector((state) => state.sendTransferMail);
  const matrikula_form = useSelector((state) => state.forms);
  const matrikulaOrdaindu = useSelector((state) => state.matrikulaOrdaindu);
  const payment = useSelector((state) => state.payment);
  const myCourses = useSelector((state) => state.myCourses);
  const transferMail_ref = useRef(transferMail);
  const re = new RegExp(`${IKASTAROA_PAY_ROUTE}$`);
  const ikastaroa_path = props.location.pathname.replace(re, '');
  const userId = token ? jwtDecode(token).sub : '';

  useEffect(() => {
    dispatch(getContent(ikastaroa_path));
    dispatch(getOrdainduDaiteke(ikastaroa_path));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!myCourses.error && !myCourses.loaded && !myCourses.loading) {
      dispatch(getMyCourses());
    }
  }, [dispatch, myCourses]);

  useEffect(() => {
    dispatch(getUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (transferMail_ref.current.loading && transferMail.loaded) {
      toast.success(
        <Toast
          autoClose={3000}
          title="Mezua bidali da"
          content="Mezua bidali zaizu zure posta elektronikora transferentzia egiteko jarraibideekin."
        />,
      );
    }
    transferMail_ref.current = transferMail;
  }, [dispatch, transferMail]);

  const payEnroll = (mode, step) => {
    // const current_step = steps.filter((s) => s.type === 'ordainketa');
    if (mode && mode !== PAYMENT_MODE_TRANSFERENCE) {
      const enrolledCourse = getEnrolledCourse(
        content?.data?.pk,
        myCourses?.courses,
      );
      dispatch(
        postMatrikulaOrdaindu({
          id: enrolledCourse.matrikula_id,
          payment_mode: mode,
        }),
      );
    } else if (mode === PAYMENT_MODE_TRANSFERENCE) {
      const enrolledCourse = getEnrolledCourse(
        content?.data?.pk,
        myCourses?.courses,
      );
      dispatch(sendTransferMail(ikastaroa_path, enrolledCourse?.prezioa));
    }
  };
  return content.data && content.data['@type'] !== 'Course' ? (
    <NotFound></NotFound>
  ) : content.get.loading || transferMail.loading ? (
    <Container>
      {' '}
      <Segment loading></Segment>
    </Container>
  ) : (
    <Container className="view-wrapper">
      {matrikulaOrdaindu.loaded &&
        matrikulaOrdaindu?.response?.registration_id && (
          <PostForm
            action={matrikulaOrdaindu.response.payment_params?.url}
            payment_params={matrikulaOrdaindu.response.payment_params?.data}
          />
        )}
      {content.data && (
        <>
          {enrolled(content?.data?.pk, myCourses?.courses) &&
          enrollStatus(content?.data?.pk, myCourses?.courses) !== 'Onartuta' ? (
            <>
              <Header as="h1" className="no-breadcrumb">
                Ordaindu matrikula
              </Header>
              <Helmet title={'Ordaindu matrikula'} />
              <Segment basic padded="very">
                <p>
                  Ikastaro honetan matrikulatuta zaude. Zure matrikularen egoera{' '}
                  <strong>
                    "{enrollStatus(content?.data?.pk, myCourses?.courses)}"
                  </strong>{' '}
                  da
                </p>
              </Segment>
            </>
          ) : (
            <>
              <Header as="h1" className="no-breadcrumb">
                Izena emanda zaude
              </Header>
              <Helmet title={'Izena emanda zaude'} />
            </>
          )}
          <Header as="h2">{content.data?.title}</Header>
          <Divider />
        </>
      )}
      {!userId ? (
        <>
          <Grid columns={2} centered>
            <Grid.Column width={6} className="login-description-container">
              <h3 className="ui header matrikulatu-login">
                Orri hau ikusteko login egin behar duzu.
              </h3>
              <Login />
            </Grid.Column>
          </Grid>
        </>
      ) : (
        content.data &&
        isEntryInTime(content.data) &&
        users.get.loaded && (
          <>
            <Container className="izen-emate-formularioa-container">
              {payment.loaded &&
              enrollStatus(content?.data?.pk, myCourses?.courses) !==
                'Onartuta' &&
              !(transferMail_ref.current.loading && transferMail.loaded) ? (
                <>
                  <h2>
                    {payment.result.can_pay &&
                    (payment.result.paypal ||
                      payment.result.creditcard ||
                      payment.result.banktransfer)
                      ? 'Ordainketa burutzeko aukera hauek dituzu:'
                      : payment.result.can_pay
                        ? 'Bukatu matrikulazio prozesua:'
                        : 'Itxaron zerrendan sartzeko aukera:'}
                  </h2>
                  <h3>
                    Ordaindu beharreko prezioa{' '}
                    <b>
                      {enrollPrice(content?.data?.pk, myCourses?.courses)}{' '}
                      eurokoa
                    </b>{' '}
                    da
                  </h3>
                  <PaymentModes
                    payment={payment}
                    form_status={matrikula_form[FORM_STATE_ENROLL]?.form_status}
                    submit_state={matrikulaOrdaindu}
                    submit={payEnroll}
                    // disable={['banktransfer']}
                    has_to_pay={true}
                    mode="payment"
                  />
                </>
              ) : (
                <Segment>
                  <h2>Mezua bidali da!</h2>
                  <p>
                    Mezua bidali zaizu zure posta elektronikora transferentzia
                    egiteko jarraibideekin.
                  </p>
                </Segment>
              )}
            </Container>
          </>
        )
      )}
    </Container>
  );
};

export default PayEnrollView;
