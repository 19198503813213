/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container, Popup } from 'semantic-ui-react';

import Icon from '@plone/volto/components/theme/Icon/Icon';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { hasBlocksData } from '@plone/volto/helpers/Blocks/Blocks';
import shareSVG from '@plone/volto/icons/share.svg';
import SocialSharing from '@codesyntax/volto-social-sharing/SocialSharing';

import { Picture } from 'volto-ueu/components';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import CourseKnowledgeFieldsCard from 'volto-ueu/components/Views/CourseView/CourseKnowledgeFieldsCard';
import { sharePositions, socialElements } from '../../../helpers/share';

import moment from 'moment';
import PropTypes from 'prop-types';
import { formatLL } from '../../utils';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content, location }) => {
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);
  const [currentUrl, setCurrentUrl] = React.useState('');
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);
  // MOBILE checker
  const [isMobile, setIsMobile] = React.useState(false);

  function handleWindowSizeChange() {
    setIsMobile(window?.innerWidth <= 768);
  }
  useEffect(() => {
    handleWindowSizeChange();
    window && window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window && window.removeEventListener('resize', handleWindowSizeChange);
    };
  });
  // end - MOBILE checker

  return (
    <Container className="view-wrapper">
      <Grid>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={10}>
          {content.title && (
            <h1 className="documentFirstHeading">
              {content.title}
              {content.subtitle && ` - ${content.subtitle}`}
            </h1>
          )}
          {content?.effective !== null && (
            <p className="newsitem-date">{formatLL(content?.effective)}</p>
          )}
          {content.image && (
            <figure>
              <Picture
                source="newsitem"
                imageBase={`${content['@id']}/@@images/image`}
                alt={content.title}
                content={content}
                lazy={false}
              ></Picture>
              {content.image_caption && (
                <figcaption>{content.image_caption}</figcaption>
              )}
            </figure>
          )}
          {content.sarrera && (
            <StringToHTML
              className="documentDescription"
              string={content.sarrera.data}
            />
          )}
          {content.imported_data && content.description ? (
            <StringToHTML
              className="documentDescription"
              string={content.description}
            />
          ) : (
            <p className="documentDescription">{content.description}</p>
          )}
          {content.text && <StringToHTML string={content.text.data} />}
          {hasBlocksData(content) &&
          content.blocks_layout?.items?.length > 0 ? (
            <RenderBlocks content={content} />
          ) : (
            ''
          )}
          {content.taxonomy_unesco_categorization && (
            <CourseKnowledgeFieldsCard content={content} linked={true} />
          )}
        </Grid.Column>
        <Grid.Column width={1} className="share-col">
          {!isMobile && (
            <Popup
              content={
                <ul className="share">
                  {socialElements
                    .filter((se) => !['tg', 'wa'].includes(se.id))
                    .map((se, key) => {
                      return (
                        <li key={key}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                              se.id !== 'mail'
                                ? `${se.sharing_url}${currentUrl}`
                                : `${se.sharing_url}[UEU] ${content.title}&body=${currentUrl}`
                            }
                          >
                            {se.name}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              }
              on="click"
              popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
              position="bottom right"
              trigger={
                <Icon
                  name={shareSVG}
                  size="30px"
                  style={{ cursor: 'pointer' }}
                />
              }
            />
          )}
          <SocialSharing
            location={location}
            bannerPosition={sharePositions}
            socialElements={socialElements}
          />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
