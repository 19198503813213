import React from 'react';
import { Container, Segment } from 'semantic-ui-react';

import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import { default as VideoBody } from '@plone/volto/components/manage/Blocks/Video/Body';

const VideoView = ({ content, location }) => {
  const data = {
    preview_image: content.image_field ? content['@id'] : null,
    url: content.video_url,
  };

  return (
    <Container className="view-wrapper">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.text && <StringToHTML string={content.text.data} />}
      <Segment basic>
        <VideoBody data={data} />
      </Segment>
    </Container>
  );
};

export default VideoView;
