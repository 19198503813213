function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

export const getRandomSrc = (uid, folder) => {
  const key = uid.charCodeAt(0) + uid.charCodeAt(1);
  // const images = importAll(
  //   require.context(`./${folder}`, false, /\.(png|jpe?g|svg)$/),
  // );
  let images = {};
  images['users'] = importAll(
    require.context('./users', false, /\.(png|jpe?g|svg)$/),
  );
  images['courses'] = importAll(
    require.context('./courses', false, /\.(png|jpe?g|svg)$/),
  );
  const img_keys = Object.keys(images[folder]);
  const index = parseInt(key % img_keys.length);
  return images[folder][img_keys[index]];
};
