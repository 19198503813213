import { UPDATE_PERSON } from '../../actions';
export const updatePersonReducer = (
  state = {
    error: null,
    loaded: false,
    loading: false,
  },
  action = {},
) => {
  switch (action.type) {
    case `${UPDATE_PERSON}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${UPDATE_PERSON}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
      };
    case `${UPDATE_PERSON}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        message: action.result,
      };
    default:
      return state;
  }
};
