/**
 * add_iragarkia.
 * @module actions/add_iragarkia
 */
export const ADD_IRAGARKIA = 'ADD_IRAGARKIA';

/**
 * Add Iragarkia.
 * @function sendIragarkiForm
 * @returns {Object} Add Iragarkia action.
 */
export function addIragarkia(path, data) {
  return {
    type: ADD_IRAGARKIA,
    request: {
      op: 'post',
      path: `${path}/@addIragarkia`,
      data: { data: data },
    },
  };
}
