import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import { Container, Header } from 'semantic-ui-react';
export const ContentData = ({ h1_text, h2_text, Body }) => {
  return (
    <Container className="view-wrapper">
      <Helmet title={h1_text} />
      <Header as="h1" className="no-breadcrumb">
        {h1_text}
      </Header>
      {h2_text && <Header as="h2">{h2_text}</Header>}
      <Body />
    </Container>
  );
};
