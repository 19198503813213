/**
 * CheckboxWidget component.
 * @module components/manage/Widgets/CheckboxWidget
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import { injectIntl } from 'react-intl';
import FormFieldWrapper from '@plone/volto/components/manage/Widgets/FormFieldWrapper';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';

/**
 * CheckboxWidget component class.
 * @function CheckboxWidget
 * @returns {string} Markup of the component.
 *
 * To use it, in schema properties, declare a field like:
 *
 * ```jsx
 * {
 *  title: "Active",
 *  type: 'boolean',
 * }
 * ```
 */
const CheckboxWidget = (props) => {
  const { id, title, value, onChange, isDisabled, required } = props;
  let attributes = {};
  if (required) {
    attributes.required = true;
    attributes['aria-required'] = 'true';
  }
  return (
    <FormFieldWrapper {...props} columns={1}>
      <div className="wrapper">
        <Checkbox
          id={`field-${id}`}
          name={`field-${id}`}
          checked={value || false}
          disabled={isDisabled}
          onChange={(event, { checked }) => {
            onChange(id, checked);
          }}
          label={<label htmlFor={`field-${id}`}>{title}</label>}
          aria-required={required ? 'true' : 'false'}
          {...attributes}
        />
        {required && (
          <span
            style={{
              display: 'inline-block',
              width: '6px',
              height: '6px',
              marginLeft: '5px',
              backgroundColor: '#E40166',
              borderRadius: '50%',
              content: '',
              marginBottom: '3px',
            }}
          ></span>
        )}
      </div>
      <p className="help">
        <StringToHTML string={props.html_description?.data || ''} />
      </p>
    </FormFieldWrapper>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
CheckboxWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  wrapped: PropTypes.bool,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
CheckboxWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default injectIntl(CheckboxWidget);
