import React from 'react';
import config from '@plone/volto/registry';
import { getPanels } from './utils';

const MenuSectionBlockView = (props) => {
  const { data } = props;
  const variationsConfig = config.blocks.blocksConfig['menuSection'].variations;
  let BodyTemplate = '';
  for (let variation in variationsConfig) {
    if (variationsConfig[variation].id === data?.variation) {
      BodyTemplate = variationsConfig[variation].template;
    }
  }
  const menuItems = getPanels(data.data);

  return (
    <BodyTemplate
      menuItems={menuItems}
      data={data}
      isEditMode={false}
      {...props}
    />
  );
};
export default MenuSectionBlockView;
