import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Segment, List } from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import { usePrevious } from 'volto-ueu/components/utils';
import config from '@plone/volto/registry';
import { getNavigation } from '@plone/volto/actions/navigation/navigation';
import { hasApiExpander } from '@plone/volto/helpers/Utils/Utils';
import { getBaseUrl, flattenToAppURL } from '@plone/volto/helpers/Url/Url';

import cx from 'classnames';

const SubsiteNavigation = ({
  isVisible = false,
  toggleVisibility,
  subsite,
  pahtname,
}) => {
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.users.user);
  // const saskia = useSelector((state) => state.saskia.items);

  // const usermodifications = useSelector((state) => state.usermodifications);
  // useEffect(() => {
  //   dispatch(getDropdownMenuNavitems());
  // }, [dispatch]);
  // const dropdownMenuNavItems = useSelector(
  //   (state) => state.dropdownMenuNavItems?.result,
  // );
  let location = useLocation();
  const prevLocation = usePrevious({ location });
  const token = useSelector((state) => state.userSession.token);
  const { items, loaded } = useSelector((state) => state.navigation);
  const { settings } = config;

  useEffect(() => {
    if (prevLocation && isVisible) {
      toggleVisibility();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    isVisible
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }, [isVisible]);

  useEffect(() => {
    if (!hasApiExpander('navigation', getBaseUrl(subsite?.['@id']))) {
      dispatch(
        getNavigation(flattenToAppURL(subsite?.['@id']), settings.navDepth),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsite, token, settings.navDepth, pahtname]); // dependencies array

  return (
    <div
      className={cx(
        'subsite-header-menu',
        isVisible ? 'menu-opened' : 'menu-closed',
      )}
    >
      <div className="header-navigation-menu">
        {loaded &&
          items.map((group, key) => (
            <div className="header-menu-item" key={key}>
              <Segment basic className="header-menu-segment">
                <div className="title">
                  <UniversalLink href={group.url}>
                    <span className="header-menu-segment-title">
                      {group.title}
                    </span>
                  </UniversalLink>
                </div>
                {group.items?.length > 0 && (
                  <List>
                    {group.items.map((subgroup) => (
                      <List.Item
                        className="header-menu-segment-list-item"
                        key={subgroup.id}
                      >
                        <List.Content>
                          <UniversalLink href={subgroup.url}>
                            {subgroup.title}
                          </UniversalLink>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                )}
              </Segment>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SubsiteNavigation;
