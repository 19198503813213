import React from 'react';
import { List, Segment, Icon, Accordion } from 'semantic-ui-react';
import cx from 'classnames';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  noTitle: {
    id: 'noTitle',
    defaultMessage: 'No title',
  },
});

const UeuMenuSectionView = (props) => {
  const { data, isEditMode, setSelectedItemBlock, menuItems } = props;
  const intl = useIntl();
  const edition = (uid) => {
    return isEditMode
      ? {
          onClick: () => setSelectedItemBlock(uid),
          onKeyDown: () => {
            setSelectedItemBlock(uid);
          },
        }
      : {};
  };
  const [openedAccordion, setOpenedAccordion] = React.useState(0);

  return (
    <Segment basic className={cx(props.className, 'header-menu-segment')}>
      <Accordion className="mobile tablet only">
        <Accordion.Title onClick={() => setOpenedAccordion(!openedAccordion)}>
          {data.title && (
            <>
              <ConditionalLink
                to={data?.url?.[0]?.['@id'] || ''}
                condition={!isEditMode && data?.url?.[0]?.['@id']}
              >
                <span className="header-menu-segment-title">{data?.title}</span>
              </ConditionalLink>
              {data.icon && <Icon name={data.icon} />}
            </>
          )}
        </Accordion.Title>
        <Accordion.Content active={!!openedAccordion}>
          <List>
            {menuItems &&
              menuItems.map(([uid, item], index) => {
                return (
                  <List.Item
                    key={index}
                    {...edition(uid)}
                    className="header-menu-segment-list-item"
                  >
                    <List.Content>
                      <ConditionalLink
                        to={item?.url?.[0]?.['@id'] || ''}
                        condition={!isEditMode && !!item?.url?.[0]?.['@id']}
                      >
                        {item.title || intl.formatMessage(messages.noTitle)}
                      </ConditionalLink>
                    </List.Content>
                    {item.icon && <List.Icon size="small" name={item.icon} />}
                  </List.Item>
                );
              })}
          </List>
        </Accordion.Content>
      </Accordion>
      <div className="ui computer large screen only">
        {data.title && (
          <>
            <ConditionalLink
              to={data?.url?.[0]?.['@id'] || ''}
              condition={!isEditMode && data?.url?.[0]?.['@id']}
            >
              <span className="header-menu-segment-title">{data?.title}</span>
            </ConditionalLink>
            {data.icon && <Icon name={data.icon} />}
          </>
        )}
        <List>
          {menuItems &&
            menuItems.map(([uid, item], index) => {
              return (
                <List.Item
                  key={index}
                  {...edition(uid)}
                  className="header-menu-segment-list-item"
                >
                  <List.Content>
                    <ConditionalLink
                      to={item?.url?.[0]?.['@id'] || ''}
                      condition={!isEditMode && !!item?.url?.[0]?.['@id']}
                    >
                      {item.title || intl.formatMessage(messages.noTitle)}
                    </ConditionalLink>
                  </List.Content>
                  {item.icon && <List.Icon size="small" name={item.icon} />}
                </List.Item>
              );
            })}
        </List>
      </div>
    </Segment>
  );
};

export default UeuMenuSectionView;
