import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import isBoolean from 'lodash/isBoolean';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
// import { useHistory, Prompt } from 'react-router';
import { Container, Header, Segment, Divider, Grid } from 'semantic-ui-react';
import {
  clearFormValue,
  setFormValue,
  setFormStatus,
  setMatrikulatu,
  getOrdainduDaiteke,
  getMyCourses,
} from 'volto-ueu/actions';
import { FormSteps, Form, PaymentModes } from 'volto-ueu/components';
import { PostForm } from 'volto-ueu/components/PostForm/PostForm';
import { PreviousNaRequest } from 'volto-ueu/components/PreviousNaRequest/PreviousNaRequest';
import { FORM_STATE_ENROLL } from 'volto-ueu/reducers/forms/forms';

import { getContent } from '@plone/volto/actions/content/content';
import { getUser } from '@plone/volto/actions/users/users';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import Login from '@plone/volto/components/theme/Login/Login';
import NotFound from '@plone/volto/components/theme/NotFound/NotFound';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import lockSVG from '@plone/volto/icons/lock.svg';
import { step1_schema, step2_schema, step4_schema } from './schemas';
import { isEntryInTime, enrolled, enrollStatus } from '../CourseView/utils';

import {
  getPricesSchema,
  getMatrikulaPrice,
  getExtraSchema,
  showExtraStep,
  hasToPay,
} from './utils';

// import { slugify } from 'volto-ueu/components/utils';
import { IKASTAROA_FORM_ROUTE, IKASTAROA_PAY_ROUTE } from 'volto-ueu/constants';

const CourseEnrollView = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const token = useSelector((state) => state.userSession.token);
  const content = useSelector((state) => state.content);
  // const check_nan = useSelector((state) => state.checkNan);
  // const user = useSelector((state) => state.users.user);
  const users = useSelector((state) => state.users);
  const matrikula_form = useSelector((state) => state.forms);
  const matrikulatu = useSelector((state) => state.matrikulatu);
  const payment = useSelector((state) => state.payment);
  const myCourses = useSelector((state) => state.myCourses);
  const re = new RegExp(`${IKASTAROA_FORM_ROUTE}$`);
  const ikastaroa_path = props.location.pathname.replace(re, '');
  const [parsedExtraSchema, setParsedExtraSchema] = useState({});
  const userId = token ? jwtDecode(token).sub : '';
  const steps = [
    { id: 1, schema: step1_schema },
    { id: 2, schema: step2_schema },
    ...(Object.keys(parsedExtraSchema).length !== 0
      ? [
          {
            id: 3,
            schema: () => {
              return parsedExtraSchema;
            },
          },
          { id: 4, schema: step4_schema },
          { id: 5, type: 'ordainketa' },
        ]
      : [
          { id: 3, schema: step4_schema },
          { id: 4, type: 'ordainketa' },
        ]),
  ];

  useEffect(() => {
    dispatch(getContent(ikastaroa_path));
    if (userId) {
      dispatch(getMyCourses());
      dispatch(getOrdainduDaiteke(ikastaroa_path));
    }
    dispatch(clearFormValue(FORM_STATE_ENROLL));
    // LEIHOA FRESKATZEA EKIDITEKO, ORAINGOZ EZ DUGU AKTIBATUKO

    // window.onbeforeunload = (event) => {
    //   const e = event || window.event;
    //   // Cancel the event
    //   e.preventDefault();
    //   if (e) {
    //     e.returnValue = '';
    //   }
    //   return '';
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!myCourses.loaded && !myCourses.loading && userId) {
      dispatch(getMyCourses());
    }
    if (!payment.loaded && !payment.loading) {
      dispatch(getOrdainduDaiteke(ikastaroa_path));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, myCourses.loaded, token]);

  useEffect(() => {
    if (content.data) {
      let extra_schema = getPricesSchema(content?.data);
      if (content?.data?.session_data) {
        try {
          getExtraSchema(content.data.session_data, extra_schema);
        } catch (error) {
          extra_schema = getPricesSchema(content.data);
        }
      }
      showExtraStep(content.data) && setParsedExtraSchema(extra_schema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.data]);

  useEffect(() => {
    dispatch(getUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (!matrikulatu.loaded) {
      if (matrikula_form[FORM_STATE_ENROLL].form_status === 'completed') {
        dispatch(
          setMatrikulatu(ikastaroa_path, matrikula_form[FORM_STATE_ENROLL]),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, matrikula_form]);

  const payEnroll = (mode, step) => {
    const current_step = steps.filter((s) => s.type === 'ordainketa');
    dispatch(
      setFormValue(FORM_STATE_ENROLL, {
        payment_mode: mode,
      }),
    );
    dispatch(setFormStatus(FORM_STATE_ENROLL, step + 1));
    if (current_step.length > 0) {
      if (current_step[0].id === steps[steps.length - 1].id) {
        dispatch(setFormStatus(FORM_STATE_ENROLL, 'completed'));
      }
    }
  };

  const stepSubmitHandler = (formData, step) => {
    const current_step = steps.filter((s) => s.id === step);
    if (
      steps.filter(
        (s) =>
          s.id === matrikula_form[FORM_STATE_ENROLL].form_status + 1 &&
          s.type === 'ordainketa',
      ).length > 0
    ) {
      dispatch(getMyCourses());
    }
    let form_values = {};
    if (current_step.length > 0) {
      current_step[0].schema(content).fieldsets[0].fields.forEach((field) => {
        form_values[field] =
          formData[field] ||
          (formData[field] !== undefined && isBoolean(formData[field]))
            ? formData[field]
            : null;
        if (
          (current_step[0].schema(content).properties[field].type ===
            'boolean' ||
            current_step[0].schema(content).properties[field].type ===
              'required_boolean') &&
          !form_values[field]
        ) {
          form_values[field] = false;
        }
      });
      if (current_step[0].id === 3 && steps.length === 5) {
        form_values['course_extra_form_keys'] = Object.keys(form_values);
      }
      dispatch(
        setFormValue(FORM_STATE_ENROLL, {
          ...form_values,
          form_status: step + 1,
        }),
      );
    }
    dispatch(setFormStatus(FORM_STATE_ENROLL, step + 1));
    if (current_step.length > 0) {
      if (current_step[0].id === steps[steps.length - 1].id) {
        dispatch(setFormStatus(FORM_STATE_ENROLL, 'completed'));
      }
    }
  };

  // const handleBlockedNavigation = (nextLocation, action) => {
  //   if (action === 'POP') {
  //     if (allowBack) {
  //       return true;
  //     }
  //     if (!nextLocation.pathname.includes(IKASTAROA_FORM_ROUTE)) {
  //       inputEl.current.click();
  //     }
  //     return false;
  //   }
  //   return true;
  // };
  const general_price = content?.data?.general_price;
  const member_price = content?.data?.member_price;
  const default_matrikula =
    general_price === 0
      ? `general_price#${general_price}`
      : !users?.user?.bazkide_id
        ? `general_price#${general_price}`
        : `member_price#${member_price}`;

  return content.data && content.data['@type'] !== 'Course' ? (
    <NotFound></NotFound>
  ) : content.get.loading ? (
    <Container>
      {' '}
      <Segment loading></Segment>
    </Container>
  ) : (
    <Container className="view-wrapper">
      {matrikulatu.loaded && matrikulatu?.matrikulatu?.registration_id && (
        <PostForm
          action={matrikulatu.matrikulatu.payment_params?.url}
          payment_params={matrikulatu.matrikulatu.payment_params?.data}
        />
      )}
      {/* <Prompt when={true} message={handleBlockedNavigation} /> */}
      {/* <UeuModal
        header="Matrikulazio prozesutik irten nahi duzu?"
        closeAction={{
          closeText: 'Ez, formularioa betetzen jarraituko dut',
          closeOnClick: () => {
            setallowBack(false);
            history.go(1);
          },
        }}
        okAction={{
          okText: 'Bai, irten nahi dut',
          okOnClick: () => {
            setallowBack(true);
            // history.go(0);
            history.replace(
              history.location?.pathname?.replace(
                RegExp(`${IKASTAROA_FORM_ROUTE}$`),
                '',
              ),
            );
          },
        }}
        trigger={() => {
          return <div ref={inputEl}></div>;
        }}
      >
        <p>Matrikulazio prozesutik irtetera zoaz.</p>
        <p>
          Kontu izan, orain arte bete dituzun datuak galduko direla matrikulazio
          prozesutik irteten bazara.
        </p>
        <p>
          <strong>Ziur zaude matrikulazio prozesutik irten nahi duzula?</strong>
        </p>
      </UeuModal> */}
      {content.data &&
        steps.filter(
          (s) => s.id === matrikula_form[FORM_STATE_ENROLL].form_status,
        ) && (
          <>
            {enrolled(content?.data?.pk, myCourses?.courses) ? (
              <>
                <Header as="h1" className="no-breadcrumb">
                  Izena emanda zaude
                </Header>
                <Segment basic padded="very">
                  <p>
                    Ikastaro honetan matrikulatuta zaude. Zure matrikularen
                    egoera{' '}
                    <strong>
                      "{enrollStatus(content?.data?.pk, myCourses?.courses)}"
                    </strong>{' '}
                    da
                  </p>
                  {!enrollStatus(content?.data?.pk, myCourses?.courses) ===
                    'Onartuta' && (
                    <UniversalLink
                      className="ui primary button ueu"
                      href={`${content?.data['@id']}${IKASTAROA_PAY_ROUTE}`}
                    >
                      <FormattedMessage
                        id="Ordaindu matrikula"
                        defaultMessage="Ordaindu matrikula"
                      />
                    </UniversalLink>
                  )}
                </Segment>
              </>
            ) : (
              <Header as="h1" className="no-breadcrumb">
                {!isEntryInTime(content.data) && (
                  <Icon name={lockSVG} size="30px" />
                )}
                Izen ematea {!isEntryInTime(content.data) && 'ITXITA DAGO'}
              </Header>
            )}
            <Header as="h2">{content.data?.title}</Header>
            <Helmet title={'Izen ematea'} />
            <Divider />
          </>
        )}
      {!userId ? (
        <>
          <Grid columns={2} centered>
            <Grid.Column width={6} className="login-description-container">
              <h3 className="ui header matrikulatu-login">
                Ikastaroan matrikulatzeko saioa hasi behar duzu.
              </h3>
              <Login />
            </Grid.Column>
          </Grid>
        </>
      ) : users.user.nan &&
        content.data &&
        isEntryInTime(content.data) &&
        !enrolled(content?.data?.pk, myCourses?.courses) &&
        (myCourses.error || myCourses.loaded) &&
        users.get.loaded ? (
        <>
          <FormSteps
            steps={steps}
            process={matrikula_form[FORM_STATE_ENROLL]}
            form_state={FORM_STATE_ENROLL}
            setStep={setFormStatus}
          />
          <Container className="izen-emate-formularioa-container">
            {steps.filter(
              (s) =>
                s.id === matrikula_form[FORM_STATE_ENROLL].form_status &&
                s.type !== 'ordainketa',
            ).length > 0 ? (
              steps.filter(
                (s) => s.id === matrikula_form[FORM_STATE_ENROLL].form_status,
              ).length > 0 &&
              users.get.loaded &&
              users.user?.email && (
                <Form
                  title={
                    steps
                      .filter(
                        (s) =>
                          s.id ===
                          matrikula_form[FORM_STATE_ENROLL].form_status,
                      )[0]
                      .schema(content).title
                  }
                  formData={{
                    ...users.user,
                    ...matrikula_form[FORM_STATE_ENROLL],
                    ...{ matrikula_mota: default_matrikula },
                  }}
                  schema={
                    !users.user
                      ? {}
                      : steps
                          .filter(
                            (s) =>
                              s.id ===
                              matrikula_form[FORM_STATE_ENROLL].form_status,
                          )[0]
                          .schema(content, users.user)
                  }
                  onSubmit={(event) => {
                    stepSubmitHandler(
                      event,
                      matrikula_form[FORM_STATE_ENROLL].form_status,
                    );
                    const formItem = document.getElementById('form-steps');
                    if (formItem !== null) {
                      const formItemPosition = formItem.getBoundingClientRect();
                      formItemPosition !== null &&
                        window.scrollTo({
                          top: formItemPosition.x,
                          left: formItemPosition.y,
                          behavior: 'smooth',
                        });
                    }
                  }}
                  isLast={
                    steps.findIndex(
                      (s) =>
                        s.id === matrikula_form[FORM_STATE_ENROLL].form_status,
                    ) ===
                    steps.length - 1
                  }
                  isFirst={
                    steps.findIndex(
                      (s) =>
                        s.id === matrikula_form[FORM_STATE_ENROLL].form_status,
                    ) === 0
                  }
                  step={matrikula_form[FORM_STATE_ENROLL].form_status}
                  intl={intl}
                />
              )
            ) : (
              <>
                {!matrikulatu.loaded &&
                  !matrikulatu.error &&
                  payment.loaded && (
                    <>
                      <h2>
                        {/* payment.result.can_pay &&
                         (payment.result.paypal ||
                           payment.result.creditcard ||
                           payment.result.banktransfer) */}
                        {hasToPay(
                          payment.result,
                          matrikula_form[FORM_STATE_ENROLL],
                          content?.data,
                        )
                          ? 'Ordainketa burutzeko aukera hauek dituzu:'
                          : payment.result.can_pay
                            ? 'Bukatu matrikulazio prozesua:'
                            : 'Itxaron zerrendan sartzeko aukera:'}
                      </h2>
                      <h3>
                        Ordaindu beharreko prezioa{' '}
                        <b>
                          {getMatrikulaPrice(matrikula_form, content)} eurokoa
                        </b>{' '}
                        da
                      </h3>
                      <PaymentModes
                        payment={payment}
                        form_status={
                          matrikula_form[FORM_STATE_ENROLL].form_status
                        }
                        has_to_pay={hasToPay(
                          payment.result,
                          matrikula_form[FORM_STATE_ENROLL],
                          content?.data,
                        )}
                        submit_state={matrikulatu}
                        submit={payEnroll}
                      />
                    </>
                  )}
                {matrikulatu.error && (
                  <>
                    <Segment placeholder>
                      <h2 className="text-centered">Errore bat gertatu da:</h2>
                      <h3 className="text-centered mezua">
                        {matrikulatu.message}
                      </h3>
                    </Segment>
                  </>
                )}
                {matrikulatu.loaded && <Segment loading></Segment>}
              </>
            )}
          </Container>
        </>
      ) : (
        !users.user.nan &&
        content.data &&
        isEntryInTime(content.data) &&
        !enrolled(content?.data?.pk, myCourses?.courses) &&
        (myCourses.error || myCourses.loaded) &&
        users.get.loaded && <PreviousNaRequest />
      )}
    </Container>
  );
};

export default CourseEnrollView;
