export const step1_schema = (content) => {
  return {
    title: 'Datu pertsonalak',
    type: 'object',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'izena',
          'lehen_abizena',
          'bigarren_abizena',
          'jaiotze_data',
          'nan',
          'sexua',
          'telefonoa1',
          'telefonoa2',
          'helbidea',
          'herria',
          'kode_postala',
          'herrialdea',
        ],
      },
    ],
    properties: {
      izena: {
        title: 'Izena',
        type: 'string',
        maxLength: 200,
      },
      lehen_abizena: {
        title: 'Lehen abizena',
        type: 'string',
        maxLength: 200,
      },
      bigarren_abizena: {
        title: 'Bigarren abizena',
        type: 'string',
        maxLength: 200,
      },
      jaiotze_data: {
        title: 'Jaiotze data',
        widget: 'date',
      },
      nan: {
        title: 'Na',
        description:
          'NA zenbakia: nortasun agiri zenbakia, pasaportea, segurantza soziala...',
        type: 'string',
        maxLength: 20,
      },
      sexua: {
        title: 'Generoa/Sexua',
        vocabulary: { '@id': 'ueu.addon.GenderVocabulary' },
      },
      telefonoa1: {
        title: 'Telefonoa 1',
        type: 'string',
        minLength: 9,
        maxLength: 150,
      },
      telefonoa2: {
        title: 'Telefonoa 2',
        type: 'string',
        maxLength: 50,
      },
      helbidea: {
        title: 'Helbidea',
        type: 'string',
        maxLength: 200,
      },
      herria: {
        title: 'Herria',
        type: 'string',
        maxLength: 30,
      },
      kode_postala: {
        title: 'Posta kodea',
        type: 'string',
        minLength: 5,
        maxLength: 8,
      },
      herrialdea: {
        title: 'Herrialdea',
        vocabulary: { '@id': 'ueu.addon.KudeuProvincesVocabulary' },
      },
    },
    required: [
      'izena',
      'lehen_abizena',
      'bigarren_abizena',
      'nan',
      'telefonoa1',
      'jaiotze_data',
      'sexua',
      'helbidea',
      'herria',
      'kode_postala',
      'herrialdea',
    ],
  };
};
