/**
 * Register container.
 * @module components/theme/Register/Register
 */

import React, { Component } from 'react';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import Form from '@plone/volto/components/manage/Form/Form';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import { createUser } from '@plone/volto/actions/users/users';

const messages = defineMessages({
  title: {
    id: 'Registration form',
    defaultMessage: 'Registration form',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  izenaTitle: {
    id: 'Izena',
    defaultMessage: 'Izena',
  },
  izenaDescription: {
    id: 'Idatzi zure izena',
    defaultMessage: 'Idatzi zure izena',
  },
  abizenakTitle: {
    id: 'Abizenak',
    defaultMessage: 'Abizenak',
  },
  abizenakDescription: {
    id: 'Idatzi zure Abizenak.',
    defaultMessage: 'Idatzi zure Abizenak.',
  },
  emailTitle: {
    id: 'E-mail',
    defaultMessage: 'E-mail',
  },
  emailDescription: {
    id: 'Enter an email address. This will be your login name. We respect your privacy, and will not give the address away to any third parties or expose it anywhere.',
    defaultMessage:
      'Enter an email address. This will be your login name. We respect your privacy, and will not give the address away to any third parties or expose it anywhere.',
  },
  successRegisterCompletedTitle: {
    id: 'Account Registration Completed',
    defaultMessage: 'Account Registration Completed',
  },
  successRegisterCompletedBody: {
    id: 'The registration process has been successful. Please check your e-mail inbox for information on how activate your account.',
    defaultMessage:
      'The registration process has been successful. Please check your e-mail inbox for information on how activate your account.',
  },
  register: {
    id: 'Register',
    defaultMessage: 'Register',
  },
  existing_email: {
    id: 'existing_email',
    defaultMessage:
      'Sartu duzun helbide elektronikoarekin iada existitzen da erabiltzaile bat webgunean. Eskatu pasahitz berria edo erabili beste helbide bat.',
  },
  wrong_email: {
    id: 'wrong_email',
    defaultMessage: 'Baliozko helbide elektroniko bat idatzi mesedez.',
  },
});

const getErrorMessage = (error, intl) => {
  let error_msg = error?.response?.body?.error
    ? error?.response?.body?.error
    : error?.response?.body
      ? error?.response?.body
      : error;
  if (
    intl &&
    error_msg?.message ===
      'The login name you selected is already in use or is not valid. Please choose another.'
  ) {
    error_msg.message = intl.formatMessage(messages.existing_email);
  }

  if (intl && error_msg?.message === 'You must enter a valid email address.') {
    error_msg.message = intl.formatMessage(messages.wrong_email);
  }

  return error_msg;
};

/**
 * Register class.
 * @class Register
 * @extends Component
 */
class Register extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      error: null,
    };
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loading && nextProps.loaded) {
      toast.success(
        <Toast
          success
          title={this.props.intl.formatMessage(
            messages.successRegisterCompletedTitle,
          )}
          content={this.props.intl.formatMessage(
            messages.successRegisterCompletedBody,
          )}
        />,
      );
      this.props.history.push('/login');
    }
  }

  /**
   * On submit handler
   * @method onSubmit
   * @param {object} data Form data.
   * @returns {undefined}
   */
  onSubmit(data) {
    this.props.createUser({
      fullname: `${data.izena} ${data.lehen_abizena}`,
      izena: data.izena,
      lehen_abizena: data.lehen_abizena,
      // bigarren_abizena: data.bigarren_abizena,
      // jaiotze_data: data.jaiotze_data,
      // sexua: data.sexua,
      email: data.email,
      // telefonoa1: data.telefonoa1,
      // telefonoa2: data.telefonoa2,
      // nan: data.nan,
      password: data.password,
    });
    this.setState({
      error: null,
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div id="page-register">
        <Helmet title={this.props.intl.formatMessage(messages.register)} />
        <Form
          onSubmit={this.onSubmit}
          title={this.props.intl.formatMessage(messages.title)}
          error={getErrorMessage(
            this.state.error || this.props.error,
            this.props.intl,
          )}
          loading={this.props.loading}
          submitLabel={this.props.intl.formatMessage(messages.register)}
          schema={{
            fieldsets: [
              {
                id: 'default',
                title: this.props.intl.formatMessage(messages.default),
                fields: [
                  'izena',
                  'lehen_abizena',
                  // 'bigarren_abizena',
                  // 'jaiotze_data',
                  // 'sexua',
                  'email',
                  // 'telefonoa1',
                  // 'telefonoa2',
                  // 'nan',
                ],
              },
            ],
            properties: {
              izena: {
                title: 'Izena',
                type: 'string',
              },
              lehen_abizena: {
                title: 'Lehen abizena',
                type: 'string',
              },
              // bigarren_abizena: {
              //   title: 'Bigarren abizena',
              //   type: 'string',
              // },
              // jaiotze_data: {
              //   title: 'Jaiotze data',
              //   type: 'react_datepicker',
              // },
              // sexua: {
              //   title: 'Generoa/Sexua',
              //   vocabulary: { '@id': 'ueu.addon.GenderVocabulary' },
              // },
              email: {
                type: 'string',
                title: this.props.intl.formatMessage(messages.emailTitle),
                description: this.props.intl.formatMessage(
                  messages.emailDescription,
                ),
              },
              // telefonoa1: {
              //   title: 'Telefonoa 1',
              //   type: 'string',
              //   minLength: 9,
              // },
              // telefonoa2: {
              //   title: 'Telefonoa 2',
              //   type: 'string',
              // },
              // nan: {
              //   title: 'NA',
              //   type: 'string',
              // },
            },
            required: [
              'izena',
              'lehen_abizena',
              // 'bigarren_abizena',
              'email',
              // 'sexua',
              // 'nan',
            ],
          }}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state) => ({
      loading: state.users.create.loading,
      loaded: state.users.create.loaded,
      error: state.users.create.error,
    }),
    { createUser },
  ),
)(Register);
