import React from 'react';
import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';
import config from '@plone/volto/registry';

import Button from './Button';
import { ButtonSchema } from './ButtonSchema';

const ButtonBlockEdit = (props) => {
  const { block, data, onChangeBlock, selected, intl } = props;
  return (
    <>
      <Button variation={data?.variation} isEditMode={true} {...props}>
        {data.title || 'Botoia'}
      </Button>

      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={ButtonSchema(config, intl)}
          title="Button block"
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
          block={block}
        />
      </SidebarPortal>
    </>
  );
};

export default ButtonBlockEdit;
