import {
  SET_FORM_STATE,
  SET_FORM_VALUE,
  CLEAR_FORM_VALUE,
} from '../../actions';

export const FORM_STATE_BUY = 'FORM_STATE_BUY';
export const FORM_STATE_ENROLL = 'FORM_STATE_ENROLL';
const initialState = {
  [FORM_STATE_BUY]: { form_status: 1 },
  [FORM_STATE_ENROLL]: { form_status: 1 },
};

export const formsReducer = (state = initialState, action = {}) => {
  switch (action?.type) {
    case SET_FORM_VALUE:
      return {
        ...state,
        [action.key]: { ...state[action.key], ...action.value },
      };
    case CLEAR_FORM_VALUE:
      return {
        ...state,
        [action.key]: { form_status: 1 },
      };
    case SET_FORM_STATE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...{ form_status: action.form_status },
        },
      };
    default:
      return state;
  }
};
