import React from 'react';
import { Button } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import { compose } from 'redux';
import Icon from '@plone/volto/components/theme/Icon/Icon';
// import {
//   Option,
//   DropdownIndicator,
// } from '@plone/volto/components/manage/Widgets/SelectStyling';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
// import {
//   selectTheme,
//   sortOnSelectStyles,
// } from '@plone/volto/components/manage/Blocks/Search/components/SelectStyling';

import upSVG from '@plone/volto/icons/sort-up.svg';
import downSVG from '@plone/volto/icons/sort-down.svg';

const messages = defineMessages({
  noSelection: {
    id: 'No selection',
    defaultMessage: 'No selection',
  },
  sortOn: {
    id: 'Sort on',
    defaultMessage: 'Sort on',
  },
  ascending: {
    id: 'Ascending',
    defaultMessage: 'Ascending',
  },
  descending: {
    id: 'Descending',
    defaultMessage: 'Descending',
  },
});

const SortOn = (props) => {
  const {
    data = {},
    // reactSelect,
    sortOn = null,
    sortOrder = null,
    setSortOn,
    setSortOrder,
    isEditMode,
    querystring = {},
    intl,
  } = props;
  const { sortable_indexes } = querystring;
  // const Select = reactSelect.default;

  // const activeSortOn = sortOn || data?.query?.sort_on || '';

  const { sortOnOptions = [] } = data;
  // const value = {
  //   value: activeSortOn || intl.formatMessage(messages.noSelection),
  //   label:
  //     activeSortOn && sortable_indexes
  //       ? sortable_indexes[activeSortOn]?.title
  //       : activeSortOn || intl.formatMessage(messages.noSelection),
  // };

  return (
    <div className="search-sort-wrapper">
      <div className="search-sort-on">
        {data.sortOnLabel && (
          <span className="sort-label">{data.sortOnLabel}</span>
        )}
        {/* <Select
          id="select-search-sort-on"
          name="select-searchblock-sort-on"
          className="search-react-select-container"
          classNamePrefix="react-select"
          placeholder={intl.formatMessage(messages.sortOn)}
          styles={sortOnSelectStyles}
          theme={selectTheme}
          components={{ DropdownIndicator, Option }}
          options={[
            ...sortOnOptions.map((k) => ({
              value: k,
              label: sortable_indexes[k]?.title || k,
            })),
          ]}
          value={value}
          onChange={(data) => {
            !isEditMode && setSortOn(data.value);
          }}
        /> */}
        <div className="sort-order-wrapper">
          {sortOrder === 'ascending' && (
            <Button
              icon
              basic
              compact
              title={intl.formatMessage(messages.ascending)}
              onClick={() => {
                !isEditMode && setSortOrder('descending');
              }}
            >
              <Icon name={upSVG} size="25px" />
            </Button>
          )}
          {sortOrder === 'descending' && (
            <Button
              icon
              basic
              compact
              title={intl.formatMessage(messages.descending)}
              onClick={() => {
                !isEditMode && setSortOrder('ascending');
              }}
            >
              <Icon name={downSVG} size="25px" />
            </Button>
          )}
          Ordenatu emaitzak:
        </div>
        <div className="sort-options-wrapper">
          {sortOnOptions.length > 0 &&
            sortOnOptions.map((option, index) => {
              return (
                <Button
                  basic
                  className={cx(
                    'sort-option link',
                    option === sortOn && 'active',
                  )}
                  onClick={() => setSortOn(option)}
                  key={index}
                >
                  {sortable_indexes[option]?.title}
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default compose(injectIntl, injectLazyLibs(['reactSelect']))(SortOn);
