export const GET_ORDAINDU_DAITEKE = 'GET_ORDAINDU_DAITEKE';

/**
 * Can I pay the Enrollment for this course?.
 * @function getOrdainduDaiteke
 * @returns {Boolean} can I pay the enrollment?.
 */
export function getOrdainduDaiteke(path) {
  return {
    type: GET_ORDAINDU_DAITEKE,
    request: {
      op: 'get',
      path: `${path}/@ordaindudaiteke`,
    },
  };
}
