import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Grid, List, Segment } from 'semantic-ui-react';
import { get_taxonomy_children } from 'volto-ueu/helpers/taxonomy_utils';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import config from '@plone/volto/registry';

import jwtDecode from 'jwt-decode';

const GeneralInfoCard = ({
  content,
  chapters,
  authors,
  taxonomy_unesco_categorization,
}) => {
  const user = useSelector((state) => state.users.user);
  const token = useSelector((state) => state.userSession.token);
  const querystring = useSelector((state) => state.querystring);
  const index = querystring?.indexes?.taxonomy_unesco_categorization || {};
  const { values = {} } = index;
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const { settings } = config;
  return (
    <>
      <Segment className="generalinfo-card">
        <Grid>
          <Grid.Column>
            <List bulleted>
              {authors && (
                <List.Item>
                  <strong>{'Autorea(k): '}</strong>
                  {authors.map((author, key) => {
                    return (
                      <React.Fragment key={key}>
                        {key !== 0 ? ', ' : ''}
                        {author['@id'] ? (
                          <UniversalLink href={author['@id']}>
                            {author.title}
                          </UniversalLink>
                        ) : (
                          <>{author}</>
                        )}
                      </React.Fragment>
                    );
                  })}
                </List.Item>
              )}
              {taxonomy_unesco_categorization &&
                taxonomy_unesco_categorization.length > 0 && (
                  <List.Item>
                    <strong>{'Jakintza-arloak: '}</strong>
                    {taxonomy_unesco_categorization
                      .filter((cat) =>
                        values[cat]?.title
                          ? values[cat]?.title.includes(' » ')
                          : false,
                      )
                      .map((cat) =>
                        get_taxonomy_children(values[cat]?.title || ''),
                      )
                      .join(', ')}
                  </List.Item>
                )}
              {content?.isbn && (
                <List.Item>
                  <strong>{'ISSN: '}</strong>
                  {content.isbn}
                </List.Item>
              )}
              <List.Item>
                <strong>{'Argitalpen urtea: '}</strong>
                {content?.publication_year && `${content.publication_year}`}
              </List.Item>
              <List.Item>
                <strong>{'Orrialdeak: '}</strong>
                {content?.pages && `${content.pages}`}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </Segment>
      {!user.uztaro_id && chapters.some((ch) => !ch.downloadable_file) && (
        <Segment color="black">
          <p>
            Aldizkari honetako artikulu guztiak deskargatzeko, bertako
            harpideduna izan behar zara.
          </p>
          {!isLoggedIn && (
            <>
              <p>
                Horrez gain, artikuluak deskargatzeko webgunean login egin behar
                da:
              </p>
              <UniversalLink
                aria-label="login"
                className="ui button ueu primary"
                href={`/login${
                  content?.['@id']
                    ? `?return_url=${content['@id'].replace(
                        settings.apiPath,
                        '',
                      )}`
                    : ''
                }`}
              >
                <FormattedMessage id="Log in" defaultMessage="Log in" />
              </UniversalLink>
            </>
          )}
        </Segment>
      )}
      {!isLoggedIn &&
        chapters.some((ch) => ch.downloadable_file) &&
        !chapters.some((ch) => !ch.downloadable_file) && (
          <Segment color="black">
            <p>
              Aldizkari honetako artikuluak deskargatzeko webgunean login egin
              behar da:
            </p>
            <UniversalLink
              aria-label="login"
              className="ui button ueu primary"
              href={`/login${
                content?.['@id']
                  ? `?return_url=${content['@id'].replace(
                      settings.apiPath,
                      '',
                    )}`
                  : ''
              }`}
            >
              <FormattedMessage id="Log in" defaultMessage="Log in" />
            </UniversalLink>
          </Segment>
        )}
    </>
  );
};

export default GeneralInfoCard;
