import React from 'react';
import { Segment } from 'semantic-ui-react';
import cx from 'classnames';

import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

import { getTeaserImageURL } from './utils';
import { Picture } from 'volto-ueu/components';

const HizlariTeaserView = (props) => {
  const { data, isEditMode } = props;
  const { preview_image } = data;
  const image = preview_image?.[0];
  const href = data.href?.[0];
  const hrefId = href && href.hasOwnProperty('@id') ? href['@id'] : '';
  return (
    <Segment basic className={cx(props.className, 'hizlari-teaser')}>
      <ConditionalLink condition={hrefId && !isEditMode && image} href={hrefId}>
        {image && (
          <Picture
            imageBase={flattenToAppURL(getTeaserImageURL('', image))}
            alt={data?.image_alt}
            width="100%"
            source="grid"
            className="hizlari-image"
          />
        )}

        {data.title && <h3 className="hizlari-title">{data?.title}</h3>}
      </ConditionalLink>
    </Segment>
  );
};

export default HizlariTeaserView;
