import { GET_CHAPTERS } from 'volto-ueu/actions';
const initialState = {
  error: null,
  loaded: false,
  loading: false,
  chapters: [],
};

/**
 * Chapters reducer.
 * @function chapters
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const getChaptersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_CHAPTERS}_PENDING`:
      return {
        ...state,
        loaded: false,
        loading: true,
        chapters: [],
      };
    case `${GET_CHAPTERS}_SUCCESS`:
      return {
        ...state,
        loaded: true,
        loading: false,
        chapters: action.result.items,
      };
    case `${GET_CHAPTERS}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
        chapters: [],
      };
    default:
      return state;
  }
};
