import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Header, Divider } from 'semantic-ui-react';

import Login from '@plone/volto/components/theme/Login/Login';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';

import {
  setFormValue,
  setFormStatus,
  postErosketa,
  getSaskiaPrezioa,
  getOrdainduDaiteke,
} from 'volto-ueu/actions';
import { FORM_STATE_BUY } from 'volto-ueu/reducers/forms/forms';
import { Form, FormSteps, PaymentModes } from 'volto-ueu/components';
import { useUeuSaskia } from 'volto-ueu/components/saskia';
import { step1_schema, step3_schema } from './schemas';
import { EROSI_ROUTE } from 'volto-ueu/constants';
import { PostForm } from 'volto-ueu/components/PostForm/PostForm';
import { PreviousNaRequest } from 'volto-ueu/components/PreviousNaRequest/PreviousNaRequest';

import jwtDecode from 'jwt-decode';

const ErosiView = (props) => {
  const token = useSelector((state) => state.userSession.token);
  const users = useSelector((state) => state.users);
  const saskia = useSelector((state) => state.saskia.items);
  const erosketa_form = useSelector((state) => state.forms);
  const erosketak = useSelector((state) => state.erosketak);
  const payment = useSelector((state) => state.payment);
  const [updatedSaskia, setUpdatedSaskia] = useState(false);
  const [showPayments, setshowPayments] = useState(true);
  const userId = token ? jwtDecode(token).sub : '';
  const dispatch = useDispatch();
  const re = new RegExp(`${EROSI_ROUTE}$`);
  const root_path = props.location.pathname.replace(re, '');
  const steps = [
    { id: 1, schema: step1_schema },
    { id: 2, schema: step3_schema },
    { id: 3, type: 'ordainketa' },
  ];
  const { clearSaskia } = useUeuSaskia();
  let eroksetak_post_ref = useRef(erosketak.post);

  useEffect(() => {
    if (
      saskia?.content &&
      Object.keys(saskia?.content).length > 0 &&
      !updatedSaskia
    ) {
      dispatch(getSaskiaPrezioa(saskia));
      setUpdatedSaskia(true);
    }
    if (
      eroksetak_post_ref.current.loading &&
      erosketak?.post?.loaded &&
      Object.keys(saskia).length !== 0
    ) {
      clearSaskia();
      setshowPayments(false);
    }
    eroksetak_post_ref.current = erosketak.post;
  }, [dispatch, clearSaskia, erosketak, saskia, updatedSaskia]);

  useEffect(() => {
    dispatch(getOrdainduDaiteke(root_path));
    dispatch(setFormStatus(FORM_STATE_BUY, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!erosketak.loaded) {
      if (erosketa_form[FORM_STATE_BUY]?.form_status === 'completed') {
        dispatch(postErosketa(saskia, erosketa_form[FORM_STATE_BUY]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, erosketa_form]);

  const pay = (mode, step) => {
    const current_step = steps.filter((s) => s.type === 'ordainketa');
    dispatch(
      setFormValue(FORM_STATE_BUY, {
        payment_mode: mode,
      }),
    );
    dispatch(setFormStatus(FORM_STATE_BUY, step + 1));
    if (current_step.length > 0) {
      if (current_step[0].id === steps[steps.length - 1].id) {
        dispatch(setFormStatus(FORM_STATE_BUY, 'completed'));
      }
    }
  };

  const stepSubmitHandler = (formData, step) => {
    const current_step = steps.filter((s) => s.id === step);
    let form_values = {};
    if (current_step.length > 0) {
      current_step[0].schema().fieldsets[0].fields.forEach((field) => {
        form_values[field] = formData[field] || null;
        // dispatch(setFieldValue(field, formData[field]));
      });
      dispatch(
        setFormValue(FORM_STATE_BUY, { ...form_values, form_status: step + 1 }),
      );
      // if (current_step[0].id === steps[steps.length - 1].id) {
      //   dispatch(postErosketa(saskia, erosi));
      // }
    }
    dispatch(setFormStatus(FORM_STATE_BUY, step + 1));
    if (current_step.length > 0) {
      if (current_step[0].id === steps[steps.length - 1].id) {
        dispatch(setFormStatus(FORM_STATE_BUY, 'completed'));
      }
    }
  };

  return (
    <Container className="view-wrapper">
      {erosketak?.post?.loaded &&
        erosketak?.post?.result?.registration_id &&
        eroksetak_post_ref.current.loading && (
          <PostForm
            action={erosketak.post.result.payment_params?.url}
            payment_params={erosketak.post.result.payment_params?.data}
          />
        )}
      {steps.filter(
        (s) => s.id === erosketa_form[FORM_STATE_BUY]?.form_status,
      ) ? (
        <>
          <Header as="h1" className="no-breadcrumb">
            Erosi
          </Header>
          <Header as="h2">Saskiaren laburpena:</Header>
          {saskia?.content && Object.keys(saskia.content).length > 0 && (
            <ul className="saskia-laburpena">
              {Object.keys(saskia?.content)?.map((item, key) => (
                <li key={key}>
                  {`${saskia?.content?.[item].title ?? '-'} (${
                    saskia?.content?.[item].format ?? '-'
                  })`}
                </li>
              ))}
            </ul>
          )}
          <Divider />
          <Helmet title={'Erosi'} />
        </>
      ) : (
        <>
          <Header as="h1" className="no-breadcrumb">
            Zure erosketa ondo burutu da!
          </Header>
          <Helmet title={'Zure erosketa ondo burutu da!'} />
        </>
      )}
      {!userId ? (
        <>
          <h3 className="text-centered mezua">
            Erosketa burutu ahal izateko saioa hasi behar duzu
          </h3>
          <Login />
        </>
      ) : (
        <>
          {Object.keys(saskia).length === 0 &&
          erosketa_form[FORM_STATE_BUY]?.form_status === 1 ? (
            <h3 className="text-centered mezua">
              Erosketa burutu ahal izateko saskian zerbait gehitu behar duzu!
            </h3>
          ) : Object.keys(saskia).length !== 0 &&
            !users.user.nan &&
            users.get.loaded ? (
            <PreviousNaRequest />
          ) : (
            <>
              <FormSteps
                steps={steps}
                process={erosketa_form[FORM_STATE_BUY]}
                form_state={FORM_STATE_BUY}
                setStep={setFormStatus}
              />
              <Container className="erosketa-formularioa-container">
                {steps.filter(
                  (s) =>
                    s.id === erosketa_form[FORM_STATE_BUY]?.form_status &&
                    s.type !== 'ordainketa',
                ).length > 0 ? (
                  users.get.loaded && (
                    <Form
                      title={
                        steps
                          .filter(
                            (s) =>
                              s.id ===
                              erosketa_form[FORM_STATE_BUY]?.form_status,
                          )[0]
                          .schema().title
                      }
                      form
                      formData={{ ...users.user }}
                      schema={steps
                        .filter(
                          (s) =>
                            s.id === erosketa_form[FORM_STATE_BUY]?.form_status,
                        )[0]
                        .schema()}
                      onSubmit={(event) =>
                        stepSubmitHandler(
                          event,
                          erosketa_form[FORM_STATE_BUY]?.form_status,
                        )
                      }
                      isLast={
                        steps.findIndex(
                          (s) =>
                            s.id === erosketa_form[FORM_STATE_BUY]?.form_status,
                        ) ===
                        steps.length - 1
                      }
                      isFirst={
                        steps.findIndex(
                          (s) =>
                            s.id === erosketa_form[FORM_STATE_BUY]?.form_status,
                        ) === 0
                      }
                      form_state={FORM_STATE_BUY}
                      step={erosketa_form[FORM_STATE_BUY]?.form_status}
                    />
                  )
                ) : (
                  <>
                    <h2>
                      {payment?.result?.can_pay
                        ? 'Ordainketa burutzeko aukera hauek dituzu:'
                        : 'Momentu honetan ezin da ordainketarik burutu'}
                    </h2>
                    {showPayments && (
                      <PaymentModes
                        payment={payment}
                        form_status={erosketa_form[FORM_STATE_BUY]?.form_status}
                        submit_state={erosketak?.post}
                        submit={pay}
                        disable={[]}
                        has_to_pay={true}
                        mode="shop"
                      />
                    )}
                  </>
                )}
              </Container>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ErosiView;
