/**
 * Relations actions.
 * @module actions/relations/relations
 */

import { LIST_RELATIONS } from '@plone/volto/constants/ActionTypes';

/**
 * Query relations
 * @function queryRelations
 * @param {string} relation Name of relation
 * @param {boolean} onlyBroken
 * @returns {Object} List relations action
 */
export function queryRelations(
  relation = null,
  onlyBroken = false,
  subrequest = null,
  source = null,
  target = null,
  query_source = null,
  query_target = null,
  metadata_fields = [],
) {
  let path = '/@relations';
  var searchParams = new URLSearchParams();
  relation && searchParams.append('relation', relation);
  onlyBroken && searchParams.append('onlyBroken', onlyBroken);
  source && searchParams.append('source', source);
  target && searchParams.append('target', target);
  query_source && searchParams.append('query_source', query_source);
  query_target && searchParams.append('query_target', query_target);
  metadata_fields.forEach((mf) => searchParams.append('metadata_fields', mf));
  const searchParamsToString = searchParams.toString();
  if (searchParamsToString) {
    path += `?${searchParamsToString}`;
  }
  return {
    type: LIST_RELATIONS,
    subrequest,
    request: {
      op: 'get',
      path: path,
    },
  };
}
