import React from 'react';
import { matchPath } from 'react-router';
import config from '@plone/volto/registry';
import { SubsiteLoader } from 'volto-subsites';

const AppExtras = (props) => {
  const { settings } = config;
  const { appExtras = [] } = settings;
  const { pathname } = props;
  const active = appExtras
    .map((reg) => {
      const ignored = matchPath(pathname, reg.ignore);
      if (ignored) return null;
      const match = matchPath(pathname, reg.match);
      return match ? { reg, match } : null;
    })
    .filter((reg) => reg);
  return (
    <>
      <SubsiteLoader pathname={pathname} content={props.content} />
      {active.map(({ reg: { component, props: extraProps }, match }, i) => {
        const Insert = component;
        return (
          <Insert
            key={`appextra-${i}`}
            match={match}
            {...props}
            {...extraProps}
          />
        );
      })}
    </>
  );
};

export default AppExtras;
