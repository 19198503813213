/**
 * Erosi actions.
 */
export const SET_FORM_VALUE = 'SET_FORM_VALUE';
export const CLEAR_FORM_VALUE = 'CLEAR_FORM_VALUE';
export const SET_FORM_STATE = 'SET_FORM_STATE';

/**
 * Set forms value.
 * @function setFormValue
 * @returns {Object} forms value.
 */

export function setFormValue(key, value) {
  return {
    type: SET_FORM_VALUE,
    key: key,
    value: value,
  };
}

/**
 * Clear form value.
 * @function clearFormValue
 */

export function clearFormValue(key) {
  return {
    type: CLEAR_FORM_VALUE,
    key: key,
  };
}

/**
 * Set form status.
 * @function setFormStatus
 * @returns {Object} form status.
 */
export function setFormStatus(key, form_status) {
  return {
    type: SET_FORM_STATE,
    key,
    form_status,
  };
}
