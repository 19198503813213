/**
 * TxiotesiaView view component.
 * @module components/theme/View/TxiotesiaView
 */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container } from 'semantic-ui-react';

import moment from 'moment';
import PropTypes from 'prop-types';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';

/**
 * TxiotesiaView view component class.
 * @function TxiotesiaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const TxiotesiaView = ({ content, location }) => {
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);

  useEffect(() => {
    const twitterscript = document.createElement('script');
    const mastodonscript = document.createElement('script');

    mastodonscript.src = 'https://mastodon.eus/embed.js';
    mastodonscript.async = 'async';

    twitterscript.src = '//platform.twitter.com/widgets.js';
    twitterscript.async = true;

    document.body.appendChild(twitterscript);
    document.body.appendChild(mastodonscript);

    return () => {
      document.body.removeChild(twitterscript);
      document.body.removeChild(mastodonscript);
    };
  }, []);

  return (
    <Container className="view-wrapper">
      <Grid>
        <Grid.Column width={1} />
        <Grid.Column width={10}>
          {content.title && (
            <h1 className="documentFirstHeading">
              {content.title}
              {content.subtitle && ` - ${content.subtitle}`}
            </h1>
          )}
          {content.text && (
            <Grid.Row>
              <StringToHTML string={content.text.data} />
            </Grid.Row>
          )}
          <Grid.Row>
            <strong>Egilea:</strong> {content?.egilea}{' '}
            <a
              href={
                content?.mastodon !== true
                  ? `https://twitter.com/${content?.screenname}`
                  : `https://mastodon.eus/@${content?.screenname}`
              }
            >
              {`@${content?.screenname}`}
            </a>
          </Grid.Row>
          <Grid.Row>
            <strong>Etiketak:</strong>{' '}
            {content?.subjects?.map((subject, i) => (
              <a
                key={i}
                href={`https://www.ueu.eus/txiotesia/txiotesia/tag?id=${subject}`}
              >
                {' '}
                {subject}{' '}
              </a>
            ))}
          </Grid.Row>
          <Grid.Row>
            {content.txiotesiak.split('\n').map((txiotesia) => {
              return content?.mastodon === true ? (
                <iframe
                  title={content.title}
                  src={`${txiotesia}/embed`}
                  class="mastodon-embed txiotesia"
                  //style="max-width: 100%; border: 0"
                  allowfullscreen="allowfullscreen"
                />
              ) : (
                <blockquote class="twitter-tweet txiotesia" lang="eu">
                  <a href={txiotesia.replace('x.com', 'twitter.com')}>
                    Kargatzen...
                  </a>
                </blockquote>
              );
            })}
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={1} />
      </Grid>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TxiotesiaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default TxiotesiaView;
