export const step3_schema = (content, user) => {
  return {
    title: 'Erosketari dagokionez',
    type: 'object',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'faktura_beharra',
          'faktura_erakundea',
          'faktura_helbidea',
          'faktura_herria',
          'faktura_posta_kodea',
          'faktura_herrialdea',
          'faktura_ifz',
          'pribatutasuna',
        ],
      },
    ],
    properties: {
      pribatutasuna: {
        title: 'Baldintza hauek ulertu eta borondatez onartzen ditut',
        html_description: {
          data: 'UDAKO EUSKAL UNIBERTSITATEA zure datu pertsonalen tratamenduaren arduraduna da. Informazioa gehiagorako, irakurri gure <a class="pribatutasun-baldintzak" href="/jarduera-akademikoa/pribatutasun-baldintzak">Pribatutasun baldintzak</a>',
        },
        type: 'required_boolean',
      },
      faktura_beharra: {
        title: 'Faktura egiteko datuak aldatu',
        description:
          'UEUren webgunean ordainketa bat egitean, erregistroan emandako datuekin egiten da automatikoki faktura bat. Faktura aldatzea beharko bazenu, klikatu laukitxoan eta osatu datu berriak.',
        type: 'boolean',
      },
      faktura_erakundea: {
        title: 'Erakundea',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_helbidea: {
        title: 'Helbidea',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_herria: {
        title: 'Herria',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_posta_kodea: {
        title: 'Posta-kodea',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_herrialdea: {
        title: 'Herrialdea',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_ifz: {
        title: 'IFZ',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
    },
    required: ['pribatutasuna'],
  };
};
