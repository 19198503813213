import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

import PropTypes from 'prop-types';

const ViewRedirectBlock = (props) => {
  const { data } = props;
  const remoteUrl = data.remoteUrl;
  const token = useSelector((state) => state.userSession?.token);
  React.useEffect(() => {
    if (!token) {
      window.location.href = remoteUrl;
    }
  }, [token, remoteUrl]);

  return !token ? (
    <p>
      <FormattedMessage id="Kargatzen..." defaultMessage="Kargatzen..." />
    </p>
  ) : (
    <Container>
      {remoteUrl && (
        <p>
          <FormattedMessage
            id="The link address is:"
            defaultMessage="The link address is:"
          />
          <UniversalLink href={remoteUrl}>
            {flattenToAppURL(remoteUrl)}
          </UniversalLink>
        </p>
      )}
    </Container>
  );
};

ViewRedirectBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ViewRedirectBlock;
