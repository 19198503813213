import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Header, Segment } from 'semantic-ui-react';

import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';

import { Form } from 'volto-ueu/components';
import { schema } from './add_schema';
import { ADD_IRAGARKIA_ROUTE } from 'volto-ueu/constants';
import { addIragarkia } from '../../../actions';
import { toast } from 'react-toastify';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

const AddIragarkiaView = (props) => {
  const add_iragarkia = useSelector((state) => state.addiragarkia);
  const dispatch = useDispatch();
  const schema_data = schema();
  const [showForm, setShowForm] = useState(false);
  setTimeout(() => {
    setShowForm(true);
  }, 500);

  const re = new RegExp(`${ADD_IRAGARKIA_ROUTE}$`);
  const root_path = props.location.pathname.replace(re, '');
  let add_iragarkia_ref = useRef(add_iragarkia);
  useEffect(() => {
    if (add_iragarkia.loaded && add_iragarkia_ref.current.loading) {
      toast.success(
        <Toast
          autoClose={3000}
          title={'Ondo joan da!'}
          content="Iragarkia ongi sortu da."
        />,
      );
      window.location.href = root_path;
    } else if (add_iragarkia_ref.current.loading && add_iragarkia.error) {
      toast.error(
        <Toast
          autoClose={3000}
          title={add_iragarkia.response}
          content="Saiatu berriro beranduago."
        />,
      );
    }
    add_iragarkia_ref.current = add_iragarkia;
  }, [add_iragarkia, add_iragarkia.loaded, add_iragarkia.loading, root_path]);

  const submitHandler = (formData, schema_data) => {
    if (
      schema_data.properties.captcha.zuzena.toLowerCase() ===
      formData.captcha.toLowerCase()
    )
      dispatch(addIragarkia(root_path, formData));
    else
      toast.error(
        <Toast
          autoClose={3000}
          title="Captcha ez da zuzena!"
          content="Zuzendu eta saiatu berriro."
        />,
      );
  };

  return (
    <Container className="view-wrapper">
      <Helmet title={'Iragarkia gehitu'} />
      <Header as="h1" className="add-iragarkia-title">
        Iragarkia gehitu
      </Header>
      <p>
        Itzuli{' '}
        <UniversalLink href={root_path}>iragarkien oholera</UniversalLink>
      </p>
      {showForm ? (
        <Form
          title={schema_data.title}
          form
          schema={schema_data}
          onSubmit={(data) => submitHandler(data, schema_data)}
          isLast={false}
          isFirst={true}
          submitLabel={'Bidali iragarkia'}
        />
      ) : (
        <Segment loading></Segment>
      )}
    </Container>
  );
};

export default AddIragarkiaView;
