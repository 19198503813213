import { defineMessages } from 'react-intl';
const messages = defineMessages({
  harpidetzaBazkidetza: {
    id: 'harpidetzaBazkidetza',
    defaultMessage: 'Formulario mota',
  },
});

export const HarpidetzaBazkidetzaSchemaEnhancer = (props) => {
  const { intl, schema } = props;
  return {
    ...schema,
    fieldsets: [
      {
        ...schema.fieldsets[0],
        fields: [...schema.fieldsets[0].fields, 'harpidetzaBazkidetza'],
      },
    ],
    properties: {
      ...schema.properties,
      harpidetzaBazkidetza: {
        title: intl.formatMessage(messages.harpidetzaBazkidetza),
        choices: [
          ['uztaro_id', 'Uztaroko Harpidetza'],
          ['bazkide_id', 'UEUko bazkidetza'],
        ],
      },
    },
  };
};
