import { useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Grid, Header, Segment, Tab } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';
import React from 'react';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import { sendCourseForm } from 'volto-ueu/actions';
import { Form } from 'volto-ueu/components';
// import { isEntryInTime } from './utils';
import { dynamicSort } from 'volto-ueu/components/utils';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
// import { UeuListing } from 'volto-ueu/components/Variations';
import { course_contact_form_schema } from './courseContactFormSchema';

const messages = defineMessages({
  ikastaroariBuruz: {
    id: 'IKASTAROARI BURUZ',
    defaultMessage: 'IKASTAROARI BURUZ',
  },
  jardunaldiariBuruz: {
    id: 'JARDUNALDIARI BURUZ',
    defaultMessage: 'JARDUNALDIARI BURUZ',
  },
  egitaraua: {
    id: 'EGITARAUA',
    defaultMessage: 'EGITARAUA',
  },
  matrikulazioArgibideak: {
    id: 'MATRIKULAZIO ARGIBIDEAK',
    defaultMessage: 'MATRIKULAZIO ARGIBIDEAK',
  },
  oharrak: {
    id: 'OHARRAK',
    defaultMessage: 'OHARRAK',
  },
  babesleak: {
    id: 'BABESLEAK',
    defaultMessage: 'BABESLEAK',
  },
  iradokizunakEdoKontaktua: {
    id: 'IRADOKIZUNAK EDO KONTAKTUA',
    defaultMessage: 'IRADOKIZUNAK EDO KONTAKTUA',
  },
});

const CourseTabsCard = ({ content, dispatch, location }) => {
  const intl = useIntl();
  const courseForm = useSelector((state) => state.courseform);
  let courseForm_ref = useRef(courseForm);
  const path = location.pathname;

  const handleSubmit = (e, course) => {
    dispatch(sendCourseForm(path, e, course));
  };
  useEffect(() => {
    if (courseForm.loaded && courseForm_ref.current.loading) {
      toast.success(
        <Toast
          autoClose={3000}
          title={courseForm.response}
          content="Ahal bezain laster jarriko gara zurekin harremanetan."
        />,
      );
    }
    courseForm_ref.current = courseForm;
  }, [courseForm]);
  const panes = [
    {
      menuItem:
        content.course_type === 'Jardunaldiak'
          ? intl.formatMessage(messages.jardunaldiariBuruz)
          : intl.formatMessage(messages.ikastaroariBuruz),
      render: () => (
        <Tab.Pane className="Tabs">
          {content?.presentation && (
            <>
              <Header as="h2">Aurkezpena</Header>
              <StringToHTML string={content?.presentation} />
            </>
          )}
          {content?.teachers && content?.teachers.length > 0 && (
            <>
              <Header as="h2">Irakasleak</Header>
              {/* <UeuListing
                items={content.teachers}
                howManyColumns={3}
                isEditMode={false}
                showUnconditionally={true}
              /> */}
              <ul>
                {content.teachers
                  .sort(dynamicSort('surname1'))
                  .map((teacher, key) => {
                    return (
                      <li key={key}>
                        {teacher.review_state !== 'published' ? (
                          <>{teacher.title}</>
                        ) : (
                          <UniversalLink href={teacher['@id']}>
                            {teacher.title}
                          </UniversalLink>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </>
          )}
          {content?.objectives && (
            <>
              <Header as="h2">Helburuak</Header>
              <StringToHTML string={content?.objectives} />
            </>
          )}
          {content?.contents && (
            <>
              <Header as="h2">Edukiak</Header>
              <StringToHTML string={content?.contents} />
            </>
          )}
          {content?.metodology && (
            <>
              <Header as="h2">Metodologia</Header>
              <StringToHTML string={content?.metodology} />
            </>
          )}
          {content?.addressed_to && (
            <>
              <Header as="h2">Nori zuzenduta</Header>
              <StringToHTML string={content?.addressed_to} />
            </>
          )}
          {/* {content?.academic_value && (
            <>
              <Header as="h2">Balio Akademikoa</Header>
              <StringToHTML string={content?.academic_value} />
            </>
          )} */}
        </Tab.Pane>
      ),
    },
    ...(!content?.hide_calendar && content.days?.items?.length > 0
      ? [
          {
            menuItem: intl.formatMessage(messages.egitaraua),
            render: () => {
              let egitarauaJSON = {};
              if (content.days && content.days?.items.length > 0) {
                content.days.items.forEach((item) => {
                  if (egitarauaJSON[item.eguna]) {
                    egitarauaJSON[item.eguna].orduak.push({
                      hasiera_ordua: item.hasiera_ordua,
                      bukaera_ordua: item.bukaera_ordua,
                      deskribapena: item.deskribapena,
                      izenburua: item.izenburua,
                      irakaslea: item.irakaslea,
                    });
                  } else {
                    egitarauaJSON[item.eguna] = {
                      orduak: [
                        {
                          hasiera_ordua: item.hasiera_ordua,
                          bukaera_ordua: item.bukaera_ordua,
                          deskribapena: item.deskribapena,
                          izenburua: item.izenburua,
                          irakaslea: item.irakaslea,
                        },
                      ],
                    };
                  }
                });
              }
              return (
                <Tab.Pane className="Tabs">
                  {Object.entries(egitarauaJSON).map((item) => (
                    <React.Fragment key={item}>
                      <Header as="h2">{item[0]}</Header>
                      <Grid className="egitarau-taula" celled>
                        <Grid.Row columns={2}>
                          <Grid.Column width={3}>
                            <strong>
                              <FormattedMessage
                                id="Ordua"
                                defaultMessage="Ordua"
                              />
                            </strong>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <strong>
                              <FormattedMessage
                                id="Edukiak"
                                defaultMessage=" Edukiak"
                              />
                            </strong>
                          </Grid.Column>
                        </Grid.Row>
                        {item[1].orduak.length > 0 &&
                          item[1].orduak.map((ordua) => (
                            <Grid.Row key={ordua}>
                              <Grid.Column width={3}>
                                <StringToHTML
                                  string={`${ordua.hasiera_ordua.slice(
                                    0,
                                    -3,
                                  )} - ${ordua.bukaera_ordua.slice(0, -3)}`}
                                />
                              </Grid.Column>
                              <Grid.Column width={9}>
                                <span className="egitarau-taula-title">
                                  <StringToHTML string={ordua.izenburua} />
                                </span>
                                <StringToHTML string={ordua.deskribapena} />
                              </Grid.Column>
                            </Grid.Row>
                          ))}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Tab.Pane>
              );
            },
          },
        ]
      : [{}]),
    ...(content?.registration_information
      ? [
          {
            menuItem: intl.formatMessage(messages.matrikulazioArgibideak),
            render: () => (
              <Tab.Pane className="Tabs">
                {(content.general_price >= 0 ||
                  content.reduced_price >= 0 ||
                  content.member_price >= 0) && (
                  <Segment compact color="pink">
                    <ul>
                      {content.general_price >= 0 && (
                        <li>
                          <strong>Prezio orokorra: </strong>
                          {` ${content.general_price}€`}
                        </li>
                      )}
                      {content.reduced_price >= 0 && (
                        <li>
                          <strong>Prezio murriztua: </strong>
                          {` ${content.reduced_price}€`}
                        </li>
                      )}
                      {content.member_price >= 0 && (
                        <li>
                          <strong>Bazkideentzat prezioa: </strong>
                          {` ${content.member_price}€`}
                        </li>
                      )}
                    </ul>
                  </Segment>
                )}{' '}
                {content?.registration_information && (
                  <StringToHTML string={content?.registration_information} />
                )}
              </Tab.Pane>
            ),
          },
        ]
      : [{}]),
    ...(content?.supporters_json?.items?.length
      ? [
          {
            menuItem: intl.formatMessage(messages.babesleak),
            render: () => (
              <Tab.Pane className="Tabs">
                <Grid columns={3} stackable doubling>
                  <Grid.Row centered>
                    {content.supporters_json.items.map((supporter) => {
                      return (
                        <Grid.Column key={supporter} verticalAlign="middle">
                          <Segment>
                            {supporter.webgunea ? (
                              <UniversalLink href={supporter.webgunea}>
                                {supporter.logoa && (
                                  <Image
                                    src={`https://kudeu.ueu.eus/media/${supporter.logoa}`}
                                    alt={supporter.izena}
                                  />
                                )}
                                <span>{supporter.izena}</span>
                              </UniversalLink>
                            ) : (
                              <div>
                                {supporter.logoa && (
                                  <Image
                                    src={`https://kudeu.ueu.eus/media/${supporter.logoa}`}
                                    alt={supporter.izena}
                                  />
                                )}
                                <span>{supporter.izena}</span>
                              </div>
                            )}
                          </Segment>
                        </Grid.Column>
                      );
                    })}
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            ),
          },
        ]
      : [{}]),
    ...(content?.notes
      ? [
          {
            menuItem: intl.formatMessage(messages.oharrak),
            render: () => (
              <Tab.Pane className="Tabs">
                {content?.notes && <StringToHTML string={content?.notes} />}
              </Tab.Pane>
            ),
          },
        ]
      : [{}]),
    // ...(isEntryInTime(content)
    //   ? [
    {
      menuItem: intl.formatMessage(messages.iradokizunakEdoKontaktua),
      render: () => (
        <Tab.Pane className="Tabs">
          <Segment basic>
            <Header as="h2">
              <FormattedMessage
                id="ikastaro_contact_form_title"
                defaultMessage="Kontaktu formularioa"
              />
            </Header>
            <p>
              <FormattedMessage
                id="ikastaro_contact_form_description"
                defaultMessage="Ikastaroaren gaineko zalantzarik baduzu edo iradokizunik egin nahi baduzu, bete ondoko
                    formularioa eta jarriko gara zurekin harremanetan."
              />
            </p>
            <Form
              schema={course_contact_form_schema}
              onSubmit={(e) => handleSubmit(e, content)}
              isFirst={true}
              submitLabel="Bidali"
              resetAfterSubmit
            ></Form>
          </Segment>
        </Tab.Pane>
      ),
    },
    //] : [{}]),
  ];
  return (
    <>
      <Tab className="ikastaro-panelak" panes={panes} />
    </>
  );
};

export default CourseTabsCard;
