import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Header, Button, Icon } from 'semantic-ui-react';

import {
  // selectFacetSchemaEnhancer,
  selectFacetStateToValue,
  selectFacetValueToQuery,
} from '@plone/volto/components/manage/Blocks/Search/components/base';
import { selectOrderFacetSchemaEnhancer } from './facetEnhancers';
import { SearchInput } from '@plone/volto/components/manage/Blocks/Search/components';
import { getValuesForFacet, getFacetValueLengths } from './utils';
import { useMobileChecker } from '../../../helpers/useMobileChecker';

/**
 * A facet that uses radio/checkboxes to provide an explicit list of values for
 * filtering
 */
const CheckboxWithNumberFacet = (props) => {
  const { facet, choices, isMulti, onChange, value, isEditMode } = props;
  const adaptedBaseQuery = useSelector(
    (state) => state.querystringsearch?.subrequests?.adaptedBaseQuery,
  );
  const isMobile = useMobileChecker();
  const [openFacet, setopenFacet] = useState(false);

  const facetValue = value;
  const [result, setresult] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [disabled, setdisabled] = useState([]);
  const [searchedText, setSearchedText] = useState('');
  const [searchedChoices, setSearchedChoices] = useState(choices);
  React.useEffect(() => {
    if (adaptedBaseQuery?.loaded) {
      const tmp_result = getValuesForFacet(facet.field.value, adaptedBaseQuery);
      setresult(tmp_result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adaptedBaseQuery]);

  React.useEffect(() => {
    setdisabled(
      result.length > 0
        ? choices
            .filter((choice) => !result.includes(choice.value))
            .map((choice) => choice.value)
        : choices.map((choice) => choice.value),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);
  const facetNumbers = getFacetValueLengths(
    adaptedBaseQuery?.items,
    facet?.field,
  );
  const filteredChoices = !facetNumbers
    ? facet.reverse
      ? choices.filter((ch) => !disabled.includes(ch.value)).reverse()
      : choices.filter((ch) => !disabled.includes(ch.value))
    : facet.reverse
      ? choices.reverse()
      : choices;
  const n = showMore ? choices.length : 5;
  const base = 10;
  return (
    <div className="checkbox-facet">
      <Header
        as="h4"
        onClick={() => {
          setopenFacet(!openFacet);
        }}
      >
        {facet.title ?? facet?.field?.label}
        {openFacet && isMobile ? (
          <span className="mobile only">
            <Icon name="minus" />
          </span>
        ) : (
          <span className="mobile only">
            <Icon name="plus" />
          </span>
        )}
      </Header>
      {!facet.searchable ? (
        filteredChoices.length > 0 ? (
          (!isMobile || openFacet) && (
            <div className="entries">
              {filteredChoices.slice(0, n).map(({ label, value }, i) => (
                <div className="entry" key={value}>
                  {(facetNumbers && facetNumbers[label.value]) ||
                  !Object.keys(facetNumbers).length ? (
                    <Checkbox
                      disabled={isEditMode}
                      label={
                        facetNumbers[label.value]
                          ? `${label} (${facetNumbers[value] ?? '0'})`
                          : `${label}`
                      }
                      radio={!isMulti}
                      checked={
                        isMulti
                          ? !!facetValue?.find((f) => f.value === value)
                          : facetValue && facetValue.value === value
                      }
                      onChange={(e, { checked }) =>
                        onChange(
                          facet.field.value,
                          isMulti
                            ? [
                                ...facetValue
                                  .filter((f) => f.value !== value)
                                  .map((f) => f.value),
                                ...(checked ? [value] : []),
                              ]
                            : checked
                              ? value
                              : null,
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ))}
              {!showMore && n < filteredChoices.length && (
                <Button className="ueu" onClick={() => setShowMore(true)}>
                  Ikusi gehiago...
                </Button>
              )}
            </div>
          )
        ) : (
          <div>Ez dago aukerarik...</div>
        )
      ) : (
        (!isMobile || openFacet) && (
          <>
            <div className="search-wrapper">
              <SearchInput
                id={props.id}
                data={{ searchInputPrompt: '' }}
                searchText={searchedText}
                setSearchText={setSearchedText}
                isLive={true}
                onTriggerSearch={(value) => {
                  const newChoices = choices.filter(
                    (ch) =>
                      ch.label.toLowerCase().indexOf(value.toLowerCase()) !==
                      -1,
                  );
                  setSearchedChoices([...newChoices]);
                }}
              />
            </div>
            {searchedChoices.length > 0 ? (
              <div className="entries">
                {searchedChoices.slice(0, base).map(({ label, value }, i) => (
                  <React.Fragment key={i}>
                    <div className="entry" key={value}>
                      <Checkbox
                        disabled={isEditMode}
                        label={
                          facetNumbers[label.value]
                            ? `${label} (${facetNumbers[value] ?? '0'})`
                            : `${label}`
                        }
                        radio={!isMulti}
                        checked={
                          isMulti
                            ? !!facetValue?.find((f) => f.value === value)
                            : facetValue && facetValue.value === value
                        }
                        onChange={(e, { checked }) =>
                          onChange(
                            facet.field.value,
                            isMulti
                              ? [
                                  ...facetValue
                                    .filter((f) => f.value !== value)
                                    .map((f) => f.value),
                                  ...(checked ? [value] : []),
                                ]
                              : checked
                                ? value
                                : null,
                          )
                        }
                      />
                    </div>
                  </React.Fragment>
                ))}
                {searchedChoices.length - base > 0 && (
                  <strong>
                    eta beste {searchedChoices.length - base} gehiago...
                  </strong>
                )}
              </div>
            ) : (
              <strong>Ez da aurkitu...</strong>
            )}
          </>
        )
      )}
    </div>
  );
};

CheckboxWithNumberFacet.schemaEnhancer = selectOrderFacetSchemaEnhancer;
CheckboxWithNumberFacet.stateToValue = selectFacetStateToValue;
CheckboxWithNumberFacet.valueToQuery = selectFacetValueToQuery;

export default CheckboxWithNumberFacet;
