/**
 * Extra breadcrumb items reducer.
 * @module reducers/getExtraBreadcrumbReducer
 */

import { GET_EXTRA_BREADCRUMB_ITEMS } from 'volto-ueu/actions';

const initialState = {
  items: [],
};

export const getExtraBreadcrumbReducer = (
  state = initialState,
  action = {},
) => {
  switch (action?.type) {
    case `${GET_EXTRA_BREADCRUMB_ITEMS}`:
      return {
        ...state,
        items: action.items,
      };
    default:
      return state;
  }
};
