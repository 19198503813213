import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Header, Icon, Container, Accordion } from 'semantic-ui-react';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { getBooks, getBooksByAuthors } from 'volto-ueu/actions';
import { UeuLoader } from 'volto-ueu/components';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import { UeuListing } from 'volto-ueu/components/Variations';
import BookCard from './BookCard';
// import ArtikuluaCard from './ArtikuluCard';
import GeneralInfoCard from './GeneralInfoCard';
import config from '@plone/volto/registry';
import { default as VoltoIcon } from '@plone/volto/components/theme/Icon/Icon';

const BookView = ({ content }) => {
  const dispatch = useDispatch();
  const authorsUIDs = content.authors ? content.authors.map((a) => a.UID) : [];
  React.useEffect(() => {
    const all_authors = authorsUIDs.concat(content.other_authors);
    dispatch(
      getBooks(
        4,
        content.taxonomy_unesco_categorization
          ? content.taxonomy_unesco_categorization.map((t_cat) => {
              return t_cat.token;
            })
          : [],
      ),
    );
    dispatch(getBooksByAuthors(all_authors, 5));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, content.UID]);
  const books = useSelector((state) => state.books.books);
  const booksByAuthor = useSelector((state) => state.books.books_by_authors);
  const accordionConfig = config.blocks.blocksConfig.accordion;
  const { titleIcons } = accordionConfig;

  const [openedAccordion, setOpenedAccordion] = React.useState(false);

  return (
    <Container className="view-wrapper">
      <Grid>
        <Grid.Column width={11}>
          <Header as="h1">{content.title}</Header>
        </Grid.Column>
        <Grid.Column width={1} textAlign="right">
          <Icon name="share alternate" />
        </Grid.Column>
      </Grid>
      <Grid stackable className="course-card">
        <Grid.Row>
          <Grid.Column width={8}>
            <GeneralInfoCard content={content} />
            {content?.text?.data && (
              <StringToHTML
                className="documentDescription"
                string={content?.text?.data}
              />
            )}
            {content?.index?.data && content?.index?.data !== '<p></p>' && (
              <div className="accordion-block">
                <Accordion fluid styled className="book-accordion">
                  <Accordion.Title
                    onClick={() => setOpenedAccordion(!openedAccordion)}
                    className=" title accordion-title align-arrow-right"
                    active={openedAccordion}
                  >
                    <VoltoIcon
                      name={
                        openedAccordion
                          ? titleIcons.opened.rightPosition
                          : titleIcons.closed.rightPosition
                      }
                      size={titleIcons.size}
                    />
                    {content.index && <span>Aurkibidea</span>}
                  </Accordion.Title>
                  <Accordion.Content
                    className="book-accordion-content"
                    active={openedAccordion}
                  >
                    <StringToHTML
                      className="documentDescription"
                      string={content?.index?.data}
                    />
                  </Accordion.Content>
                </Accordion>
              </div>
            )}
            {content.file_sample && (
              <div className="chapter-download-button-container">
                <UniversalLink
                  href={`${content.file_sample.download}`}
                  className="ui button ueu"
                >
                  {'Deskargatu kapitulu bat'}
                  <Icon name="chevron down" />
                </UniversalLink>
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            <BookCard content={content} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {books.loaded &&
        books.data.filter((book) => book.UID !== content.UID).length > 0 && (
          <UeuListing
            header="Jakintza arlo bereko liburu gehiago"
            items={books.data.filter((book) => book.UID !== content.UID)}
            howManyColumns={4}
            CardComponent={'Book'}
            isEditMode={false}
            showUnconditionally={true}
          />
        )}
      {books.loading && <UeuLoader />}
      {booksByAuthor.loaded &&
        booksByAuthor.data.filter((book) => book.UID !== content.UID).length >
          0 && (
          <UeuListing
            header="Egileen liburu gehiago"
            items={booksByAuthor.data.filter(
              (book) => book.UID !== content.UID,
            )}
            howManyColumns={4}
            CardComponent={'Book'}
            isEditMode={false}
            showUnconditionally={true}
          />
        )}
    </Container>
  );
};

export default BookView;
