import { Label, Segment, Icon } from 'semantic-ui-react';
import MaybeWrap from '@plone/volto/components/manage/MaybeWrap/MaybeWrap';
import { useDispatch } from 'react-redux';

const StepsComponent = ({ steps, process, setStep, form_state }) => {
  const dispatch = useDispatch();
  return (
    <>
      {steps.filter((step) => step.id === process?.form_status).length !==
        0 && (
        <Segment basic className="erosketa-steps-container" id="form-steps">
          {steps
            .map((step, key) => (
              <MaybeWrap
                as="button"
                condition={process?.form_status > step.id}
                className="ui button basic"
                onClick={() => dispatch(setStep(form_state, step.id))}
                key={key}
              >
                <Label
                  circular
                  basic={process?.form_status !== step.id}
                  color={process?.form_status < step.id ? 'grey' : 'black'}
                  size="massive"
                  key={key}
                >
                  {step.id}
                </Label>
              </MaybeWrap>
            ))
            .reduce(
              (acc, x) =>
                acc === null
                  ? [x]
                  : [
                      acc,
                      <Icon key={x} size="big" name="arrow right"></Icon>,
                      x,
                    ],
              null,
            )}
        </Segment>
      )}
    </>
  );
};

export default StepsComponent;
