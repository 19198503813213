import React from 'react';
import { Grid } from 'semantic-ui-react';

import { default as VideoBody } from '@plone/volto/components/manage/Blocks/Video/Body';

const VideoCard = ({ item, isEditMode, showDescription = true }) => {
  const data = {
    preview_image: item.image_field ? item['@id'] : null,
    url: item.video_url,
  };
  return (
    <Grid textAlign="center">
      <Grid.Column width={16}>
        <Grid.Row>
          <VideoBody data={data} />
        </Grid.Row>
        <Grid.Row>
          <h3>{item.title}</h3>
        </Grid.Row>
        {showDescription && item.description && (
          <Grid.Row>
            <p>{item.description}</p>
          </Grid.Row>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default VideoCard;
