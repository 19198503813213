import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { Picture } from 'volto-ueu/components';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import moment from 'moment';

const ElkarrizketaCard = ({ item, isEditMode, showDate }) => {
  return (
    <Grid textAlign="center" className="elkarrizketa-card">
      <Grid.Column width={16}>
        <Grid.Row>
          <ConditionalLink item={item} condition={!isEditMode}>
            {!item.image_field && (
              <Image
                circular
                src={DefaultImageSVG}
                alt="This content has no image, this is a default placeholder."
                size="small"
              />
            )}
            {item?.image_field && (
              <Picture
                className="circular"
                imageBase={`${item['@id']}/@@images/${item.image_field}`}
                alt={item.title}
              ></Picture>
            )}
          </ConditionalLink>
        </Grid.Row>
        <Grid.Row>
          <h4>Elkarrizketa</h4>
        </Grid.Row>
        <Grid.Row>
          {showDate &&
            item?.effective !== null &&
            moment(item?.effective).format('L')}
        </Grid.Row>
        <Grid.Row>
          <h3>
            <ConditionalLink item={item} condition={!isEditMode}>
              {item.title}
            </ConditionalLink>
          </h3>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

export default ElkarrizketaCard;
