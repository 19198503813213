import { cloneDeep } from 'lodash';

import { TeaserSchema } from '@plone/volto/components/manage/Blocks/Teaser/schema';
import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';
import { SelectFacetFilterListEntry } from '@plone/volto/components/manage/Blocks/Search/components';
import boldSVG from '@plone/volto/icons/bold.svg';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import bulletList from '@plone/volto/icons/list-bullet.svg';
import linkIcon from '@plone/volto/icons/link.svg';
import iconC from '@eeacms/volto-columns-block/ColumnsBlock/icons/one-third-right.svg';
import { COLUMNSBLOCK } from '@eeacms/volto-columns-block/constants';
import DefaultEdit from '@eeacms/volto-tabs-block/components/variations/default/Edit';
import { blockSchema } from '@eeacms/volto-tabs-block/components/schema';
import TabsEdit from '@eeacms/volto-tabs-block/components/Edit';
import TabsView from '@eeacms/volto-tabs-block/components/View';
import layoutSchema from '@eeacms/volto-tabs-block/components/layoutSchema';
import { TABS_BLOCK } from '@eeacms/volto-tabs-block/constants';
import gridSVG from '@kitconcept/volto-blocks-grid/icons/grid.svg';

import ButtonBlockView from './components/Blocks/ButtonBlock/ButtonBlockView';
import ButtonBlockEdit from './components/Blocks/ButtonBlock/ButtonBlockEdit';
import { ButtonSchema } from './components/Blocks/ButtonBlock/ButtonSchema';
import FooterResidenceBlockView from './components/Blocks/FooterResidenceBlock/FooterResidenceBlockView';
import FooterResidenceBlockEdit from './components/Blocks/FooterResidenceBlock/FooterResidenceBlockEdit';
import MenuSectionBlockView from './components/Blocks/MenuSectionBlock/MenuSectionBlockView';
import MenuSectionBlockEdit from './components/Blocks/MenuSectionBlock/MenuSectionBlockEdit';
import { HarpidetzaBazkidetzaSchemaEnhancer } from './components/Variations/FormBlock/HarpidetzaBazkidetzaSchemaEnhancer';
import customIdFieldSchema from './components/Variations/FormBlock/customIdFieldSchema';
import GridTemplates from './components/Blocks/Grid/templates';
import { UeuMenuSectionView } from './components/Blocks/MenuSectionBlock/Variations';
import EditRedirectBlock from './components/Blocks/RedirectBlock/Edit';
import ViewRedirectBlock from './components/Blocks/RedirectBlock/View';
import ScheduleEdit from './components/Blocks/Schedule/Edit';
import ScheduleView from './components/Blocks/Schedule/View';
import { removeDefaultFields, removeFields } from './components/utils';
import CarouselView from './components/Variations/TabsBlock/CarouselView';

import UeuListing from './components/Variations/ListingBlock/UeuListing';
import AgendaListing from './components/Variations/ListingBlock/AgendaListing';
import TxiotesiListing from './components/Variations/ListingBlock/TxiotesiListing';
import { UeuListingSchema } from './components/Variations/ListingBlock/UeuListingSchema';
import LeftColumnUeuFacets from './components/Variations/SearchBlock/LeftColumnUeuFacets';
import CheckboxWithNumberFacet from './components/Variations/SearchBlock/CheckboxWithNumberFacet';
import CheckboxTreeParentFacet from './components/Variations/SearchBlock/CheckboxTreeParentFacet';
import { ButtonTextSchemaEnhancer } from './components/Variations/TeaserBlock/ButtonTextSchemaEnhancer';
import BannerTeaserView from './components/Variations/TeaserBlock/BannerTeaserView';
import TextAndLinkTeaserView from './components/Variations/TeaserBlock/TextAndLinkTeaserView';
import WorkGroupTeaserView from './components/Variations/TeaserBlock/WorkGroupTeaserView';
import AldizkariTeaserView from './components/Variations/TeaserBlock/AldizkariTeaserView';
import CarouselTeaserView from './components/Variations/TeaserBlock/CarouselTeaserView';
import IkergazteHomeTeaserView from './components/Variations/TeaserBlock/IkergazteHomeTeaserView';
import HizlariTeaserView from './components/Variations/TeaserBlock/HizlariTeaserView';
import DateTeaserView from './components/Variations/TeaserBlock/DateTeaserView';
import { DateTeaserSchemaEnhancer } from './components/Variations/TeaserBlock/DateTeaserSchemaEnhancer';
import CountupBlockView from './components/Variations/CountupBlock/CountupBlockView';
import { WorkgroupSchemaEnhancer } from './components/Variations/TeaserBlock/WorkgroupSchemaEnhancer';
import { BannerTeaserSchemaEnhancer } from './components/Variations/TeaserBlock/BannerSchemaEnhancer';
import { ImageSchemaEnhancer } from './components/Variations/TeaserBlock/ImageSchemaEnhancer';
import { TitleSchemaEnhancer } from './components/Variations/TeaserBlock/TitleSchemaEnhancer';
import { DescriptionSchemaEnhancer } from './components/Variations/TeaserBlock/DescriptionSchemaEnhancer';

import TaxonomyFacetFilterListEntry from './components/Variations/SearchBlock/TaxonomyFacetFilterListEntry';
import { rewriteOptions } from './components/Variations/SearchBlock/utils';
// import { StyleSchema } from './stylingSchemas';
import { installHomeTitlePlugin, installStyledSlate } from './editor/plugins';
import { composeSchema } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import isEmpty from 'lodash/isEmpty';

const applyBlocks = (config, blocksConfig) => {
  let restrictedBlocksTree = {};
  let mostUsedBlocksTree = {};

  blocksConfig.restricted.forEach(
    (rBlock) =>
      (restrictedBlocksTree[rBlock] = {
        ...config.blocks.blocksConfig[rBlock],
        restricted: true,
      }),
  );
  blocksConfig.mostUsed.forEach((muBlock) => {
    if (muBlock.startsWith('-')) {
      mostUsedBlocksTree[muBlock.substring(1)] = {
        ...config.blocks.blocksConfig[muBlock.substring(1)],
        mostUsed: false,
      };
    } else {
      mostUsedBlocksTree[muBlock] = {
        ...config.blocks.blocksConfig[muBlock],
        mostUsed: true,
      };
    }
  });

  config.blocks.blocksConfig = {
    ...config.blocks.blocksConfig,
    ...restrictedBlocksTree,
    ...mostUsedBlocksTree,
  };

  config = installHomeTitlePlugin(config);
  config = installStyledSlate(config);
  config.blocks.blocksConfig[TABS_BLOCK].mostUsed = true;
  config.blocks.blocksConfig[TABS_BLOCK].edit = TabsEdit;
  config.blocks.blocksConfig[TABS_BLOCK].view = TabsView;
  config.blocks.blocksConfig[TABS_BLOCK].variations = [
    ...config.blocks.blocksConfig[TABS_BLOCK]?.variations,
    {
      id: 'CSCarousel',
      title: 'Carousel',
      edit: DefaultEdit,
      view: CarouselView,
      schema: blockSchema,
    },
    // default: config.blocks.blocksConfig[TABS_BLOCK]?.templates?.default,
  ];

  config.blocks.blocksConfig.countUpBlock.schemaEditable = {
    time: ['duration'],
    extras: [],
    decimals: [],
    thousands: ['separator'],
    styling: [
      'basic',
      'inverted',
      'compact',
      'circular',
      'floated',
      'textAlign',
      'color',
    ],
  };
  config.blocks.blocksConfig.countUpBlock.schemaValues = {
    duration: 2,
    delay: 0,
    prefix: '',
    suffix: '',
    decimal: ',',
    decimals: 0,
    separator: '',
    basic: false,
    inverted: false,
    compact: false,
    circular: true,
    floated: true,
    textAlign: 'center',
    color: 'transparent',
  };
  config.blocks.blocksConfig.countUpBlock.view = CountupBlockView;
  config.blocks.blocksConfig.countUpBlock.schemaEnhancer = (props) => {
    props.schema.fieldsets = [
      ...props.schema.fieldsets,
      { id: 'link', title: 'Esteka', fields: ['link'] },
    ];
    props.schema.properties = {
      ...props.schema.properties,
      link: {
        title: 'Esteka',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
    };
    return props.schema;
  };
  config.blocks.blocksConfig.redirect = {
    id: 'redirect',
    title: 'Berbideraketa',
    icon: linkIcon,
    group: 'common',
    view: ViewRedirectBlock,
    edit: EditRedirectBlock,
    schema: BlockSettingsSchema,
    restricted: false, // If the block is restricted, it won't show in the chooser
    mostUsed: false, // A meta group `most used`, appearing at the top of the chooser
    blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
    sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
    security: {
      addPermission: [], // Future proof (not implemented yet) add user permission role(s)
      view: [], // Future proof (not implemented yet) view user role(s)
    },
  };

  config.blocks.blocksConfig.menuSection = {
    id: 'menuSection', // The name (id) of the block
    title: 'Menu Section', // The display name of the block
    icon: bulletList, // The icon used in the block chooser
    group: 'header', // The group (blocks can be grouped, displayed in the chooser)
    view: MenuSectionBlockView, // The view mode component
    edit: MenuSectionBlockEdit, // The edit mode component
    schema: layoutSchema(config),
    restricted: ({ properties, block }) => {
      return properties?.mode !== 'dropdown';
    }, // If the block is restricted, it won't show in the chooser
    mostUsed: false, // A meta group `most used`, appearing at the top of the chooser
    blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
    sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
    security: {
      addPermission: [], // Future proof (not implemented yet) add user permission role(s)
      view: [], // Future proof (not implemented yet) view user role(s)
    },
    variations: [
      {
        id: 'default',
        isDefault: true,
        title: 'Default',
        template: UeuMenuSectionView,
      },
    ],
    getBlocks: (data) => {
      const { blocks = {}, blocks_layout = {} } = data?.data;
      if (blocks_layout?.items?.length) {
        return {
          blocks: blocks_layout.items.map((block, index) => ({
            title: blocks[block]['title'] || `Tab ${index + 1}`,
            id: block,
            parentId: data.parentId,
            type: TABS_BLOCK,
          })),
        };
      }
      return {};
    },
  };

  config.blocks.blocksConfig.form.fieldSchema = customIdFieldSchema;
  config.blocks.blocksConfig.form.schemaEnhancer = (props) =>
    HarpidetzaBazkidetzaSchemaEnhancer(props);

  config.blocks.blocksConfig.footerResidence = {
    id: 'footerResidence', // The name (id) of the block
    title: 'Footer Egoitza', // The display name of the block
    icon: bulletList, // The icon used in the block chooser
    group: 'header', // The group (blocks can be grouped, displayed in the chooser)
    view: FooterResidenceBlockView, // The view mode component
    edit: FooterResidenceBlockEdit, // The edit mode component
    schema: layoutSchema(config),
    restricted: ({ properties, block }) => {
      return properties?.mode !== 'dropdown';
    }, // If the block is restricted, it won't show in the chooser
    mostUsed: false, // A meta group `most used`, appearing at the top of the chooser
    blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
    sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
    security: {
      addPermission: [], // Future proof (not implemented yet) add user permission role(s)
      view: [], // Future proof (not implemented yet) view user role(s)
    },
  };

  config.blocks.blocksConfig.gridBlock.gridAllowedBlocks = [
    'teaser',
    'image',
    'slate',
    'countUpBlock',
  ];
  config.blocks.blocksConfig.gridBlock.templates = GridTemplates;

  config.blocks.blocksConfig.teaser.dataAdapter = ({
    block,
    data,
    id,
    onChangeBlock,
    value,
  }) => {
    let dataSaved = {
      ...data,
      [id]: value,
    };
    if (id === 'href' && !isEmpty(value) && !data.title && !data.description) {
      dataSaved = {
        ...dataSaved,
        title: value[0].Title,
        description: value[0].Description,
        head_title: value[0].head_title,
      };
    }
    if (!isEmpty(value) && isEmpty(data?.href) && id !== 'href') {
      dataSaved = {
        ...dataSaved,
        href: [],
      };
    }
    onChangeBlock(block, dataSaved);
  };
  config.blocks.blocksConfig.teaser.blockHasOwnFocusManagement = true;
  config.blocks.blocksConfig.teaser.variations = [
    ...config.blocks.blocksConfig.teaser.variations,
    {
      id: 'banner',
      isDefault: false,
      title: 'Banner',
      template: BannerTeaserView,
      schemaEnhancer: BannerTeaserSchemaEnhancer,
    },
    {
      id: 'text-and-link',
      isDefault: true,
      title: 'Text and Link',
      template: TextAndLinkTeaserView,
      schemaEnhancer: composeSchema(
        TitleSchemaEnhancer,
        DescriptionSchemaEnhancer,
        ButtonTextSchemaEnhancer,
        (props) =>
          removeDefaultFields(props.schema, [
            'href',
            'head_title',
            'preview_image',
            'overwrite',
          ]),
      ),
    },
    {
      id: 'work-group',
      isDefault: false,
      title: 'Lantaldea',
      template: WorkGroupTeaserView,
      schemaEnhancer: composeSchema(
        TitleSchemaEnhancer,
        WorkgroupSchemaEnhancer,
        (props) =>
          removeDefaultFields(props.schema, [
            'description',
            'head_title',
            'href',
            'overwrite',
          ]),
      ),
    },
    {
      id: 'aldizkari',
      isDefault: false,
      title: 'Aldizkaria',
      template: AldizkariTeaserView,
      schemaEnhancer: composeSchema(
        TitleSchemaEnhancer,
        DescriptionSchemaEnhancer,
        ImageSchemaEnhancer,
        ButtonTextSchemaEnhancer,
        (props) =>
          removeDefaultFields(props.schema, [('head_title', 'overwrite')]),
      ),
    },
    {
      id: 'carousel',
      isDefault: false,
      title: 'Carousel',
      template: CarouselTeaserView,
      schemaEnhancer: composeSchema(
        TitleSchemaEnhancer,
        DescriptionSchemaEnhancer,
        ImageSchemaEnhancer,
        ButtonTextSchemaEnhancer,
        (props) =>
          removeDefaultFields(props.schema, ['head_title', 'overwrite']),
      ),
      // schemaEnhancer: (props) =>
      //   removeDefaultFields(ButtonTextSchemaEnhancer(props), [
      //     'head_title',
      //     'overwrite',
      //   ]),
    },
    {
      id: 'ikergazteHomeTeaser',
      isDefault: false,
      title: 'Ikergazte desktakatua',
      template: IkergazteHomeTeaserView,
      schemaEnhancer: composeSchema(WorkgroupSchemaEnhancer, (props) =>
        removeDefaultFields(props.schema, [
          'href',
          'head_title',
          'description',
          'imageSide',
          'overwrite',
        ]),
      ),
    },
    {
      id: 'hizlariTeaser',
      isDefault: false,
      title: 'Hizlaria',
      template: HizlariTeaserView,
      schemaEnhancer: composeSchema(
        TitleSchemaEnhancer,
        ImageSchemaEnhancer,
        (props) =>
          removeDefaultFields(props.schema, [
            'head_title',
            'overwrite',
            'description',
          ]),
      ),
    },
    {
      id: 'dateTeaser',
      isDefault: false,
      title: 'Ikergazte Data',
      template: DateTeaserView,
      schemaEnhancer: composeSchema(DateTeaserSchemaEnhancer, (props) =>
        removeDefaultFields(props.schema, [
          'head_title',
          'overwrite',
          'description',
        ]),
      ),
    },
  ];
  config.blocks.blocksConfig.teaser.imageScale = 'larger';

  // Here we add all variation, may be it makes sense to filter out the unwanted ones such us the variations
  // that take the full width of the page. To be decided later
  config.blocks.blocksConfig.gridBlock.blocksConfig.teaser.variations =
    cloneDeep(config.blocks.blocksConfig.teaser.variations);

  config.blocks.blocksConfig.gridBlock.blocksConfig.teaser.schemaEnhancer =
    cloneDeep(config.blocks.blocksConfig.teaser.schemaEnhancer);

  config.blocks.blocksConfig.__grid = {
    ...config.blocks.blocksConfig.__grid,
    gridAllowedBlocks: ['teaser', 'image', 'slate', 'countUpBlock'],
    maxNumberOfColumns: 6,
    restricted: true,
  };
  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      {
        id: 'listing',
        title: 'Listing',
        template: UeuListing,
        schemaEnhancer: UeuListingSchema,
        isDefault: true,
      },
      {
        id: 'txiotesi-edizioak',
        title: 'Txiotesi aurreko edizioak',
        template: TxiotesiListing,
      },
      {
        id: 'event-listing',
        title: 'Agenda zerrenda',
        template: AgendaListing,
        schemaEnhancer: UeuListingSchema,
      },
      ...config.blocks.blocksConfig.listing.variations,
    ],
  };
  config.blocks.blocksConfig[COLUMNSBLOCK] = {
    ...config.blocks.blocksConfig[COLUMNSBLOCK],
    title: 'Columns',
    mostUsed: true,
    variants: [
      {
        icon: iconC,
        defaultData: {
          gridSize: 12,
          gridCols: ['hirulaurden', 'laurdena'],
        },
        common: true,
        title: '75 / 25',
      },
      ...config.blocks.blocksConfig[COLUMNSBLOCK].variants,
    ],
    gridSizes: {
      ...config.blocks.blocksConfig[COLUMNSBLOCK].gridSizes,
      hirulaurden: {
        mobile: 12,
        tablet: 9,
        computer: 9,
      },
      laurdena: {
        mobile: 12,
        tablet: 3,
        computer: 3,
      },
    },
  };
  config.blocks.blocksConfig.search = {
    ...config.blocks.blocksConfig.search,
    variations: [
      {
        id: 'facetsLeftSideUeu',
        title: 'UEU Ezkerreko fazetak',
        view: LeftColumnUeuFacets,
        isDefault: true,
      },
      // ...config.blocks.blocksConfig.search.variations,
    ],
    extensions: {
      ...config.blocks.blocksConfig.search.extensions,
      facetWidgets: {
        ...config.blocks.blocksConfig.search.extensions.facetWidgets,
        rewriteOptions: (name, choices) => {
          return rewriteOptions(name, choices);
        },
        types: [
          {
            id: 'checkboxWithNumberFacet',
            title: 'UEU Checkbox',
            view: CheckboxWithNumberFacet,
            isDefault: true,
            schemaEnhancer: CheckboxWithNumberFacet.schemaEnhancer,
            stateToValue: CheckboxWithNumberFacet.stateToValue,
            valueToQuery: CheckboxWithNumberFacet.valueToQuery,
            filterListComponent: SelectFacetFilterListEntry,
          },
          {
            id: 'checkboxTreeParentFacet',
            title: 'UEU Jakintza Arloak',
            view: CheckboxTreeParentFacet,
            isDefault: false,
            schemaEnhancer: CheckboxTreeParentFacet.schemaEnhancer,
            stateToValue: CheckboxTreeParentFacet.stateToValue,
            valueToQuery: CheckboxTreeParentFacet.valueToQuery,
            filterListComponent: TaxonomyFacetFilterListEntry,
          },

          ...config.blocks.blocksConfig.search.extensions.facetWidgets.types,
        ],
      },
    },
  };
  config.blocks.blocksConfig.accordion = {
    ...config.blocks.blocksConfig.accordion,
    mostUsed: true,
    schemaEnhancer: (props) =>
      removeFields(
        props.schema,
        ['options', 'styling'],
        ['right_arrows', 'styles'],
      ),
  };
  config.blocks.blocksConfig.UEUbutton = {
    id: 'UEUbutton',
    title: 'Button',
    icon: boldSVG,
    group: 'common',
    view: ButtonBlockView,
    edit: ButtonBlockEdit,
    schema: ButtonSchema,
    restricted: false, // If the block is restricted, it won't show in the chooser
    mostUsed: true, // A meta group `most used`, appearing at the top of the chooser
    blockHasOwnFocusManagement: false, // Set this to true if the block manages its own focus
    sidebarTab: 1, // The sidebar tab you want to be selected when selecting the block
    security: {
      addPermission: [], // Future proof (not implemented yet) add user permission role(s)
      view: [], // Future proof (not implemented yet) view user role(s)
    },
    variation: [
      {
        id: 'basic',
        isDefault: true,
        title: 'Oinarrizkoa',
      },
      {
        id: 'primary',
        isDefault: false,
        title: 'Iluna',
      },
      {
        id: 'ikergazte-button',
        isDefault: false,
        title: 'Ikergazte botoia',
      },
    ],
  };
  config.blocks.blocksConfig.teaserGrid = {
    ...config.blocks.blocksConfig.teaserGrid,
    title: 'Teaser Sareta',
    icon: gridSVG,
  };
  config.blocks.blocksConfig.schedule = {
    id: 'schedule',
    title: 'Schedule',
    icon: calendarSVG,
    view: ScheduleView,
    edit: ScheduleEdit,
    group: 'common',
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  config.blocks.blocksConfig.teaser.blockSchema = (props) => {
    let schema = TeaserSchema(props);
    schema.properties.styles.schema.properties.align.default = '';
    return schema;
  };
  // This disables the `@kitconcept/volto-blocks-grid` grid block
  config.blocks.blocksConfig.__grid.restricted = true;
  config.blocks.groupBlocksOrder = [
    ...config.blocks.groupBlocksOrder,
    { id: 'header', title: 'Headerra' },
  ];

  config.blocks.requiredBlocks = [];
};

export default applyBlocks;
