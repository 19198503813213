import ArgitalpenaView from './components/Views/ArgitalpenaView/ArgitalpenaView';
import BookView from './components/Views/BookView/BookView';
import ChapterView from './components/Views/ChapterView/ChapterView';
import CourseView from './components/Views/CourseView/CourseView';
import EventView from './components/Views/EventView/EventView';
import IkergazteEventView from './components/Views/EventView/IkergazteEventView';
import IragarkiaView from './components/Views/IragarkiaView/IragarkiaView';
import NewsItemView from './components/Views/NewsItemView/NewsItemView';
import PertsonaView from './components/Views/PertsonaView/PertsonaView';
import PodcastView from './components/Views/PodcastView/PodcastView';
import PosterView from './components/Views/PosterView/PosterView';
import TxiotesiaView from './components/Views/TxiotesiaView/TxiotesiaView';
import VideoView from './components/Views/VideoView/VideoView';

const applyViews = (config) => {
  config.views.contentTypesViews = {
    ...config.contentTypesViews,
    Publication: ArgitalpenaView,
    Person: PertsonaView,
    Course: CourseView,
    Event: (props) => (
      <>
        {props.content['@components'].subsite?.UID ? (
          <IkergazteEventView {...props} />
        ) : (
          <EventView {...props} />
        )}
      </>
    ),
    'News Item': NewsItemView,
    Book: BookView,
    Chapter: ChapterView,
    Video: VideoView,
    Podcast: PodcastView,
    Txiotesia: TxiotesiaView,
    Iragarkia: IragarkiaView,
    Poster: PosterView,
  };
  return config;
};

export default applyViews;
