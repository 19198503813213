import React from 'react';
import TagManager from 'react-gtm-module';

const VoltoTagManager = ({ gtmCode }) => {
  React.useEffect(() => {
    const tagManagerArgs = {
      gtmId: gtmCode,
    };
    TagManager.initialize(tagManagerArgs);
    return () => {};
  }, [gtmCode]);

  return <></>;
};
export default VoltoTagManager;
