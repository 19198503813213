import { defineMessages } from 'react-intl';
const messages = defineMessages({
  richtext: {
    id: 'richtext',
    defaultMessage: 'Testua',
  },
  imageSide: {
    id: 'imageSide',
    defaultMessage: 'Irudia non',
  },
  imageSideDescription: {
    id: 'imageSideDescription',
    defaultMessage: 'Irudia testuaren zein aldetan jarri',
  },
});

export const WorkgroupSchemaEnhancer = (props) => {
  const { intl, schema } = props;
  return {
    ...schema,
    fieldsets: [
      {
        ...schema.fieldsets[0],
        fields: [
          ...schema.fieldsets[0].fields,
          'preview_image',
          'imageSide',
          'richtext',
        ],
      },
    ],
    properties: {
      ...schema.properties,
      overwrite: {
        ...schema.properties.overwrite,
        default: true,
      },
      richtext: {
        title: intl.formatMessage(messages.richtext),
        widget: 'richtext',
      },
      imageSide: {
        title: intl.formatMessage(messages.imageSide),
        description: intl.formatMessage(messages.imageSideDescription),
        choices: [
          ['ezkerrean', 'ezkerrean'],
          ['eskuman', 'eskuman'],
        ],
        default: 'ezkerrean',
      },
    },
    required: [],
  };
};
