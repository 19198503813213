import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { Header, Button, Segment } from 'semantic-ui-react';

import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import Field from '@plone/volto/components/manage/Form/Field';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import { unescoPost } from '../../../actions/unesco_migrator/unesco_migrator';
export const UnescoMigratorView = ({ location, staticContext }) => {
  const token = useSelector((state) => state.userSession.token);
  const unesco_post = useSelector((state) => state.unescoPost);
  const userId = token ? jwtDecode(token).sub : '';
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const dispatch = useDispatch();
  const isLoggedIn = !!userId;

  const testHandler = (origin) => {
    dispatch(unescoPost(origin));
  };

  const migrateHandler = (origin, destination) => {
    dispatch(unescoPost(origin, destination));
  };
  return !isLoggedIn ? (
    <>
      {token ? (
        <Forbidden pathname={location.pathname} staticContext={staticContext} />
      ) : (
        <Unauthorized
          pathname={location.pathname}
          staticContext={staticContext}
        />
      )}
    </>
  ) : (
    <div className="ui container">
      <Helmet title="UNESCO migrator"></Helmet>
      <Header as="h1" className="no-breadcrumb">
        UNESCO migrator
      </Header>
      <Segment basic>
        <Field
          title="UNESCO origin"
          value={origin}
          onChange={(id, v) => setOrigin(v)}
        />
        <Field
          title="UNESCO destination"
          value={destination}
          onChange={(id, v) => setDestination(v)}
        />
        <Button className="ueu large" onClick={() => testHandler(origin)}>
          Test it
        </Button>
        <Button
          className="ueu large primary"
          onClick={() => migrateHandler(origin, destination)}
        >
          Migrate
        </Button>
      </Segment>
      <Segment basic loading={unesco_post.loading}>
        {unesco_post.loaded && unesco_post.response?.message && (
          <p>{unesco_post.response?.message}</p>
        )}
        {unesco_post.response?.origin && !unesco_post.response?.destination && (
          <p>
            Bilaketa: <strong>{unesco_post.response?.origin}</strong>
            <br />
            Guztira: <strong>{unesco_post.response?.items?.length}</strong>
          </p>
        )}
        {unesco_post.response?.origin && unesco_post.response?.destination && (
          <p>
            Originala: <strong>{unesco_post.response?.origin}</strong>
            <br />
            Berria: <strong>{unesco_post.response?.destination}</strong>
            <br />
            Guztira: <strong>{unesco_post.response?.items?.length}</strong>
          </p>
        )}
        {unesco_post.loaded && unesco_post.response?.items?.length > 0 && (
          <ul>
            {unesco_post.response?.items.map((item, key) => (
              <li key={key}>
                {item.title} - {item.portal_type}
              </li>
            ))}
          </ul>
        )}
      </Segment>
    </div>
  );
};
