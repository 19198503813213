export const GET_BOOKS = 'GET_BOOKS';
export const GET_BOOKS_BY_AUTHORS = 'GET_BOOKS_BY_AUTHOR';
/**
 * Get books.
 * @function getBooks
 * @returns {Object} Get books action.
 */
export function getBooks(limit = 4, unesco = []) {
  const unesco_filter =
    unesco.length > 0
      ? `&taxonomy_unesco_categorization=${unesco.join(
          '&taxonomy_unesco_categorization=',
        )}`
      : '';
  return {
    type: GET_BOOKS,
    request: {
      op: 'get',
      path: `@search?portal_type=Book&sort_on=effective&sort_order=reverse&sort_limit=${limit}${unesco_filter}`,
    },
  };
}

/**
 * Get books by authors.
 * @function getBooksByAuthors
 * @returns {Object} Get books by authors action.
 */
export function getBooksByAuthors(authors = [], limit = 4) {
  const authors_filter =
    authors.length > 0 ? `&all_authors=${authors.join('&all_authors=')}` : '';
  return {
    type: GET_BOOKS_BY_AUTHORS,
    request: {
      op: 'get',
      path: `@search?portal_type=Book&sort_on=effective&sort_order=reverse&sort_limit=${limit}${authors_filter}`,
    },
  };
}
