export const getFieldValue = (
  raw_value,
  raw_value_key,
  userschema,
  vocabularies,
) => {
  if (
    userschema.properties[raw_value_key] &&
    userschema.properties[raw_value_key].vocabulary &&
    vocabularies
  ) {
    const vocab_item =
      vocabularies[userschema.properties[raw_value_key].vocabulary['@id']]
        ?.items?.length > 0 &&
      vocabularies[
        userschema.properties[raw_value_key].vocabulary['@id']
      ].items.filter(
        (item) => item.value?.toString() === raw_value?.toString(),
      );
    return vocab_item.length > 0 ? vocab_item[0].label : raw_value;
  } else {
    return raw_value;
  }
};
