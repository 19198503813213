import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Header,
  Image,
  Container,
  Segment,
  Button,
} from 'semantic-ui-react';

import config from '@plone/volto/registry';

import {
  getCoursesByTeacher,
  getChaptersByTeacher,
  getBooksByAuthors,
  updatePerson,
} from 'volto-ueu/actions';
import { Picture, UeuModal } from 'volto-ueu/components';
// import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { getRandomSrc } from 'volto-ueu/components/RandomImage/RandomImage';
import { UeuListing } from 'volto-ueu/components/Variations';
import GeneralInfoCard from './GeneralInfoCard';

const PertsonaView = ({ content, location }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCoursesByTeacher([content.title, content.UID], 6));
    dispatch(getChaptersByTeacher([content.title, content.UID], 4));
    dispatch(getBooksByAuthors([content.title, content.UID], 4));
  }, [dispatch, content.UID, content.title]);
  const courses = useSelector((state) => state.courses.courses);
  const chapters = useSelector((state) => state.chapters.chapters);
  const updatePersonState = useSelector((state) => state.updatePerson);
  let updatePersonState_ref = useRef(updatePersonState);
  const person_path = location.pathname;
  // const publications = useSelector((state) => state.publications.publications);
  const books = useSelector((state) => state.books.books_by_authors);
  const user = useSelector((state) => state.users.user);
  useEffect(() => {
    updatePersonState_ref.current = updatePersonState;
  }, [updatePersonState]);
  return (
    <Container className="view-wrapper">
      <Header as="h1">{content.title}</Header>
      {user?.roles &&
        config.settings.importerRoles.find((element) =>
          user?.roles.includes(element),
        ) && (
          <Segment color="pink" loading={updatePersonState.loading}>
            Pertsona honen datuak Kudeutik eguneratzeko:
            <UeuModal
              header="Pertsonaren datuak Kudeu-tik eguneratu"
              closeAction={{
                closeText: 'Itxi eguneratu gabe',
              }}
              okAction={{
                okText: 'Bai, Kudeutik datuak ekarri',
                okOnClick: () => {
                  dispatch(updatePerson(person_path));
                },
              }}
              trigger={() => {
                return (
                  <Button className="ueu primary">Eguneratu kudeutik</Button>
                );
              }}
            >
              <p>
                Kudeuko datu basean pertsona honi dagozkion datuak ekartzera
                zoaz.
              </p>
              <p>
                Kontu izan, orain dauden datuak eguneratu egingo direla eta
                berriak ikusiko dituzula hemendik aurrera.
              </p>
              <p>
                <strong>
                  Ziur zaude Kudeutik datuak ekarri nahi dituzula?
                </strong>
              </p>
            </UeuModal>
            {!updatePersonState.loading && updatePersonState.error && (
              <Segment inverted color="red" tertiary>
                Errore bat egon da pertsonaren datuak ekartzerakoan.
              </Segment>
            )}
            {updatePersonState_ref.current.loading &&
              updatePersonState.loaded &&
              !updatePersonState.error && (
                <Segment inverted color="green" tertiary>
                  {updatePersonState.message}
                </Segment>
              )}
          </Segment>
        )}
      <Grid stackable width={12} className="course-card">
        <Grid.Row>
          <Grid.Column width={7}>
            <GeneralInfoCard props={content} />
          </Grid.Column>
          <Grid.Column width={5} verticalAlign="middle">
            {!content?.image && (
              <Image
                src={getRandomSrc(content?.UID, 'users')}
                alt="This content has no image, this is a default placeholder."
                size="large"
              />
            )}
            {content?.image && (
              <Picture
                imageBase={`${content['@id']}/@@images/image`}
                alt={content.title}
              ></Picture>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {courses.length > 0 && (
        <div className="irakasle-ikastaroak">
          <UeuListing
            header="Irakasle honen ikastaroak"
            items={courses}
            howManyColumns={2}
            CardComponent={'Course'}
            isEditMode={false}
            showUnconditionally={true}
            moreLinkUrl={
              courses.length === 6 && [
                {
                  '@id': `https://www.ueu.eus/jarduera-akademikoa?query=%5B%7B%22i%22%3A%22SearchableText%22%2C%22o%22%3A%22paqo.string.contains%22%2C%22v%22%3A%22${content.name} ${content.surname1} ${content.surname2}%22%7D%5D`,
                },
              ]
            }
            moreLinkText={courses.length === 6 && 'Ikusi ikastaro guztiak'}
          />
        </div>
      )}
      {books.loaded && books?.data?.length > 0 && (
        <div className="irakasle-argitalpenak">
          <UeuListing
            header="Argitalpenak"
            items={books.data}
            howManyColumns={4}
            CardComponent={'Book'}
            isEditMode={false}
            showUnconditionally={true}
            moreLinkUrl={
              books?.data.length === 4 && [
                {
                  '@id': `https://www.ueu.eus/argitaletxea/liburuak?query=%5B%7B%22i%22%3A%22SearchableText%22%2C%22o%22%3A%22paqo.string.contains%22%2C%22v%22%3A%22${content.name} ${content.surname1} ${content.surname2}%22%7D%5D`,
                },
              ]
            }
            moreLinkText={
              books?.data.length === 4 && 'Ikusi argitalpen guztiak'
            }
          />
        </div>
      )}
      {chapters.length > 0 && (
        <div className="irakasle-artikuluak">
          <UeuListing
            header="Artikuluak"
            items={chapters}
            howManyColumns={4}
            CardComponent={'Chapter'}
            isEditMode={false}
            showUnconditionally={true}
            // moreLinkUrl={
            //   chapters.length === 4 && [
            //     {
            //       '@id': `https://www.ueu.eus/jarduera-akademikoa?query=%5B%7B%22i%22%3A%22SearchableText%22%2C%22o%22%3A%22paqo.string.contains%22%2C%22v%22%3A%22${content.name} ${content.surname1} ${content.surname2}%22%7D%5D`,
            //     },
            //   ]
            // }
            // moreLinkText={chapters.length === 4 && 'Ikusi artikulu guztiak'}
          />
        </div>
      )}
    </Container>
  );
};

export default PertsonaView;
