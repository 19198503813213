/**
 * Get modification.
 * @module actions/modification
 */
export const GET_MODIFICATION = 'GET_MODIFICATION';

/**
 * Get modification.
 * @function getModification
 * @returns {Object} Get modification action.
 */
export function getModification(UID) {
  return {
    type: GET_MODIFICATION,
    request: {
      op: 'get',
      path: `@modificationget/${UID}`,
    },
  };
}

/**
 * Get usermodifications.
 * @module actions/usermodifications
 */
export const GET_USERMODIFICATIONS = 'GET_USERMODIFICATIONS';

/**
 * Get usermodifications.
 * @function getUserModifications
 * @returns {Object} Get user modifications action.
 */
export function getUserModifications() {
  return {
    type: GET_USERMODIFICATIONS,
    request: {
      op: 'get',
      path: `@usermodificationsget`,
    },
  };
}

export const GET_ALLUSERMODIFICATIONS = 'GET_ALLUSERMODIFICATIONS';

/**
 * Get all user modifications.
 * @function getAllUserModifications
 * @returns {Object} Get all user modifications action.
 */
export function getAllUserModifications() {
  return {
    type: GET_ALLUSERMODIFICATIONS,
    request: {
      op: 'get',
      path: `@allusermodificationsget`,
    },
  };
}
export const ADD_USERMODIFICATIONS = 'ADD_USERMODIFICATIONS';

/**
 * Add user modifications.
 * @function addUserModifications
 * @returns {Object} Add user modifications action.
 */
export function addUserModifications(modifications) {
  return {
    type: ADD_USERMODIFICATIONS,
    request: {
      op: 'post',
      path: `@usermodificationsadd`,
      data: { modifications: modifications },
    },
  };
}

export const REMOVE_USERMODIFICATION = 'REMOVE_USERMODIFICATION';

/**
 * Remove user modification.
 * @function removeUserModification
 * @returns {Object} Remove user modification action.
 */
export function removeUserModification(uid) {
  return {
    type: REMOVE_USERMODIFICATION,
    request: {
      op: 'post',
      path: `@usermodificationremove`,
      data: { UID: uid },
    },
  };
}
