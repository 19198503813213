import { Card, Segment } from 'semantic-ui-react';

import { PaymentFormatCard } from 'volto-ueu/components';
import {
  PAYMENT_MODE_TRANSFERENCE,
  PAYMENT_MODE_PAYPAL,
  PAYMENT_MODE_CARD,
} from '../../constants';
import iCard from './i_card.svg';
import iPaypal from './i_paypal.svg';
import iTransfer from './i_transfer.svg';

export const PaymentModes = ({
  form_status,
  submit_state,
  submit,
  payment,
  mode = 'enroll',
  has_to_pay = false,
  disable = [],
}) => {
  return (
    <Segment basic loading={submit_state.loading}>
      {payment.result.can_pay && !submit_state.loading ? (
        <Card.Group itemsPerRow={3} centered stackable doubling>
          {has_to_pay &&
            payment.result.paypal &&
            !disable.includes('paypal') && (
              <PaymentFormatCard
                header={'Paypal bidez'}
                description={
                  'Hautatu aukera hau ordainketa Paypal bidez egin nahi baduzu.'
                }
                imageSrc={iPaypal}
                submit={submit}
                submit_mode={PAYMENT_MODE_PAYPAL}
                submit_text={'Paypal bidez ordaindu'}
                form_status={form_status}
              />
            )}
          {has_to_pay &&
            payment.result.creditcard &&
            !disable.includes('creditcard') && (
              <PaymentFormatCard
                header={'Txartel bidez'}
                description={
                  'Hautatu aukera hau ordainketa pasarela bidez egin nahi baduzu.'
                }
                imageSrc={iCard}
                submit={submit}
                submit_mode={PAYMENT_MODE_CARD}
                submit_text={'Txartelarekin ordaindu'}
                form_status={form_status}
              />
            )}
          {has_to_pay &&
            payment.result.banktransfer &&
            !disable.includes('banktransfer') && (
              <PaymentFormatCard
                header={'Transferentzia bidez'}
                description={
                  'Hautatu aukera hau ordainketa transferentzia bidez egin nahi baduzu.'
                }
                imageSrc={iTransfer}
                submit={submit}
                submit_mode={PAYMENT_MODE_TRANSFERENCE}
                submit_text={'Transferentzia egingo dut'}
                form_status={form_status}
              />
            )}
          {((!payment.result.paypal &&
            !payment.result.creditcard &&
            !payment.result.banktransfer) ||
            !has_to_pay) &&
            mode === 'enroll' && (
              <PaymentFormatCard
                header={'Matrikulatu'}
                description={
                  'Datu guztiak zuzen bete dituzu, azken pausu moduan bidali botoia sakatzea besterik ez zaizu falta'
                }
                submit={submit}
                submit_mode={4}
                submit_text={'Bidali matrikulazioa'}
                form_status={form_status}
              />
            )}
        </Card.Group>
      ) : mode === 'enroll' && !submit_state.loading ? (
        <Card.Group itemsPerRow={3} centered>
          <PaymentFormatCard
            header={'Itxaron zerrendara'}
            description={
              'Aurrera egin eta ikastaroan matrikulatu zaitezke, baina itxaron zerrendan geratuko zara. Onartua izango bazara jarriko ginateke zurekin harremanetan.'
            }
            submit={submit}
            submit_mode={0}
            submit_text={'Bidali matrikula'}
            form_status={form_status}
          />
        </Card.Group>
      ) : (
        <p>
          Momentu honetan ezin da ordainketa burutu, saiatu beranduago mesedez.
        </p>
      )}
    </Segment>
  );
};
