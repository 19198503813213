export const isEntryInTime = (content) => {
  if (!content) {
    return false;
  }
  const start = content?.entry_limit;
  const end = content?.entry_limit_end;
  const force_open = content?.force_enroll_open;
  const closed_enroll = content?.closed_enroll;
  const d_entry = new Date(start).setHours(0, 0, 0, 0);
  const d_entry_end = new Date(end).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  const entry_in_time = d_entry <= today && today <= d_entry_end ? true : false;
  return !closed_enroll && (force_open || entry_in_time);
};

export const getEnrolledCourse = (id, courses) => {
  if (
    courses.filter((course) => course?.ikastaro_id.toString() === id.toString())
      .length > 0
  ) {
    return courses.filter(
      (course) => course?.ikastaro_id.toString() === id.toString(),
    )[0];
  }
  return null;
};

export const enrolled = (id, courses) => {
  const enrolledCourse = getEnrolledCourse(id, courses);
  if (enrolledCourse) {
    return true;
  }
  return false;
};

export const enrollStatus = (id, courses) => {
  const enrolledCourse = getEnrolledCourse(id, courses);
  if (enrolledCourse) {
    return enrolledCourse?.data?.onartuta?.vocabulary[
      enrolledCourse.data.onartuta.data
    ];
  }
  return null;
};

export const enrollPrice = (id, courses) => {
  const enrolledCourse = getEnrolledCourse(id, courses);
  if (enrolledCourse) {
    return enrolledCourse?.prezioa;
  }
  return '0';
};
