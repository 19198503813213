import React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import cx from 'classnames';

const TextandLinkTeaserView = (props) => {
  const { data, isEditMode } = props;
  const buttonLink = data.buttonLink?.[0];
  return (
    <Segment
      textAlign="center"
      basic
      className={cx(props.className, 'text-link-container')}
    >
      {data.title && <h2>{data?.title}</h2>}
      {data.description && <h3>{data?.description} </h3>}
      {buttonLink && (
        <Button
          className={cx('ueu', 'text-link-button')}
          size="small"
          href={!isEditMode ? buttonLink['@id'] : ''}
          basic
        >
          {data.buttonText}
        </Button>
      )}
    </Segment>
  );
};

export default TextandLinkTeaserView;
