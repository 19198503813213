import React from 'react';
import { flushSync } from 'react-dom';
import { defineMessages, useIntl } from 'react-intl';
// import { useSelector } from 'react-redux';
import { Grid, Segment, Button } from 'semantic-ui-react';

import {
  SearchInput,
  SearchDetails,
  FilterList,
  Facets,
} from '@plone/volto/components/manage/Blocks/Search/components';

// import { UeuLoader } from 'volto-ueu/components';
// import Facets from './Facets';
import SortOn from './SortOn';

const messages = defineMessages({
  searchButtonText: {
    id: 'Search',
    defaultMessage: 'Search',
  },
});

const FacetWrapper = ({ children }) => (
  <Segment basic className="facet">
    {children}
  </Segment>
);

const LeftColumnUeuFacets = (props) => {
  const {
    children,
    data,
    totalItems,
    facets,
    setFacets,
    setSortOn,
    setSortOrder,
    sortOn,
    sortOrder,
    onTriggerSearch,
    searchedText, // search text for previous search
    searchText, // search text currently being entered (controlled input)
    isEditMode,
    querystring = {},
    // searchData,
    // mode = 'view',
    // variation,
  } = props;
  const { showSearchButton } = data;
  const isLive = !showSearchButton;
  const intl = useIntl();
  // const adaptedBaseQuery = useSelector(
  //   (state) => state.querystringsearch?.subrequests?.adaptedBaseQuery,
  // );
  return (
    <Grid className="searchBlock-facets left-column-facets" stackable>
      <Grid.Row>
        <Grid.Column>
          {data.headline && <h2 className="headline">{data.headline}</h2>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={12} tablet={4} computer={3}>
          {(Object.keys(data).includes('showSearchInput')
            ? data.showSearchInput
            : true) && (
            <div className="search-wrapper">
              <SearchInput {...props} isLive={isLive} />
              {data.showSearchButton && (
                <Button primary onClick={() => onTriggerSearch(searchText)}>
                  {data.searchButtonLabel ||
                    intl.formatMessage(messages.searchButtonText)}
                </Button>
              )}
            </div>
          )}
          {data.facets &&
            data.facets.filter((f) => !f.hidden)?.length > 0 &&
            facets && (
              <div className="facets">
                {data.facetsTitle && <h3>{data.facetsTitle}</h3>}
                {/* {(!adaptedBaseQuery?.loading && adaptedBaseQuery?.loaded) ||
                data.disableNumbers ? ( */}
                <Facets
                  querystring={querystring}
                  data={data}
                  facets={facets}
                  setFacets={(f) => {
                    flushSync(() => {
                      setFacets(f);
                      onTriggerSearch(searchedText || '', f);
                    });
                  }}
                  facetWrapper={FacetWrapper}
                />
                {/* ) : (
                  <UeuLoader />
                )} */}
              </div>
            )}
        </Grid.Column>
        {data.facets && !data.facets.filter((f) => !f.hidden)?.length && (
          <Grid.Column
            mobile={12}
            tablet={data.facets.filter((f) => !f.hidden)?.length ? 12 : 8}
            computer={data.facets.filter((f) => !f.hidden)?.length ? 12 : 9}
          >
            <div>
              <FilterList
                {...props}
                querystring={querystring}
                isEditMode={isEditMode}
                setFacets={(f) => {
                  flushSync(() => {
                    setFacets(f);
                    onTriggerSearch(searchedText || '', f);
                  });
                }}
              />
            </div>

            <div className="search-results-count-sort">
              <SearchDetails
                text={searchedText}
                total={totalItems}
                data={data}
              />

              {data.showSortOn && (
                <SortOn
                  querystring={querystring}
                  data={data}
                  isEditMode={isEditMode}
                  sortOn={sortOn}
                  sortOrder={sortOrder}
                  setSortOn={(sortOn) => {
                    flushSync(() => {
                      setSortOn(sortOn);
                      onTriggerSearch(searchedText || '', facets, sortOn);
                    });
                  }}
                  setSortOrder={(sortOrder) => {
                    flushSync(() => {
                      setSortOrder(sortOrder);
                      onTriggerSearch(
                        searchedText || '',
                        facets,
                        sortOn,
                        sortOrder,
                      );
                    });
                  }}
                />
              )}
            </div>
          </Grid.Column>
        )}
        <Grid.Column
          mobile={12}
          tablet={
            data.facets && data.facets.filter((f) => !f.hidden)?.length ? 8 : 12
          }
          computer={
            data.facets && data.facets.filter((f) => !f.hidden)?.length ? 9 : 12
          }
        >
          {/* {(Object.keys(data).includes('showSearchInput')
            ? data.showSearchInput
            : true) && (
            <div className="search-wrapper">
              <SearchInput {...props} isLive={isLive} />
              {data.showSearchButton && (
                <Button primary onClick={() => onTriggerSearch(searchText)}>
                  {data.searchButtonLabel ||
                    intl.formatMessage(messages.searchButtonText)}
                </Button>
              )}
            </div>
          )} */}
          {data.facets && data.facets.filter((f) => !f.hidden)?.length > 0 && (
            <>
              <div>
                <FilterList
                  {...props}
                  querystring={querystring}
                  isEditMode={isEditMode}
                  setFacets={(f) => {
                    flushSync(() => {
                      setFacets(f);
                      onTriggerSearch(searchedText || '', f);
                    });
                  }}
                />
              </div>

              <div className="search-results-count-sort">
                <SearchDetails
                  text={searchedText}
                  total={totalItems}
                  data={data}
                />

                {data.showSortOn && (
                  <SortOn
                    querystring={querystring}
                    data={data}
                    isEditMode={isEditMode}
                    sortOn={sortOn}
                    sortOrder={sortOrder}
                    setSortOn={(sortOn) => {
                      flushSync(() => {
                        setSortOn(sortOn);
                        onTriggerSearch(searchedText || '', facets, sortOn);
                      });
                    }}
                    setSortOrder={(sortOrder) => {
                      flushSync(() => {
                        setSortOrder(sortOrder);
                        onTriggerSearch(
                          searchedText || '',
                          facets,
                          sortOn,
                          sortOrder,
                        );
                      });
                    }}
                  />
                )}
              </div>
            </>
          )}
          {children}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default LeftColumnUeuFacets;
