import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Table, Label } from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';

import { getErosketak } from 'volto-ueu/actions';
import { NIRE_EROSKETAK_ROUTE } from 'volto-ueu/constants';

import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { formatLL } from '../../utils';

const NireErosketakView = ({ content, location, staticContext }) => {
  const erosketak = useSelector((state) => state.erosketak);
  let lang = useSelector((state) => state.intl.locale);
  const token = useSelector((state) => state.userSession.token);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getErosketak());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  moment.locale(lang);
  return (
    <div className="ui container">
      {!isLoggedIn ? (
        <>
          {token ? (
            <Forbidden
              pathname={location.pathname}
              staticContext={staticContext}
            />
          ) : (
            <Unauthorized
              pathname={location.pathname}
              staticContext={staticContext}
            />
          )}
        </>
      ) : (
        <>
          <Helmet title="Nire erosketak"></Helmet>
          <Header as="h1" className="no-breadcrumb">
            Nire erosketak
          </Header>
          <Table single line="true" className="resume-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Erosketa data</Table.HeaderCell>
                <Table.HeaderCell>Erosketa</Table.HeaderCell>
                <Table.HeaderCell>Egoera</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {erosketak.erosketak.length > 0 ? (
                erosketak?.erosketak
                  .sort(function (a, b) {
                    return b.datetime.localeCompare(a.datetime);
                  })
                  .map((erosketa, key) => (
                    <Table.Row key={key}>
                      <Table.Cell>{formatLL(erosketa.datetime)}</Table.Cell>
                      <Table.Cell>
                        <ul>
                          {Object.entries(erosketa.saskia.content).map(
                            (elementua) => (
                              <li key={elementua}>
                                {elementua[1].title.length > 400 ? (
                                  `${elementua[1].title.substring(0, 40)}...
                                    (${elementua[1].format})`
                                ) : (
                                  <>
                                    {elementua[1].title} ({elementua[1].format})
                                  </>
                                )}
                              </li>
                            ),
                          )}
                        </ul>
                      </Table.Cell>
                      <Table.Cell>
                        {erosketa.status === 0 ? (
                          <Label color="red">
                            <FormattedMessage
                              id="Ordaindu gabe"
                              defaultMessage="Ordaindu gabe"
                            />
                          </Label>
                        ) : (
                          <Label color="green">
                            <FormattedMessage
                              id="Ordainduta"
                              defaultMessage="Ordainduta"
                            />
                          </Label>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <UniversalLink
                          className="ui button ueu"
                          href={`${NIRE_EROSKETAK_ROUTE}/${erosketa.UID}`}
                        >
                          <FormattedMessage
                            id="Ikusi xehetasunak"
                            defaultMessage="Ikusi xehetasunak"
                          />
                        </UniversalLink>
                      </Table.Cell>
                    </Table.Row>
                  ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="4">
                    <FormattedMessage
                      id="no_purchases_table_text"
                      defaultMessage="Oraindik ez daukazu erosketarik"
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </>
      )}
    </div>
  );
};

export default NireErosketakView;
