import { defineMessages } from 'react-intl';
const messages = defineMessages({
  buttonConfiguration: {
    id: 'buttonConfiguration',
    defaultMessage: 'Bannerreko botoia',
  },
  buttonWhere: {
    id: 'buttonWhere',
    defaultMessage: 'Botoiaren kokapena',
  },
  buttonWhereDescription: {
    id: 'buttonWhereDescription',
    defaultMessage: 'Defektuz botoia behean joango da',
  },
  buttonHarpidetza: {
    id: 'buttonHarpidetza',
    defaultMessage: 'Harpidetza modukoa',
  },
  buttonHarpidetzaDescription: {
    id: 'buttonHarpidetzaDescription',
    defaultMessage: 'Defektuz ez da izango harpidetza modukoa',
  },
});

export const BannerTeaserSchemaEnhancer = (props) => {
  const { intl, schema, formData } = props;
  const fieldsToRemove =
    formData.variation === 'banner' ? ['description', 'head_title'] : [];
  return {
    ...schema,
    fieldsets: [
      {
        ...schema.fieldsets[0],
        fields: [
          'title',
          ...schema.fieldsets[0].fields.filter(
            (field) => !fieldsToRemove.includes(field),
          ),
        ],
      },
      {
        id: 'button',
        title: intl.formatMessage(messages.buttonConfiguration),
        fields: ['buttonWhere', 'buttonHarpidetza'],
      },
    ],
    properties: {
      ...schema.properties,
      buttonWhere: {
        title: intl.formatMessage(messages.buttonWhere),
        description: intl.formatMessage(messages.buttonWhereDescription),
        choices: [
          ['behean', 'behean'],
          ['goian', 'goian'],
        ],
      },
      buttonHarpidetza: {
        title: intl.formatMessage(messages.buttonHarpidetza),
        description: intl.formatMessage(messages.buttonHarpidetzaDescription),
        type: 'boolean',
      },
    },
  };
};
