import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import { getContent } from '@plone/volto/actions/content/content';
import NotFound from '@plone/volto/components/theme/NotFound/NotFound';

import { isEntryInTime } from 'volto-ueu/components/Views/CourseView/utils';
import {
  UNPAID_MODE_TRANSFERENCE,
  UNPAID_MODE_FULL,
  TRANSFERENCE_ROUTE,
  UNPAID_ROUTE,
} from 'volto-ueu/constants';
import { ContentData } from './ContentData';

/**
 * OnholdView view component.
 * @module components/Views/OrdainketaView/OnholdView
 */
/**
 * OnholdView view.
 * @function OnholdView
 * @returns {string} Markup of the component.
 */

const OnholdView = (props) => {
  const dispatch = useDispatch();
  const { mode } = props;
  const re_tr = new RegExp(`${TRANSFERENCE_ROUTE}$`);
  const re_f = new RegExp(`${UNPAID_ROUTE}$`);
  const ikastaroa_path = props.location.pathname
    .replace(re_f, '')
    .replace(re_tr, '');
  const content = useSelector((state) => state.content);
  const title_text = 'Matrikulazioa ongi burutu da!';
  useEffect(() => {
    dispatch(getContent(ikastaroa_path));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return content.get.loading && !content.get.loaded ? (
    <Segment loading></Segment>
  ) : (content?.data && content?.data['@type'] !== 'Course') ||
    !isEntryInTime(content.data) ? (
    <NotFound></NotFound>
  ) : (
    <ContentData
      h1_text={title_text}
      h2_text={content.data?.title}
      Body={() => (
        <>
          {mode === UNPAID_MODE_TRANSFERENCE && (
            <>
              <Segment basic>
                <p>
                  Jaso dugu zure izen ematea. Ordainketa egiteko transferentzia
                  aukeratu duzu.
                </p>
                <p>
                  Zure helbide elektronikora bidali dizkizugu matrikula
                  konfirmazioa eta ordainketa bideratzeko argibideak.
                </p>
                <p>
                  <strong>Adi!</strong> Mezu automatikoa da, baliteke spam edo
                  publizitate karpetara iristea, egiaztatu mezua jaso duzula.
                </p>
                <p>
                  Ez bada mezurik iritsi, jarri arduradunarekin harremanetan:{' '}
                  <strong>{content.data?.manager_name}</strong>
                  {' - '}
                  <i>{content.data?.manager_phone}</i>
                  {' edo '}
                  <i>{content.data?.manager_email}</i>
                </p>
              </Segment>
              <Segment basic>Mila esker!</Segment>
            </>
          )}
          {mode === UNPAID_MODE_FULL && (
            <>
              <Segment basic>
                <p>
                  Jaso dugu zure izen ematea baina taldea osatuta dago eta
                  itxaron zerrendan sartu zara. Plazaren bat libre gelditzen
                  bada, izen emate ordenaren arabera jakinaraziko dizuegu.
                </p>
                <p>
                  Zure helbide elektronikora bidali dizkizugu izen emateari
                  buruzko argibideak.
                </p>
                <p>
                  <strong>Adi!</strong> Mezu automatikoa da, baliteke spam edo
                  publizitate karpetara iristea, egiaztatu mezua jaso duzula.
                </p>
                <p>
                  Ez bada mezurik iritsi, jarri arduradunarekin harremanetan:{' '}
                  <strong>{content.data?.manager_name}</strong>
                  {' - '}
                  <i>{content.data?.manager_phone}</i>
                  {' edo '}
                  <i>{content.data?.manager_email}</i>
                </p>
              </Segment>
              <Segment basic>Mila esker!</Segment>
            </>
          )}
        </>
      )}
    />
  );
};

export default OnholdView;
