import React from 'react';
import { useSelector } from 'react-redux';
// import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import { Grid, Segment, Icon } from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import { get_taxonomy_children } from 'volto-ueu/helpers/taxonomy_utils';

import jwtDecode from 'jwt-decode';

const ChapterCard = ({ item, showDescription = true }) => {
  const user = useSelector((state) => state.users.user);
  const querystring = useSelector((state) => state.querystring);
  const index = querystring?.indexes?.taxonomy_unesco_categorization || {};
  const { values = {} } = index;
  const token = useSelector((state) => state.userSession.token);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const ch_authors = item.authors ? item.authors : [];
  const ch_other_authors = item.other_authors ? item.other_authors : [];
  const authors = [...ch_authors, ...ch_other_authors];
  return (
    <Segment className="artikulu-card">
      <Grid>
        <Grid.Column width={14}>
          <Grid.Row className="artikulu-title">
            <UniversalLink item={item}>
              {item.title && <StringToHTML string={item.title} />}
            </UniversalLink>
          </Grid.Row>
          {authors && authors.length > 0 && (
            <Grid.Row className="artikulu-authors">
              <strong>
                {authors.map((author, key) => {
                  return (
                    <React.Fragment key={key}>
                      {key !== 0 ? ', ' : ''}
                      {author['@id'] ? (
                        <UniversalLink href={author['@id']}>
                          {author.title}
                        </UniversalLink>
                      ) : (
                        <>{author}</>
                      )}
                    </React.Fragment>
                  );
                })}
              </strong>
            </Grid.Row>
          )}
          {item.taxonomy_unesco_categorization &&
            item.taxonomy_unesco_categorization.length > 0 && (
              <Grid.Row className="artikulu-knowledge">
                {item.taxonomy_unesco_categorization
                  .filter((cat) =>
                    values[cat]?.title
                      ? values[cat]?.title.includes(' » ')
                      : false,
                  )
                  .map((cat) => get_taxonomy_children(values[cat]?.title || ''))
                  .join(', ')}
              </Grid.Row>
            )}
          {showDescription && (
            <Grid.Row>
              {item.publication_title} ({item.chapter_year})
            </Grid.Row>
          )}
          <Grid.Row>
            {/* Chapter ak duen pdf a deskargatzeko botoia **/}
            {(item.downloadable_file || user.uztaro_id) && (
              <>
                {item.downloadable_file && !isLoggedIn ? (
                  <span
                    title="Hau deskargatzeko login egin behar duzu"
                    className="ui button ueu disabled"
                  >
                    Jaitsi
                    <Icon name="chevron down" />
                  </span>
                ) : (
                  <UniversalLink
                    href={`${item['@id']}/@@download/file`}
                    className="ui button ueu"
                    item={item}
                    // item={flattenToAppURL(`${item['@id']}/@@download/file`)}
                  >
                    {'Jaitsi'}
                    <Icon name="chevron down" />
                  </UniversalLink>
                )}
              </>
            )}
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default ChapterCard;
