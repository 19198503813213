export const GET_USERKUDEU = 'GET_USERKUDEU';

/**
 * Get Kudeu user.
 * @function getUserKudeu
 * @returns {Object} Get Kudeu user action.
 */
export function getUserKudeu(userId) {
  return {
    type: GET_USERKUDEU,
    request: {
      op: 'post',
      path: `@userkudeuget`,
      data: { userId: userId },
    },
  };
}

export const SET_USERKUDEU = 'SET_USERKUDEU';

/**
 * Get Kudeu user.
 * @function setUserKudeu
 * @returns {Object} Get Kudeu user action.
 */
export function setUserKudeu(kudeu_id, modifications) {
  return {
    type: SET_USERKUDEU,
    request: {
      op: 'patch',
      path: `@users/${kudeu_id}`,
      data: { ...modifications },
    },
  };
}
