import { defineMessages } from 'react-intl';
const messages = defineMessages({
  start: {
    id: 'start',
    defaultMessage: 'Noiz/Noiztik',
  },
  end: {
    id: 'end',
    defaultMessage: 'Noiz arte',
  },
  endDescription: {
    id: 'endDescription',
    defaultMessage: 'Ez badauka bukaera data, ez bete eremu hau.',
  },
});

export const DateTeaserSchemaEnhancer = (props) => {
  const { intl, schema } = props;
  return {
    ...schema,
    fieldsets: [
      {
        ...schema.fieldsets[0],
        fields: ['title', 'start', 'end'],
      },
    ],
    properties: {
      ...schema.properties,
      start: {
        title: intl.formatMessage(messages.start),
        widget: 'date',
      },
      end: {
        title: intl.formatMessage(messages.end),
        description: intl.formatMessage(messages.endDescription),
        widget: 'date',
      },
    },
  };
};
