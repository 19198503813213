import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import { structure_taxonomy_content_terms } from 'volto-ueu/helpers/taxonomy_utils';

const CourseKnowledgeFieldsCard = ({ content, linked = true }) => {
  const knowledge_tree = structure_taxonomy_content_terms(
    content.taxonomy_unesco_categorization,
  );
  return (
    <>
      <Header as="h2" className="KnowledgeFieldsHeader">
        <FormattedMessage
          id="Jakintza-arloak"
          defaultMessage="Jakintza-arloak"
        />
      </Header>
      <Grid className="KnowledgeFields">
        <Grid.Row centered columns={6}>
          {knowledge_tree.length > 0 &&
            knowledge_tree?.map((item, index) => (
              <Grid.Column key={index} textAlign="center">
                {linked ? (
                  <UniversalLink
                    href={`${content.parent['@id']}?query=%5B%7B"i"%3A"taxonomy_unesco_categorization"%2C"o"%3A"paqo.list.contains"%2C"v"%3A%5B"${item.value}"%5D%7D%5D`}
                    title={item.label}
                  >
                    {item.label}
                  </UniversalLink>
                ) : (
                  <span>{item.label}</span>
                )}
              </Grid.Column>
            ))}
        </Grid.Row>
      </Grid>
    </>
  );
};

export default CourseKnowledgeFieldsCard;
