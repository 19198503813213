export const GET_PUBLICATIONS = 'GET_PUBLICATIONS';
/**
 * Get tokens.
 * @function getPublications
 * @returns {Object} Get tokens action.
 */
export function getPublications(path, limit = 4) {
  return {
    type: GET_PUBLICATIONS,
    request: {
      op: 'get',
      path: `@search?path.query=${path}&portal_type=Publication&sort_on=created&sort_order=reverse&sort_limit=${limit}`,
    },
  };
}

export function getPublicationsByTeacher(uid, limit = 4) {
  return {
    type: GET_PUBLICATIONS,
    request: {
      op: 'get',
      path: `@search?portal_type=Book&sort_on=created&sort_order=reverse&sort_limit=${limit}&all_authors=${uid}`,
    },
  };
}
