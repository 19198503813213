import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { checkNan } from 'volto-ueu/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Container, Segment } from 'semantic-ui-react';
import { Form } from 'volto-ueu/components';
import { getUser } from '@plone/volto/actions/users/users';

export const PreviousNaRequest = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const users = useSelector((state) => state.users);
  const check_nan = useSelector((state) => state.checkNan);
  const token = useSelector((state) => state.userSession.token);
  const userId = token ? jwtDecode(token).sub : '';
  useEffect(() => {
    if (check_nan.loaded && check_nan.data.status === 'updated') {
      dispatch(getUser(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, check_nan]);

  return (
    <Container className="izen-emate-formularioa-container">
      <Segment>
        Kaixo <strong>{users.user.izena}</strong>!<br />
        Posible da gure datu basean zuri buruzko datuak izatea (aurretik
        matrikularen bat egin duzulako, hizlari izan zarelako...) eta
        errepikapenak ekiditeko zure NA zenbakia behar dugu.
        <br />
        Lagun gaitzazu zuri buruzko datuak eguneratzen eta idatzi mesedez{' '}
        <strong>IZENA</strong>, abizenik gabe eta zure{' '}
        <strong>NA (NAN, Pasaportea, Gizarte Segurantza...)</strong> ondoko
        formularioan.
        <br />
        <br />
        NA: printzipioz, espainiako NAN-az gain,{' '}
        <strong>beste zenbaki batzuk ere onartzen ditugu</strong> (frantziako
        Gizarte segurantza, pasaportea... etab) baina modu konkretu batean sartu
        behar dira kudeun:
        <ul>
          <li>
            - <strong>NA espainola formatua:</strong> 15387508W
          </li>
          <li>- Bestelako agiriak: gidoirik gabe</li>
        </ul>
        <br />
        {/* <strong>Jarraibideak:</strong>
              <ol>
                <li>
                  Idatzi zure <strong>IZENA</strong> (abizenik gabe)
                  formularioko <i>Izena</i> eremuan
                </li>
                <li>
                  Idatzi zure <strong>Nortasun Agiria (NA)</strong> formularioko
                  <i>Nortasun Agiria</i> eremuan
                </li>
              </ol> */}
      </Segment>
      <Form
        title="Datuen konprobaketa"
        formData={{}}
        error={check_nan.data.status === 'error' ? check_nan.data : null}
        loading={check_nan.loading}
        schema={{
          type: 'object',
          fieldsets: [
            {
              id: 'default',
              title: 'Default',
              fields: ['izena', 'nan'],
            },
          ],
          properties: {
            izena: {
              title: 'Izena',
              description:
                'Idatzi zure IZENA bakarrik, ABIZENIK GABE. Adb. Mari Jose, Joxe Mari edo Ander. ',
              type: 'text',
            },
            nan: {
              title: 'Nortasun Agiria',
              type: 'text',
            },
          },
          required: ['izena', 'nan'],
        }}
        onSubmit={(event) => {
          dispatch(checkNan({ nan: event.nan, izena: event.izena }));
        }}
        isLast={false}
        isFirst={true}
        submitLabel="Konprobatu NA zenbakia"
        step={1}
        intl={intl}
      />
    </Container>
  );
};
