/**
 * PodcastView view component.
 * @module components/theme/View/PodcastView
 */
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container } from 'semantic-ui-react';

import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { hasBlocksData } from '@plone/volto/helpers/Blocks/Blocks';

import { Picture } from 'volto-ueu/components';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';

import moment from 'moment';
import PropTypes from 'prop-types';
import { formatLL } from '../../utils';

/**
 * PodcastView view component class.
 * @function PodcastView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PodcastView = ({ content, location }) => {
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);

  return (
    <Container className="view-wrapper">
      {content.title && (
        <h1 className="documentFirstHeading">
          {content.title}
          {content.subtitle && ` - ${content.subtitle}`}
        </h1>
      )}
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            {content.image && (
              <figure>
                <Picture
                  source="newsitem"
                  imageBase={`${content['@id']}/@@images/image`}
                  alt={content.title}
                  content={content}
                  lazy={false}
                ></Picture>
                {content.image_caption && (
                  <figcaption>{content.image_caption}</figcaption>
                )}
              </figure>
            )}
            {content?.effective !== null && (
              <p className="newsitem-date">{formatLL(content?.effective)}</p>
            )}
          </Grid.Column>
          <Grid.Column width={9}>
            {content?.url.match('spotify') ? (
              <iframe
                title="Spotify embed"
                src={content?.url.replace(
                  'open.spotify.com',
                  'open.spotify.com/embed',
                )}
                frameborder="0"
                width="100%"
                height="352"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            ) : content?.url.match('youtu') ? (
              <div class="ui 16:9 embed">
                <iframe
                  width="560"
                  height="315"
                  src={content?.url.replace(
                    'youtu.be',
                    'www.youtube.com/embed',
                  )}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            ) : (
              <div class="ui 16:9 embed">
                <iframe
                  title="Ikerl(h)ari  4. atala: Etorkizuna"
                  width="560"
                  height="315"
                  src={content?.url.replace('/w/', '/videos/embed/')}
                  frameborder="0"
                  allowfullscreen=""
                  sandbox="allow-same-origin allow-scripts allow-popups"
                />
              </div>
            )}

            {content.description ? (
              <StringToHTML
                className="documentDescription"
                string={content.description}
              />
            ) : (
              <p className="documentDescription">{content.description}</p>
            )}
            {content.text && <StringToHTML string={content.text.data} />}
            {hasBlocksData(content) &&
            content.blocks_layout?.items?.length > 0 ? (
              <RenderBlocks content={content} />
            ) : (
              ''
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PodcastView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default PodcastView;
