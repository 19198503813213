import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, Container, Segment } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getBreadcrumbs } from '@plone/volto/actions/breadcrumbs/breadcrumbs';
import { getBaseUrl } from '@plone/volto/helpers/Url/Url';
import { hasApiExpander } from '@plone/volto/helpers/Utils/Utils';
// import Icon from '@plone/volto/components/theme/Icon/Icon';
// import homeSVG from '@plone/volto/icons/home.svg';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
  controlpanel: {
    id: 'Site Setup',
    defaultMessage: 'Site Setup',
  },
});

const BreadcrumbsComponent = ({ pathname }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname: realPath } = useLocation();
  const controlpanelItems = [
    {
      url: '/controlpanel',
      title: intl.formatMessage(messages.controlpanel),
    },
  ];

  const subsite = useSelector(
    (state) => state.content?.data?.['@components']?.subsite,
  );
  const items = useSelector(
    (state) =>
      realPath.startsWith('/controlpanel')
        ? controlpanelItems
        : state.breadcrumbs.items,
    shallowEqual,
  );
  // const root = useSelector((state) => state.breadcrumbs.root);

  useEffect(() => {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(pathname))) {
      dispatch(getBreadcrumbs(getBaseUrl(pathname)));
    }
  }, [dispatch, pathname]);

  return (
    <>
      {items.length > 0 && !subsite?.title ? (
        <Segment
          role="navigation"
          aria-label={intl.formatMessage(messages.breadcrumbs)}
          className="breadcrumbs"
          secondary
          vertical
        >
          <Container>
            <Breadcrumb>
              {items?.map((item, index, items) => [
                index < items.length - 1 ? (
                  <React.Fragment key={item.url}>
                    <Link to={item.url} className="section">
                      {item.title}
                    </Link>
                    <Breadcrumb.Divider key={`divider-${item.url}`} />
                  </React.Fragment>
                ) : (
                  <Breadcrumb.Section key={item.url} active>
                    {item.title}
                  </Breadcrumb.Section>
                ),
              ])}
            </Breadcrumb>
          </Container>
        </Segment>
      ) : (
        items.length > 0 && (
          <div
            role="navigation"
            aria-label={intl.formatMessage(messages.breadcrumbs)}
            className="ikergazte-breadcrumbs"
          >
            <Breadcrumb>
              <React.Fragment key={subsite?.['@id']}>
                <UniversalLink href={subsite?.['@id']} className="section">
                  Home
                </UniversalLink>
                <Breadcrumb.Divider key={`divider-${subsite?.['@id']}`} />
              </React.Fragment>
              {items?.map((item, index, items) => [
                index < items.length - 1 ? (
                  <React.Fragment key={item.url}>
                    <Link to={item.url} className="section">
                      {item.title}
                    </Link>
                    <Breadcrumb.Divider key={`divider-${item.url}`} />
                  </React.Fragment>
                ) : (
                  <Breadcrumb.Section key={item.url} active>
                    {item.title}
                  </Breadcrumb.Section>
                ),
              ])}
            </Breadcrumb>
          </div>
        )
      )}
    </>
  );
};

BreadcrumbsComponent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default BreadcrumbsComponent;
