import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Image, Icon, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import config from '@plone/volto/registry';

import { getRegistry } from 'volto-ueu/actions';
import { Picture, BuyEditionsForm } from 'volto-ueu/components';

import jwtDecode from 'jwt-decode';

const BookCard = ({ content }) => {
  // const { addSaskira } = useUeuSaskia();
  const dispatch = useDispatch();

  const bazkide_deskontua_key = config.settings.registry.bazkide_deskontua;
  const token = useSelector((state) => state.userSession.token);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const { settings } = config;

  useEffect(() => {
    dispatch(getRegistry(bazkide_deskontua_key));
  }, [dispatch, bazkide_deskontua_key]);

  const digital_editions =
    content.items?.length > 0 &&
    content.items.filter(
      (item) => item['@type'] === 'Edition' && item.format !== 'paper',
    );
  const paper_editions =
    content.items?.length > 0 &&
    content.items.filter(
      (item) => item['@type'] === 'Edition' && item.format === 'paper',
    );
  return (
    <Grid className="aldizkaria-card">
      <Grid.Row>
        <Grid.Column>
          {!content?.image && (
            <Image
              src={DefaultImageSVG}
              alt="This content has no image, this is a default placeholder."
              size="large"
            />
          )}
          {content?.image && (
            <Picture
              imageBase={`${content['@id']}/@@images/image`}
              alt={content.title}
            ></Picture>
          )}
        </Grid.Column>
      </Grid.Row>
      {((paper_editions.length > 0 &&
        paper_editions.some((pe) => pe.available)) ||
        (digital_editions.length > 0 &&
          digital_editions.some((de) => de.available))) && (
        <Grid.Row>
          <Grid.Column>
            {digital_editions.some((de) => de.available) ? (
              digital_editions.map((de, key) => {
                return de.price === 0 && isLoggedIn ? (
                  <UniversalLink
                    key={key}
                    href={`${de['@id']}/@@download/file`}
                    className="ui button ueu download-chapter"
                  >
                    {`Deskargatu (${de.format}) `}
                    <Icon name="chevron down" />
                  </UniversalLink>
                ) : de.price === 0 && !isLoggedIn ? (
                  <React.Fragment key={key}>
                    <Segment color="black">
                      <p>
                        Liburu honen doako edizioa deskargatzeko webgunean login
                        egin behar da:
                      </p>
                      <UniversalLink
                        aria-label="login"
                        className="ui button ueu primary"
                        href={`/login${
                          content?.['@id']
                            ? `?return_url=${content['@id'].replace(
                                settings.apiPath,
                                '',
                              )}`
                            : ''
                        }`}
                      >
                        <FormattedMessage id="Log in" defaultMessage="Log in" />
                      </UniversalLink>
                    </Segment>
                    <span className="ui button ueu download-chapter disabled">
                      {`Deskargatu (${de.format}) `}
                      <Icon name="chevron down" />
                    </span>
                  </React.Fragment>
                ) : (
                  <></>
                );
              })
            ) : (
              <></>
            )}
            <BuyEditionsForm
              content={content}
              paper_edition={paper_editions[0]}
              digital_editions={digital_editions}
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default BookCard;
