/**
 * Registry reducer.
 * @module reducers/registry
 */

import { EMAIL_TEMPLATES, EMAIL_TEMPLATE } from '../../actions';

const getInitialState = {
  error: null,
  loaded: false,
  loading: false,
  templates: [],
  data: '',
};

export const emailTemplatesReducer = (state = getInitialState, action = {}) => {
  switch (action?.type) {
    case `${EMAIL_TEMPLATES}_PENDING`:
    case `${EMAIL_TEMPLATE}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${EMAIL_TEMPLATES}_FAIL`:
    case `${EMAIL_TEMPLATE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };

    case `${EMAIL_TEMPLATES}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        templates: action.result,
      };
    case `${EMAIL_TEMPLATE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        data: { ...state.data, [action.templatename]: action.result },
      };
    default:
      return state;
  }
};
