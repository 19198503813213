import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatLL } from '../../../utils';

const IragarkiaCard = ({
  item,
  isEditMode,
  showDescription,
  destakatua,
  showDate,
}) => {
  let lang = useSelector((state) => state.intl.locale);
  const qs = useSelector((state) => state.querystring);
  const taxonomy_values = qs.indexes?.taxonomy_iragarki_atalak?.values;
  moment.locale(lang);
  return (
    <Segment className="iragarkia-card">
      <Header as="h3">
        <ConditionalLink item={item} condition={!isEditMode}>
          {item.title}
        </ConditionalLink>
      </Header>
      {item?.effective !== null && (
        <p>
          <span>{item?.name && <strong>{item.name}</strong>}</span>
          <br />
          <span>{item?.effective !== null && formatLL(item?.effective)}</span>
        </p>
      )}
      <div className="iragarki-tags">
        {qs.loaded &&
          item.taxonomy_iragarki_atalak &&
          item.taxonomy_iragarki_atalak.forEach((ia) => (
            <span className="ui label">{taxonomy_values[ia].title}</span>
          ))}
      </div>
    </Segment>
  );
};

export default IragarkiaCard;
