import React from 'react';
import { Header, Button, Modal } from 'semantic-ui-react';

const UeuModal = ({
  children,
  closeAction,
  okAction,
  header,
  trigger,
  ...modalProps
}) => {
  const [open, setOpen] = React.useState(false);
  const { closeText, closeOnClick = () => {}, ...closeProps } = closeAction;
  const { okText, okOnClick, ...okProps } = okAction;
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      closeOnDimmerClick={false}
      dimmer="blurring"
      trigger={trigger()}
      {...modalProps}
    >
      {header && <Header>{header}</Header>}
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button
          className="ueu"
          onClick={() => {
            closeOnClick();
            setOpen(false);
          }}
          {...closeProps}
        >
          {closeText}
        </Button>
        <Button
          className="ueu primary"
          onClick={() => {
            okOnClick();
            setOpen(false);
          }}
          {...okProps}
        >
          {okText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UeuModal;
