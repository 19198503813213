import React from 'react';
import { Image } from 'semantic-ui-react';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { defineMessages, useIntl } from 'react-intl';
import config from '@plone/volto/registry';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const messages = defineMessages({
  defaultImageAlt: {
    id: 'defaultImageAlt',
    defaultMessage: 'This content has no image, this is a default placeholder.',
  },
});

const Picture = ({
  imageBase,
  alt,
  source,
  lazy = true,
  content,
  className = '',
  ...props
}) => {
  const intl = useIntl();
  const pictureOptions = config.settings.pictureOptions;
  let sources = [];
  if (Object.keys(pictureOptions).includes(source)) {
    sources = pictureOptions[source];
  } else {
    sources = pictureOptions[Object.keys(pictureOptions)[0]];
  }
  return imageBase ? (
    <picture>
      {sources.map((source, key) => {
        return (
          <source
            key={key}
            media={source.media}
            width={
              content?.image?.scales?.[source.image]
                ? content.image.scales[source.image].width
                : null
            }
            height={
              content?.image?.scales?.[source.image]
                ? content.image.scales[source.image].height
                : null
            }
            srcSet={
              content?.image?.scales?.[source.image]
                ? content.image.scales[source.image].download
                : `${imageBase}/${source.image}`
            }
          />
        );
      })}
      {lazy ? (
        <LazyLoadImage
          alt={alt}
          src={
            content?.image?.scales?.['teaser']
              ? content.image.scales['teaser'].download
              : `${imageBase}/teaser`
          }
          className={`ui image ${className}`}
          width={'100%'}
          height={'auto'}
        />
      ) : (
        <Image
          alt={alt}
          src={
            content?.image?.scales?.['teaser']
              ? content.image.scales['teaser'].download
              : `${imageBase}/teaser`
          }
          width={
            content?.image?.scales?.['teaser']
              ? content.image.scales['teaser'].width
              : '100%'
          }
          height={
            content?.image?.scales?.['teaser']
              ? content.image.scales['teaser'].height
              : 'auto'
          }
        />
      )}
    </picture>
  ) : (
    <Image
      src={DefaultImageSVG}
      alt={intl.formatMessage(messages.defaultImageAlt)}
      size="small"
    />
  );
};

export default Picture;
