import {
  GET_EROSKETAK,
  GET_ALL_EROSKETAK,
  ADD_EROSKETAK,
  POST_EROSKETA,
  DELETE_EROSKETA,
} from '../../actions';
// import { map } from 'lodash';
const getInitialState = {
  get: { error: null, loaded: false, loading: false },
  add: { error: null, loaded: false, loading: false },
  post: { error: null, loaded: false, loading: false, result: null },
  delete: { error: null, loaded: false, loading: false, result: null },
  erosketak: [],
};

/**
 * erosketak reducer.
 * @function erosketak
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const erosketakReducer = (state = getInitialState, action = {}) => {
  switch (action.type) {
    case `${GET_EROSKETAK}_PENDING`:
      return {
        ...state,
        get: {
          ...state.get,
          error: null,
          loaded: false,
          loading: true,
        },
        erosketak: [],
      };
    case `${GET_EROSKETAK}_FAIL`:
      return {
        ...state,
        get: {
          ...state.get,
          error: true,
          loaded: false,
          loading: false,
        },
        erosketak: [],
      };
    case `${GET_EROSKETAK}_SUCCESS`:
      return {
        ...state,
        get: {
          ...state.get,
          error: null,
          loaded: true,
          loading: false,
        },
        erosketak: action.result,
      };
    case `${GET_ALL_EROSKETAK}_PENDING`:
      return {
        ...state,
        getAll: {
          ...state.getAll,
          error: null,
          loaded: false,
          loading: true,
        },
        erosketak: [],
      };
    case `${GET_ALL_EROSKETAK}_FAIL`:
      return {
        ...state,
        getAll: {
          ...state.getAll,
          error: true,
          loaded: false,
          loading: false,
        },
        erosketak: [],
      };
    case `${GET_ALL_EROSKETAK}_SUCCESS`:
      return {
        ...state,
        getAll: {
          ...state.getAll,
          error: null,
          loaded: true,
          loading: false,
        },
        erosketak: action.result,
      };
    case `${ADD_EROSKETAK}_PENDING`:
      return {
        ...state,
        add: {
          ...state.add,
          error: null,
          loaded: false,
          loading: true,
        },
        erosketak: [],
      };
    case `${ADD_EROSKETAK}_FAIL`:
      return {
        ...state,
        add: {
          ...state.add,
          error: true,
          loaded: false,
          loading: false,
        },
        erosketak: [],
      };
    case `${ADD_EROSKETAK}_SUCCESS`:
      return {
        ...state,
        add: {
          ...state.add,
          error: null,
          loaded: true,
          loading: false,
        },
        erosketak: action.result,
      };
    case `${POST_EROSKETA}_PENDING`:
      return {
        ...state,
        post: {
          ...state.post,
          error: null,
          loaded: false,
          loading: true,
          result: null,
        },
      };
    case `${POST_EROSKETA}_FAIL`:
      return {
        ...state,
        post: {
          ...state.post,
          error: true,
          loaded: false,
          loading: false,
          result: null,
        },
      };
    case `${POST_EROSKETA}_SUCCESS`:
      return {
        ...state,
        post: {
          ...state.post,
          error: null,
          loaded: true,
          loading: false,
          result: action.result,
        },
      };
    case `${DELETE_EROSKETA}_PENDING`:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loaded: false,
          loading: true,
          result: null,
        },
      };
    case `${DELETE_EROSKETA}_FAIL`:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: true,
          loaded: false,
          loading: false,
          result: null,
        },
      };
    case `${DELETE_EROSKETA}_SUCCESS`:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loaded: true,
          loading: false,
          result: action.result,
        },
      };
    default:
      return state;
  }
};
