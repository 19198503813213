import { isInternalURL } from '@plone/volto/helpers/Url/Url';

export function getTeaserImageURL(href, image) {
  if (image) {
    if (isInternalURL(image['@id'])) {
      return `${image['@id']}/@@images/image`;
    } else {
      return image['@id'];
    }
  } else {
    return `${href['@id']}/@@images/${href.image_field || 'preview_image'}`;
  }
}
