import React from 'react';
import { Grid, List, Segment } from 'semantic-ui-react';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { get_taxonomy_children } from 'volto-ueu/helpers/taxonomy_utils';
const GeneralInfoCard = ({ content }) => {
  const authors = content?.authors
    ? content?.authors?.map((author) => author)
    : [];
  const other_authors = content?.other_authors
    ? content?.other_authors?.map((other_author) => {
        return { title: other_author };
      })
    : [];
  const all_authors = [...authors, ...other_authors];
  return (
    <Segment className="generalinfo-card">
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <List bulleted>
              {all_authors.length > 0 && (
                <List.Item>
                  <strong>{'Autorea(k): '}</strong>
                  {all_authors?.map((a, key) => {
                    return a['@id'] ? (
                      <UniversalLink key={key} href={a['@id']}>
                        {key ? ', ' : ''}
                        {a.title}
                      </UniversalLink>
                    ) : (
                      <span key={key}>
                        {key ? ', ' : ''} {a.title}
                      </span>
                    );
                  })}
                </List.Item>
              )}
              {content.taxonomy_unesco_categorization &&
                content.taxonomy_unesco_categorization.length > 0 && (
                  <List.Item>
                    <strong>{'Jakintza-arloak: '}</strong>
                    {content.taxonomy_unesco_categorization
                      .map((cat) => get_taxonomy_children(cat.title))
                      .join(', ')}
                  </List.Item>
                )}
              <List.Item>
                <strong>{'ISBN: '}</strong>
                {content?.isbn && `${content.isbn}`}
              </List.Item>
              <List.Item>
                <strong>{'Argitalpen urtea: '}</strong>
                {content?.publication_year && `${content.publication_year}`}
              </List.Item>
              <List.Item>
                <strong>{'Orrialdeak: '}</strong>
                {content?.pages && `${content.pages}`}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default GeneralInfoCard;
