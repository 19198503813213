import React from 'react';

export const useMobileChecker = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  function handleWindowSizeChange() {
    setIsMobile(window?.innerWidth <= 768);
  }
  React.useEffect(() => {
    handleWindowSizeChange();
    window && window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window && window.removeEventListener('resize', handleWindowSizeChange);
    };
  });

  return isMobile;
};
