/**
 * Saskia actions.
 * @module actions/getSaskiaItems
 */
export const GET_SASKIA = 'GET_SASKIA';
export const GET_SASKIA_PREZIOA = 'GET_SASKIA_PREZIOA';
export const PAY_SASKIA = 'PAY_SASKIA';
export const SET_SASKIA = 'SET_SASKIA';
/**
 * Set Saskia items.
 * @function setSaskiaItems
 * @returns {Object} Set extra items action.
 */
export function setSaskiaItems(items) {
  return {
    type: SET_SASKIA,
    items: items,
  };
}

/**
 * Get Saskia items.
 * @function getSaskiaItems
 * @returns {Object} Get extra items action.
 */

export function getSaskiaItems() {
  return {
    type: GET_SASKIA,
  };
}

/**
 * Get Saskia prezioa.
 * @function getSaskiaPrezioa
 * @returns {Object} Get extra items action.
 */

export function getSaskiaPrezioa(saskia) {
  return {
    type: GET_SASKIA_PREZIOA,
    request: {
      op: 'post',
      path: `@saskiaprezioa`,
      data: saskia,
    },
  };
}

/**
 * Pay saskia.
 * @function paySaskia
 */

export function paySaskia(uid) {
  return {
    type: PAY_SASKIA,
    request: {
      op: 'patch',
      path: `@saskia`,
      data: { id: uid },
    },
  };
}
