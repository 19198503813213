import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Button: {
    id: 'Button',
    defaultMessage: 'Button',
  },
  variation: {
    id: 'variation',
    defaultMessage: 'Variation',
  },
  buttonText: {
    id: 'buttonText',
    defaultMessage: 'Button Text',
  },
  linkDescription: {
    id: 'linkDescription',
    defaultMessage: 'Select site content or paste external url',
  },
  position: {
    id: 'position',
    defaultMessage: 'Kokapena',
  },
});
export const ButtonSchema = (config, intl) => {
  const variationsConfig = config.blocks.blocksConfig['UEUbutton'].variation;
  const variations = Object.keys(variationsConfig).map((variation) => [
    variationsConfig[variation].id,
    variationsConfig[variation].title,
  ]);

  return {
    title: intl.formatMessage(messages.Button),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['variation', 'title', 'link', 'position'],
      },
    ],
    properties: {
      variation: {
        title: intl.formatMessage(messages.variation),
        choices: [...variations],
        required: true,
      },
      title: {
        title: intl.formatMessage(messages.buttonText),
        type: 'string',
      },
      link: {
        title: 'link URL',
        description: intl.formatMessage(messages.linkDescription),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
        allowExternals: true,
      },
      position: {
        title: intl.formatMessage(messages.position),
        choices: [
          ['unset', 'Ezkerrean'],
          ['center', 'Erdian'],
          ['flex-end', 'Eskuinean'],
        ],
        required: true,
      },
    },
    required: [],
  };
};
