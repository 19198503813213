/**
 * Registry reducer.
 * @module reducers/registry
 */

import { CHECK_NAN } from '../../actions';

const getInitialState = {
  error: null,
  loaded: false,
  loading: false,
  data: {},
};

export const checkNanReducer = (state = getInitialState, action = {}) => {
  switch (action?.type) {
    case `${CHECK_NAN}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${CHECK_NAN}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };

    case `${CHECK_NAN}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        data: action.result,
      };
    default:
      return state;
  }
};
