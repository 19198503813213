import { GET_PUBLICATIONS } from 'volto-ueu/actions';
const initialState = {
  error: null,
  publications: [],
  loaded: false,
  loading: false,
};

/**
 * Publications reducer.
 * @function publications
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const getPublicationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_PUBLICATIONS}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        publications: [],
      };
    case `${GET_PUBLICATIONS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        publications: action.result.items,
      };
    case `${GET_PUBLICATIONS}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
        publications: [],
      };
    default:
      return state;
  }
};
