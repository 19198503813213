import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { Grid } from 'semantic-ui-react';

import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { withQueryString } from '../SearchBlock/withQueryString';

import { slugify } from 'volto-ueu/components/utils';
import { getCardComponent } from './Cards';

import moment from 'moment';
import PropTypes from 'prop-types';

const UeuListing = (props) => {
  const {
    items,
    howManyColumns = 2,
    moreLinkText,
    moreLinkUrl,
    moreLinkText2,
    moreLinkUrl2,
    header,
    headerUrl,
    headerTag = 'h2',
    isEditMode,
    showDescription,
    firstElement,
    showDate,
    featureWinner,
    // showUnconditionally = false,
    doubling = false,
  } = props;
  const lang = useSelector((state) => state.intl.locale);
  const querystring = useSelector((state) => state.querystring);
  // const subrequest = useSelector(
  //   (state) => state.querystringsearch.subrequests?.[block],
  // );
  moment.locale(lang);
  let headerHref = headerUrl?.[0]?.['@id'] || '';
  let HeaderLink = () => (
    <ConditionalLink to={headerHref} condition={!isEditMode && !!headerHref}>
      {header || headerHref}
    </ConditionalLink>
  );
  const HeaderTag = headerTag;

  let moreHref = moreLinkUrl?.[0]?.['@id'] || '';
  let moreLink = (
    <ConditionalLink to={moreHref} condition={!isEditMode && !!moreHref}>
      {moreLinkText || moreHref}
    </ConditionalLink>
  );
  let moreHref2 = moreLinkUrl2?.[0]?.['@id'] || '';
  let moreLink2 = (
    <ConditionalLink to={moreHref2} condition={!isEditMode && !!moreHref2}>
      {moreLinkText2 || moreHref2}
    </ConditionalLink>
  );
  let mapItems = [...items];
  if (firstElement) {
    mapItems.splice(0, 1);
  }

  const listingType =
    items.length > 0 ? `listing-${slugify(items[0]['@type'])}` : '';

  const CardComponent = getCardComponent(items[0]);
  return (
    <div className={listingType}>
      {(header || headerHref) && (
        <HeaderTag className={'listing-header'}>
          <HeaderLink />
        </HeaderTag>
      )}
      <Grid columns={howManyColumns} stackable doubling={doubling}>
        {firstElement === true && (
          <Grid.Column>
            <CardComponent
              item={items[0]}
              showDescription={showDescription}
              isEditMode={isEditMode}
              destakatua={firstElement}
              querystring={querystring}
              showDate={showDate}
            />
          </Grid.Column>
        )}
        {mapItems.map((item, key) => {
          const CardComponent = getCardComponent(item);
          return (
            <Grid.Column id={key} key={key}>
              <CardComponent
                id={key}
                item={item}
                showDescription={showDescription}
                isEditMode={isEditMode}
                querystring={querystring}
                showDate={showDate}
                featureWinner={featureWinner}
              ></CardComponent>
            </Grid.Column>
          );
        })}
      </Grid>
      {(moreLink || moreLink2) && (
        <div className="listing-footer">
          {moreLink && moreLink}
          {moreLink2 && moreLink2}
        </div>
      )}
    </div>
  );
};
UeuListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default compose(withQueryString)(UeuListing);
