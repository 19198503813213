import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Header, Label, Icon, Segment } from 'semantic-ui-react';

import { searchContent } from '@plone/volto/actions/search/search';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import config from '@plone/volto/registry';

import { getErosketak, getAllErosketak } from 'volto-ueu/actions';
import { PostForm } from 'volto-ueu/components/PostForm/PostForm';
import {
  PAYMENT_MODE_TRANSFERENCE,
  PAYMENT_MODE_PAYPAL,
} from 'volto-ueu/constants';

// import { NIRE_EROSKETAK_ROUTE } from 'volto-ueu/constants';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

const ErosketaView = ({ content, location, staticContext }) => {
  const erosketak_state = useSelector((state) => state.erosketak);
  const erosketak = useSelector((state) => state.erosketak.erosketak);
  const token = useSelector((state) => state.userSession.token);
  const user = useSelector((state) => state.users.user);
  const subrequests = useSelector((state) => state.search?.subrequests);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const [erosketa, setErosketa] = useState();
  let lang = useSelector((state) => state.intl.locale);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      user?.roles &&
      config.settings.importerRoles.find((element) =>
        user?.roles.includes(element),
      )
    ) {
      dispatch(getAllErosketak());
    } else {
      dispatch(getErosketak());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const params = useParams();
  useEffect(() => {
    setErosketa(
      erosketak.filter((erosketa) => erosketa.UID === params.erosketa)[0],
    );
  }, [params.erosketa, erosketak]);

  useEffect(() => {
    erosketa?.saskia?.content &&
      Object.keys(erosketa?.saskia?.content).forEach((item) => {
        if (
          !subrequests?.[erosketa?.saskia?.content?.[item]?.publication] &&
          !subrequests.loading
        ) {
          dispatch(
            searchContent(
              '',
              {
                UID: erosketa.saskia?.content?.[item]?.publication,
              },
              erosketa.saskia?.content?.[item]?.publication,
            ),
          );
        }
        if (
          erosketa.saskia?.content?.[item].format === 'Digitala' &&
          erosketa?.saskia?.content?.[item]?.edition &&
          erosketa?.status === 1
        ) {
          erosketa?.saskia?.content?.[item]?.edition
            .split('##')
            .forEach((edition_id) => {
              if (!subrequests?.[edition_id] && !subrequests.loading) {
                dispatch(
                  searchContent(
                    '',
                    {
                      UID: edition_id,
                    },
                    edition_id,
                  ),
                );
              }
            });
        }
      });
  }, [erosketa, subrequests, dispatch]);

  // const handlePay = (UID) => {
  //   erosketa.saskia['UID'] = UID;
  //   if (erosketa?.status === 0) {
  //     dispatch(postErosketa(erosketa.saskia, erosketa.eroslea));
  //   }
  // };
  moment.locale(lang);
  return !isLoggedIn ? (
    <>
      {token ? (
        <Forbidden pathname={location.pathname} staticContext={staticContext} />
      ) : (
        <Unauthorized
          pathname={location.pathname}
          staticContext={staticContext}
        />
      )}
    </>
  ) : (
    <div className="ui container">
      {erosketak_state.post.loaded &&
        erosketak_state.post?.result?.registration_id && (
          <PostForm
            action={erosketak_state.post.result.payment_params?.url}
            payment_params={erosketak_state.post.result.payment_params?.data}
          />
        )}
      <Helmet title="Nire erosketa"></Helmet>
      <Header as="h1">Nire erosketa</Header>
      <Segment basic textAlign="center">
        {erosketa?.status === 0 ? (
          <Label color="red">
            <FormattedMessage
              id="Ordaindu gabe"
              defaultMessage="Ordaindu gabe"
            />
          </Label>
        ) : erosketa?.status === 1 ? (
          <Label color="green">
            <FormattedMessage id="Ordainduta" defaultMessage="Ordainduta" />
          </Label>
        ) : (
          <></>
        )}
        {/* {erosketa?.status === 0 && (
          <Button
            onClick={() => handlePay(erosketa?.UID)}
            className="ueu secondary"
          >
            Ordaindu erosketa
          </Button>
        )} */}
        {erosketa?.status === 0 && (
          <>
            {erosketa?.eroslea?.payment_mode === PAYMENT_MODE_TRANSFERENCE && (
              <p>
                Ordainketa egiteko modu bezala transferentzia aukeratu duzu.
                Jarraitu jarraibide hauek erosketa burutzeko:
              </p>
            )}
            {erosketa?.eroslea?.payment_mode === PAYMENT_MODE_PAYPAL && (
              <p>
                Paypal bidez ordaindu duzu, posible da erosketak ordainduta
                bezala markatua izateko denbora pixka bat behar izatea. 24
                orduren buruan ez bada ordaindu, jarri mesedez gurekin
                harremanetan.
              </p>
            )}
          </>
        )}
      </Segment>
      {erosketa ? (
        <>
          <Grid className="saski-taula">
            <Grid.Row columns={3}>
              <Grid.Column width={6}>
                <strong>Argitalpena</strong>
              </Grid.Column>
              <Grid.Column width={1}>
                <strong>Prezioa</strong>
              </Grid.Column>
              <Grid.Column width={4}>
                <strong>Kopurua</strong>
              </Grid.Column>
            </Grid.Row>
            {erosketa?.saskia?.content &&
              Object.keys(erosketa?.saskia?.content)?.map((item, key) => (
                <Grid.Row key={key} columns={3}>
                  <Grid.Column width={6}>
                    {subrequests?.[
                      erosketa?.saskia?.content?.[item]?.publication
                    ]?.items?.[0]?.['@id'] && (
                      <>
                        {erosketa?.saskia?.content?.[item]?.format ===
                        'Digitala' ? (
                          <>
                            <span>
                              {`${erosketa?.saskia?.content?.[item]?.title} (${erosketa?.saskia?.content?.[item]?.format})`}
                            </span>
                            {erosketa?.status === 1 && (
                              <ul>
                                <li>
                                  {erosketa?.saskia?.content?.[item]?.edition
                                    .split('##')
                                    .map((edition_id) => (
                                      <UniversalLink
                                        key={edition_id}
                                        className="ui button ueu download-chapter"
                                        href={`${subrequests?.[edition_id]?.items?.[0]?.['@id']}/@@download/file`}
                                      >
                                        Deskargatu (
                                        {
                                          subrequests?.[edition_id]?.items?.[0]
                                            ?.format
                                        }
                                        )
                                        <Icon name="chevron down" />
                                      </UniversalLink>
                                    ))}
                                </li>
                              </ul>
                            )}
                          </>
                        ) : (
                          <UniversalLink
                            href={
                              subrequests?.[
                                erosketa?.saskia?.content?.[item]?.publication
                              ]?.items?.[0]?.['@id'] ?? ''
                            }
                          >
                            {`${erosketa?.saskia?.content?.[item]?.title} (${erosketa?.saskia?.content?.[item]?.format})`}
                          </UniversalLink>
                        )}
                      </>
                    )}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    {`${erosketa?.saskia?.content?.[item]?.unit_price} €`}
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Label basic color="black" className="saskia-count">
                      {erosketa?.saskia?.content?.[item]?.count}
                    </Label>
                  </Grid.Column>
                </Grid.Row>
              ))}
          </Grid>
          <Grid className="saski-prezioak">
            <Grid.Row columns={3}>
              <Grid.Column width={6}></Grid.Column>
              <Grid.Column width={2}>Subtotala</Grid.Column>
              <Grid.Column width={3}>
                {`${erosketa?.saskia.subTotal} €`}
              </Grid.Column>
            </Grid.Row>
            {erosketa?.saskia.discounts?.length > 0 &&
              erosketa?.saskia.discounts.map((discount, key) => (
                <Grid.Row key={key} columns={3}>
                  <Grid.Column width={6}></Grid.Column>
                  <Grid.Column width={2}>{discount.title}</Grid.Column>
                  <Grid.Column width={3}>{`-${discount.value} €`}</Grid.Column>
                </Grid.Row>
              ))}
            <Grid.Row columns={3}>
              <Grid.Column width={6}></Grid.Column>
              <Grid.Column width={2}>Bidalketa gastuak</Grid.Column>
              <Grid.Column width={3}>
                {`${erosketa?.saskia.shipping} €`}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column width={6}></Grid.Column>
              <Grid.Column width={2}>
                <strong>Guztira </strong>
              </Grid.Column>
              <Grid.Column width={3}>
                <strong>{`${erosketa?.saskia.total} €`}</strong>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={6}></Grid.Column>
              <Grid.Column width={6}>
                <UniversalLink
                  href={location.pathname.split('/').slice(0, -1).join('/')}
                  className="ui button ueu primary"
                >
                  ITZULI EROSKETA ZERRENDARA
                </UniversalLink>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      ) : (
        <Header as="h2">
          Erosketaren helbidea ez dagokio zure erosketa bati
        </Header>
      )}
    </div>
  );
};

export default ErosketaView;
