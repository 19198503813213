import React, { useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Header,
  Table,
  Label,
  Button,
  Segment,
  Icon,
  Message,
  List,
  Grid,
  Pagination,
  Container,
} from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import config from '@plone/volto/registry';

import { getAllErosketak, paySaskia, deleteErosketa } from 'volto-ueu/actions';
import { UeuModal } from 'volto-ueu/components';
import {
  EROSKETA_GUZTIAK_ROUTE,
  PAYMENT_MODE_PAYPAL,
  PAYMENT_MODE_CARD,
  PAYMENT_MODE_TRANSFERENCE,
} from 'volto-ueu/constants';
import { useFilteredPagination } from 'volto-ueu/helpers';

import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { formatLL } from '../../utils';

const ErosketaGuztiakView = ({ content, location, staticContext }) => {
  const erosketak = useSelector((state) => state.erosketak);
  let lang = useSelector((state) => state.intl.locale);
  const token = useSelector((state) => state.userSession.token);
  const user = useSelector((state) => state.users.user);
  const paySaskia_state = useSelector((state) => state.paySaskia);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const dispatch = useDispatch();
  let paySaskia_ref = useRef(paySaskia_state);
  let erosketak_delete_ref = useRef(erosketak.delete);

  const use_pagination = useFilteredPagination(erosketak.erosketak, 20);
  const p_functions = use_pagination.functions;
  const p_data = use_pagination.data;
  const { pagination, currentPage, paginationSize, dataList } = p_data;

  const handleScroll = (ref) => {
    ref.scrollIntoView({ behavior: 'smooth' });
  };
  const title = React.useRef();

  const togglePaymentStatus = (uid) => {
    dispatch(paySaskia(uid));
  };

  const deletePayment = (uid) => {
    dispatch(deleteErosketa(uid));
  };

  const cellStatus = (erosketa) => {
    return {
      negative:
        erosketa.status === 0 &&
        erosketa?.eroslea?.payment_mode in
          [PAYMENT_MODE_TRANSFERENCE, PAYMENT_MODE_PAYPAL],
      warning:
        erosketa.status === 0 &&
        !(
          erosketa?.eroslea?.payment_mode in
          [PAYMENT_MODE_TRANSFERENCE, PAYMENT_MODE_PAYPAL]
        ),
      positive: erosketa.status !== 0,
    };
  };

  const getCsvData = (erosketak_data, last_x_days = 0) => {
    return erosketak_data
      .reduce((res, current) => {
        const saskia_content = Object.entries(current?.saskia.content).map(
          ([k, v]) => v,
        );
        const discounts = current?.saskia.discounts
          .map((d) => {
            return `${d.title}: ${d.value}`;
          })
          .join(', ');
        saskia_content.forEach((c) => {
          let content = {};
          content['id'] = current.id;
          content['datetime'] = current.datetime;
          content['izena'] = current?.eroslea?.izena;
          content['lehen_abizena'] = current?.eroslea?.lehen_abizena;
          content['bigarren_abizena'] = current?.eroslea?.bigarren_abizena;
          content['emaila'] = current?.emaila;
          content['telefonoa1'] = current?.eroslea?.telefonoa1;
          content['herria'] = current?.eroslea?.herria;
          content['kode_postala'] = current?.eroslea?.kode_postala;
          content['helbidea'] = current?.eroslea?.helbidea;
          const status = cellStatus(current);
          if (status.negative || status.warning) {
            content['status'] = 'Ordaindu gabe';
          } else if (status.positive) {
            content['status'] = 'Ordainduta';
          }
          if (current?.eroslea?.payment_mode === PAYMENT_MODE_PAYPAL) {
            content['payment_mode'] = 'Paypal';
          } else if (current?.eroslea?.payment_mode === PAYMENT_MODE_CARD) {
            content['payment_mode'] = 'Txartela';
          } else if (
            current?.eroslea?.payment_mode === PAYMENT_MODE_TRANSFERENCE
          ) {
            content['payment_mode'] = 'Transferentzia';
          }
          content['subTotal'] = current?.saskia.subTotal;
          content['shipping'] = current?.saskia.shipping;
          content['discounts'] = discounts;
          content['total_price'] = current?.saskia.total;
          content['title'] = c.title;
          content['format'] = c.format;
          content['unit_price'] = c.unit_price;
          content['count'] = c.count;
          res.push(content);
        });
        return res;
      }, [])
      .filter((e) => {
        return last_x_days
          ? Math.floor(
              (new Date() - new Date(e.datetime)) / (24 * 60 * 60 * 1000),
            ) <= last_x_days
          : true;
      })
      .sort(function (a, b) {
        return b.datetime.localeCompare(a.datetime);
      });
  };

  useEffect(() => {
    if (paySaskia_state.loaded && paySaskia_ref.current?.loading) {
      dispatch(getAllErosketak());
      toast.success(
        <Toast
          autoClose={3000}
          title={'Erosketa ordainduta'}
          content="Aukeratu duzun erosketa ongi ordaindu da."
        />,
      );
    }
    paySaskia_ref.current = paySaskia_state;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paySaskia_state]);

  useEffect(() => {
    if (erosketak.delete.loaded && erosketak_delete_ref.current?.loading) {
      dispatch(getAllErosketak());
      toast.success(
        <Toast
          autoClose={3000}
          title={'Erosketa ezabatuta'}
          content="Aukeratu duzun erosketa ongi ezabatu da."
        />,
      );
    }
    erosketak_delete_ref.current = erosketak.delete;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [erosketak.delete]);

  useEffect(() => {
    dispatch(getAllErosketak());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (erosketak.getAll?.loaded) {
      p_functions.setOriginalDataList(
        erosketak.erosketak.sort(function (a, b) {
          return b.datetime.localeCompare(a.datetime);
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [erosketak.getAll]);
  moment.locale(lang);
  const headers = [
    { label: 'Identifikadorea', key: 'id' },
    { label: 'Eguna', key: 'datetime' },
    { label: 'Izena', key: 'izena' },
    { label: 'Lehen Abizena', key: 'lehen_abizena' },
    { label: 'Bigarren Abizena', key: 'bigarren_abizena' },
    { label: 'Helbide elektronikoa', key: 'emaila' },
    { label: 'Telefonoa', key: 'telefonoa1' },
    { label: 'Herria', key: 'herria' },
    { label: 'Kode postala', key: 'kode_postala' },
    { label: 'Helbidea', key: 'helbidea' },
    { label: 'Ordainketa modua', key: 'payment_mode' },
    { label: 'Egoera', key: 'status' },
    { label: 'Subtotala', key: 'subTotal' },
    { label: 'Bidalketa', key: 'shipping' },
    { label: 'Deskontua', key: 'discounts' },
    { label: 'Guztira', key: 'total_price' },
    { label: 'Produktua', key: 'title' },
    { label: 'Formatua', key: 'format' },
    { label: 'Kopurua', key: 'count' },
    { label: 'Prezioa', key: 'unit_price' },
  ];

  const csvData = erosketak?.getAll?.loaded
    ? getCsvData(erosketak?.erosketak)
    : [];
  const lastDaysNumber = 30;
  const csvDataLastDays = erosketak?.getAll?.loaded
    ? getCsvData(erosketak?.erosketak, lastDaysNumber)
    : [];
  return (
    <div className="ui container">
      {!isLoggedIn ? (
        <>
          {token ? (
            <Forbidden
              pathname={location.pathname}
              staticContext={staticContext}
            />
          ) : (
            <Unauthorized
              pathname={location.pathname}
              staticContext={staticContext}
            />
          )}
        </>
      ) : user?.roles &&
        config.settings.importerRoles.find((element) =>
          user?.roles.includes(element),
        ) ? (
        <>
          <Helmet title="Erosketa guztiak"></Helmet>
          <Header as="h1" className="no-breadcrumb">
            Erosketa guztiak
          </Header>
          {paySaskia_state.loading && <Segment loading></Segment>}
          {erosketak.delete.loading && <Segment loading></Segment>}
          {csvData.length > 0 && (
            <CSVLink
              className="ui button ueu"
              data={csvData}
              headers={headers}
              filename={'UEU-erosketa-guztiak.csv'}
              separator={';'}
            >
              Deskargatu csv formatuan
            </CSVLink>
          )}
          {csvDataLastDays.length > 0 && (
            <CSVLink
              className="ui button ueu"
              data={csvDataLastDays}
              headers={headers}
              filename={`UEU-azken-${lastDaysNumber}-egunetako-erosketak.csv`}
              separator={';'}
            >
              Deskargatu azken {lastDaysNumber} egunetakoak csv formatuan
            </CSVLink>
          )}
          <div ref={title}></div>
          <Table single line="true" className="resume-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Eroslea</Table.HeaderCell>
                <Table.HeaderCell>Erosketa data</Table.HeaderCell>
                <Table.HeaderCell>Erosketa</Table.HeaderCell>
                <Table.HeaderCell>Egoera</Table.HeaderCell>
                <Table.HeaderCell>Ekintzak</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {pagination.length > 0 ? (
                pagination.map((erosketa, key) => (
                  <Table.Row key={key}>
                    <Table.Cell {...cellStatus(erosketa)}>
                      {`${erosketa?.eroslea?.izena} ${
                        erosketa?.eroslea?.lehen_abizena || ''
                      } ${erosketa?.eroslea?.bigarren_abizena || ''} (${
                        erosketa.user
                      })`}
                    </Table.Cell>
                    <Table.Cell {...cellStatus(erosketa)}>
                      {formatLL(erosketa.datetime)}
                    </Table.Cell>
                    <Table.Cell {...cellStatus(erosketa)}>
                      <p>
                        <strong>Erosketaren ID-a:</strong>{' '}
                        <span>{erosketa?.id}</span>
                      </p>

                      <ul>
                        {Object.entries(erosketa.saskia.content).map(
                          (elementua, key) => (
                            <li key={key}>
                              {elementua[1].title.length > 400 ? (
                                `${elementua[1].title.substring(0, 40)}...
                                    (${elementua[1].format})`
                              ) : (
                                <>
                                  {elementua[1].title} ({elementua[1].format})
                                </>
                              )}
                            </li>
                          ),
                        )}
                      </ul>
                    </Table.Cell>
                    <Table.Cell {...cellStatus(erosketa)}>
                      <Grid>
                        <Grid.Row
                          style={{
                            padding: '10px',
                          }}
                        >
                          <List>
                            <List.Item basic>
                              {erosketa.status === 0 ? (
                                <Label color="red" pointing="below" basic>
                                  <FormattedMessage
                                    id="Ordaindu gabe"
                                    defaultMessage="Ordaindu gabe"
                                  />
                                </Label>
                              ) : (
                                <Label color="green" pointing="below" basic>
                                  <FormattedMessage
                                    id="Ordainduta"
                                    defaultMessage="Ordainduta"
                                  />
                                </Label>
                              )}
                            </List.Item>
                            <List.Item basic>
                              {erosketa?.eroslea?.payment_mode ===
                              PAYMENT_MODE_PAYPAL ? (
                                <Label basic>
                                  <FormattedMessage
                                    id="PAYPAL"
                                    defaultMessage="PAYPAL"
                                  />
                                </Label>
                              ) : erosketa?.eroslea?.payment_mode ===
                                PAYMENT_MODE_CARD ? (
                                <Label basic>
                                  <FormattedMessage
                                    id="TXARTELA"
                                    defaultMessage="TXARTELA"
                                  />
                                </Label>
                              ) : erosketa?.eroslea?.payment_mode ===
                                PAYMENT_MODE_TRANSFERENCE ? (
                                <Label basic>
                                  <FormattedMessage
                                    id="TRANSFERENTZIA"
                                    defaultMessage="TRANSFERENTZIA"
                                  />
                                </Label>
                              ) : (
                                <></>
                              )}
                            </List.Item>
                          </List>
                        </Grid.Row>
                      </Grid>
                    </Table.Cell>
                    <Table.Cell>
                      <List>
                        <List.Item basic>
                          <UniversalLink
                            className="ui button ueu"
                            href={`${EROSKETA_GUZTIAK_ROUTE}/${erosketa.UID}`}
                          >
                            <FormattedMessage
                              id="Ikusi xehetasunak"
                              defaultMessage="Ikusi xehetasunak"
                            />
                          </UniversalLink>
                        </List.Item>
                        {erosketa.status === 0 && (
                          <List.Item basic>
                            {/* {erosketa?.eroslea?.payment_mode ===
                                PAYMENT_MODE_TRANSFERENCE && ( */}
                            <UeuModal
                              header={`${erosketa?.id} kodedun erosketa ordaindutzat eman`}
                              closeAction={{
                                closeText: 'Itxi ordaindu gabe',
                              }}
                              okAction={{
                                okText: 'Bai, ORDAINDUTA bezala markatu',
                                okOnClick: () => {
                                  togglePaymentStatus(erosketa.UID);
                                },
                              }}
                              trigger={() => {
                                return (
                                  <Button className="ui button ueu primary">
                                    <FormattedMessage
                                      id="ORDAINDU"
                                      defaultMessage="ORDAINDU"
                                    />
                                  </Button>
                                );
                              }}
                            >
                              <p>
                                Webgunean{' '}
                                <strong>{`${erosketa?.eroslea?.izena} ${
                                  erosketa?.eroslea?.lehen_abizena || ''
                                } ${
                                  erosketa?.eroslea?.bigarren_abizena || ''
                                }`}</strong>{' '}
                                erabiltzaileak egin duen erosketa ordaindu duela
                                adierazten ari zara.
                              </p>
                              <p>Hauek dira erosketaren datuak:</p>
                              <ul>
                                <li>
                                  <strong>Ordainketa modua: </strong>
                                  {erosketa?.eroslea?.payment_mode ===
                                  PAYMENT_MODE_PAYPAL ? (
                                    <Label>
                                      <FormattedMessage
                                        id="PAYPAL"
                                        defaultMessage="PAYPAL"
                                      />
                                    </Label>
                                  ) : erosketa?.eroslea?.payment_mode ===
                                    PAYMENT_MODE_CARD ? (
                                    <Label>
                                      <FormattedMessage
                                        id="TXARTELA"
                                        defaultMessage="TXARTELA"
                                      />
                                    </Label>
                                  ) : erosketa?.eroslea?.payment_mode ===
                                    PAYMENT_MODE_TRANSFERENCE ? (
                                    <Label>
                                      <FormattedMessage
                                        id="TRANSFERENTZIA"
                                        defaultMessage="TRANSFERENTZIA"
                                      />
                                    </Label>
                                  ) : (
                                    <></>
                                  )}
                                </li>
                                <li>
                                  <strong>Kodea: </strong>
                                  {erosketa.id}
                                </li>
                                <li>
                                  <strong>Data: </strong>
                                  {formatLL(erosketa.datetime)}
                                </li>
                                <li>
                                  <strong>Eroslea: </strong>
                                  {`${erosketa?.eroslea?.izena} ${
                                    erosketa?.eroslea?.lehen_abizena || ''
                                  } ${
                                    erosketa?.eroslea?.bigarren_abizena || ''
                                  } (${erosketa.user})`}
                                </li>
                                <li>
                                  <strong>Prezioa: </strong>
                                  {erosketa.saskia?.total} €
                                </li>
                              </ul>
                              <p>
                                Eragiketa honek ez dauka atzera bueltarik, eta
                                aurrera egiten baduzu erosleak mezu elektroniko
                                bat jasoko du informazio gehiagorekin, beraz{' '}
                                <strong>
                                  ziur zaude erosketa hau ordaindutzat eman nahi
                                  duzula?
                                </strong>
                              </p>
                            </UeuModal>
                            {/* )} */}
                          </List.Item>
                        )}
                        <List.Item basic>
                          <UeuModal
                            header={`${erosketa?.id} kodedun erosketa ezabatu`}
                            closeAction={{
                              closeText: 'Itxi ezabatu gabe',
                            }}
                            okAction={{
                              okText: 'Bai, EZABATU erosketa hau',
                              okOnClick: () => {
                                deletePayment(erosketa.UID);
                              },
                            }}
                            trigger={() => {
                              return (
                                <Button basic color="red" className="ueu">
                                  <FormattedMessage
                                    id="EZABATU"
                                    defaultMessage="EZABATU"
                                  />
                                </Button>
                              );
                            }}
                          >
                            <Message warning>
                              <Message.Header>
                                ADI! <Icon name="exclamation triangle" />
                              </Message.Header>
                              Aurrera egiten baduzu erosketaren erregistroa
                              ezabatuko da
                            </Message>
                            <p>
                              Webgunean{' '}
                              <strong>{`${erosketa?.eroslea?.izena} ${
                                erosketa?.eroslea?.lehen_abizena || ''
                              } ${
                                erosketa?.eroslea?.bigarren_abizena || ''
                              }`}</strong>{' '}
                              erabiltzaileak egin duen erosketa EZABATZERA zoaz.
                            </p>
                            <p>Hauek dira erosketaren datuak:</p>
                            <ul>
                              <li>
                                <strong>Ordainketa modua: </strong>
                                {erosketa?.eroslea?.payment_mode ===
                                PAYMENT_MODE_PAYPAL ? (
                                  <Label>
                                    <FormattedMessage
                                      id="PAYPAL"
                                      defaultMessage="PAYPAL"
                                    />
                                  </Label>
                                ) : erosketa?.eroslea?.payment_mode ===
                                  PAYMENT_MODE_CARD ? (
                                  <Label>
                                    <FormattedMessage
                                      id="TXARTELA"
                                      defaultMessage="TXARTELA"
                                    />
                                  </Label>
                                ) : erosketa?.eroslea?.payment_mode ===
                                  PAYMENT_MODE_TRANSFERENCE ? (
                                  <Label>
                                    <FormattedMessage
                                      id="TRANSFERENTZIA"
                                      defaultMessage="TRANSFERENTZIA"
                                    />
                                  </Label>
                                ) : (
                                  <></>
                                )}
                              </li>
                              <li>
                                <strong>Kodea: </strong>
                                {erosketa.id}
                              </li>
                              <li>
                                <strong>Data: </strong>
                                {formatLL(erosketa.datetime)}
                              </li>
                              <li>
                                <strong>Eroslea: </strong>
                                {`${erosketa?.eroslea?.izena} ${
                                  erosketa?.eroslea?.lehen_abizena || ''
                                } ${
                                  erosketa?.eroslea?.bigarren_abizena || ''
                                } (${erosketa.user})`}
                              </li>
                              <li>
                                <strong>Prezioa: </strong>
                                {erosketa.saskia?.total} €
                              </li>
                            </ul>
                            <p>
                              Eragiketa honek ez dauka atzera bueltarik, eta
                              aurrera egiten baduzu webgunean ez da honen
                              erregistrorik gordeko, beraz{' '}
                              <strong>
                                ziur zaude erosketa hau EZABATU nahi duzula?
                              </strong>
                            </p>
                          </UeuModal>
                        </List.Item>
                      </List>

                      {/*  : (
                          <>
                            {erosketa?.eroslea?.payment_mode ===
                              PAYMENT_MODE_TRANSFERENCE && (
                              <Button
                                className="ui button ueu primary"
                                onClick={() =>
                                  togglePaymentStatus(erosketa.UID)
                                }
                              >
                                <FormattedMessage
                                  id="ATZERA BOTA"
                                  defaultMessage="ATZERA BOTA"
                                />
                              </Button>
                            )}
                          </>
                        )} */}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="4">
                    <FormattedMessage
                      id="no_purchases_table_text"
                      defaultMessage="Oraindik ez daukazu erosketarik"
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Container textAlign="center">
            <Pagination
              activePage={currentPage}
              totalPages={Math.ceil(dataList.length / paginationSize)}
              onPageChange={(e, { activePage }) => {
                if (title.current) handleScroll(title.current);
                p_functions.setCurrentPage(activePage);
              }}
              firstItem={null}
              lastItem={null}
            ></Pagination>
          </Container>
        </>
      ) : (
        <Forbidden pathname={location.pathname} staticContext={staticContext} />
      )}
    </div>
  );
};

export default ErosketaGuztiakView;
