export const step4_schema = (content, user) => {
  const general_price = content.data.general_price;
  const member_price = content.data.member_price;
  const reduced_price = content.data.reduced_price;
  const matrikula_choices =
    general_price === 0
      ? [[`general_price#${general_price}`, `Orokorra: ${general_price}€`]]
      : [
          ...(!user?.bazkide_id
            ? [
                [
                  `general_price#${general_price}`,
                  `Orokorra: ${general_price}€`,
                ],
                [
                  `reduced_price#${reduced_price}`,
                  `Ikasle, langabe edo erretiratua: ${reduced_price}€`,
                ],
              ]
            : [[`member_price#${member_price}`, `Bazkidea: ${member_price}€`]]),
        ];

  return {
    title: 'Ikastaroari dagokionez',
    type: 'object',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'matrikula_mota',
          'non_ezagutu',
          'non_ezagutu_duzu_ueu',
          'oharrak',
          'faktura_beharra',
          'faktura_erakundea',
          'faktura_helbidea',
          'faktura_herria',
          'faktura_posta_kodea',
          'faktura_herrialdea',
          'faktura_ifz',
          'irudi_eskubideak',
          'pribatutasuna',
        ],
      },
    ],
    properties: {
      matrikula_mota: {
        title: 'Matrikula mota',
        choices: matrikula_choices,
      },
      non_ezagutu: {
        title: 'Nola ezagutu duzu ikastaro hau?',
        vocabulary: { '@id': 'ueu.addon.KudeuHowYouKnow' },
      },
      non_ezagutu_duzu_ueu: {
        title: 'Beste modu batez ezagutu baduzu, aipatu',
        type: 'text',
        maxLength: 100,
      },
      oharrak: {
        title: 'Oharrak',
        widget: 'textarea',
      },
      irudi_eskubideak: {
        title: 'Irudi eskubideak',
        html_description: {
          data: 'Udako Euskal Unibertsitateari baimena ematen diozu ikastaroetan hartutako irudiak erabiltzeko, ikastaroetako sustapen eta komunikazio ekintzetan. Udako Euskal Unibertsitateak honen bidez, jakinarazten dizu, irudiak ez direla erabiliko diru etekina lortzeko jardun komertzialetan. Aipatutako baldintzak ulertu eta borondatez onartzen ditut',
        },
        type: 'required_boolean',
      },
      pribatutasuna: {
        title: 'Baldintza hauek ulertu eta borondatez onartzen ditut',
        html_description: {
          data: 'UDAKO EUSKAL UNIBERTSITATEA zure datu pertsonalen tratamenduaren arduraduna da. Informazioa gehiagorako, irakurri gure <a class="pribatutasun-baldintzak" href="/jarduera-akademikoa/pribatutasun-baldintzak">Pribatutasun baldintzak</a>',
        },
        type: 'required_boolean',
      },
      faktura_beharra: {
        title: 'Faktura egiteko datuak aldatu',
        description:
          'UEUren webgunean ordainketa bat egitean, erregistroan emandako datuekin egiten da automatikoki faktura bat. Faktura aldatzea beharko bazenu, klikatu laukitxoan eta osatu datu berriak.',
        type: 'boolean',
      },
      faktura_erakundea: {
        title: 'Erakundea',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_helbidea: {
        title: 'Helbidea',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_herria: {
        title: 'Herria',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_posta_kodea: {
        title: 'Posta-kodea',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_herrialdea: {
        title: 'Herrialdea',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
      faktura_ifz: {
        title: 'IFZ',
        type: 'text',
        dependency: (formData) => {
          return formData.faktura_beharra;
        },
      },
    },
    required: ['pribatutasuna', 'matrikula_mota'],
  };
};
