import React, { useEffect, useRef } from 'react';
export const PostForm = ({ payment_params, action }) => {
  const formRef = useRef(null);
  useEffect(() => {
    if (formRef?.current) {
      formRef.current.submit();
    }
  }, []);
  if (!payment_params) {
    return <></>;
  }
  const inputs = Object.keys(payment_params);
  return (
    <form
      ref={formRef}
      method={inputs.length > 0 ? 'post' : 'get'}
      action={action}
    >
      {inputs.map((input_key, key) => (
        <input
          key={key}
          type="hidden"
          name={input_key}
          value={payment_params[input_key]}
        />
      ))}
    </form>
  );
};
