import { Card, Button, Image } from 'semantic-ui-react';
export const PaymentFormatCard = ({
  header,
  description,
  imageSrc,
  submit,
  submit_mode,
  submit_text,
  form_status,
}) => {
  return (
    <Card>
      {imageSrc && <Image src={imageSrc} centered />}
      <Card.Content>
        <Card.Header>{header}</Card.Header>
        <Card.Meta></Card.Meta>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          primary
          className="ueu"
          onClick={() => submit(submit_mode, form_status)}
        >
          {submit_text}
        </Button>
      </Card.Content>
    </Card>
  );
};
