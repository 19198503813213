/**
 * Cart items reducer.
 * @module reducers/cartItemsReducer
 */

import { PAY_SASKIA } from '../../actions/saskia/saskia';

const initialState = {
  response: {},
  loading: false,
  loaded: false,
  error: null,
};

export const paySaskiaReducer = (state = initialState, action = {}) => {
  switch (action?.type) {
    case `${PAY_SASKIA}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${PAY_SASKIA}_SUCCESS`:
      return {
        ...state,
        response: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    case `${PAY_SASKIA}_FAIL`:
      return {
        ...state,
        response: action.result,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};
