import { addUserModifications } from 'volto-ueu/actions';

/**
 * Submit handler
 * @method onSubmit
 * @param {object} data Form data.
 * @returns {undefined}
 */

export const onSubmit = (data, userId, dispatch) => {
  // We don't want the user to change his login name/username or the roles
  // from this form
  // Backend will complain anyways, but we clean the data here before it does
  delete data.id;
  delete data.username;
  delete data.roles;
  dispatch(addUserModifications(data));
};
