export const step2_schema = (content) => {
  return {
    title: 'Ikasketak eta lanbidea',
    type: 'object',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'ikasketa_maila',
          'titulazio_akademikoa',
          'ikasketa_lana',
          'zer_ikasi',
          'non_ikasi',
          'ikasketa_mota',
          'lan_mota',
          'non_lan',
        ],
      },
    ],
    properties: {
      titulazio_akademikoa: {
        title: 'Titulazio akademikoa',
        type: 'text',
        maxLength: 250,
      },
      ikasketa_lana: {
        title: 'Lanbidea',
        vocabulary: { '@id': 'ueu.addon.KudeuJobStatus' },
      },
      zer_ikasi: {
        title: 'Zer ikasi',
        type: 'text',
        dependency: (formData) => {
          return formData['ikasketa_lana'] === '1';
        },
        maxLength: 200,
      },
      non_ikasi: {
        title: 'Non ikasi',
        type: 'text',
        dependency: (formData) => {
          return formData['ikasketa_lana'] === '1';
        },
        maxLength: 200,
      },
      ikasketa_mota: {
        title: 'Ikasketa mota',
        vocabulary: { '@id': 'ueu.addon.KudeuEducationTypes' },
        dependency: (formData) => {
          return formData['ikasketa_lana'] === '1';
        },
      },
      lan_mota: {
        title: 'Lan mota',
        vocabulary: { '@id': 'ueu.addon.KudeuJobTypes' },
        dependency: (formData) => {
          return formData['ikasketa_lana'] === '2';
        },
      },
      non_lan: {
        title: 'Non lan',
        type: 'text',
        dependency: (formData) => {
          return formData['ikasketa_lana'] === '2';
        },
        maxLength: 200,
      },
      ikasketa_maila: {
        title: 'Ikasketa maila',
        vocabulary: { '@id': 'ueu.addon.KudeuEducationLevel' },
      },
    },
    required: ['ikasketa_lana', 'ikasketa_maila'],
  };
};
