import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Provider, useSelector } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import configureStore from 'redux-mock-store';
import { Header, Grid } from 'semantic-ui-react';

import { handleKey } from '@plone/volto-slate/blocks/Text/keyboard';
import deserialize from '@plone/volto-slate/editor/deserialize';
import { serializeNodes } from '@plone/volto-slate/editor/render';
import SlateEditor from '@plone/volto-slate/editor/SlateEditor';
import {
  makeEditor,
  createEmptyParagraph,
  normalizeExternalData,
} from '@plone/volto-slate/utils';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import {
  addBlock,
  getBlocksFieldname,
  applyBlockDefaults,
} from '@plone/volto/helpers/Blocks/Blocks';
import config from '@plone/volto/registry';
import { getTeaserImageURL } from './utils';

import { Picture } from 'volto-ueu/components';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';

const WorkGroupTeaserView = (props) => {
  const {
    data,
    isEditMode,
    selected,
    id,
    onChangeBlock,
    className,
    block,
    placeholder,
    properties,
  } = props;
  // const href = data.href?.[0];
  const { preview_image, richtext, imageSide = 'ezkerrean' } = data;
  const image = preview_image?.[0];
  const editor = React.useMemo(() => makeEditor(), []);
  const token = useSelector((state) => state.userSession.token);
  const memoizedValue = React.useMemo(
    () => richtext ?? { data: '' },
    [richtext],
  );
  const toHtml = React.useCallback(
    (value) => {
      const mockStore = configureStore();
      const html = ReactDOMServer.renderToStaticMarkup(
        <Provider store={mockStore({ userSession: { token } })}>
          <MemoryRouter>{serializeNodes(value || [])}</MemoryRouter>
        </Provider>,
      );
      return {
        'content-type': value ? value['content-type'] : 'text/html',
        encoding: value ? value.encoding : 'utf8',
        data: html,
      };
    },
    [token],
  );
  const fromHtml = React.useCallback(
    (value) => {
      try {
        const html = value?.data || '';

        const parsed = new DOMParser().parseFromString(html, 'text/html');
        const body =
          parsed.getElementsByTagName('google-sheets-html-origin').length > 0
            ? parsed.querySelector('google-sheets-html-origin > table')
            : parsed.body;
        let data = deserialize(editor, body);
        data = normalizeExternalData(editor, data);

        // editor.children = data;
        // Editor.normalize(editor);
        // TODO: need to add {text: ""} placeholders between elements
        const res = data.length ? data : [createEmptyParagraph()];
        return res;
      } catch {
        return;
      }
    },
    [editor],
  );

  const valueFromHtml = React.useMemo(
    () => fromHtml(memoizedValue),
    [memoizedValue, fromHtml],
  );

  const handleChange = React.useCallback(
    (newValue) => {
      onChangeBlock(id, {
        ...data,
        richtext: toHtml(newValue),
      });
    },
    [onChangeBlock, toHtml, id, data],
  );
  const reversed = imageSide === 'ezkerrean' ? 'false' : 'mobile';
  return (
    <Grid
      className="workgroup-card"
      reversed={reversed}
      stackable
      tabindex="0"
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          const [id, newFormData] = addBlock(
            properties,
            config.settings.defaultBlockType,
            props.index + 1,
          );
          const blocksFieldname = getBlocksFieldname(newFormData);
          const blockData = newFormData[blocksFieldname][id];
          newFormData[blocksFieldname][id] = applyBlockDefaults({
            data: blockData,
            intl: props.intl,
            metadata: props.metadata,
            properties,
          });
          props.onChangeFormData(newFormData);
        }
      }}
    >
      {imageSide === 'ezkerrean' && (
        <Grid.Column width={4} className="lantaldea-image-container">
          {image && (
            <div className="ui image circular">
              <Picture
                imageBase={flattenToAppURL(getTeaserImageURL('', image))}
                alt={data?.image_alt}
              />
            </div>
          )}
        </Grid.Column>
      )}
      <Grid.Column width={8} verticalAlign="middle">
        {data.title && (
          <Header as="h2" className="workgroup-header" textAlign="center">
            {data.title}
          </Header>
        )}
        {/* {isEditMode && <TextBlockEdit blocks={data.head_title} />} */}
        {isEditMode ? (
          <SlateEditor
            className={className}
            id={id}
            name={id}
            value={valueFromHtml}
            onChange={handleChange}
            onKeyDown={handleKey}
            block={block}
            selected={selected}
            properties={properties}
            placeholder={placeholder}
          />
        ) : (
          <StringToHTML string={data?.richtext?.data} />
        )}
      </Grid.Column>
      {imageSide === 'eskuman' && (
        <Grid.Column width={4} className="lantaldea-image-container">
          {image && (
            <div className="ui image circular">
              <Picture
                imageBase={flattenToAppURL(getTeaserImageURL('', image))}
                alt={data?.image_alt}
              />
            </div>
          )}
        </Grid.Column>
      )}
    </Grid>
  );
};

export default WorkGroupTeaserView;
