export const SASKIA_ROUTE = '/saskia';
export const EROSI_ROUTE = '/erosi';
export const NIRE_DATUAK_ROUTE = '/nire-datuak';
export const NIRE_EROSKETAK_ROUTE = '/nire-erosketak';
export const EROSKETA_GUZTIAK_ROUTE = '/erosketa-guztiak';
export const NIRE_IKASTAROAK_ROUTE = '/nire-ikastaroak';
export const NIRE_BAZKIDETZA_ROUTE = '/nire-bazkidetza';
export const NIRE_HARPIDETZA_ROUTE = '/nire-harpidetza';
export const IKASTAROA_FORM_ROUTE = '/izen-ematea';
export const IKASTAROA_PAY_ROUTE = '/ordaindu-ikastaroa';
export const USERMODIFICATIONS_ROUTE = '/erabiltzaileen-aldaketak';
export const PAID_OK_ROUTE = '/ordainketa-ok';
export const PAID_NOK_ROUTE = '/ordainketa-nok';
export const TRANSFERENCE_ROUTE = '/transferentzia';
export const SHOP_PAID_OK_ROUTE = '/erosketa-ok';
export const SHOP_PAID_NOK_ROUTE = '/erosketa-nok';
export const SHOP_TRANSFERENCE_ROUTE = '/erosketa-transferentzia';
export const UNPAID_ROUTE = '/itxaron-zerrenda';
export const FREE_ROUTE = '/matrikula-ok';
export const ADD_IRAGARKIA_ROUTE = '/gehitu-iragarkia';

export const UNPAID_MODE_FREE = 2;
export const UNPAID_MODE_TRANSFERENCE = 1;
export const UNPAID_MODE_FULL = 0;
export const PAYMENT_MODE_PAYPAL = 1;
export const PAYMENT_MODE_CARD = 2;
export const PAYMENT_MODE_TRANSFERENCE = 3;
export const HASTEKOAK = 'HASTEKOAK';
export const MARTXAN = 'MARTXAN';
export const AMAITUTA = 'AMAITUTA';
