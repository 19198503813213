import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

function TaxonomyFacetFilterListEntry(props) {
  const { facet, isEditMode, setFacets, facets, querystring } = props;
  const index = querystring.indexes[facet] || {};
  const { values = {} } = index;

  let choices = Object.keys(values).map((name) => ({
    value: name,
    label: values[name].title,
  }));
  return typeof facets[facet] === 'string' ? (
    <Label size="small">
      {facets[facet]}
      <Icon
        name="delete"
        onClick={() => {
          !isEditMode &&
            setFacets({
              ...facets,
              [facet]: '',
            });
        }}
      />
    </Label>
  ) : (
    <>
      {facets[facet].map((entry, i) => {
        let filtered_choice = [];
        if (choices.length > 0) {
          filtered_choice = choices.filter((choice) => choice.value === entry);
        }
        return (
          <Label size="small" key={i}>
            {filtered_choice.length > 0 ? filtered_choice[0].label : entry}
            <Icon
              name="delete"
              onClick={() => {
                const entries = facets[facet].filter((item) => item !== entry);
                !isEditMode &&
                  setFacets({
                    ...facets,
                    [facet]: entries,
                  });
              }}
            />
          </Label>
        );
      })}
    </>
  );
}

export default TaxonomyFacetFilterListEntry;
