import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Segment, Icon, Dropdown } from 'semantic-ui-react';

import { getUser } from '@plone/volto/actions/users/users';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import Logo from '@plone/volto/components/theme/Logo/Logo';
import Anontools from '@plone/volto/components/theme/Anontools/Anontools';

import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

import { getSaskiaItems, getAllUserModifications } from 'volto-ueu/actions';
import { CustomNavigation, SubsiteNavigation } from 'volto-ueu/components';
import { useUeuSaskia } from 'volto-ueu/components/saskia';
import { SASKIA_ROUTE } from 'volto-ueu/constants';
import { Image } from 'semantic-ui-react';

import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { profile_options } from 'volto-ueu/components/CustomNavigation/utils';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import ueu_zuria from 'volto-ueu/theme/images/ueu_zuria.png';
// import cx from 'classnames';

/**
 * Header component.
 * @module components/theme/Header/Header
 */
/**
 * Header component class.
 * @class Header
 * @extends Component
 */

class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
    user: {},
    usermodifications: {},
  };

  state = {
    isVisibleMenu: false,
  };

  componentDidMount() {
    this.props.getUser(this.props.token);
    this.props.getSaskiaItems();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.token !== this.props.token) {
      this.props.getUser(nextProps.token);
      this.props.getSaskiaItems();
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const language_re = new RegExp('(/en$|/en/)');
    const trigger = (
      <>
        {this.props.user.username}
        <Icon className="ueu-icon" name="user" />
      </>
    );
    const option_base = { as: 'li', className: 'profile-option' };
    // const { saskia } = this.props.useUeuSaskia();
    return !this.props.subsite?.title ? (
      <Segment basic className="header-wrapper" role="banner">
        <Container>
          <div className="header">
            <div className="logo-nav-wrapper">
              <div className="logo">
                <Logo />
              </div>
              {/* <Navigation pathname={this.props.pathname} /> */}
            </div>
            <div className="tools-search-wrapper">
              <div className=" ui computer large screen only">
                {this.props.dropdownMenuNavItems.filter(
                  (i) => i.rootPath === '/menu-beroa',
                ).length > 0 &&
                  this.props.dropdownMenuNavItems
                    .filter((i) => i.rootPath === '/menu-beroa')[0]
                    .items.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <RenderBlocks content={item} />
                        </React.Fragment>
                      );
                    })}
              </div>
              <div className="icons">
                {!this.state.isVisibleMenu && (
                  <Icon
                    onClick={() => {
                      this.setState({
                        isVisibleMenu: !this.state.isVisibleMenu,
                      });
                    }}
                    className="ueu-icon menu"
                    name={'bars'}
                    size="big"
                  />
                )}
                {this.state.isVisibleMenu && (
                  <Icon
                    onClick={() => {
                      this.setState({
                        isVisibleMenu: !this.state.isVisibleMenu,
                      });
                    }}
                    className="ueu-icon menu"
                    name={'close'}
                    size="big"
                  />
                )}
              </div>

              {!this.props.token ? (
                <>
                  <div className="tools ui computer large screen only">
                    <Anontools />
                  </div>
                  <UniversalLink href={SASKIA_ROUTE}>
                    <Icon className="ueu-icon" name="cart" /> (
                    {this.props.saskia?.content
                      ? Object.keys(this.props.saskia?.content).length
                      : 0}
                    )
                  </UniversalLink>
                </>
              ) : (
                <div className="logged-in ui computer large screen only">
                  <Dropdown
                    multiple
                    onClick={() =>
                      !this.props.usermodifications.getall.loaded &&
                      this.props.getAllUserModifications()
                    }
                    trigger={trigger}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Menu scrolling>
                        <ul className="profile-options">
                          {profile_options(
                            option_base,
                            this.props.user,
                            this.props.usermodifications,
                          ).map((option, index) => (
                            <Dropdown.Item key={index} {...option} />
                          ))}
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown.Menu>
                  </Dropdown>
                  <UniversalLink href={SASKIA_ROUTE}>
                    <Icon className="ueu-icon" name="cart" /> (
                    {this.props.saskia?.content
                      ? Object.keys(this.props.saskia?.content).length
                      : 0}
                    )
                  </UniversalLink>
                </div>
              )}
              {/* <div className="search">
                <SearchWidget />
              </div> */}
            </div>
          </div>
        </Container>
        <CustomNavigation
          isVisible={this.state.isVisibleMenu}
          isLoggedIn={!!this.props.token}
          username={this.props.user.username}
          toggleVisibility={() => {
            this.setState({
              isVisibleMenu: !this.state.isVisibleMenu,
            });
          }}
        />
      </Segment>
    ) : (
      <div className="subsite-header-wrapper" role="banner">
        <div className="subsite-pre-header">
          <div className="pre-header-left">
            <UniversalLink href={'/'} title={'UEUren webgunera joan'}>
              <Image
                style={{
                  maxWidth: '40px',
                }}
                alt="UEU logotipoa"
                src={ueu_zuria}
              />
            </UniversalLink>
          </div>
          <div className="pre-header-right">
            <div className="subsite-header-text computer only">
              {this.props.subsite.subsite_header?.data && (
                <StringToHTML string={this.props.subsite.subsite_header.data} />
              )}
            </div>
            {!this.state.isVisibleMenu ? (
              <Icon
                onClick={() => {
                  this.setState({
                    isVisibleMenu: !this.state.isVisibleMenu,
                  });
                }}
                className="ueu-icon menu"
                name={'bars'}
                size="big"
              />
            ) : (
              <Icon
                onClick={() => {
                  this.setState({
                    isVisibleMenu: !this.state.isVisibleMenu,
                  });
                }}
                className="ueu-icon menu"
                name={'close'}
                size="big"
              />
            )}
            {this.props.subsite?.subsite_en && (
              <div className="languages">
                {!language_re.test(this.props.pathname) ? (
                  <span>EU</span>
                ) : (
                  <UniversalLink item={this.props.subsite}>EU</UniversalLink>
                )}
                ' / '
                {language_re.test(this.props.pathname) ? (
                  <span>EN</span>
                ) : (
                  <UniversalLink href={`${this.props.subsite?.['@id']}/en`}>
                    EN
                  </UniversalLink>
                )}
              </div>
            )}
          </div>
        </div>
        <SubsiteNavigation
          isVisible={this.state.isVisibleMenu}
          toggleVisibility={() => {
            this.setState({
              isVisibleMenu: !this.state.isVisibleMenu,
            });
          }}
          subsite={this.props.subsite}
          pathname={this.props.pathname}
        ></SubsiteNavigation>
        <div className="subsite-header">
          {this.props.subsite.subsite_logo && (
            <div className="logo-nav-wrapper">
              <UniversalLink item={this.props.subsite}>
                <picture>
                  {Object.keys(this.props.subsite.subsite_logo.scales).map(
                    (source, key) => {
                      return (
                        <source
                          key={key}
                          media={
                            this.props.subsite.subsite_logo.scales[source]
                              .download
                          }
                          width={
                            this.props.subsite.subsite_logo.scales[source].width
                          }
                          height={
                            this.props.subsite.subsite_logo.scales[source]
                              .height
                          }
                          srcSet={
                            this.props.subsite.subsite_logo.scales[source]
                              .download
                          }
                        />
                      );
                    },
                  )}
                  <Image
                    alt={`${this.props.subsite.title} logotipoa`}
                    src={this.props.subsite.subsite_logo.download}
                    width={this.props.subsite.subsite_logo.width}
                    height={this.props.subsite.subsite_logo.height}
                  />
                </picture>
              </UniversalLink>
            </div>
          )}
          <div className="header-edition">
            <div className="header-edition-location">
              {this.props.subsite.subsite_location}
            </div>
            <div className="header-edition-dates">
              {this.props.subsite.subsite_year}
              {this.props.subsite.subsite_month}
              <div className="header-edition-days">
                {this.props.subsite.subsite_days}
              </div>
            </div>
          </div>
        </div>
        {/* <Navigation pathname={this.props.subsite['@id']} /> */}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    token: state.userSession.token
      ? jwtDecode(state.userSession.token).sub
      : '',
    user: state.users.user,
    usermodifications: state.usermodifications,
    saskia: state.saskia.items,
    dropdownMenuNavItems: state.dropdownMenuNavItems?.result,
    subsite: state.content?.data?.['@components']?.subsite,
    content: state.content?.data,
  }),
  {
    getUser,
    getSaskiaItems,
    getAllUserModifications,
    useUeuSaskia,
  },
)(Header);
