import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Header, Segment } from 'semantic-ui-react';

import { getUser } from '@plone/volto/actions/users/users';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';

import jwtDecode from 'jwt-decode';

const NireHarpidetzaView = ({ content, location, staticContext }) => {
  /** state selectors **/
  const token = useSelector((state) => state.userSession.token);
  const user = useSelector((state) => state.users.user);

  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(userId));
  }, [dispatch, userId]);

  return (
    <>
      <Helmet title={'Nire Datuak'} />
      <div className="ui container">
        {!isLoggedIn && (
          <>
            {token ? (
              <Forbidden
                pathname={location.pathname}
                staticContext={staticContext}
              />
            ) : (
              <Unauthorized
                pathname={location.pathname}
                staticContext={staticContext}
              />
            )}
          </>
        )}
        {isLoggedIn && (
          <>
            <Container className="view-wrapper">
              <Header as="h1" className="no-breadcrumb">
                Nire harpidetza
              </Header>
              {user.uztaro_id ? (
                <Segment basic className="harpidetza-segment">
                  <h2>{user.uztaro_id} kodedun Uztaroko harpideduna zara.</h2>
                  <p>
                    Harpidedun izateagatik dituzun abantailak ondorengoak dira:
                  </p>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <UniversalLink
                            className="ui button ueu primary"
                            href={`/argitaletxea/aldizkariak/uztaro`}
                          >
                            Ikusi Uztaroak
                          </UniversalLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Segment>
              ) : (
                <Segment basic className="harpidetza-segment">
                  <h2>Oraindik ez zara Uztaroko harpideduna.</h2>
                  <p>Harpidetu nahi baduzu joan helbide honetara: (Helbidea)</p>
                </Segment>
              )}
            </Container>
          </>
        )}
      </div>
    </>
  );
};

export default NireHarpidetzaView;
