import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import FormFieldWrapper from '@plone/volto/components/manage/Widgets/FormFieldWrapper';

import './ueu-react-datepicker.css';
import leftKey from '@plone/volto/icons/left-key.svg';
import rightKey from '@plone/volto/icons/right-key.svg';
const ReactDatepickerWidget = (props) => {
  const { id, min = 1920, futureYears = 0, value, onChange } = props;
  const NextIcon = (props) => (
    <div {...props} className="DayPickerNavigation_button ">
      <div
        style={{
          right: '22px',
          top: '15px',
        }}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
      >
        <Icon name={rightKey} size="30px" />
      </div>
    </div>
  );

  const PrevIcon = (props) => (
    <div {...props} className="DayPickerNavigation_button ">
      <div
        style={{
          left: '22px',
          top: '15px',
        }}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
      >
        <Icon name={leftKey} size="30px" />
      </div>
    </div>
  );

  const [year, setYear] = useState(
    value ? new Date(value).getFullYear() : new Date().getFullYear(),
  );
  const years = Array.from(
    { length: new Date().getFullYear() + futureYears + 1 - min },
    (_, i) => (min + i).toString(),
  );
  const months = [
    'Urtarrila',
    'Otsaila',
    'Martxoa',
    'Apirila',
    'Maiatza',
    'Ekaina',
    'Uztaila',
    'Abuztua',
    'Iraila',
    'Urria',
    'Azaroa',
    'Abendua',
  ];
  const weekdays = {
    Monday: 'Al.',
    Tuesday: 'Ar.',
    Wednesday: 'Az.',
    Thursday: 'Og.',
    Friday: 'Ol.',
    Saturday: 'Lr.',
    Sunday: 'Ig.',
  };
  return (
    <FormFieldWrapper {...props}>
      <div className="date-time-widget-wrapper">
        <DatePicker
          dateFormat="yyyy-MM-dd"
          placeholderText="UUUU-HH-EE"
          formatWeekDay={(data) => {
            return weekdays[data];
          }}
          isClearable
          calendarStartDay={1}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <PrevIcon onClick={decreaseMonth} />
              {/* eslint-disable-next-line jsx-a11y/no-onchange*/}
              <select
                value={year}
                onChange={({ target: { value } }) => {
                  changeYear(value);
                  setYear(value);
                }}
              >
                {years.map((option) => (
                  <option
                    key={option}
                    value={option}
                    selected={option === year}
                  >
                    {option}
                  </option>
                ))}
              </select>
              {/* eslint-disable-next-line jsx-a11y/no-onchange*/}
              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <NextIcon onClick={increaseMonth} />
            </div>
          )}
          selected={value ? new Date(value) : null}
          onChange={(date) => onChange(id, date.toISOString().slice(0, 10))}
        />
      </div>
    </FormFieldWrapper>
  );
};

export default ReactDatepickerWidget;
