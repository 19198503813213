import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import { getSaskiaPrezioa } from 'volto-ueu/actions';
import { getUser } from '@plone/volto/actions/users/users';
import {
  Container,
  Header,
  Button,
  Grid,
  Label,
  Loader,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { useUeuSaskia } from '../../saskia';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { EROSI_ROUTE } from 'volto-ueu/constants';

const SaskiaView = ({ content, location, staticContext }) => {
  const token = useSelector((state) => state.userSession.token);
  const saskiaPrezioa = useSelector((state) => state.saskia);

  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const dispatch = useDispatch();
  const { saskia, addSaskira, removeSaskitik } = useUeuSaskia();
  const [updatedSaskia, setUpdatedSaskia] = useState(false);
  useEffect(() => {
    dispatch(getUser(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      saskia?.content &&
      Object.keys(saskia?.content).length > 0 &&
      !updatedSaskia
    ) {
      dispatch(getSaskiaPrezioa(saskia));
      setUpdatedSaskia(true);
    }
    // dispatch(getUserSchema());
  }, [dispatch, updatedSaskia, saskia]);

  const handleRemove = (item) => {
    removeSaskitik(item);
    setUpdatedSaskia(false);
  };

  const handleAdd = (item) => {
    addSaskira(item);
    setUpdatedSaskia(false);
  };

  const handleDelete = (item, count) => {
    removeSaskitik(item, count);
    setUpdatedSaskia(false);
  };

  return (
    <Container>
      <Helmet title="Erosketa saskia" />
      <Header as="h1" className="no-breadcrumb">
        Erosketa saskia
      </Header>
      {saskia?.content && Object.keys(saskia.content).length > 0 ? (
        <Segment basic>
          <Grid className="saski-taula">
            <Grid.Row columns={3}>
              <Grid.Column width={6}>
                <strong>Argitalpena</strong>
              </Grid.Column>
              <Grid.Column width={1}>
                <strong>Prezioa</strong>
              </Grid.Column>
              <Grid.Column width={4}>
                <strong>Kopurua</strong>
              </Grid.Column>
            </Grid.Row>
            {saskia?.content &&
              Object.keys(saskia?.content)?.map((item, key) => (
                <Grid.Row key={key} columns={3}>
                  <Grid.Column width={6}>
                    {saskia?.content?.[item].title ? (
                      <>
                        {saskia?.content?.[item].title}
                        {` (${saskia?.content?.[item].format})`}
                      </>
                    ) : (
                      <Loader active size="tiny" inline />
                    )}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    {saskia?.content?.[item].unit_price ? (
                      `${saskia?.content?.[item].unit_price} €`
                    ) : (
                      <Loader active size="tiny" inline />
                    )}
                  </Grid.Column>
                  <Grid.Column width={4}>
                    {!saskiaPrezioa.loading ? (
                      <>
                        {saskiaPrezioa.loaded &&
                          saskia?.content?.[item].format !== 'Digitala' && (
                            <Button
                              className="ueu ken"
                              icon
                              onClick={() => handleRemove(item)}
                            >
                              <Icon name="minus" />
                            </Button>
                          )}
                        <Label basic color="black" className="saskia-count">
                          {saskia?.content?.[item].count}
                        </Label>
                        {saskiaPrezioa.loaded &&
                          saskia?.content?.[item].format !== 'Digitala' && (
                            <Button
                              className="ueu gehi"
                              onClick={() => handleAdd(item)}
                            >
                              <Icon name="add" />
                            </Button>
                          )}
                        <Button icon className="ueu ueuButton" primary={true}>
                          <Icon
                            name="trash"
                            onClick={() =>
                              handleDelete(item, saskia?.content?.[item].count)
                            }
                          />
                        </Button>
                      </>
                    ) : (
                      <Loader active inline />
                    )}
                  </Grid.Column>
                </Grid.Row>
              ))}
          </Grid>
          <Grid className="saski-prezioak">
            <Grid.Row columns={3}>
              <Grid.Column width={6}></Grid.Column>
              <Grid.Column width={2}>Subtotala</Grid.Column>
              <Grid.Column width={3}>
                {saskia.subTotal ? (
                  `${saskia.subTotal} €`
                ) : (
                  <Loader active size="tiny" inline />
                )}
              </Grid.Column>
            </Grid.Row>
            {saskia.discounts?.length > 0 &&
              saskia.discounts.map((discount, key) => (
                <Grid.Row key={key} columns={3}>
                  <Grid.Column width={6}></Grid.Column>
                  <Grid.Column width={2}>{discount.title}</Grid.Column>
                  <Grid.Column width={3}>{`-${discount.value} €`}</Grid.Column>
                </Grid.Row>
              ))}
            <Grid.Row columns={3}>
              <Grid.Column width={6}></Grid.Column>
              <Grid.Column width={2}>Bidalketa gastuak</Grid.Column>
              <Grid.Column width={3}>
                {saskia.shipping ? (
                  `${saskia.shipping} €`
                ) : (
                  <Loader active size="tiny" inline />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column width={6}></Grid.Column>
              <Grid.Column width={2}>
                <strong>Guztira </strong>
              </Grid.Column>
              <Grid.Column width={3}>
                <strong>
                  {saskia.total ? (
                    `${saskia.total} €`
                  ) : (
                    <Loader active size="tiny" inline />
                  )}
                </strong>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={6}></Grid.Column>
              <Grid.Column width={6}>
                <Button className="ueu ueuButton" as="a" secondary={true}>
                  IKUSI GEHIAGO
                </Button>
                <UniversalLink
                  href={isLoggedIn ? EROSI_ROUTE : '/login?return_url=/saskia'}
                  className="ui button ueu primary"
                >
                  EROSI
                </UniversalLink>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      ) : (
        <Container>Zure saskia hutsik dago</Container>
      )}
    </Container>
  );
};

export default SaskiaView;
