/**
 * Unesco migrator post.
 * @module actions/iragarki_form
 */
export const UNESCO_POST = 'UNESCO_POST';

/**
 * Unesco migrator post.
 * @function unescoPost
 * @returns {Object} Unesco migrator post action.
 */
export function unescoPost(origin, destination = null) {
  return {
    type: UNESCO_POST,
    request: {
      op: 'post',
      path: `/@unescopost`,
      data: { origin: origin, destination: destination },
    },
  };
}
