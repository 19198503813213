export const GET_CHAPTERS = 'GET_CHAPTERS';
/**
 * Get chapters.
 * @function getChapters
 * @returns {Object} Get chapters action.
 */
export function getChapters(path, limit = 4) {
  return {
    type: GET_CHAPTERS,
    request: {
      op: 'get',
      path: `@search?path.query=${path}&portal_type=Chapter&sort_limit=${limit}`,
    },
  };
}

/**
 * Get chapters of given authors.
 * @function getChaptersByTeacher
 * @returns {Object} Get chapters of given authors action.
 */
export function getChaptersByTeacher(authors = [], limit = 4) {
  const authors_filter =
    authors.length > 0 ? `&all_authors=${authors.join('&all_authors=')}` : '';
  return {
    type: GET_CHAPTERS,
    request: {
      op: 'get',
      path: `@search?portal_type=Chapter&sort_on=effective&sort_order=reverse&sort_limit=${limit}${authors_filter}`,
    },
  };
}
