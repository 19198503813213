/**
 * Cart items reducer.
 * @module reducers/cartItemsReducer
 */

import {
  SET_SASKIA,
  GET_SASKIA,
  GET_SASKIA_PREZIOA,
} from '../../actions/saskia/saskia';
import { SASKIA_KEY } from 'volto-ueu/components/saskia';
import Cookies from 'js-cookie';

const initialState = {
  items: {},
  loading: false,
  loaded: false,
  error: null,
};

export const saskiaReducer = (state = initialState, action = {}) => {
  switch (action?.type) {
    case SET_SASKIA:
      return {
        ...state,
        items: action.items,
      };
    case GET_SASKIA:
      const cookieSaskia = Cookies.get(SASKIA_KEY);
      return {
        ...state,
        items: cookieSaskia ? JSON.parse(cookieSaskia) : {},
      };
    case `${GET_SASKIA_PREZIOA}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GET_SASKIA_PREZIOA}_SUCCESS`:
      return {
        ...state,
        items: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    case `${GET_SASKIA_PREZIOA}_FAIL`:
      return {
        ...state,
        items: action.result,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};
