export const ImageSchemaEnhancer = (props) => {
  const { schema } = props;
  return {
    ...schema,
    fieldsets: [
      {
        ...schema.fieldsets[0],
        fields: [...schema.fieldsets[0].fields, 'preview_image'],
      },
    ],
  };
};
