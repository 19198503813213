export const course_contact_form_schema = {
  type: 'object',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['izen-abizenak', 'telefonoa', 'email', 'testua'],
    },
  ],
  properties: {
    'izen-abizenak': {
      title: 'Izen abizenak',
      type: 'string',
    },
    telefonoa: {
      title: 'Telefonoa',
      type: 'string',
      minLength: 9,
    },
    email: {
      title: 'Helbide elektronikoa',
      type: 'email',
    },
    testua: {
      title: 'Testua',
      widget: 'textarea',
    },
  },
  required: ['izen-abizenak', 'telefonoa', 'email', 'testua'],
};
