/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */
import React from 'react';
import {
  FormattedMessage,
  // defineMessages,
  injectIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { Icon, List, Segment, Grid } from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { default as PIcon } from '@plone/volto/components/theme/Icon/Icon';
import facebookSVG from 'volto-ueu/theme/icons/facebook_icon.svg';
import xitterSVG from 'volto-ueu/theme/icons/x_icon.svg';
import blueskySVG from 'volto-ueu/theme/icons/bluesky_icon.svg';
import mastodonSVG from 'volto-ueu/theme/icons/mastodon_icon.svg';
import peertubeSVG from 'volto-ueu/theme/icons/peertube_icon.svg';
import instagramSVG from 'volto-ueu/theme/icons/instagram_icon.svg';
import youtubeSVG from 'volto-ueu/theme/icons/youtube_icon.svg';

import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
// import loadable from '@loadable/component';

import config from '@plone/volto/registry';
import { useGoogleAnalytics } from 'volto-google-analytics';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
// const RenderBlocks = loadable(() =>
//   import('@plone/volto/components/theme/View/RenderBlocks'),
// );

// const messages = defineMessages({
//   copyright: {
//     id: 'Copyright',
//     defaultMessage: 'Copyright',
//   },
// });
/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */

const Footer = ({ intl }) => {
  useGoogleAnalytics();

  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const subsite = useSelector(
    (state) => state.content?.data?.['@components']?.subsite,
  );
  const dropdownMenuNavItems = useSelector(
    (state) => state.dropdownMenuNavItems?.result,
  );
  return (
    <>
      {!subsite?.title && (
        <Segment
          role="contentinfo"
          color="grey"
          basic
          padded="very"
          textAlign="center"
          className="footer-locations-container"
        >
          <Grid columns={4} stackable doubling>
            {dropdownMenuNavItems.filter((i) => i.rootPath === '/footer')
              .length > 0 &&
              dropdownMenuNavItems
                .filter((i) => i.rootPath === '/footer')[0]
                .items.map((item, index) => {
                  return (
                    <Grid.Column key={index}>
                      <RenderBlocks content={item} />
                    </Grid.Column>
                  );
                })}
          </Grid>
        </Segment>
      )}
      {!subsite?.title && (
        <Segment basic className="footer-social-container">
          <List horizontal>
            <List.Item>
              <UniversalLink href="https://mastodon.eus/@ueu_orokorra">
                <Icon circular inverted className="volto mastodon" />
              </UniversalLink>
            </List.Item>
            <List.Item>
              <UniversalLink href="https://bsky.app/profile/ueu-orokorra.bsky.social">
                <Icon circular inverted className="volto bluesky" />
              </UniversalLink>
            </List.Item>
            <List.Item>
              <UniversalLink href="https://www.instagram.com/ueu_orokorra">
                <Icon circular inverted name="instagram" />
              </UniversalLink>
            </List.Item>
            <List.Item>
              <UniversalLink href="https://www.x.com/ueu_orokorra">
                <Icon circular inverted className="volto twitter" />
              </UniversalLink>
            </List.Item>
            <List.Item>
              <UniversalLink href="https://www.facebook.com/udakoeuskalunibertsitatea">
                <Icon circular inverted name="facebook f" />
              </UniversalLink>
            </List.Item>
            <List.Item>
              <UniversalLink href="https://peertube.eus/c/ueu_orokorra_channel/videos">
                <Icon circular inverted className="volto peertube" />
              </UniversalLink>
            </List.Item>
            <List.Item>
              <UniversalLink href="https://www.youtube.com/user/UEUkanala">
                <Icon circular inverted name="youtube" />
              </UniversalLink>
            </List.Item>
          </List>
        </Segment>
      )}
      {!subsite?.title && (
        <Segment
          color="black"
          basic
          className="discreet footer-links-container"
        >
          <List horizontal>
            {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
            <div role="listitem" className="item">
              <UniversalLink
                className="item"
                href={
                  settings.isMultilingual
                    ? `/${lang}/ohar-legala`
                    : '/ohar-legala'
                }
              >
                <FormattedMessage
                  id="Ohar legala"
                  defaultMessage="Ohar legala"
                />
              </UniversalLink>
            </div>
            <div role="listitem" className="item">
              <UniversalLink
                className="item"
                href={
                  settings.isMultilingual
                    ? `/${lang}/cookie-politika`
                    : '/cookie-politika'
                }
              >
                <FormattedMessage
                  id="Cookie-politika"
                  defaultMessage="Cookie-politika"
                />
              </UniversalLink>
            </div>
            <div role="listitem" className="item">
              <UniversalLink
                className="item"
                href={
                  settings.isMultilingual
                    ? `/${lang}/irisgarritasuna`
                    : '/irisgarritasuna'
                }
              >
                <FormattedMessage
                  id="Accessibility"
                  defaultMessage="Accessibility"
                />
              </UniversalLink>
            </div>
            <div role="listitem" className="item">
              <UniversalLink
                className="item"
                href={
                  settings.isMultilingual
                    ? `/${lang}/ueu/kontaktua`
                    : '/ueu/kontaktua'
                }
              >
                <FormattedMessage id="Contact" defaultMessage="Contact" />
              </UniversalLink>
            </div>
            <div role="listitem" className="item">
              <a className="item" href="https://plone.org">
                <FormattedMessage
                  id="Powered by Plone & Python"
                  defaultMessage="Powered by Plone & Python"
                />
              </a>
            </div>
          </List>
        </Segment>
      )}
      {subsite?.subsite_css_class?.token && (
        <div className="subsite-footer">
          <div role="contentinfo" className="subsite-footer-data">
            <StringToHTML string={subsite.subsite_footer?.data} />
          </div>
          <div role="contentinfo" className="subsite-footer-logos">
            <UniversalLink href="https://mastodon.eus/@ueu_orokorra">
              <PIcon name={mastodonSVG} />
            </UniversalLink>
            <UniversalLink href="https://bsky.app/profile/ueu-orokorra.bsky.social">
              <PIcon name={blueskySVG} />
            </UniversalLink>
            <UniversalLink href="https://www.instagram.com/ueu_orokorra">
              <PIcon name={instagramSVG} />
            </UniversalLink>
            <UniversalLink href="https://www.x.com/ueu_orokorra">
              <PIcon name={xitterSVG} />
            </UniversalLink>
            <UniversalLink href="https://www.facebook.com/udakoeuskalunibertsitatea">
              <PIcon name={facebookSVG} />
            </UniversalLink>
            <UniversalLink href="https://peertube.eus/c/ueu_orokorra_channel/videos">
              <PIcon name={peertubeSVG} />
            </UniversalLink>
            <UniversalLink href="https://www.youtube.com/user/UEUkanala">
              <PIcon name={youtubeSVG} />
            </UniversalLink>
          </div>
        </div>
      )}
    </>
  );
};

Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
