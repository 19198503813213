import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { List, Icon, Dropdown } from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import SearchWidget from '@plone/volto/components/theme/SearchWidget/SearchWidget';
import Anontools from '@plone/volto/components/theme/Anontools/Anontools';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

import { getDropdownMenuNavitems } from 'volto-dropdownmenu/actions';
import { usePrevious } from 'volto-ueu/components/utils';
import { getAllUserModifications } from 'volto-ueu/actions';

import './customNavigation.less';
import { profile_options } from './utils';
import { SASKIA_ROUTE } from 'volto-ueu/constants';
import cx from 'classnames';

const CustomNavigation = ({
  isVisible = false,
  toggleVisibility,
  isLoggedIn,
  username,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const saskia = useSelector((state) => state.saskia.items);

  const usermodifications = useSelector((state) => state.usermodifications);
  useEffect(() => {
    dispatch(getDropdownMenuNavitems());
  }, [dispatch]);
  const dropdownMenuNavItems = useSelector(
    (state) => state.dropdownMenuNavItems?.result,
  );
  let location = useLocation();
  const prevLocation = usePrevious({ location });
  useEffect(() => {
    if (prevLocation && isVisible) {
      toggleVisibility();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    isVisible
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }, [isVisible]);

  const option_base = { as: 'li', className: 'profile-option' };
  const trigger = (
    <>
      {user.username}
      <Icon className="ueu-icon" name="user" />
    </>
  );
  return (
    <div
      className={cx('header-menu', isVisible ? 'menu-opened' : 'menu-closed')}
    >
      <div className="ui container">
        {!isLoggedIn ? (
          <div className="tools mobile tablet only">
            <Anontools />
          </div>
        ) : (
          <div className="logged-in mobile tablet only">
            <Dropdown
              multiple
              onClick={() =>
                !usermodifications.getall.loaded && getAllUserModifications()
              }
              trigger={trigger}
            >
              <Dropdown.Menu>
                <Dropdown.Menu scrolling>
                  <ul className="profile-options">
                    {profile_options(option_base, user, usermodifications).map(
                      (option, index) => (
                        <Dropdown.Item key={index} {...option} />
                      ),
                    )}
                  </ul>
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
            <UniversalLink href={SASKIA_ROUTE}>
              <Icon className="ueu-icon" name="cart" /> (
              {saskia?.content ? Object.keys(saskia?.content).length : 0})
            </UniversalLink>
          </div>
        )}
        <div className="menu-beroa ui mobile tablet only">
          {dropdownMenuNavItems.filter((i) => i.rootPath === '/menu-beroa')
            .length > 0 &&
            dropdownMenuNavItems
              .filter((i) => i.rootPath === '/menu-beroa')[0]
              .items.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <RenderBlocks content={item} />
                  </React.Fragment>
                );
              })}
        </div>
        <div className="search-subscribe-container ui computer large screen only">
          <div className="search">
            <SearchWidget />
          </div>
          <div className="subscribe">
            <UniversalLink
              as="a"
              className="ui button ueu subscribe-button"
              size="large"
              href="/komunitatea/bazkideak"
              fluid="true"
            >
              EGIN ZAITEZ BAZKIDE
            </UniversalLink>
          </div>
        </div>
        <div className="header-navigation-menu">
          {dropdownMenuNavItems.filter((i) => i.rootPath === '/').length > 0 &&
            dropdownMenuNavItems
              .filter((i) => i.rootPath === '/')[0]
              .items.map((item, index) => {
                return (
                  <div key={index} className="header-menu-item">
                    <RenderBlocks content={item} />
                  </div>
                );
              })}
        </div>
        <div className="newsletter-social-container">
          <div className="search mobile tablet only">
            <SearchWidget />
          </div>
          <div className="subscribe mobile tablet only">
            <UniversalLink
              as="a"
              className="ui button ueu subscribe-button"
              size="large"
              href="/komunitatea/bazkideak"
              fluid="true"
            >
              EGIN ZAITEZ BAZKIDE
            </UniversalLink>
          </div>
          <div className="newsletter">
            <Icon name="envelope open" />
            <UniversalLink href="https://acumbamail.com/newform/web/Xj4JJ4vRN6HzxrUUQOyd1XOOO08PYHxp5iZ0K6Y8nk/8367/">
              HARPIDETU BULETINERA!
            </UniversalLink>
          </div>
          <div className="languages-social">
            {/* <div className="languages">
              <UniversalLink to={'/harpidetu'} href="">
                ES
              </UniversalLink>
              <UniversalLink to={'/harpidetu'} href="">
                EN
              </UniversalLink>
              <UniversalLink to={'/harpidetu'} href="">
                FR
              </UniversalLink>
            </div> */}
            <div className="social">
              <List horizontal>
                <List.Item>
                  <UniversalLink href="https://mastodon.eus/@ueu_orokorra">
                    <Icon circular inverted className="volto mastodon" />
                  </UniversalLink>
                </List.Item>
                <List.Item>
                  <UniversalLink href="https://bsky.app/profile/ueu-orokorra.bsky.social">
                    <Icon circular inverted className="volto bluesky" />
                  </UniversalLink>
                </List.Item>
                <List.Item>
                  <UniversalLink href="https://www.instagram.com/ueu_orokorra">
                    <Icon circular inverted name="instagram" />
                  </UniversalLink>
                </List.Item>
                <List.Item>
                  <UniversalLink href="https://www.x.com/ueu_orokorra">
                    <Icon circular inverted className="volto twitter" />
                  </UniversalLink>
                </List.Item>
                <List.Item>
                  <UniversalLink href="https://www.facebook.com/udakoeuskalunibertsitatea">
                    <Icon circular inverted name="facebook f" />
                  </UniversalLink>
                </List.Item>
                <List.Item>
                  <UniversalLink href="https://peertube.eus/c/ueu_orokorra_channel/videos">
                    <Icon circular inverted className="volto peertube" />
                  </UniversalLink>
                </List.Item>
                <List.Item>
                  <UniversalLink href="https://www.youtube.com/user/UEUkanala">
                    <Icon circular inverted name="youtube" />
                  </UniversalLink>
                </List.Item>
              </List>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomNavigation;
