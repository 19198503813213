import React, { useState } from 'react';
import MenuSectionSchema, { ItemSchema } from './MenuSectionSchema';

import BlockDataForm from '@plone/volto/components/manage/Form/BlockDataForm';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';
import InlineForm from '@plone/volto/components/manage/Form/InlineForm';

import { emptyCard, getPanels, getVariationTemplate } from './utils';
import { isEmpty } from 'lodash';
import config from '@plone/volto/registry';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  menuBlock: {
    id: 'menuBlock',
    defaultMessage: 'Menu block',
  },
});

const MenuSectionBlockEdit = (props) => {
  const { data = {}, block = null, onChangeBlock, selected, intl } = props;
  const [selectedItemBlock, setSelectedItemBlock] = useState(-1);

  const variationsConfig = config.blocks.blocksConfig['menuSection'].variations;
  const properties = isEmpty(data?.data?.blocks) ? emptyCard(3) : data.data;
  const itemsData = properties;
  const menuItems = getPanels(itemsData);
  const { BodyTemplate, variationId } = getVariationTemplate(
    data,
    variationsConfig,
  );
  React.useEffect(() => {
    if (isEmpty(data?.data)) {
      onChangeBlock(block, {
        ...data,
        data: {
          ...properties,
        },
        variation: variationId,
      });
    }
    /* eslint-disable-next-line */
  }, []);
  React.useEffect(() => {
    if (!selected) {
      setSelectedItemBlock(-1);
    }
  }, [selected]);
  return (
    <>
      <div
        className="menuSection-header"
        onClick={() => {
          props.setSidebarTab(1);
          setSelectedItemBlock(-1);
        }}
        aria-hidden="true"
      >
        {intl.formatMessage(messages.menuBlock)}
      </div>
      {menuItems && (
        <BodyTemplate
          onChangeBlock={onChangeBlock}
          isEditMode={true}
          selectedItemBlock={selectedItemBlock}
          setSelectedItemBlock={setSelectedItemBlock}
          menuItems={menuItems}
          {...props}
        />
      )}
      <SidebarPortal selected={selected && selectedItemBlock === -1}>
        <BlockDataForm
          title="Menu Section block"
          schema={MenuSectionSchema(config, intl)}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
          block={block}
        />
      </SidebarPortal>
      <SidebarPortal
        selected={
          selected && data.data?.blocks[selectedItemBlock] !== undefined
        }
      >
        <InlineForm
          title="Menu Item"
          schema={ItemSchema(intl)}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              data: {
                ...data.data,
                blocks: {
                  ...data.data.blocks,
                  [selectedItemBlock]: {
                    ...data.data.blocks[selectedItemBlock],
                    [id]: value,
                  },
                },
              },
            });
          }}
          formData={data.data?.blocks[selectedItemBlock]}
          block={block}
        />
      </SidebarPortal>
    </>
  );
};

export default MenuSectionBlockEdit;
