/**
 * EventView view component.
 * @module components/theme/View/EventView
 */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Container,
  // Popup,
  Segment,
} from 'semantic-ui-react';

// import Icon from '@plone/volto/components/theme/Icon/Icon';
// import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
// import shareSVG from '@plone/volto/icons/share.svg';
// import SocialSharing from '@codesyntax/volto-social-sharing/SocialSharing';

import { Picture } from 'volto-ueu/components';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
// import CourseKnowledgeFieldsCard from 'volto-ueu/components/Views/CourseView/CourseKnowledgeFieldsCard';
// import { sharePositions, socialElements } from '../../../helpers/share';

import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * EventView view component class.
 * @function EventView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const EventView = ({ content, location }) => {
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);
  // const [currentUrl, setCurrentUrl] = React.useState('');
  // useEffect(() => {
  //   setCurrentUrl(window.location.href);
  // }, []);
  // MOBILE checker
  // const [isMobile, setIsMobile] = React.useState(false);

  // function handleWindowSizeChange() {
  //   setIsMobile(window?.innerWidth <= 768);
  // }
  // useEffect(() => {
  //   handleWindowSizeChange();
  //   window && window.addEventListener('resize', handleWindowSizeChange);
  //   return () => {
  //     window && window.removeEventListener('resize', handleWindowSizeChange);
  //   };
  // });
  // end - MOBILE checker
  return (
    <Container className="view-wrapper">
      <Grid stackable>
        <Grid.Column width={5}>
          {content.title && <h1 className="">{content.title}</h1>}
          <p className="documentDescription">{content.description}</p>
          {content.text && <StringToHTML string={content.text.data} />}
          <Grid className="event-data">
            <Grid.Column width={4}>
              <Segment className="event-date">
                <span className="event-month">
                  {moment(content?.start).format('MMMM')}
                </span>
                <span className="event-day">
                  {moment(content?.start).format('DD')}
                </span>
                <span className="event-time">
                  {moment(content?.start).format('HH:mm')}
                </span>
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment basic className="event-location">
                {content.location}
              </Segment>
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column width={7}>
          {content.preview_image && (
            <figure>
              <Picture
                source="newsitem"
                imageBase={`${content['@id']}/@@images/preview_image`}
                alt={content.title}
                content={content}
                lazy={false}
              ></Picture>
              {content.image_caption && (
                <figcaption>{content.image_caption}</figcaption>
              )}
            </figure>
          )}
        </Grid.Column>
        {/* <Grid.Column width={1} className="share-col">
          {!isMobile && (
            <Popup
              content={
                <ul className="share">
                  {socialElements
                    .filter((se) => !['tg', 'wa'].includes(se.id))
                    .map((se, key) => {
                      return (
                        <li key={key}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                              se.id !== 'mail'
                                ? `${se.sharing_url}${currentUrl}`
                                : `${se.sharing_url}[UEU] ${content.title}&body=${currentUrl}`
                            }
                          >
                            {se.name}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              }
              on="click"
              popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
              position="bottom right"
              trigger={
                <Icon
                  name={shareSVG}
                  size="30px"
                  style={{ cursor: 'pointer' }}
                />
              }
            />
          )}
          <SocialSharing
            location={location}
            bannerPosition={sharePositions}
            socialElements={socialElements}
          />
        </Grid.Column> */}
      </Grid>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
EventView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default EventView;
