import {
  NewsItemCard,
  CourseCard,
  PersonCard,
  ChapterCard,
  PublicationCard,
  BookCard,
  VideoCard,
  ElkarrizketaCard,
  IritziaCard,
  PageCard,
  PodcastCard,
  TxiotesiaCard,
  IragarkiaCard,
  EventCard,
  PosterCard,
} from './';

const typesComponents = {
  Course: CourseCard,
  Person: PersonCard,
  Publication: PublicationCard,
  Book: BookCard,
  Page: PageCard,
  Poster: PosterCard,
  Chapter: ChapterCard,
  'News Item': NewsItemCard,
  Video: VideoCard,
  Event: EventCard,
  Podcast: PodcastCard,
  Txiotesia: TxiotesiaCard,
  Iragarkia: IragarkiaCard,
};

const tagsComponents = {
  albistea: NewsItemCard,
  elkarrizketa: ElkarrizketaCard,
  iritzia: IritziaCard,
};

export const getCardComponent = (item) => {
  if (!item) {
    return typesComponents['@type'];
  }
  const type = item.hasOwnProperty('@type')
    ? Object.keys(typesComponents).includes(item['@type'])
      ? item['@type']
      : 'Page'
    : 'Page';

  if (type === 'News Item') {
    //TODO Albistearen etiketa batetik erauzi beharko da albistea, bideo, elkarrizketa edo iritzia formatua izango duen
    let subtype = 'albistea';
    if (item?.newsitem_type?.includes('interview')) {
      subtype = 'elkarrizketa';
    } else if (item?.author_name) {
      subtype = 'iritzia';
    }
    return tagsComponents[subtype];
  }
  return typesComponents[type];
};
