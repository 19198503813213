import CourseEnrollView from './components/Views/CourseEnrollView/CourseEnrollView';
import ErosiView from './components/Views/ErosiView/ErosiView';
import AddIragarkiaView from './components/Views/IragarkiaView/AddIragarkiaView';
import NireBazkidetzaView from './components/Views/NireBazkidetzaView/NireBazkidetzaView';
import NireDatuakView from './components/Views/NireDatuakView/NireDatuakView';
import ErosketaGuztiakView from './components/Views/NireErosketakView/ErosketaGuztiakView';
import ErosketaView from './components/Views/NireErosketakView/ErosketaView';
import NireErosketakView from './components/Views/NireErosketakView/NireErosketakView';
import NireHarpidetzaView from './components/Views/NireHarpidetzaView/NireHarpidetzaView';
import NireIkastaroakView from './components/Views/NireIkastaroakView/NireIkastaroakView';
import FreeOkView from './components/Views/OrdainketaViews/FreeOkView';
import NokView from './components/Views/OrdainketaViews/NokView';
import OkView from './components/Views/OrdainketaViews/OkView';
import OnholdView from './components/Views/OrdainketaViews/OnholdView';
import ShopNokView from './components/Views/OrdainketaViews/ShopNokView';
import ShopOkView from './components/Views/OrdainketaViews/ShopOkView';
import ShopTransferView from './components/Views/OrdainketaViews/ShopTransferView';
import PayEnrollView from './components/Views/PayEnrollView/PayEnrollView';
import SaskiaView from './components/Views/SaskiaView/SaskiaView';
import UserModificationsView from './components/Views/UserModificationsView/UserModificationsView';
import UserModificationView from './components/Views/UserModificationsView/UserModificationView';
import {
  ADD_IRAGARKIA_ROUTE,
  EROSI_ROUTE,
  EROSKETA_GUZTIAK_ROUTE,
  FREE_ROUTE,
  IKASTAROA_FORM_ROUTE,
  IKASTAROA_PAY_ROUTE,
  NIRE_BAZKIDETZA_ROUTE,
  NIRE_DATUAK_ROUTE,
  NIRE_EROSKETAK_ROUTE,
  NIRE_HARPIDETZA_ROUTE,
  NIRE_IKASTAROAK_ROUTE,
  PAID_NOK_ROUTE,
  PAID_OK_ROUTE,
  SASKIA_ROUTE,
  SHOP_PAID_NOK_ROUTE,
  SHOP_PAID_OK_ROUTE,
  SHOP_TRANSFERENCE_ROUTE,
  TRANSFERENCE_ROUTE,
  UNPAID_MODE_FULL,
  UNPAID_MODE_TRANSFERENCE,
  UNPAID_ROUTE,
  USERMODIFICATIONS_ROUTE,
} from './constants';
import EmailTemplatesPreview from './components/Views/EmailTemplatesPreview/EmailTemplatesPreview';
import { UnescoMigratorView } from './components/Views/UnescoMigratorView/UnescoMigratorView';

const applyRoutes = (config) => {
  config.addonRoutes = [
    ...config.addonRoutes,
    {
      path: NIRE_DATUAK_ROUTE,
      component: NireDatuakView,
    },
    {
      path: NIRE_IKASTAROAK_ROUTE,
      component: NireIkastaroakView,
    },
    {
      path: `${NIRE_EROSKETAK_ROUTE}/:erosketa${SHOP_TRANSFERENCE_ROUTE}`,
      component: ShopTransferView,
    },
    {
      path: `${NIRE_EROSKETAK_ROUTE}/:erosketa${SHOP_PAID_NOK_ROUTE}`,
      component: ShopNokView,
    },
    {
      path: `${NIRE_EROSKETAK_ROUTE}/:erosketa${SHOP_PAID_OK_ROUTE}`,
      component: ShopOkView,
    },
    {
      path: `${NIRE_EROSKETAK_ROUTE}/:erosketa`,
      component: ErosketaView,
    },
    {
      path: NIRE_EROSKETAK_ROUTE,
      component: NireErosketakView,
    },
    {
      path: `${EROSKETA_GUZTIAK_ROUTE}/:erosketa`,
      component: ErosketaView,
    },
    {
      path: EROSKETA_GUZTIAK_ROUTE,
      component: ErosketaGuztiakView,
    },

    {
      path: NIRE_BAZKIDETZA_ROUTE,
      component: NireBazkidetzaView,
    },
    {
      path: NIRE_HARPIDETZA_ROUTE,
      component: NireHarpidetzaView,
    },

    {
      path: SASKIA_ROUTE,
      component: SaskiaView,
    },
    {
      path: EROSI_ROUTE,
      component: ErosiView,
    },
    {
      path: `/jarduera-akademikoa/**${IKASTAROA_FORM_ROUTE}`,
      component: CourseEnrollView,
    },
    {
      path: `/**${IKASTAROA_PAY_ROUTE}`,
      component: PayEnrollView,
    },
    {
      path: `/**${PAID_NOK_ROUTE}`,
      component: NokView,
    },
    {
      path: `/**${PAID_OK_ROUTE}`,
      component: OkView,
    },
    {
      path: `/**${TRANSFERENCE_ROUTE}`,
      component: (props) => (
        <OnholdView {...props} mode={UNPAID_MODE_TRANSFERENCE} />
      ),
    },
    {
      path: `/**${UNPAID_ROUTE}`,
      component: (props) => <OnholdView {...props} mode={UNPAID_MODE_FULL} />,
    },
    {
      path: `/**${FREE_ROUTE}`,
      component: FreeOkView,
    },
    {
      path: `${USERMODIFICATIONS_ROUTE}/:modification`,
      component: UserModificationView,
    },
    {
      path: USERMODIFICATIONS_ROUTE,
      component: UserModificationsView,
    },
    {
      path: `/**${ADD_IRAGARKIA_ROUTE}`,
      component: AddIragarkiaView,
    },
    {
      path: '/emailtemplatespreview',
      component: EmailTemplatesPreview,
      exact: true,
    },
    {
      path: '/controlpanel/unesco_migrator',
      component: UnescoMigratorView,
      exact: true,
    },
  ];
  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    NIRE_DATUAK_ROUTE,
    NIRE_IKASTAROAK_ROUTE,
    NIRE_BAZKIDETZA_ROUTE,
    NIRE_HARPIDETZA_ROUTE,
    new RegExp(`${NIRE_EROSKETAK_ROUTE}/.*${SHOP_TRANSFERENCE_ROUTE}$`),
    new RegExp(`${NIRE_EROSKETAK_ROUTE}/.*${SHOP_PAID_NOK_ROUTE}$`),
    new RegExp(`${NIRE_EROSKETAK_ROUTE}/.*${SHOP_PAID_OK_ROUTE}$`),
    new RegExp(`${NIRE_EROSKETAK_ROUTE}/.*$`),
    NIRE_EROSKETAK_ROUTE,
    new RegExp(`${EROSKETA_GUZTIAK_ROUTE}/.*$`),
    SASKIA_ROUTE,
    EROSI_ROUTE,
    new RegExp(`/jarduera-akademikoa/.*${IKASTAROA_FORM_ROUTE}$`),
    new RegExp(`/.*${IKASTAROA_PAY_ROUTE}$`),
    `${PAID_NOK_ROUTE}`,
    `${PAID_OK_ROUTE}`,
    `${TRANSFERENCE_ROUTE}`,
    `${UNPAID_ROUTE}`,
    `${FREE_ROUTE}`,
    `${ADD_IRAGARKIA_ROUTE}`,
    `${USERMODIFICATIONS_ROUTE}/:modification`,
    USERMODIFICATIONS_ROUTE,
    `/passwordreset`,
    '/passwordreset/:token',
    '/apunteak',
    '/emailtemplatespreview',
  ];
  config.settings.externalRoutes = [{ match: '/apunteak' }];
  config.settings.customizations = process.env.WEBPACK_ALIASES;

  return config;
};

export default applyRoutes;
