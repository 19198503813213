import { defineMessages } from 'react-intl';
const messages = defineMessages({
  variation: {
    id: 'variation',
    defaultMessage: 'Variation',
  },
  menuSectionTitle: {
    id: 'menuSectionTitle',
    defaultMessage: 'Title',
  },
  menuSectionItems: {
    id: 'menuSectionItems',
    defaultMessage: 'Items',
  },
  menuSectionItemBlockTitle: {
    id: 'menuSectionItemBlockTitle',
    defaultMessage: 'Menu Section Items',
  },
  menuSectionItemTitle: {
    id: 'menuSectionItemTitle',
    defaultMessage: 'Title',
  },
  menuSectionItemUrl: {
    id: 'menuSectionItemUrl',
    defaultMessage: 'Url',
  },
  menuSectionItemIcon: {
    id: 'menuSectionItemIcon',
    defaultMessage: 'Icon',
  },
});

export default messages;
