import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Header,
  Icon,
  Container,
  List,
  Segment,
} from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import config from '@plone/volto/registry';

import { getPublications, getChapters } from 'volto-ueu/actions';
import { Picture } from 'volto-ueu/components';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import { UeuListing } from 'volto-ueu/components/Variations';
import { get_taxonomy_children } from 'volto-ueu/helpers/taxonomy_utils';

import jwtDecode from 'jwt-decode';

const ChapterView = ({ content, location }) => {
  const dispatch = useDispatch();
  const publications = useSelector((state) => state.publications);
  const chapters = useSelector((state) => state.chapters);
  const user = useSelector((state) => state.users.user);
  const token = useSelector((state) => state.userSession.token);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const { settings } = config;
  useEffect(() => {
    dispatch(
      getPublications(
        location.pathname.split('/').slice(0, -1).slice(0, -1).join('/'),
        5,
      ),
    );
    dispatch(
      getChapters(location.pathname.split('/').slice(0, -1).join('/'), 5),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const c_authors = content.authors ? content.authors : [];
  const c_other_authors = content.other_authors ? content.other_authors : [];
  const authors = [...c_authors, ...c_other_authors];

  return (
    <Container className="view-wrapper">
      <Grid>
        <Grid.Column width={11}>
          <Header as="h1">
            {content.title && (
              <StringToHTML
                string={content.title.replace('<p>', '').replace('</p>', '')}
                as="span"
              />
            )}
          </Header>
        </Grid.Column>
        <Grid.Column width={1} textAlign="right">
          <Icon name="share alternate" />
        </Grid.Column>
      </Grid>
      <Grid stackable className="course-card">
        <Grid.Column width={8}>
          <Segment className="generalinfo-card">
            <Grid>
              <Grid.Column>
                <List bulleted>
                  {authors && (
                    <List.Item>
                      <strong>{'Autorea(k): '}</strong>
                      {authors.map((author, key) => {
                        return (
                          <React.Fragment key={key}>
                            {key !== 0 ? ', ' : ''}
                            {author['@id'] ? (
                              <UniversalLink href={author['@id']}>
                                {author.title}
                              </UniversalLink>
                            ) : (
                              <>{author}</>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </List.Item>
                  )}
                  {content.taxonomy_unesco_categorization &&
                    content.taxonomy_unesco_categorization.length > 0 && (
                      <List.Item>
                        <strong>{'Jakintza-arloak: '}</strong>
                        {content.taxonomy_unesco_categorization
                          .map((cat) => get_taxonomy_children(cat.title))
                          .join(', ')}
                      </List.Item>
                    )}
                  <List.Item>
                    <strong>{'Orrialdeak: '}</strong>
                    {content?.page_numbers && `${content.page_numbers}`}
                  </List.Item>
                </List>
                {content?.text && (
                  <StringToHTML string={content.text.data}></StringToHTML>
                )}
              </Grid.Column>
            </Grid>
          </Segment>
          {!user.uztaro_id && !content.downloadable_file && (
            <Segment color="black">
              <p>
                Aldizkari honetako artikulu guztiak deskargatzeko, bertako
                harpideduna izan behar zara.
              </p>
              {!isLoggedIn && (
                <>
                  <p>
                    Horrez gain, artikuluak deskargatzeko webgunean login egin
                    behar da:
                  </p>
                  <UniversalLink
                    aria-label="login"
                    className="ui button ueu primary"
                    href={`/login${
                      content?.['@id']
                        ? `?return_url=${content['@id'].replace(
                            settings.apiPath,
                            '',
                          )}`
                        : ''
                    }`}
                  >
                    <FormattedMessage id="Log in" defaultMessage="Log in" />
                  </UniversalLink>
                </>
              )}
            </Segment>
          )}
          {!isLoggedIn && content.downloadable_file && (
            <Segment color="black">
              <p>
                Aldizkari honetako artikuluak deskargatzeko webgunean login egin
                behar da:
              </p>
              <UniversalLink
                aria-label="login"
                className="ui button ueu primary"
                href={`/login${
                  content?.['@id']
                    ? `?return_url=${content['@id'].replace(
                        settings.apiPath,
                        '',
                      )}`
                    : ''
                }`}
              >
                <FormattedMessage id="Log in" defaultMessage="Log in" />
              </UniversalLink>
            </Segment>
          )}
          {content.file && (content.downloadable_file || user.uztaro_id) && (
            <>
              {content.downloadable_file && !user.email ? (
                <span
                  title="Hau deskargatzeko login egin behar duzu"
                  className="ui button ueu disabled download-chapter"
                >
                  Jaitsi
                  <Icon name="chevron down" />
                </span>
              ) : (
                <UniversalLink
                  href={`${content['@id']}/@@download/file`}
                  className="ui button ueu download-chapter"
                  item={content}
                >
                  {'Jaitsi'}
                  <Icon name="chevron down" />
                </UniversalLink>
              )}
            </>
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          <Grid className="aldizkaria-card">
            <Grid.Row>
              <Grid.Column>
                <Picture
                  imageBase={`${content?.parent?.['@id']}/@@images/image`}
                  alt={content?.parent?.title}
                ></Picture>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* <AldizkariaCard content={content} /> */}
        </Grid.Column>
      </Grid>
      {chapters.chapters.length > 0 &&
        chapters?.chapters?.filter((pub) => pub.UID !== content.UID).length >
          0 && (
          <UeuListing
            header="Argitalpen bereko beste artikulu batzuk"
            items={chapters?.chapters
              ?.filter((ch) => ch.UID !== content.UID)
              .slice(0, 4)}
            howManyColumns={4}
            CardComponent={'Chapter'}
            isEditMode={false}
            showDescription={false}
            showUnconditionally={true}
            doubling={true}
          />
        )}
      {publications?.publications &&
        publications?.publications?.filter(
          (pub) => pub.UID !== content?.parent?.UID,
        ).length > 0 && (
          <UeuListing
            header="Beste edizio batzuk"
            items={publications?.publications
              .filter((pub) => pub.UID !== content?.parent?.UID)
              .slice(0, 4)}
            howManyColumns={4}
            CardComponent={'Publication'}
            isEditMode={false}
            showUnconditionally={true}
            doubling={true}
          />
        )}
    </Container>
  );
};

export default ChapterView;
