export const selectOrderFacetSchemaEnhancer = ({ schema, formData }) => {
  // adds (enables) the 'multiple' field after the 'type' dropdown
  let { fields } = schema.fieldsets[0];
  const pos = fields.indexOf('type') + 1;
  fields = [
    ...fields.slice(0, pos),
    'reverse',
    'multiple',
    'searchable',
    ...fields.slice(pos, fields.length),
  ];
  schema.properties.reverse = {
    type: 'boolean',
    title: 'Alderantzizko ordena',
    default: false,
  };
  schema.properties.searchable = {
    type: 'boolean',
    title: 'Gehitu bilaketa',
    default: false,
  };
  schema.fieldsets[0].fields = fields;
  return schema;
};
