import { SEND_COURSE_FORM } from 'volto-ueu/actions';
// import { map } from 'lodash';
const getInitialState = {
  error: null,
  loaded: false,
  loading: false,
  response: '',
};

/**
 * course_form reducer.
 * @function course_form
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const sendCourseFormReducer = (state = getInitialState, action = {}) => {
  switch (action.type) {
    case `${SEND_COURSE_FORM}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        response: '',
      };
    case `${SEND_COURSE_FORM}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
        response: 'Arazo bat gertatu da!',
      };
    case `${SEND_COURSE_FORM}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        response: action.result,
      };
    default:
      return state;
  }
};
