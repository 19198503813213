import { UNESCO_POST } from 'volto-ueu/actions/unesco_migrator/unesco_migrator';
// import { map } from 'lodash';
const getInitialState = {
  error: null,
  loaded: false,
  loading: false,
  response: {},
};

/**
 * unesco_migrator reducer.
 * @function unesco_migrator
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const unescoPostReducer = (state = getInitialState, action = {}) => {
  switch (action.type) {
    case `${UNESCO_POST}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        response: {},
      };
    case `${UNESCO_POST}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
        response: { message: 'Arazo bat gertatu da!' },
      };
    case `${UNESCO_POST}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        response: action.result,
      };
    default:
      return state;
  }
};
