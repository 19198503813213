import React from 'react';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
// import { getPanels } from './utils';
import { Segment, List } from 'semantic-ui-react';

const FooterResidenceBlockView = (props) => {
  const { data } = props;
  // const menuItems = getPanels(data.data);

  return (
    <Segment basic>
      <h4>{data.title}</h4>
      <List>
        {data.phone && (
          <List.Item>
            <List.Icon name="phone" />
            <List.Content>
              <strong>Tlf:</strong> {data.phone}
            </List.Content>
          </List.Item>
        )}
        {data.mail && (
          <List.Item>
            <List.Icon name="mail" />
            <List.Content>
              <strong>Helbide elektronikoa: </strong>
              <UniversalLink href={`mailto:${data.mail}`}>
                {data.mail}
              </UniversalLink>
            </List.Content>
          </List.Item>
        )}
        {data.map && (
          <List.Item>
            <List.Icon name="map marker alternate" />
            <List.Content>
              <UniversalLink href={data.map}>Kokapena mapan</UniversalLink>
            </List.Content>
          </List.Item>
        )}
      </List>
    </Segment>
  );
};
export default FooterResidenceBlockView;
