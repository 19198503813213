import React from 'react';
import { useSelector } from 'react-redux';
import { Segment } from 'semantic-ui-react';

// import { getContent } from '@plone/volto/actions/content/content';
// import NotFound from '@plone/volto/components/theme/NotFound/NotFound';

// import { isEntryInTime } from 'volto-ueu/components/Views/CourseView/utils';
// import { PAID_NOK_ROUTE } from 'volto-ueu/constants';
import { ContentData } from './ContentData';

/**
 * ShopNokView view component.
 * @module components/Views/OrdainketaView/ShopNokView
 */
/**
 * ShopNokView view.
 * @function ShopNokView
 * @returns {string} Markup of the component.
 */

const ShopNokView = (props) => {
  // const dispatch = useDispatch();
  // const re = new RegExp(`${PAID_NOK_ROUTE}$`);
  // const ikastaroa_path = props.location.pathname.replace(re, '');
  const content = useSelector((state) => state.content);
  const title_text = 'Ordainketa ez da burutu...';
  // useEffect(() => {
  //   dispatch(getContent(ikastaroa_path));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <ContentData
      h1_text={title_text}
      h2_text={content.data?.title}
      Body={() => (
        <>
          <Segment basic>
            <p>
              Ordainketa ezin izan da burutu, jarri gurekin harremanetan
              ordainketa burutzeko.
            </p>
          </Segment>
          <Segment basic>Mila esker!</Segment>
        </>
      )}
    />
  );
};

export default ShopNokView;
