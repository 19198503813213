export const UNLOCK_CHAPTERS = 'UNLOCK_CHAPTERS';
/**
 * Unlock chapters.
 * @function unlockChapters
 * @returns Unlock chapters.
 */
export function unlockChapters(path) {
  return {
    type: UNLOCK_CHAPTERS,
    request: {
      op: 'post',
      path: `${path}/@unlock-chapters`,
    },
  };
}
