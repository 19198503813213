import messages from '@codesyntax/volto-listingadvanced-variation/messages';
import { defineMessages } from 'react-intl';
import { QSSchema } from './QSSchema';
import {
  isOnlyTxiotesia,
  showDateFieldset,
  showDescriptionFieldset,
} from './utils';

const ourMessages = defineMessages({
  moreLinkText2: {
    id: 'moreLinkText2',
    defaultMessage: 'Testua 2',
  },
  moreLinkTextDescription2: {
    id: 'moreLinkTextDescription2',
    defaultMessage: 'Gehiago ikusteko bigarren estekaren testua',
  },
  dateAccordion: {
    id: 'dateAccordion',
    defaultMessage: 'Data',
  },
  showDate: {
    id: 'showDate',
    defaultMessage: 'Erakutsi data',
  },
  showDateDescription: {
    id: 'showDateDescription',
    defaultMessage: 'Erakutsi albistearen data',
  },
  moreLinkUrl2: {
    id: 'moreLinkUrl2',
    defaultMessage: 'Esteka edo plone objektua',
  },
  moreLinkUrlDescription2: {
    id: 'moreLinkUrlDescription2',
    defaultMessage: 'Itsatsi kanpoko esteka edo aukeratu plone objektua',
  },
  firstElement: {
    id: 'firstElement',
    defaultMessage: 'Lehen elementua destakatu',
  },
  firstElementDescription: {
    id: 'firstElementDescription',
    defaultMessage: 'Lehen elementua destakatu moduan erakusteko aukera',
  },
  featureWinnerConfiguration: {
    id: 'featureWinnerConfiguration',
    defaultMessage: 'Txiotesi irabazleak',
  },
  featureWinnerTitle: {
    id: 'featureWinnerTitle',
    defaultMessage: 'Destakatu txiotesi irabazlea',
  },
  disableNumbersGroup: {
    id: 'disableNumbersGroup',
    defaultMessage: 'Fazeteko zenbakiak',
  },
  disableNumbers: {
    id: 'disableNumbers',
    defaultMessage: 'Desaktibatu fazeteko zenbakiak',
  },
});

export const UeuListingSchema = (props) => {
  const {
    intl,
    schema,
    formData,
    // data
  } = props;
  const headingChoices = [
    ['h2', 'H2'],
    ['h3', 'H3'],
    ['h4', 'H4'],
  ];

  // remove querystring from default fieldset
  schema.fieldsets.map((fieldset) => {
    if (fieldset.id === 'searchquery') {
      fieldset.fields.push('firstElement');
    }
    return fieldset;
  });

  const querystring =
    formData && formData['@type'] === 'listing'
      ? [
          {
            id: 'querystring',
            title: intl.formatMessage(messages.querystring),
            fields: ['querystring', 'firstElement'],
          },
        ]
      : [];

  // const disableNumbers =
  //   data && data['@type'] === 'search'
  //     ? [
  //         {
  //           id: 'disableNumbers',
  //           title: intl.formatMessage(ourMessages.disableNumbersGroup),
  //           fields: ['disableNumbers'],
  //         },
  //       ]
  //     : [];

  const include_fieldsets = ['searchquery', 'facets', 'controls'];
  const [default_fieldset] = schema.fieldsets.filter(
    (fieldset) => fieldset.id === 'default',
  );
  const remove_from_default = ['headlineTag', 'headline', 'querystring'];
  const dateFieldset = showDateFieldset(formData)
    ? [
        {
          id: 'date',
          title: intl.formatMessage(ourMessages.dateAccordion),
          fields: ['showDate'],
        },
      ]
    : [];

  const descriptionFieldset = showDescriptionFieldset(formData)
    ? [
        {
          id: 'description',
          title: intl.formatMessage(messages.descriptionConfiguration),
          fields: ['showDescription'],
        },
      ]
    : [];

  const featureWinner = isOnlyTxiotesia(formData)
    ? [
        {
          id: 'featureWinner',
          title: intl.formatMessage(ourMessages.featureWinnerConfiguration),
          fields: ['featureWinner'],
        },
      ]
    : [];
  return {
    ...schema,
    fieldsets: [
      {
        ...default_fieldset,
        fields: default_fieldset.fields.filter(
          (field) => !remove_from_default.includes(field),
        ),
      },
      ...(schema.fieldsets &&
        schema.fieldsets.length > 0 &&
        schema.fieldsets.filter((fieldset) =>
          include_fieldsets.includes(fieldset.id),
        )),
      ...querystring,
      {
        id: 'header',
        title: intl.formatMessage(messages.headerConfiguration),
        fields: ['header', 'headerUrl', 'headerTag'],
      },
      {
        id: 'columns',
        title: intl.formatMessage(messages.columnsConfiguration),
        fields: ['howManyColumns'],
      },
      ...descriptionFieldset,
      ...dateFieldset,
      ...featureWinner,
      {
        id: 'moreLink',
        title: intl.formatMessage(messages.moreLinkConfiguration),
        fields: [
          'moreLinkText',
          'moreLinkUrl',
          'moreLinkText2',
          'moreLinkUrl2',
        ],
      },
      // ...disableNumbers,
    ],
    properties: {
      ...schema.properties,
      querystring: {
        ...schema.properties.querystring,
        schemaEnhancer: QSSchema,
      },
      header: {
        title: intl.formatMessage(messages.header),
        description: intl.formatMessage(messages.headerDescription),
      },
      headerUrl: {
        title: intl.formatMessage(messages.headerUrl),
        description: intl.formatMessage(messages.headerUrlDescription),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      headerTag: {
        title: intl.formatMessage(messages.headerTag),
        description: intl.formatMessage(messages.headerTagDescription),
        choices: [['h1', 'H1'], ...headingChoices],
      },
      howManyColumns: {
        title: intl.formatMessage(messages.columnsCount),
        choices: [
          [1, '1'],
          [2, '2'],
          [3, '3'],
          [4, '4'],
        ],
      },
      moreLinkText: {
        title: intl.formatMessage(messages.moreLinkText),
        description: intl.formatMessage(messages.moreLinkTextDescription),
      },
      moreLinkUrl: {
        title: intl.formatMessage(messages.moreLinkUrl),
        description: intl.formatMessage(messages.moreLinkUrlDescription),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      moreLinkText2: {
        title: intl.formatMessage(ourMessages.moreLinkText2),
        description: intl.formatMessage(ourMessages.moreLinkTextDescription2),
      },
      moreLinkUrl2: {
        title: intl.formatMessage(ourMessages.moreLinkUrl2),
        description: intl.formatMessage(ourMessages.moreLinkUrlDescription2),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      showDescription: {
        title: intl.formatMessage(messages.descriptionTitle),
        description: intl.formatMessage(messages.descriptionDescription),
        type: 'boolean',
        default: true,
      },
      showDate: {
        title: intl.formatMessage(ourMessages.showDate),
        description: intl.formatMessage(ourMessages.showDateDescription),
        type: 'boolean',
        default: true,
      },
      disableNumbers: {
        title: intl.formatMessage(ourMessages.disableNumbers),
        type: 'boolean',
        default: false,
      },
      firstElement: {
        title: intl.formatMessage(ourMessages.firstElement),
        description: intl.formatMessage(ourMessages.firstElementDescription),
        type: 'boolean',
        default: false,
      },
      featureWinner: {
        title: intl.formatMessage(ourMessages.featureWinnerTitle),
        type: 'boolean',
        default: false,
      },
    },
  };
};
