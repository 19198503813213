import { GET_USERKUDEU, SET_USERKUDEU } from '../../actions';
// import { map } from 'lodash';
const getInitialState = {
  get: { error: null, loaded: false, loading: false },
  user_data: {},
};

/**
 * user kudeu reducer.
 * @function user kudeu
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const userKudeuReducer = (state = getInitialState, action = {}) => {
  switch (action.type) {
    case `${GET_USERKUDEU}_PENDING`:
      return {
        ...state,
        get: {
          ...state.get,
          error: null,
          loaded: false,
          loading: true,
        },
      };
    case `${GET_USERKUDEU}_FAIL`:
      return {
        ...state,
        get: {
          ...state.get,
          error: true,
          loaded: false,
          loading: false,
        },
      };
    case `${GET_USERKUDEU}_SUCCESS`:
      return {
        ...state,
        get: {
          ...state.get,
          error: null,
          loaded: true,
          loading: false,
        },
        user_data: action.result?.data,
        data_order: action.result?.order,
      };
    case `${SET_USERKUDEU}_PENDING`:
      return {
        ...state,
        set: {
          ...state.set,
          error: null,
          loaded: false,
          loading: true,
        },
      };
    case `${SET_USERKUDEU}_FAIL`:
      return {
        ...state,
        set: {
          ...state.set,
          error: true,
          loaded: false,
          loading: false,
          message: action?.error?.response?.body?.error,
        },
      };
    case `${SET_USERKUDEU}_SUCCESS`:
      return {
        ...state,
        set: {
          ...state.set,
          error: null,
          loaded: true,
          loading: false,
        },
      };
    default:
      return state;
  }
};
