/**
 * Iragarkia view component.
 * @module components/theme/View/IragarkiaView
 */
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Grid,
  Container,
  Header,
  Tab,
  Popup,
  Segment,
} from 'semantic-ui-react';

import Icon from '@plone/volto/components/theme/Icon/Icon';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import shareSVG from '@plone/volto/icons/share.svg';
import SocialSharing from '@codesyntax/volto-social-sharing/SocialSharing';

import { sendIragarkiForm } from 'volto-ueu/actions';
// import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { Picture, Form } from 'volto-ueu/components';
import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import { sharePositions, socialElements } from '../../../helpers/share';
import { contact_form_schema } from './contact_form_schema';

import moment from 'moment';
import PropTypes from 'prop-types';
import { formatLL } from '../../utils';

/**
 * Iragarkia view component class.
 * @function IragarkiaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const IragarkiaView = ({ content, location }) => {
  const dispatch = useDispatch();
  const path = location.pathname;
  let lang = useSelector((state) => state.intl.locale);
  const iragarkiaForm = useSelector((state) => state.iragarkiform);
  let iragarkiaForm_ref = useRef(iragarkiaForm);
  const contact_form_schema_data = contact_form_schema();

  const [isMobile, setIsMobile] = React.useState(false);
  const [currentUrl, setCurrentUrl] = React.useState('');
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);
  const handleWindowSizeChange = () => {
    setIsMobile(window?.innerWidth <= 768);
  };
  useEffect(() => {
    handleWindowSizeChange();
    window && window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window && window.removeEventListener('resize', handleWindowSizeChange);
    };
  });
  useEffect(() => {
    if (iragarkiaForm.loaded && iragarkiaForm_ref.current.loading) {
      toast.success(
        <Toast
          autoClose={3000}
          title={iragarkiaForm.response}
          content="Iragarkiaren arduraduna jarriko da zurekin harremanetan."
        />,
      );
    }
    iragarkiaForm_ref.current = iragarkiaForm;
  }, [iragarkiaForm]);

  moment.locale(lang);

  const handleSubmit = (data, content, schema) => {
    if (
      schema.properties.captcha.zuzena.toLowerCase() ===
      data.captcha.toLowerCase()
    )
      dispatch(sendIragarkiForm(path, data, content));
    else
      toast.error(
        <Toast
          autoClose={3000}
          title="Captcha ez da zuzena!"
          content="Zuzendu eta saiatu berriro."
        />,
      );
  };
  const iragarki_pane = content.text
    ? [
        {
          menuItem: 'IRAGARKIA',
          render: () => (
            <Tab.Pane className="Tabs">
              {content.text && <StringToHTML string={content.text.data} />}
            </Tab.Pane>
          ),
        },
      ]
    : [];

  const panes = [
    ...iragarki_pane,
    {
      menuItem: 'KONTAKTUA',
      render: () => (
        <Tab.Pane className="Tabs">
          <Form
            schema={contact_form_schema_data}
            onSubmit={(data) =>
              handleSubmit(data, content, contact_form_schema_data)
            }
            isFirst={true}
            submitLabel="Bidali"
            resetAfterSubmit
          ></Form>
        </Tab.Pane>
      ),
    },
  ];
  return (
    <Container className="view-wrapper">
      <Grid>
        <Grid.Column computer={11} mobile={12}>
          <Header as="h1" className="iragarki-title">
            {content?.title}
          </Header>
          <Grid>
            <Grid.Row>
              <Grid.Column computer={3} mobile={12}>
                <Segment basic className="extra-data">
                  <p>
                    {content?.name && <strong>{content.name}</strong>}
                    {content.phone && (
                      <>
                        <br />
                        {content.phone}
                      </>
                    )}
                  </p>
                  <p>
                    {content?.effective !== null &&
                      formatLL(content?.effective)}
                  </p>
                  {content.image && (
                    <UniversalLink
                      target="_blank"
                      href={`${content.image.download}`}
                    >
                      <figure>
                        <Picture
                          source="newsitem"
                          imageBase={`${content['@id']}/@@images/image`}
                          alt={content.title}
                          content={content}
                          lazy={false}
                        ></Picture>
                        {content.image_caption && (
                          <figcaption>{content.image_caption}</figcaption>
                        )}
                      </figure>
                    </UniversalLink>
                  )}
                </Segment>
              </Grid.Column>
              <Grid.Column computer={9} mobile={12}>
                <Tab className="ikastaro-panelak" panes={panes} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={1} className="share-col">
          {!isMobile && (
            <Popup
              content={
                <ul className="share">
                  {socialElements
                    .filter((se) => !['tg', 'wa'].includes(se.id))
                    .map((se, key) => {
                      return (
                        <li key={key}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                              se.id !== 'mail'
                                ? `${se.sharing_url}${currentUrl}`
                                : `${se.sharing_url}[UEU] ${content.title}&body=${currentUrl}`
                            }
                          >
                            {se.name}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              }
              on="click"
              popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
              position="bottom right"
              trigger={
                <Icon
                  name={shareSVG}
                  size="30px"
                  style={{ cursor: 'pointer' }}
                />
              }
            />
          )}
          <SocialSharing
            location={location}
            bannerPosition={sharePositions}
            socialElements={socialElements}
          />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
IragarkiaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default IragarkiaView;
