export const sharePositions = {
  desktop: {},
  mobile: {
    position: 'fixed',
    left: '50%',
    bottom: '-23px',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    zIndex: '9999',
  },
};

export const socialElements = [
  {
    name: 'Facebook',
    fa_name: ['fab', 'facebook'],
    color: '#fff',
    sharing_url: 'https://www.facebook.com/sharer.php?u=',
    only_mobile: true,
    id: 'fb',
  },
  {
    name: 'Twitter',
    fa_name: ['fab', 'twitter'],
    color: '#fff',
    sharing_url: 'https://twitter.com/intent/tweet?text=',
    only_mobile: true,
    id: 'tw',
  },
  {
    name: 'Posta elektronikoa',
    fa_name: ['fas', 'envelope'],
    color: '#fff',
    sharing_url: 'mailto:?subject=',
    only_mobile: true,
    id: 'mail',
  },
  {
    name: 'Mastodon',
    fa_name: ['fab', 'mastodon'],
    color: '#fff',
    sharing_url: 'https://mastodon.eus/share?text=',
    only_mobile: true,
    id: 'mtd',
  },
  {
    name: 'Telegram',
    fa_name: ['fab', 'telegram-plane'],
    color: '#fff',
    sharing_url: 'tg://msg?text=',
    only_mobile: true,
    id: 'tg',
  },
  {
    name: 'Whatsapp',
    fa_name: ['fab', 'whatsapp'],
    color: '#fff',
    sharing_url: 'whatsapp://send?text=',
    only_mobile: true,
    id: 'wa',
  },
];
