import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Segment, Label, Table } from 'semantic-ui-react';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { getVocabulary } from '@plone/volto/actions/vocabularies/vocabularies';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import Field from '@plone/volto/components/manage/Form/Field';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import {
  getModification,
  getUserKudeu,
  removeUserModification,
  setUserKudeu,
  getUserSchema,
} from 'volto-ueu/actions';
import { UeuModal } from 'volto-ueu/components';
import { USERMODIFICATIONS_ROUTE } from 'volto-ueu/constants';
import { getFieldValue } from './utils';
import { formatLL } from '../../utils';

const UserModificationView = ({ content, location, staticContext }) => {
  const usermodifications = useSelector((state) => state.usermodifications);
  const userkudeu = useSelector((state) => state.userkudeu);
  const token = useSelector((state) => state.userSession.token);
  const userschema = useSelector((state) => state.userschema.userschema);
  let lang = useSelector((state) => state.intl.locale);
  const vocabularies = useSelector((state) => state.vocabularies);
  moment.locale(lang);
  let usermodifications_ref = useRef(usermodifications);
  let userkudeu_ref = useRef(userkudeu);

  const [modification, setModification] = useState();
  const [editMode, setEditMode] = useState(false);

  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const params = useParams();
  const dispatch = useDispatch();
  // initial execution to get the modifications and the userschema
  useEffect(() => {
    dispatch(getModification(params?.modification));
    // dispatch(getAllUserModifications());
    dispatch(getUserSchema());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get the vocabularies for the userschema
  useEffect(() => {
    if (userschema) {
      Object.entries(userschema.properties).forEach((property) => {
        if (property[1].vocabulary && property[1].vocabulary['@id']) {
          dispatch(
            getVocabulary({
              vocabNameOrURL: property[1].vocabulary['@id'],
            }),
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userschema]);

  useEffect(() => {
    // go to parent route when the modification has been removed
    if (
      usermodifications_ref.current.remove.loading &&
      usermodifications.remove.loaded
    ) {
      history.push(USERMODIFICATIONS_ROUTE);
    }
    //set initial data for modification
    if (
      usermodifications_ref.current.get.loading &&
      usermodifications.get.loaded
    ) {
      setModification(usermodifications.modifications[0]);
      dispatch(getUserKudeu(usermodifications.modifications[0]?.user));
    }
    usermodifications_ref.current = usermodifications;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usermodifications]);

  // get user from Kudeu after the modification is filtered
  useEffect(() => {
    if (userkudeu_ref.current.set?.loading && userkudeu.set?.loaded) {
      toast.success(
        <Toast
          success
          title={'Datuak aldatuta'}
          content={'Datuak zuzen aldatu dira Kudeun eta ueu.eus-en.'}
        />,
        {
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        },
      );
      dispatch(removeUserModification(modification.UID));
    }
    if (userkudeu_ref.current.set?.loading && userkudeu.set?.error) {
      toast.error(
        <Toast
          error
          title={'Arazo bat gertatu da'}
          content={userkudeu.set?.message?.message}
        />,
        {
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        },
      );
    }
    userkudeu_ref.current = userkudeu;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userkudeu]);

  let history = useHistory();

  const sendToKudeu = (kudeu_data, modification) => {
    for (var field in modification.modifications) {
      kudeu_data[field] = modification.modifications[field];
    }
    dispatch(setUserKudeu(modification.user, modification.modifications));
  };
  return !isLoggedIn ? (
    <>
      {token ? (
        <Forbidden pathname={location.pathname} staticContext={staticContext} />
      ) : (
        <Unauthorized
          pathname={location.pathname}
          staticContext={staticContext}
        />
      )}
    </>
  ) : (
    <div className="ui container">
      <Helmet
        title={`${modification?.user} erabiltzailearen aldaketa`}
      ></Helmet>
      <Header
        as="h1"
        className="no-breadcrumb"
      >{`${modification?.user} erabiltzailearen aldaketa`}</Header>
      {modification ? (
        <>
          <h2>Aldaketa data:</h2>
          <span className="modification-datetime">
            {formatLL(modification.datetime)}
          </span>
          <Segment>
            <h3>Erabiltzailearen aldaketak</h3>
            <Table unstackable className="erabiltzailearen-aldaketa-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Aldagaia</Table.HeaderCell>
                  <Table.HeaderCell>Kudeu</Table.HeaderCell>
                  <Table.HeaderCell>Aldaketa</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {userkudeu?.data_order?.length > 0 &&
                  userkudeu?.data_order.map((field_key, key) => {
                    const modified_data = Object.keys(
                      modification?.modifications,
                    ).includes(field_key);
                    const extras =
                      field_key === 'jaiotze_data'
                        ? { type: 'react_datepicker', widget: null }
                        : {};
                    const label_params = modified_data
                      ? { color: 'grey' }
                      : { basic: true };
                    return (
                      <Table.Row key={key}>
                        <Table.Cell>
                          <Label size="large" {...label_params}>
                            {userschema?.properties
                              ? userschema?.properties[field_key]?.title
                              : field_key}
                            :
                          </Label>
                        </Table.Cell>
                        <Table.Cell>
                          <span style={{ color: 'grey' }}>
                            {getFieldValue(
                              userkudeu.user_data[field_key],
                              field_key,
                              userschema,
                              vocabularies,
                            )}
                          </span>
                        </Table.Cell>
                        <Table.Cell>
                          {modified_data &&
                            (editMode ? (
                              <Field
                                id={field_key}
                                value={modification?.modifications[field_key]}
                                onChange={(id, value) => {
                                  const newModification = {
                                    ...modification,
                                    modifications: {
                                      ...modification.modifications,
                                      [field_key]: value,
                                    },
                                  };
                                  setModification(newModification);
                                }}
                                {...userschema.properties[field_key]}
                                {...extras}
                              ></Field>
                            ) : (
                              <strong>
                                {getFieldValue(
                                  modification.modifications[field_key],
                                  field_key,
                                  userschema,
                                  vocabularies,
                                )}
                              </strong>
                            ))}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {modified_data && !editMode && (
                            <Button
                              className="ueu"
                              onClick={() => {
                                const newModification = {
                                  ...modification,
                                };
                                delete newModification.modifications[field_key];
                                setModification(newModification);
                              }}
                            >
                              Baztertu
                            </Button>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
              <Table.Footer fullwidth>
                <Table.Row>
                  <Table.Cell colspan={4}>
                    <Segment basic floated="left">
                      <UniversalLink
                        className="ui button ueu"
                        href={USERMODIFICATIONS_ROUTE}
                      >
                        Atzera
                      </UniversalLink>
                    </Segment>
                    <Segment basic floated="right">
                      {Object.keys(modification.modifications).length > 0 ? (
                        <>
                          <Button
                            className="ueu"
                            onClick={() => setEditMode(!editMode)}
                          >
                            {editMode ? 'Onartu' : 'Aldatu'}
                          </Button>
                          {!editMode && (
                            <UeuModal
                              header="Erabiltzailearen aldaketa baztertu"
                              closeAction={{
                                closeText: 'Itxi aldaketarik egin gabe',
                              }}
                              okAction={{
                                okText: 'Bai, baztertu aldaketa',
                                okOnClick: () => {
                                  dispatch(
                                    removeUserModification(modification.UID),
                                  );
                                },
                              }}
                              trigger={() => {
                                return (
                                  <Button className="ueu">
                                    Baztertu aldaketa
                                  </Button>
                                );
                              }}
                            >
                              <p>
                                Erabiltzaileak egin duen aldaketa baztertzera
                                zoaz. Baztertzen baduzu, informazioa ez da
                                ueu.eus gunean eta Kudeun gordeko.
                              </p>
                              <p>
                                <ul>
                                  {Object.entries(
                                    modification.modifications,
                                  ).map((elementua, key) => (
                                    <li key={key}>
                                      <strong>{elementua[0]}: </strong>
                                      {elementua[1]}
                                    </li>
                                  ))}
                                </ul>
                              </p>
                              <p>
                                <strong>
                                  Ziur zaude aldaketa baztertu nahi duzula?
                                </strong>
                              </p>
                            </UeuModal>
                          )}
                          {!editMode && (
                            <UeuModal
                              header="Erabiltzailearen aldaketa Kudeu-ra bidali"
                              closeAction={{
                                closeText: 'Itxi aldaketak bidali gabe',
                              }}
                              okAction={{
                                okText: 'Bai, bidali aldaketa Kudeura',
                                okOnClick: () => {
                                  sendToKudeu(
                                    userkudeu?.user_data,
                                    modification,
                                  );
                                },
                              }}
                              trigger={() => {
                                return (
                                  <Button className="ueu primary">
                                    Bidali Kudeura
                                  </Button>
                                );
                              }}
                            >
                              <p>
                                Erabiltzaileak egin duen aldaketa Kudeu-ra
                                bidaltzera zoaz. Honekin, informazioa
                                erabiltzaileari ueu.eus gunean eta Kudeun
                                eguneratuko zaio.
                              </p>
                              <p>Hauek dira gauzatuko diren aldaketak:</p>
                              <p>
                                <ul>
                                  {Object.entries(
                                    modification.modifications,
                                  ).map((elementua, key) => (
                                    <li key={key}>
                                      <strong>{elementua[0]}: </strong>
                                      {elementua[1]}
                                    </li>
                                  ))}
                                </ul>
                              </p>
                              <p>
                                <strong>
                                  Ziur zaude aldaketa Kudeura bidali nahi
                                  duzula?
                                </strong>
                              </p>
                            </UeuModal>
                          )}
                        </>
                      ) : (
                        <UeuModal
                          header="Erabiltzailearen aldaketa baztertu"
                          closeAction={{
                            closeText: 'Itxi aldaketarik egin gabe',
                          }}
                          okAction={{
                            okText: 'Bai, baztertu aldaketa',
                            okOnClick: () =>
                              dispatch(
                                removeUserModification(modification.UID),
                              ),
                          }}
                          trigger={() => {
                            return (
                              <Button className="ueu">Baztertu aldaketa</Button>
                            );
                          }}
                        >
                          <p>
                            Erabiltzaileak egin duen aldaketa baztertzera zoaz.
                            Baztertzen baduzu, informazioa ez da ueu.eus gunean
                            eta Kudeun gordeko.
                          </p>
                          <p>
                            <strong>
                              Ziur zaude aldaketa baztertu nahi duzula?
                            </strong>
                          </p>
                        </UeuModal>
                      )}
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
        </>
      ) : (
        <Header as="h2">Aldaketaren helbidea ez da zuzena</Header>
      )}
    </div>
  );
};

export default UserModificationView;
