import { FORM_STATE_ENROLL } from '../../../reducers/forms/forms';
import { slugify } from '../../../components/utils';

export const getPricesSchema = (data) => {
  const pricesSchema = {
    title: 'Bestelako datuak',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [],
      },
    ],
    properties: {
      bazkaria_ordainketa: {
        title: 'Bazkaltzen geratuko zara?',
        html_description: {
          data: `Bazkariaren prezioa <strong>${data?.lunch_price} eurokoa</strong> izango da, matrikulaz aparte.`,
        },
        type: 'required_boolean',
      },
      afaria_ordainketa: {
        title: 'Afaltzen geratuko zara?',
        html_description: {
          data: `Afariaren prezioa <strong>${data?.dinner_price} eurokoa</strong> izango da, matrikulaz aparte.`,
        },
        type: 'required_boolean',
      },
      loa_ordainketa: {
        title: 'Lotan geratuko zara?',
        html_description: {
          data: `Lotarako lekuaren prezioa <strong>${data?.sleep_price} eurokoa</strong> izango da, matrikulaz aparte.`,
        },
        type: 'required_boolean',
      },
    },
    required: [],
  };
  if (data?.sleep_lunch) {
    if (data?.lunch_price > 0) {
      pricesSchema.fieldsets[0].fields.push('bazkaria_ordainketa');
    }
    if (data?.dinner_price > 0) {
      pricesSchema.fieldsets[0].fields.push('afaria_ordainketa');
    }
    if (data?.sleep_price > 0) {
      pricesSchema.fieldsets[0].fields.push('loa_ordainketa');
    }
  }
  return pricesSchema;
};

export const getExtraSchema = (session_data, extra_schema) => {
  let session_extra_schema = JSON.parse(session_data);
  Object.keys(session_extra_schema?.properties).forEach((s_prop) => {
    // TYPE STRING
    if (session_extra_schema.properties[s_prop].type === 'string') {
      extra_schema.properties[slugify(s_prop)] = {
        ...session_extra_schema.properties[s_prop],
        title: s_prop,
      };
      // TYPE ANY OF
    } else if (session_extra_schema.properties[s_prop].anyOf) {
      extra_schema.properties[slugify(s_prop)] = {
        title: s_prop,
        choices: session_extra_schema.properties[s_prop].anyOf.map((opt) => {
          return [slugify(opt.description), opt.description];
        }),
      };
      // TYPE ALL OF
    } else if (session_extra_schema.properties[s_prop].allOf) {
      extra_schema.properties[slugify(s_prop)] = {
        title: s_prop,
        choices: session_extra_schema.properties[s_prop].allOf.map((opt) => {
          return [slugify(opt.description), opt.description];
        }),
        isMulti: true,
        noValueOption: false,
      };
    } else {
      extra_schema.properties[slugify(s_prop)] = {
        ...session_extra_schema.properties[s_prop],
        title: s_prop,
      };
    }
    extra_schema.fieldsets[0].fields.push(slugify(s_prop));
    // delete session_extra_schema.properties[s_prop];
  });

  session_extra_schema?.required &&
    session_extra_schema?.required.forEach((s_req, key) => {
      extra_schema.required[key] = slugify(s_req);
    });
};

export const getMatrikulaPrice = (matrikula_data, content) => {
  let result = 0;
  if (matrikula_data) {
    if (matrikula_data[FORM_STATE_ENROLL].matrikula_mota) {
      result =
        result +
        parseInt(
          matrikula_data[FORM_STATE_ENROLL].matrikula_mota.split('#')[1],
        );
    }
    if (matrikula_data[FORM_STATE_ENROLL].afaria_ordainketa) {
      result = result + parseInt(content?.data?.dinner_price ?? 0);
    }
    if (matrikula_data[FORM_STATE_ENROLL].bazkaria_ordainketa) {
      result = result + parseInt(content?.data?.lunch_price ?? 0);
    }
    if (matrikula_data[FORM_STATE_ENROLL].loa_ordainketa) {
      result = result + parseInt(content?.data?.sleep_price ?? 0);
    }
  }
  return result;
};

export const showExtraStep = ({
  sleep_lunch = false,
  lunch_price = 0,
  dinner_price = 0,
  sleep_price = 0,
  session_data = '',
}) => {
  let result = false;
  if (sleep_lunch && (lunch_price || dinner_price || sleep_price))
    result = true;
  try {
    const session_extra_schema = JSON.parse(session_data);
    const keys = Object.keys(session_extra_schema?.properties);
    if (keys.length > 0) {
      result = true;
    }
  } catch {}

  return result;
};

export const hasToPay = (payment, form_data, content_data) => {
  if (!payment || !form_data || !content_data) return false;
  const {
    can_pay = false,
    paypal = false,
    creditcard = false,
    banktransfer = false,
  } = payment;
  const {
    bazkaria_ordainketa = false,
    afaria_ordainketa = false,
    loa_ordainketa = false,
  } = form_data;
  const {
    general_price = 0,
    lunch_price = 0,
    dinner_price = 0,
    sleep_price = 0,
  } = content_data;
  let result = false;
  if (!can_pay && !paypal && !creditcard && !banktransfer) return false;
  if (general_price > 0) {
    result = true;
  }
  if (bazkaria_ordainketa && lunch_price > 0) {
    result = true;
  }
  if (afaria_ordainketa && dinner_price > 0) {
    result = true;
  }
  if (loa_ordainketa && sleep_price > 0) {
    result = true;
  }
  return result;
};
