import { GET_ORDAINDU_DAITEKE } from 'volto-ueu/actions';
const initialState = {
  error: null,
  ordaindu_daiteke: {},
  loaded: false,
  loading: false,
  message: '',
};

/**
 * Ordaindu daiteke reducer.
 * @function ordaindu_daiteke
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const ordainduDaitekeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_ORDAINDU_DAITEKE}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_ORDAINDU_DAITEKE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        result: action.result,
      };
    case `${GET_ORDAINDU_DAITEKE}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
        message: action?.error?.response?.body?.error,
      };
    default:
      return state;
  }
};
