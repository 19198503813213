import { getCaptcha } from '../../Form/utils';
export const contact_form_schema = () => {
  return {
    type: 'object',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'izen-abizenak',
          'email',
          'telefonoa',
          'testua',
          'captcha',
          'accept',
        ],
      },
    ],
    properties: {
      'izen-abizenak': {
        title: 'Izen abizenak',
        type: 'string',
      },
      telefonoa: {
        title: 'Telefonoa',
        type: 'string',
        minLength: 9,
      },
      email: {
        title: 'Helbide elektronikoa',
        type: 'email',
      },
      testua: {
        title: 'Testua',
        widget: 'textarea',
      },
      captcha: getCaptcha(),
      accept: {
        title:
          'Web gune honen erabiltzaileak hemen azaldutakoa ulertu eta borondatez onartzen du',
        html_description: {
          data: 'Hona gehitzen den material guztia CC-BY-SA lizentzia librearekin zabalduko da. Jakinarazi nahi dizugu 2016ko apirilaren 27ko 679/2016 Datuen Babeserako Erregelamendu Orokorrean aurreikusitakoaren arabera, zure datuak "kudeaketa" izeneko fitxategian sartuko direla. Datu pertsonalak biltzearen xedea soil-soilik da UEUren http://www.unibertsitatea.net webgunearekin duzun harremana kudeatzea. Eskubidea daukazu datuok ikusi, zuzendu, ezabatu edo aurka egiteko, honako helbide honetara idatziz: UEU, Erribera kalea 14, 48005 Bilbo edota helbide elektroniko honetara idatziz: ataria@ueu.org',
        },
        type: 'required_boolean',
      },
    },
    required: ['izen-abizenak', 'email', 'testua', 'captcha', 'accept'],
  };
};
