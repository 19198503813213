import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container, Header, Segment } from 'semantic-ui-react';

import { getUser } from '@plone/volto/actions/users/users';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';

import Toast from '@plone/volto/components/manage/Toast/Toast';

import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';

import { getUserSchema } from 'volto-ueu/actions';
import { Form, UeuLoader } from 'volto-ueu/components';
import { onSubmit } from './utils';

import jwtDecode from 'jwt-decode';
import { extendDependencies } from 'volto-ueu/components/utils';

const NireDatuakView = ({ content, location, staticContext }) => {
  /** state selectors **/
  const token = useSelector((state) => state.userSession.token);
  const user = useSelector((state) => state.users.user);
  const usermodifications = useSelector((state) => state.usermodifications);
  const userschema = useSelector((state) => state.userschema);
  let usermodifications_add_ref = useRef(usermodifications.add);

  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(userId));
    dispatch(getUserSchema());
  }, [dispatch, userId]);

  useEffect(() => {
    if (
      usermodifications.add.loaded &&
      usermodifications_add_ref.current.loading
    ) {
      toast.success(
        <Toast
          success
          title={usermodifications.title}
          content={usermodifications.message}
        />,
        {
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        },
      );
    } else if (
      usermodifications.add.error &&
      usermodifications_add_ref.current.loading
    ) {
      toast.error(
        <Toast
          error
          title={'Arazo bat gertatu da'}
          content={usermodifications?.add?.message?.message}
        />,
        {
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        },
      );
    }
    usermodifications_add_ref.current = usermodifications.add;
  }, [dispatch, usermodifications]);

  return (
    <>
      <Helmet title={'Nire Datuak'} />
      <div className="ui container">
        {!isLoggedIn && (
          <>
            {token ? (
              <Forbidden
                pathname={location.pathname}
                staticContext={staticContext}
              />
            ) : (
              <Unauthorized
                pathname={location.pathname}
                staticContext={staticContext}
              />
            )}
          </>
        )}
        {isLoggedIn && (
          <>
            <Container className="view-wrapper">
              <Header as="h1" className="no-breadcrumb">
                Nire datuak
              </Header>
              {userschema?.loaded && (
                <div className="nire-datuak-form">
                  <Form
                    formData={user}
                    schema={extendDependencies(userschema.userschema)}
                    onSubmit={(event) => onSubmit(event, userId, dispatch)}
                    loading={userschema.loading}
                    isFirst={true}
                  />
                </div>
              )}
              {userschema?.loading && <UeuLoader />}

              {userschema.error && (
                <Segment placeholder>
                  <h2>
                    Arazoren bat egon da zure datuak kargatzerakoan. Saiatu
                    beranduago mesedez.
                  </h2>
                </Segment>
              )}
            </Container>
          </>
        )}
      </div>
    </>
  );
};

export default NireDatuakView;
