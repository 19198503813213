import React from 'react';
import { Grid, Image, Divider, Header } from 'semantic-ui-react';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { Picture } from 'volto-ueu/components';
import { getRandomSrc } from 'volto-ueu/components/RandomImage/RandomImage';

const PersonCard = ({ item, isEditMode }) => {
  const knowledge_areas = item?.knowledge_areas_title?.map(
    (knowledge_area) => knowledge_area,
  );
  return (
    <Grid divided="vertically" textAlign="center">
      <Grid.Column width={16}>
        <div>
          <ConditionalLink item={item} condition={!isEditMode}>
            {!item?.image_field && (
              <Image
                circular
                src={getRandomSrc(item.UID, 'users')}
                alt="This content has no image, this is a default placeholder."
                size="small"
              />
            )}
            {item?.image_field && (
              <Picture
                className="circular"
                imageBase={`${item['@id']}/@@images/${item?.image_field}`}
                alt={item.title}
              ></Picture>
            )}
          </ConditionalLink>
        </div>
        <Divider />
        <div>
          <h3>
            <ConditionalLink
              item={item}
              condition={!isEditMode && item.review_state !== 'private'}
            >
              {item?.name}
              {item?.surname1 && ' ' + item.surname1}
              {item?.surname2 && ' ' + item.surname2}
            </ConditionalLink>
          </h3>
        </div>
        {knowledge_areas && (
          <div centered>
            <Header size="small">{`${knowledge_areas?.join(', ')}`}</Header>
          </div>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default PersonCard;
