/**
 * getEmailTemplates.
 * @module actions/EMAIL_TEMPLATES
 */

export const EMAIL_TEMPLATES = 'EMAIL_TEMPLATES';
/**
 * getEmailTemplates.
 * @function getEmailTemplates
 * @returns Check person nan at Kudeu.
 */
export function getEmailTemplates() {
  return {
    type: EMAIL_TEMPLATES,
    request: {
      op: 'get',
      path: `@emailtemplates`,
    },
  };
}

export const EMAIL_TEMPLATE = 'EMAIL_TEMPLATE';
export function getEmailTemplate(templatename) {
  return {
    type: EMAIL_TEMPLATE,
    request: {
      op: 'post',
      path: `@emailtemplate`,
      data: { template: templatename },
    },
    templatename,
  };
}
