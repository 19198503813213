/**
 * Root reducer.
 * @module reducers/root
 */

import { getPublicationsReducer } from './publication/publications';
import { getBooksReducer } from './books/books';
import {
  getCoursesReducer,
  updateCourseReducer,
  getMyCoursesReducer,
} from './course/courses';
import { getExtraBreadcrumbReducer } from './extra_breadcrumb/extra_breadcrumb';
import { getUserschemaReducer } from './userschema/userschema';
import { saskiaReducer } from './saskia/saskia';
import querystringsearch_bstart from './querystringsearch_bstart/querystringsearch_bstart';
import { formsReducer } from './forms/forms';
import { matrikulatuReducer } from './matrikulazioa/matrikulatu';
import { matrikulaOrdainduReducer } from './matrikulazioa/matrikula_ordaindu';
import { ordainduDaitekeReducer } from './matrikulazioa/ordaindu_daiteke';
import { sendTransferMailReducer } from './matrikulazioa/send_transfer_mail';
import { registryReducer } from './registry/registry';
import { erosketakReducer } from './erosketak/erosketak';
import { sendCourseFormReducer } from './course_form/course_form';
import { sendIragarkiFormReducer } from './iragarki_form/iragarki_form';
import { userModificationsReducer } from './usermodifications/usermodifications';
import { userKudeuReducer } from './kudeu/kudeu_user';
import { updatePersonReducer } from './person/update_person';
import { lockChaptersReducer } from './chapters/lock';
import { unlockChaptersReducer } from './chapters/unlock';
import { getChaptersReducer } from './chapters/get';
import { paySaskiaReducer } from './saskia/paySaskia';
import { checkNanReducer } from './check_nan/check_nan';
import { addIragarkiaReducer } from './add_iragarkia/add_iragarkia';
import { emailTemplatesReducer } from './email_templates/email_templates';
import { unescoPostReducer } from './unesco_migrator/unesco_migrator';
/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  // ...defaultReducers,
  // Add your reducers here
  courses: getCoursesReducer,
  myCourses: getMyCoursesReducer,
  publications: getPublicationsReducer,
  books: getBooksReducer,
  extra_breadcrumbs: getExtraBreadcrumbReducer,
  userschema: getUserschemaReducer,
  querystringsearch: querystringsearch_bstart,
  saskia: saskiaReducer,
  paySaskia: paySaskiaReducer,
  forms: formsReducer,
  matrikulatu: matrikulatuReducer,
  matrikulaOrdaindu: matrikulaOrdainduReducer,
  payment: ordainduDaitekeReducer,
  registry: registryReducer,
  erosketak: erosketakReducer,
  courseform: sendCourseFormReducer,
  iragarkiform: sendIragarkiFormReducer,
  addiragarkia: addIragarkiaReducer,
  usermodifications: userModificationsReducer,
  userkudeu: userKudeuReducer,
  updateCourse: updateCourseReducer,
  updatePerson: updatePersonReducer,
  lockChapters: lockChaptersReducer,
  unlockChapters: unlockChaptersReducer,
  chapters: getChaptersReducer,
  checkNan: checkNanReducer,
  sendTransferMail: sendTransferMailReducer,
  emailTemplates: emailTemplatesReducer,
  unescoPost: unescoPostReducer,
};

export default reducers;
