import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Provider, useSelector } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import configureStore from 'redux-mock-store';
import { Segment } from 'semantic-ui-react';
import cx from 'classnames';

import { handleKey } from '@plone/volto-slate/blocks/Text/keyboard';
import deserialize from '@plone/volto-slate/editor/deserialize';
import { serializeNodes } from '@plone/volto-slate/editor/render';
import SlateEditor from '@plone/volto-slate/editor/SlateEditor';
import {
  makeEditor,
  createEmptyParagraph,
  normalizeExternalData,
} from '@plone/volto-slate/utils';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

import StringToHTML from 'volto-ueu/components/Utils/StringToHTML';
import { getTeaserImageURL } from './utils';
import { Picture } from 'volto-ueu/components';

const IkergazteHomeTeaserView = (props) => {
  const {
    data,
    isEditMode,
    selected,
    id,
    onChangeBlock,
    className,
    block,
    placeholder,
    properties,
  } = props;
  const { preview_image, richtext } = data;
  const image = preview_image?.[0];
  const value = React.useMemo(() => richtext ?? { data: '' }, [richtext]);
  const editor = React.useMemo(() => makeEditor(), []);
  const token = useSelector((state) => state.userSession.token);

  const toHtml = React.useCallback(
    (value) => {
      const mockStore = configureStore();
      const html = ReactDOMServer.renderToStaticMarkup(
        <Provider store={mockStore({ userSession: { token } })}>
          <MemoryRouter>{serializeNodes(value || [])}</MemoryRouter>
        </Provider>,
      );
      return {
        'content-type': value ? value['content-type'] : 'text/html',
        encoding: value ? value.encoding : 'utf8',
        data: html,
      };
    },
    [token],
  );
  const fromHtml = React.useCallback(
    (value) => {
      try {
        const html = value?.data || '';

        const parsed = new DOMParser().parseFromString(html, 'text/html');
        const body =
          parsed.getElementsByTagName('google-sheets-html-origin').length > 0
            ? parsed.querySelector('google-sheets-html-origin > table')
            : parsed.body;
        let data = deserialize(editor, body);
        data = normalizeExternalData(editor, data);

        // editor.children = data;
        // Editor.normalize(editor);
        // TODO: need to add {text: ""} placeholders between elements
        const res = data.length ? data : [createEmptyParagraph()];
        return res;
      } catch {
        return;
      }
    },
    [editor],
  );

  const valueFromHtml = React.useMemo(() => fromHtml(value), [value, fromHtml]);

  const handleChange = React.useCallback(
    (newValue) => {
      onChangeBlock(id, {
        ...data,
        richtext: toHtml(newValue),
      });
    },
    [onChangeBlock, toHtml, id, data],
  );
  return (
    <Segment basic className={cx(props.className, 'banner-ikergazte')}>
      {image && (
        <Picture
          imageBase={flattenToAppURL(getTeaserImageURL('', image))}
          alt={data?.image_alt}
          width="100%"
          source="newsitem"
          className="banner-image"
        />
      )}

      <div className="banner-top-main-block-title">
        {data.title && (
          <h2>
            <strong>{data?.title}</strong>
          </h2>
        )}
        {isEditMode ? (
          <SlateEditor
            className={className}
            id={id}
            name={id}
            value={valueFromHtml}
            onChange={handleChange}
            onKeyDown={handleKey}
            block={block}
            selected={selected}
            properties={properties}
            placeholder={placeholder}
          />
        ) : (
          <StringToHTML string={data?.richtext?.data} />
        )}
      </div>
    </Segment>
  );
};

export default IkergazteHomeTeaserView;
