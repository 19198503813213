import { Dimmer, Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import './loader.css';
export const UeuLoader = (props) => {
  return (
    <Dimmer active inverted className="ueu-loader">
      <Loader indeterminate size="small">
        <FormattedMessage id="loading" defaultMessage="Loading" />
      </Loader>
    </Dimmer>
  );
};
