import { LOCK_CHAPTERS } from 'volto-ueu/actions';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
};

export const lockChaptersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${LOCK_CHAPTERS}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${LOCK_CHAPTERS}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
        message: action.result,
      };
    case `${LOCK_CHAPTERS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        message: action.result,
      };
    default:
      return state;
  }
};
