import React from 'react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import PropTypes from 'prop-types';

const Button = (props) => {
  let { variation, data, children, isEditMode = false } = props;
  const styles = { display: 'flex', justifyContent: data.position ?? 'unset' };
  const className =
    variation === 'primary'
      ? 'primary'
      : 'ikergazte-button'
        ? 'ikergazte-button'
        : '';
  const href = data.link?.[0];
  return (
    <div style={styles}>
      {href && !isEditMode ? (
        <UniversalLink className={`ui button ueu ${className}`} item={href}>
          {children}
        </UniversalLink>
      ) : (
        <span className={`ui button ueu ${className}`}>{children}</span>
      )}
    </div>
  );
};

Button.propTypes = {
  variation: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.node,
};
export default Button;
