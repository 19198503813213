export const captchas = [
  {
    title: 'CAPTCHA: Idatzi zenbakiz sei gehi bost',
    zuzena: '11',
  },
  {
    title: 'CAPTCHA: Idatzi zenbakiz zazpi gehi 3',
    zuzena: '10',
  },
  {
    title: 'CAPTCHA: Idatzi testuz hamar ken 3',
    zuzena: 'zazpi',
  },
  {
    title: 'CAPTCHA: Idatzi testuz sei gehi lau',
    zuzena: 'hamar',
  },
  {
    title: 'CAPTCHA: Idatzi zenbakiz hamaika ken 3',
    zuzena: '8',
  },
  {
    title: 'CAPTCHA: Idatzi testuz bederatzi gehi 3',
    zuzena: 'hamabi',
  },
];

export const getCaptcha = () => {
  const index = Math.floor(Math.random() * captchas.length);
  return { ...captchas[index], type: 'string' };
};
