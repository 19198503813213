/**
 * Update person.
 * @module actions/update_person
 */

export const UPDATE_PERSON = 'UPDATE_PERSON';
/**
 * Get tokens.
 * @function updatePerson
 * @returns Updates person with data from Kudeu.
 */
export function updatePerson(path) {
  return {
    type: UPDATE_PERSON,
    request: {
      op: 'post',
      path: `${path}/@updateteacher`,
    },
  };
}
