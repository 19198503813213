import React from 'react';
import { Segment } from 'semantic-ui-react';

import cx from 'classnames';
import moment from 'moment';

// import FeaturedImageEditor from '@codesyntax/volto-featured-block/components/Blocks/FeaturedBlock/FeaturedImageEditor';

const DateTeaserView = (props) => {
  const { data } = props;
  return (
    <Segment basic className={cx(props.className, 'ikergazte-date')}>
      {data?.title && <h3>{data?.title}</h3>}
      <div className="date-container">
        {data.start && (
          <div className="start">
            <div className="month">{moment(data?.start).format('MMM')}</div>
            <div className="date">{moment(data?.start).format('DD')}</div>
          </div>
        )}
        {data.end && <div className="date-separator">{'>'}</div>}
        {data.end && (
          <div className="end">
            <div className="month">{moment(data?.end).format('MMM')}</div>
            <div className="date">{moment(data?.end).format('DD')}</div>
          </div>
        )}
      </div>
    </Segment>
  );
};

export default DateTeaserView;
