import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Header,
  Icon,
  Container,
  Segment,
  Button,
} from 'semantic-ui-react';

import config from '@plone/volto/registry';

import {
  lockChapters,
  unlockChapters,
  getPublications,
} from 'volto-ueu/actions';
import { UeuModal } from 'volto-ueu/components';
import { UeuListing } from 'volto-ueu/components/Variations';
import AldizkariaCard from './AldizkariaCard';
import GeneralInfoCard from './GeneralInfoCard';
import { getDataFromChapters } from './utils';

const ArgitalpenaView = ({ content, location }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const lockChaptersState = useSelector((state) => state.lockChapters);
  const unlockChaptersState = useSelector((state) => state.unlockChapters);
  const publications = useSelector((state) => state.publications);

  let lockChaptersState_ref = useRef(lockChaptersState);
  let unlockChaptersState_ref = useRef(unlockChaptersState);

  useEffect(() => {
    dispatch(
      getPublications(location.pathname.split('/').slice(0, -1).join('/'), 5),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    lockChaptersState_ref.current = lockChaptersState;
  }, [lockChaptersState]);
  useEffect(() => {
    unlockChaptersState_ref.current = unlockChaptersState;
  }, [unlockChaptersState]);

  const publication_path = location.pathname;
  const chapters = content.items.filter((item) => {
    return item['@type'] === 'Chapter';
  });
  const locked_chapters = chapters.filter((ch) => !ch.downloadable_file);
  const unlocked_chapters = chapters.filter((ch) => ch.downloadable_file);

  const ch_other_authors = getDataFromChapters(chapters, 'other_authors');
  const ch_authors = getDataFromChapters(chapters, 'authors');
  const authors = [...ch_authors, ...ch_other_authors];
  const taxonomy_unesco_categorization = getDataFromChapters(
    chapters,
    'taxonomy_unesco_categorization',
  );

  return (
    <Container className="view-wrapper">
      <Grid>
        <Grid.Column width={11}>
          <Header as="h1">{content.title}</Header>
        </Grid.Column>
        <Grid.Column width={1} textAlign="right">
          <Icon name="share alternate" />
        </Grid.Column>
      </Grid>
      {user?.roles &&
        config.settings.importerRoles.find((element) =>
          user?.roles.includes(element),
        ) && (
          <>
            {locked_chapters.length > 0 && (
              <Segment color="red" loading={unlockChaptersState.loading}>
                {locked_chapters.length} Artikulu daude blokeatuta. Argitalpen
                honen artikuluak desblokeatu:
                <UeuModal
                  header="Artikuluak desblokeatu"
                  closeAction={{
                    closeText: 'Itxi desblokeatu gabe',
                  }}
                  okAction={{
                    okText: 'Bai, desblokeatu artikuluak',
                    okOnClick: () => {
                      dispatch(unlockChapters(publication_path));
                    },
                  }}
                  trigger={() => {
                    return (
                      <Button className="ueu primary">
                        Desblokeatu artikuluak
                      </Button>
                    );
                  }}
                >
                  <p>
                    Argitalpen honetako artikulu guztiak DESBLOKEATZERA zoaz.
                  </p>
                  <p>
                    Kontuan izan desblokeatzen badituzu publiko ororentzat
                    eskuragarri geratuko direla artikuluak.
                  </p>
                  <p>
                    <strong>
                      Ziur zaude artikulu GUZTIAK DESBLOKEATU nahi dituzula?
                    </strong>
                  </p>
                </UeuModal>
                {!unlockChaptersState.loading && unlockChaptersState.error && (
                  <Segment inverted color="red" tertiary>
                    Errore bat egon da artikuluak desblokeatzerakoan.
                  </Segment>
                )}
                {unlockChaptersState_ref.current.loading &&
                  unlockChaptersState.loaded &&
                  !unlockChaptersState.error && (
                    <Segment inverted color="green" tertiary>
                      Artikulu guztiak DESBLOKEATU dira
                    </Segment>
                  )}
              </Segment>
            )}
            {unlocked_chapters.length > 0 && (
              <Segment color="green" loading={lockChaptersState.loading}>
                {unlocked_chapters.length} Artikulu daude desblokeatuta.
                Argitalpen honen artikuluak blokeatu:
                <UeuModal
                  header="Artikuluak blokeatu"
                  closeAction={{
                    closeText: 'Itxi blokeatu gabe',
                  }}
                  okAction={{
                    okText: 'Bai, blokeatu artikuluak',
                    okOnClick: () => {
                      dispatch(lockChapters(publication_path));
                    },
                  }}
                  trigger={() => {
                    return (
                      <Button className="ueu primary">
                        Blokeatu artikuluak
                      </Button>
                    );
                  }}
                >
                  <p>Argitalpen honetako artikulu guztiak BLOKEATZERA zoaz.</p>
                  <p>
                    Kontuan izan blokeatzen badituzu harpidedunek soilik izango
                    dituztela eskuragarri artikuluak.
                  </p>
                  <p>
                    <strong>
                      Ziur zaude artikulu GUZTIAK BLOKEATU nahi dituzula?
                    </strong>
                  </p>
                </UeuModal>
                {!lockChaptersState.loading && lockChaptersState.error && (
                  <Segment inverted color="red" tertiary>
                    Errore bat egon da artikuluak blokeatzerakoan.
                  </Segment>
                )}
                {lockChaptersState_ref.current.loading &&
                  lockChaptersState.loaded &&
                  !lockChaptersState.error && (
                    <Segment inverted color="green" tertiary>
                      Artikulu guztiak BLOKEATU dira
                    </Segment>
                  )}
              </Segment>
            )}
          </>
        )}
      <Grid stackable className="course-card">
        <Grid.Column width={8}>
          <GeneralInfoCard
            content={content}
            chapters={chapters}
            authors={authors}
            taxonomy_unesco_categorization={taxonomy_unesco_categorization}
          />
          {chapters.length > 0 && (
            <UeuListing
              items={chapters}
              howManyColumns={3}
              CardComponent={'Chapter'}
              isEditMode={false}
              showDescription={false}
              showUnconditionally={true}
              doubling={true}
            />
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          <AldizkariaCard content={content} />
        </Grid.Column>
      </Grid>
      {publications?.publications &&
        publications?.publications?.filter((pub) => pub.UID !== content.UID)
          .length > 0 && (
          <UeuListing
            header="Beste edizio batzuk"
            items={publications?.publications.filter(
              (pub) => pub.UID !== content.UID,
            )}
            howManyColumns={4}
            CardComponent={'Publication'}
            isEditMode={false}
            showUnconditionally={true}
            doubling={true}
          />
        )}
    </Container>
  );
};

export default ArgitalpenaView;
