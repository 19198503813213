import { getCaptcha } from '../../Form/utils';

export const schema = () => {
  return {
    title: 'Iragarki berria',
    type: 'object',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'atala',
          'title',
          'text',
          'image',
          'name',
          'email',
          'phone',
          'captcha',
          'accept',
        ],
      },
    ],
    properties: {
      atala: {
        title: 'Atala',
        description: 'Aukeratu zein atali dagokion',
        vocabulary: { '@id': 'collective.taxonomy.iragarki_atalak' },
      },
      name: {
        title: 'Izen abizenak',
        type: 'string',
        maxLength: 200,
      },
      title: {
        title: 'Izenburua',
        type: 'string',
        maxLength: 300,
      },
      image: {
        title: 'Irudia',
        type: 'object',
        factory: 'Image',
      },
      email: {
        title: 'Helbide elektronikoa',
        type: 'string',
        description:
          'Idatzi harremanetarako helbide elektronikoa (ez da argitaratuko)',
      },
      phone: {
        title: 'Telefonoa',
        type: 'string',
        description:
          'Idatzi harremanetarako telefonoa (sartuz gero argitaratu egingo da)',
        minLength: 9,
        maxLength: 15,
      },
      text: {
        title: 'Deskribapena',
        type: 'string',
        widget: 'richtext',
      },
      captcha: getCaptcha(),
      accept: {
        title:
          'Web gune honen erabiltzaileak hemen azaldutakoa ulertu eta borondatez onartzen du',
        html_description: {
          data: 'Hona gehitzen den material guztia CC-BY-SA lizentzia librearekin zabalduko da. Jakinarazi nahi dizugu 2016ko apirilaren 27ko 679/2016 Datuen Babeserako Erregelamendu Orokorrean aurreikusitakoaren arabera, zure datuak "kudeaketa" izeneko fitxategian sartuko direla. Datu pertsonalak biltzearen xedea soil-soilik da UEUren http://www.unibertsitatea.net webgunearekin duzun harremana kudeatzea. Eskubidea daukazu datuok ikusi, zuzendu, ezabatu edo aurka egiteko, honako helbide honetara idatziz: UEU, Erribera kalea 14, 48005 Bilbo edota helbide elektroniko honetara idatziz: ataria@ueu.org',
        },
        type: 'required_boolean',
      },
    },
    required: ['title', 'name', 'email', 'atala', 'captcha', 'accept'],
  };
};
