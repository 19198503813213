import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getSaskiaItems, setSaskiaItems } from 'volto-ueu/actions';

export const SASKIA_KEY = 'UEU-SASKIA';

const useUeuSaskia = () => {
  const saskia = useSelector((state) => state.saskia?.items);
  const user_id = useSelector((state) => state.users.user.id);
  const dispatch = useDispatch();

  useEffect(() => {
    getSaskia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const setSaskia = (saskia) => {
    Cookies.set(SASKIA_KEY, JSON.stringify(saskia, null, 2), { expires: 2 });
    dispatch(setSaskiaItems(saskia));
  };

  const getSaskia = () => {
    const cookieSaskia = Cookies.get(SASKIA_KEY);
    dispatch(getSaskiaItems(user_id));

    return cookieSaskia ? JSON.parse(cookieSaskia) : {};
  };

  const addSaskira = (publication, edition, count = 1) => {
    var cookieSaskia = Cookies.get(SASKIA_KEY);
    const key = edition ? `${publication}-${edition}` : publication;
    if (!cookieSaskia) {
      cookieSaskia = {
        content: {
          [key]: {
            publication: publication,
            edition: edition,
            count: count,
          },
        },
      };
    } else {
      cookieSaskia = JSON.parse(cookieSaskia);
      if (cookieSaskia['content'][key]) {
        cookieSaskia['content'][key].count += count;
      } else {
        cookieSaskia['content'][key] = {
          publication: publication,
          edition: edition,
          count: count,
        };
      }
    }
    setSaskia(cookieSaskia);
  };

  const removeSaskitik = (key, count = 1) => {
    var cookieSaskia = Cookies.get(SASKIA_KEY);
    cookieSaskia = JSON.parse(cookieSaskia);
    if (cookieSaskia['content'][key]) {
      if (cookieSaskia['content'][key].count === 1 || !count) {
        delete cookieSaskia['content'][key];
      } else {
        cookieSaskia['content'][key].count -= count;
        if (cookieSaskia['content'][key].count <= 0) {
          delete cookieSaskia['content'][key];
        }
      }
    }
    setSaskia(cookieSaskia);
  };

  const clearSaskia = () => {
    Cookies.remove(SASKIA_KEY);
    dispatch(setSaskiaItems({}));
  };

  return {
    saskia,
    addSaskira,
    clearSaskia,
    getSaskia,
    removeSaskitik,
  };
};

export { useUeuSaskia };
