import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Header,
  Button,
  Segment,
  Accordion,
} from 'semantic-ui-react';

import { getUser } from '@plone/volto/actions/users/users';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import { getEmailTemplates, getEmailTemplate } from 'volto-ueu/actions';
import jwtDecode from 'jwt-decode';

const EmailTemplatesPreview = ({ content, location, staticContext }) => {
  /** state selectors **/
  const token = useSelector((state) => state.userSession.token);
  const emailTemplates = useSelector((state) => state.emailTemplates);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(userId));
    dispatch(getEmailTemplates());
  }, [dispatch, userId]);

  const [openedAccordion, setOpenedAccordion] = React.useState('');

  return (
    <>
      <Helmet title={'Email plantillak'} />
      <div className="ui container">
        {!isLoggedIn && (
          <>
            {token ? (
              <Forbidden
                pathname={location.pathname}
                staticContext={staticContext}
              />
            ) : (
              <Unauthorized
                pathname={location.pathname}
                staticContext={staticContext}
              />
            )}
          </>
        )}
        {isLoggedIn && (
          <>
            <Container className="view-wrapper">
              <Header as="h1" className="no-breadcrumb">
                Email plantillak
              </Header>
              {emailTemplates.templates.length > 0 ? (
                emailTemplates.templates.map((t) => {
                  return (
                    <Accordion fluid styled key={t}>
                      <Accordion.Title
                        onClick={() =>
                          t.filename === openedAccordion
                            ? setOpenedAccordion('')
                            : setOpenedAccordion(t.filename)
                        }
                      >
                        {t.humanname}
                      </Accordion.Title>
                      <Accordion.Content
                        active={t.filename === openedAccordion}
                      >
                        <Button
                          onClick={() => dispatch(getEmailTemplate(t.filename))}
                        >
                          Ikusi
                        </Button>
                        {emailTemplates?.data?.[t.filename] && (
                          <Segment>
                            <div id="unseter">
                              <iframe
                                title={t.filename}
                                width={'100%'}
                                height={'500px'}
                                srcdoc={emailTemplates?.data?.[t.filename]}
                              ></iframe>
                            </div>
                          </Segment>
                        )}
                      </Accordion.Content>
                    </Accordion>
                  );
                })
              ) : (
                <></>
              )}
            </Container>
          </>
        )}
      </div>
    </>
  );
};

export default EmailTemplatesPreview;
