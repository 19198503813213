import React from 'react';

import Button from './Button';

const ButtonBlockView = (props) => {
  const { data } = props;

  return (
    <div>
      <Button variation={data?.variation} {...props}>
        {data.title || 'Text example...'}
      </Button>
    </div>
  );
};

export default ButtonBlockView;
