/**
 * Check nan.
 * @module actions/check_nan
 */

export const CHECK_NAN = 'CHECK_NAN';
/**
 * Check nan.
 * @function checkNan
 * @returns Check person nan at Kudeu.
 */
export function checkNan({ nan, izena }) {
  return {
    type: CHECK_NAN,
    request: {
      op: 'post',
      path: `@checknan`,
      data: { nan, izena },
    },
  };
}
