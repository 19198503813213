import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import { getContent } from '@plone/volto/actions/content/content';
import NotFound from '@plone/volto/components/theme/NotFound/NotFound';

import { isEntryInTime } from 'volto-ueu/components/Views/CourseView/utils';
import { PAID_OK_ROUTE } from 'volto-ueu/constants';
import { ContentData } from './ContentData';

/**
 * OkView view component.
 * @module components/Views/OrdainketaView/OkView
 */
/**
 * OkView view.
 * @function OkView
 * @returns {string} Markup of the component.
 */

const OkView = (props) => {
  const dispatch = useDispatch();
  const re = new RegExp(`${PAID_OK_ROUTE}$`);
  const ikastaroa_path = props.location.pathname.replace(re, '');
  const content = useSelector((state) => state.content);
  const title_text = 'Ordainketa ongi burutu da!';
  useEffect(() => {
    dispatch(getContent(ikastaroa_path));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return content.get.loading && !content.get.loaded ? (
    <Segment loading></Segment>
  ) : (content?.data && content?.data['@type'] !== 'Course') ||
    !isEntryInTime(content.data) ? (
    <NotFound></NotFound>
  ) : (
    <ContentData
      h1_text={title_text}
      h2_text={content.data?.title}
      Body={() => (
        <>
          <Segment basic>
            <p>
              Jaso dugu zure izen ematea eta ordainketa. Jardueran matrikulatuta
              eta onartuta zaude.
            </p>
            <p>
              Zure helbide elektronikora bidali dizkizugu matrikula konfirmazioa
              eta argibide batzuk.
            </p>
            <p>
              <strong>Adi!</strong> Mezu automatikoa da, baliteke spam edo
              publizitate karpetara iristea, egiaztatu mezua jaso duzula.
            </p>
            <p>
              Ez bada mezurik iritsi, jarri arduradunarekin harremanetan:{' '}
              <strong>{content.data?.manager_name}</strong>
              {' - '}
              <i>{content.data?.manager_phone}</i>
              {' edo '}
              <i>{content.data?.manager_email}</i>
            </p>
          </Segment>
          <Segment basic>Mila esker!</Segment>
        </>
      )}
    />
  );
};

export default OkView;
