/**
 * Erosketak.
 * @module actions/erosketak
 */
export const GET_EROSKETAK = 'GET_EROSKETAK';

/**
 * Get erosketak.
 * @function getErosketak
 * @returns {Object} Get erosketak action.
 */
export function getErosketak() {
  return {
    type: GET_EROSKETAK,
    request: {
      op: 'get',
      path: `@erosketakget`,
    },
  };
}

export const GET_ALL_EROSKETAK = 'GET_ALL_EROSKETAK';

/**
 * Get erosketak.
 * @function getErosketak
 * @returns {Object} Get erosketak action.
 */
export function getAllErosketak() {
  return {
    type: GET_ALL_EROSKETAK,
    request: {
      op: 'get',
      path: `@allerosketakget`,
    },
  };
}

export const ADD_EROSKETAK = 'ADD_EROSKETAK';

/**
 * Add erosketak.
 * @function addErosketak
 * @returns {Object} Add erosketak action.
 */
export function addErosketak(saskia, eroslea) {
  return {
    type: ADD_EROSKETAK,
    request: {
      op: 'post',
      path: `@erosketakadd`,
      data: { saskia: saskia, eroslea: eroslea },
    },
  };
}

export const POST_EROSKETA = 'POST_EROSKETA';

/**
 * Post erosketa.
 * @function postErosketa
 * @returns {Object} Post erosketa action.
 */
export function postErosketa(saskia, eroslea) {
  return {
    type: POST_EROSKETA,
    request: {
      op: 'post',
      path: `@posterosketa`,
      data: { saskia: saskia, eroslea: eroslea },
    },
  };
}

export const DELETE_EROSKETA = 'DELETE_EROSKETA';

/**
 * Delete erosketa.
 * @function deleteErosketa
 * @returns {Object} Delete erosketa action.
 */
export function deleteErosketa(erosketa_id) {
  return {
    type: DELETE_EROSKETA,
    request: {
      op: 'del',
      path: `@erosketa/${erosketa_id}`,
    },
  };
}
