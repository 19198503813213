import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Checkbox, Button } from 'semantic-ui-react';

import Toast from '@plone/volto/components/manage/Toast/Toast';
import config from '@plone/volto/registry';

import { getRegistry } from 'volto-ueu/actions';
import { useUeuSaskia } from 'volto-ueu/components/saskia';

export const BuyEditionsForm = ({
  content,
  paper_edition = {},
  digital_editions = [],
  buttonText = 'Erosi',
  toastTitle = 'Saskira gehituta!',
  toastBody = 'Aukeratu duzun edizioa saskira gehitu da! Saskia ikusteko, goiburuan dagoen orgatxoan klik egin dezakezu.',
}) => {
  const dispatch = useDispatch();
  const { addSaskira } = useUeuSaskia();
  const [checkedPaper, setCheckedPaper] = useState(false);
  const [checkedDigital, setCheckedDigital] = useState(false);
  const registryRecords = useSelector((state) => state.registry);
  const bazkide_deskontua_key = config.settings.registry.bazkide_deskontua;
  useEffect(() => {
    dispatch(getRegistry(bazkide_deskontua_key));
  }, [dispatch, bazkide_deskontua_key]);
  const bazkide_deskontua = registryRecords?.records
    ? registryRecords?.records[bazkide_deskontua_key]
    : 0;
  const bazkide_paper_price = paper_edition.price
    ? (
        (parseInt(paper_edition.price) * (100 - parseInt(bazkide_deskontua))) /
        100
      ).toFixed(1)
    : '0';
  const handleSubmit = (event, book_uuid, paper_uuid, digital_editions) => {
    if (event.target.paper && event.target.paper.checked) {
      addSaskira(book_uuid, paper_uuid);
      setCheckedPaper(false);
    }
    if (event.target.digital && event.target.digital.checked) {
      addSaskira(book_uuid, digital_editions.map((de) => de.UID).join('##'));
      setCheckedDigital(false);
    }
    toast.success(
      <Toast autoClose={3000} title={toastTitle} content={toastBody} />,
    );
  };
  return (
    <Form
      onSubmit={(event) =>
        handleSubmit(event, content.UID, paper_edition.UID, digital_editions)
      }
    >
      {paper_edition.price > 0 && paper_edition.available && (
        <Form.Field>
          <Checkbox
            id="paper"
            label={`Paperekoa ${paper_edition.price ?? '-'} € ${
              bazkide_deskontua
                ? `/ Bazkideek ${bazkide_paper_price || '-'} €`
                : ''
            }`}
            checked={checkedPaper}
            onClick={() => setCheckedPaper(!checkedPaper)}
          />
        </Form.Field>
      )}
      {digital_editions.length > 0 && digital_editions[0].price > 0 && (
        <Form.Field>
          <Checkbox
            id="digital"
            label={`Digitala (${digital_editions
              .filter((de) => de.available)
              .map((item) => {
                return item.format.replace('unknown', 'bestelakoa');
              })
              .join(', ')})
                      ${digital_editions[0].price ?? '-'} € / Bazkideek ${
                        (
                          (digital_editions[0].price *
                            (100 - bazkide_deskontua)) /
                          100
                        ).toFixed(1) || '-'
                      } €`}
            checked={checkedDigital}
            onClick={() => setCheckedDigital(!checkedDigital)}
          />
        </Form.Field>
      )}
      {(digital_editions.some((de) => de.available && de.price > 0) ||
        (paper_edition.available && paper_edition.price > 0)) && (
        <Button className="ui button ueu large primary" type="submit">
          {buttonText}
        </Button>
      )}
    </Form>
  );
};
