import React from 'react';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';

const PosterCard = ({ item, isEditMode, showDescription = true }) => {
  return (
    <div className="poster-list-item">
      <h3>
        <ConditionalLink item={item} condition={!isEditMode}>
          {item.title}
        </ConditionalLink>
        {item.poster_authors && `, ${item.poster_authors}`}
      </h3>
      {showDescription && item.description}
    </div>
  );
};

export default PosterCard;
