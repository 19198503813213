import { v4 as uuid } from 'uuid';

export const emptyCard = (count) => {
  const blocks = {};
  const items = [];
  for (let x = 0; x < count; x++) {
    const id = uuid();
    blocks[id] = {};
    blocks[id].title = 'Custom title ' + x;
    blocks[id]['@type'] = 'menuSection';
    items.push(id);
  }
  return {
    blocks,
    blocks_layout: {
      items,
    },
  };
};

export const getPanels = (data) => {
  return (data?.blocks_layout?.items || []).map((id) => [
    id,
    data.blocks?.[id],
  ]);
};

export const getVariationTemplate = (data, variationsConfig) => {
  let BodyTemplate = '';
  let variationId = '';
  if (!!data?.variation) {
    for (let variation in variationsConfig) {
      if (variationsConfig[variation].id === data.variation) {
        variationId = data.variation;
        BodyTemplate = variationsConfig[variation].template;
      }
    }
  } else {
    for (let variation in variationsConfig) {
      if (variationsConfig[variation].isDefault === true) {
        variationId = variationsConfig[variation].id;
        BodyTemplate = variationsConfig[variation].template;
      }
    }
  }
  return { BodyTemplate, variationId };
};
