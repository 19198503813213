import messages from './messages';
const MenuSectionSchema = (config, intl) => {
  const variationsConfig = config.blocks.blocksConfig['menuSection'].variations;
  const variations = Object.keys(variationsConfig).map((variation) => [
    variationsConfig[variation].id,
    variationsConfig[variation].title,
  ]);

  return {
    title: 'Menu Section',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['variation', 'title', 'url', 'icon', 'data'],
      },
    ],
    properties: {
      variation: {
        title: intl.formatMessage(messages.variation),
        type: 'array',
        choices: [...variations],
      },
      title: {
        title: intl.formatMessage(messages.menuSectionTitle),
        type: 'string',
      },
      url: {
        title: intl.formatMessage(messages.menuSectionItemUrl),
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      icon: {
        title: intl.formatMessage(messages.menuSectionItemIcon),
        type: 'string',
        description: 'https://react.semantic-ui.com/elements/icon/',
      },
      data: {
        title: intl.formatMessage(messages.menuSectionItems),
        type: 'panels',
        schema: ItemSchema(intl),
      },
    },
    required: [],
  };
};

export const ItemSchema = (intl) => ({
  title: intl.formatMessage(messages.menuSectionItemBlockTitle),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'url', 'icon'],
    },
  ],
  properties: {
    title: {
      title: intl.formatMessage(messages.menuSectionItemTitle),
      type: 'string',
    },
    url: {
      title: intl.formatMessage(messages.menuSectionItemUrl),
      widget: 'object_browser',
      mode: 'link',
      allowExternals: true,
    },
    icon: {
      title: intl.formatMessage(messages.menuSectionItemIcon),
      type: 'string',
      description: 'https://react.semantic-ui.com/elements/icon/',
    },
  },
  required: [],
});

export default MenuSectionSchema;
