export const isOnlyTxiotesia = (formData) => {
  let result = false;
  if (formData?.querystring?.query && formData?.querystring?.query.length > 0) {
    formData?.querystring?.query.forEach((q) => {
      if (q.i === 'portal_type' && q.v.length === 1 && q.v[0] === 'Txiotesia') {
        result = true;
      }
    });
  }
  return result;
};

export const showDateFieldset = (formData) => {
  let result = true;
  if (isOnlyTxiotesia(formData)) {
    result = false;
  }
  return result;
};

export const showDescriptionFieldset = (formData) => {
  let result = true;
  if (isOnlyTxiotesia(formData)) {
    result = false;
  }
  return result;
};
