import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Table, Segment, Button } from 'semantic-ui-react';

import { getVocabulary } from '@plone/volto/actions/vocabularies/vocabularies';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import Forbidden from '@plone/volto/components/theme/Forbidden/Forbidden';
import Unauthorized from '@plone/volto/components/theme/Unauthorized/Unauthorized';
import { Helmet } from '@plone/volto/helpers/Helmet/Helmet';
import config from '@plone/volto/registry';

import {
  getAllUserModifications,
  removeUserModification,
  getUserSchema,
} from 'volto-ueu/actions';
import { UeuModal } from 'volto-ueu/components';
import { USERMODIFICATIONS_ROUTE } from 'volto-ueu/constants';
import { getFieldValue } from './utils';

import jwtDecode from 'jwt-decode';
import moment from 'moment';

const UserModificationsView = ({ content, location, staticContext }) => {
  const usermodifications = useSelector((state) => state.usermodifications);
  const userschema = useSelector((state) => state.userschema.userschema);
  const vocabularies = useSelector((state) => state.vocabularies);
  const user = useSelector((state) => state.users.user);

  let lang = useSelector((state) => state.intl.locale);
  const token = useSelector((state) => state.userSession.token);
  const userId = token ? jwtDecode(token).sub : '';
  const isLoggedIn = !!userId;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUserModifications());
    dispatch(getUserSchema());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // get the vocabularies for the userschema
  useEffect(() => {
    if (userschema) {
      Object.entries(userschema.properties).forEach((property) => {
        if (property[1].vocabulary && property[1].vocabulary['@id']) {
          dispatch(
            getVocabulary({
              vocabNameOrURL: property[1].vocabulary['@id'],
            }),
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userschema]);
  moment.locale(lang);
  return (
    <div className="ui container">
      {!isLoggedIn ? (
        <>
          {token ? (
            <Forbidden
              pathname={location.pathname}
              staticContext={staticContext}
            />
          ) : (
            <Unauthorized
              pathname={location.pathname}
              staticContext={staticContext}
            />
          )}
        </>
      ) : user?.roles &&
        config.settings.importerRoles.find((element) =>
          user?.roles.includes(element),
        ) ? (
        <>
          <Helmet title="Erabiltzaileen aldaketak"></Helmet>
          <Header as="h1" className="no-breadcrumb">
            Erabiltzaileen aldaketak
          </Header>
          <Segment basic>
            {usermodifications.modifications.length > 0 ? (
              <Table unstackable className="erabiltzaileen-aldaketak-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Aldaketa data</Table.HeaderCell>
                    <Table.HeaderCell>Erabiltzailea</Table.HeaderCell>
                    <Table.HeaderCell>Aldaketa</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {usermodifications.modifications.length > 0 &&
                    usermodifications?.modifications
                      .sort(function (a, b) {
                        return b.datetime.localeCompare(a.datetime);
                      })
                      .map((modification, key) => (
                        <Table.Row key={key}>
                          <Table.Cell>
                            {moment(modification.datetime).format('L')}
                          </Table.Cell>
                          <Table.Cell>{`${modification.email} (${modification.user})`}</Table.Cell>
                          <Table.Cell>
                            <ul>
                              {Object.entries(modification.modifications).map(
                                (elementua, key) => (
                                  <li key={key}>
                                    <strong>
                                      {userschema?.properties
                                        ? userschema?.properties[elementua[0]]
                                            ?.title
                                        : elementua[0]}
                                      :{' '}
                                    </strong>
                                    {getFieldValue(
                                      elementua[1],
                                      elementua[0],
                                      userschema,
                                      vocabularies,
                                    )}
                                  </li>
                                ),
                              )}
                            </ul>
                          </Table.Cell>
                          <Table.Cell>
                            <UniversalLink
                              className="ui button ueu"
                              href={`${USERMODIFICATIONS_ROUTE}/${modification.UID}`}
                            >
                              Kudeatu aldaketa
                            </UniversalLink>
                            <UeuModal
                              header="Erabiltzailearen aldaketa baztertu"
                              closeAction={{
                                closeText: 'Itxi aldaketarik egin gabe',
                              }}
                              okAction={{
                                okText: 'Bai, baztertu aldaketa',
                                okOnClick: () =>
                                  dispatch(
                                    removeUserModification(modification.UID),
                                  ),
                              }}
                              trigger={() => {
                                return (
                                  <Button className="ueu">
                                    Baztertu aldaketa
                                  </Button>
                                );
                              }}
                            >
                              <p>
                                Erabiltzaileak egin duen aldaketa baztertzera
                                zoaz. Baztertzen baduzu, informazioa ez da
                                ueu.eus gunean eta Kudeun gordeko.
                              </p>
                              <p>
                                <ul>
                                  {Object.entries(
                                    modification.modifications,
                                  ).map((elementua, key) => (
                                    <li key={key}>
                                      <strong>{elementua[0]}: </strong>
                                      {elementua[1]}
                                    </li>
                                  ))}
                                </ul>
                              </p>
                              <p>
                                <strong>
                                  Ziur zaude aldaketa baztertu nahi duzula?
                                </strong>
                              </p>
                            </UeuModal>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                </Table.Body>
              </Table>
            ) : (
              <Header as="h2">Ez dago kudeatu beharreko aldaketarik.</Header>
            )}
          </Segment>
        </>
      ) : (
        <Forbidden pathname={location.pathname} staticContext={staticContext} />
      )}
    </div>
  );
};

export default UserModificationsView;
