import React from 'react';
// import FeaturedImageEditor from '../FeaturedImageEditor';
import { Button } from 'semantic-ui-react';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import { getTeaserImageURL } from './utils';

const CarouselTeaserView = (props) => {
  const { data, isEditMode } = props;
  const href = data.href?.[0];
  const hrefId = href && href.hasOwnProperty('@id') ? href['@id'] : '';
  const buttonLink = data.buttonLink?.[0];
  const image = data.preview_image?.[0];
  return (
    <div
      className="banner-top-main"
      style={
        image
          ? {
              backgroundImage: `url(${flattenToAppURL(
                getTeaserImageURL(href, image),
              )})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
            }
          : {}
      }
    >
      {/* {isEditMode && <FeaturedImageEditor {...props} />} */}
      <div className="ui two column grid">
        <div className="row">
          <div className="ten wide column left floated"></div>
          <div className="six wide column right floated">
            <div className="banner-top-main-block-title">
              {data.title && (
                <h2>
                  <ConditionalLink
                    to={hrefId}
                    condition={!isEditMode && !!hrefId && !!data.title}
                  >
                    {data?.title}
                  </ConditionalLink>
                </h2>
              )}
              {data.description && <h3>{data?.description} </h3>}
              {buttonLink && (
                <Button
                  as="a"
                  className="ueu"
                  size="large"
                  href={buttonLink['@id']}
                  primary
                >
                  {data.buttonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselTeaserView;
