import { HASTEKOAK, AMAITUTA, MARTXAN } from 'volto-ueu/constants';
export const getValuesForFacet = (facetName, adaptedFullQuery) => {
  let result = [];
  if (adaptedFullQuery?.loaded && adaptedFullQuery.items.length > 0) {
    adaptedFullQuery.items.forEach((item) => {
      const facetValue = item[facetName];
      if (facetValue && !result.includes(facetValue)) {
        if (Number.isInteger(facetValue)) {
          result.push(facetValue.toString());
        } else {
          result.push(facetValue);
        }
      }
    });
  }
  return result.flat();
};

export const getFacetValueLengths = (items, field) => {
  let result = {};
  if (!items) return result;
  items.forEach((item) => {
    const itemFacetValue = item[field.value];
    if (Array.isArray(itemFacetValue)) {
      itemFacetValue.forEach((i) => {
        if (result[i]) {
          result[i] = ++result[i];
        } else {
          result[i] = 1;
        }
      });
    } else {
      if (result[itemFacetValue]) {
        result[itemFacetValue] = ++result[itemFacetValue];
      } else {
        result[itemFacetValue] = 1;
      }
    }
  });
  return result;
};

export const checkAllChildren = (value, option) => {
  if (!option?.childrens || option.childrens.length === 0) {
    return [...value, { label: option.label, value: option.value }];
  }
  option.childrens.forEach((ch) => {
    if (value.filter((v) => v.value === ch.value).length === 0) {
      value.push(ch);
    }
  });
  return [...value, { label: option.label, value: option.value }];
};

export const uncheckOptionAndChildren = (value, option) => {
  return value
    .filter((item) => item.value !== option.value)
    .filter((item) => {
      if (option.childrens?.length > 0) {
        return !option.childrens.map((ch) => ch.value).includes(item.value);
      } else {
        return true;
      }
    });
};

export const rewriteOptions = (name, choices) => {
  var result = choices;
  if (name === 'review_state') {
    result = choices.map((opt) => ({
      ...opt,
      label: opt.label.replace(/\[.+\]/, '').trim(),
    }));
  }
  const status = [HASTEKOAK, MARTXAN, AMAITUTA];

  if (name === 'course_status') {
    result = choices
      // .map((opt) => {
      //   return { ...opt, label: status[opt.value] };
      // })
      .sort((a, b) => {
        return status.indexOf(a.value) - status.indexOf(b.value);
      });
  }
  return result;
};
