import React, { useState } from 'react';
import { Checkbox, Header, Icon } from 'semantic-ui-react';
import { structure_taxonomy_terms } from 'volto-ueu/helpers/taxonomy_utils';
import { useSelector } from 'react-redux';

import {
  selectFacetSchemaEnhancer,
  selectFacetStateToValue,
  selectFacetValueToQuery,
} from '@plone/volto/components/manage/Blocks/Search/components/base';
import { uncheckOptionAndChildren } from './utils';
import { getValuesForFacet, getFacetValueLengths } from './utils';
import { useMobileChecker } from '../../../helpers/useMobileChecker';

const hasAllChildrensSelected = (value, childrens) => {
  var result = true;
  if (!childrens || childrens.length === 0) {
    result = false;
  }
  childrens.forEach((ch) => {
    if (value.filter((v) => v.value === ch.value).length === 0) {
      result = false;
    }
  });
  return result;
};

const CheckboxTreeParentFacet = (props) => {
  const { facet, choices = [], onChange, value } = props;
  const isMobile = useMobileChecker();
  const [openFacet, setopenFacet] = useState(false);

  const facetValue = value;
  let options = [];
  const [result, setresult] = useState([]);
  const [disabled, setdisabled] = useState([]);
  const adaptedBaseQuery = useSelector(
    (state) => state.querystringsearch?.subrequests?.adaptedBaseQuery,
  );
  React.useEffect(() => {
    if (adaptedBaseQuery?.loaded) {
      const tmp_result = getValuesForFacet(facet.field.value, adaptedBaseQuery);
      setresult(tmp_result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adaptedBaseQuery]);
  React.useEffect(() => {
    setdisabled(
      result.length > 0
        ? choices
            .filter((choice) => !result.flat(1).includes(choice.value))
            .map((choice) => choice.value)
        : choices.map((choice) => choice.value),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);
  const facetNumbers = getFacetValueLengths(
    adaptedBaseQuery?.items,
    facet?.field,
  );
  if (choices?.length > 0) {
    const selectableChoices = !facetNumbers
      ? choices.filter((choice) => !disabled.includes(choice.value))
      : choices;
    options = structure_taxonomy_terms(selectableChoices);
  }
  return (
    <div className="checkbox-facet">
      <Header
        as="h4"
        onClick={() => {
          setopenFacet(!openFacet);
        }}
      >
        {facet.title ?? facet?.field?.label}
        {openFacet && isMobile ? (
          <span className="mobile only">
            <Icon name="minus" />
          </span>
        ) : (
          <span className="mobile only">
            <Icon name="plus" />
          </span>
        )}
      </Header>
      {(!isMobile || openFacet) && (
        <div className="entries">
          {options.map((option, key) => (
            <div className="entry" key={key}>
              {(facetNumbers && facetNumbers[option.value]) ||
              !Object.keys(facetNumbers).length ? (
                <Checkbox
                  key={option.value}
                  name={`field-${option.value}`}
                  onChange={(event, { checked }) => {
                    return checked
                      ? onChange(facet.field.value, [
                          // if this option has children, check them all
                          // ...checkAllChildren(facetValue, option).map(
                          //   (f) => f.value,
                          // ),
                          ...[
                            ...facetValue,
                            { label: option.label, value: option.value },
                          ].map((f) => f.value),
                        ])
                      : onChange(facet.field.value, [
                          ...uncheckOptionAndChildren(facetValue, option).map(
                            (f) => f.value,
                          ),
                        ]);
                  }}
                  label={
                    facetNumbers[option.value] ? (
                      <label htmlFor={`field-${option.value}`}>
                        {option.label} ({facetNumbers[option.value] ?? '0'})
                      </label>
                    ) : (
                      <label htmlFor={`field-${option.value}`}>
                        {option.label}
                      </label>
                    )
                  }
                  checked={
                    facetValue.some((item) => item.value === option.value) ||
                    hasAllChildrensSelected(facetValue, option.childrens)
                  }
                  value={option.value}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CheckboxTreeParentFacet.schemaEnhancer = selectFacetSchemaEnhancer;
CheckboxTreeParentFacet.stateToValue = selectFacetStateToValue;
CheckboxTreeParentFacet.valueToQuery = selectFacetValueToQuery;
export default CheckboxTreeParentFacet;
