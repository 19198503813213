import React from 'react';
import { Icon } from 'semantic-ui-react';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import config from '@plone/volto/registry';

import {
  NIRE_DATUAK_ROUTE,
  NIRE_BAZKIDETZA_ROUTE,
  NIRE_HARPIDETZA_ROUTE,
  NIRE_EROSKETAK_ROUTE,
  EROSKETA_GUZTIAK_ROUTE,
  NIRE_IKASTAROAK_ROUTE,
  USERMODIFICATIONS_ROUTE,
} from 'volto-ueu/constants';

export const profile_options = (option_base, user, usermodifications) => {
  return [
    user?.roles &&
      config.settings.importerRoles.find((element) =>
        user?.roles.includes(element),
      ) && {
        ...option_base,
        content: (
          <UniversalLink href={USERMODIFICATIONS_ROUTE}>
            Erabiltzaileen aldaketak ({usermodifications.modifications.length})
          </UniversalLink>
        ),
      },
    {
      ...option_base,
      content: (
        <UniversalLink href={NIRE_DATUAK_ROUTE}>Nire datuak</UniversalLink>
      ),
    },
    {
      ...option_base,
      content: (
        <UniversalLink href="https://acumbamail.com/newform/web/Xj4JJ4vRN6HzxrUUQOyd1XOOO08PYHxp5iZ0K6Y8nk/8367/">
          Buletinera harpidetza
        </UniversalLink>
      ),
    },
    user?.bazkide_id && {
      ...option_base,
      content: (
        <UniversalLink href={NIRE_BAZKIDETZA_ROUTE}>Bazkidetza</UniversalLink>
      ),
    },
    user?.uztaro_id && {
      ...option_base,
      content: (
        <UniversalLink href={NIRE_HARPIDETZA_ROUTE}>Uztaro</UniversalLink>
      ),
    },
    {
      ...option_base,
      content: (
        <UniversalLink href={NIRE_IKASTAROAK_ROUTE}>
          Nire ikastaroak
        </UniversalLink>
      ),
    },
    {
      ...option_base,
      content: (
        <UniversalLink href={NIRE_EROSKETAK_ROUTE}>
          Nire erosketak
        </UniversalLink>
      ),
    },
    user?.roles &&
      config.settings.importerRoles.find((element) =>
        user?.roles.includes(element),
      ) && {
        ...option_base,
        content: (
          <UniversalLink href={EROSKETA_GUZTIAK_ROUTE}>
            Erosketa guztiak
          </UniversalLink>
        ),
      },
    {
      ...option_base,
      content: (
        <UniversalLink href="/passwordreset">Pasahitza aldatu</UniversalLink>
      ),
    },
    {
      ...option_base,
      content: (
        <UniversalLink href="/logout">
          Irten <Icon className="ueu-icon" name={'close'} />
        </UniversalLink>
      ),
    },
  ];
};
