/**
 * Get course_form.
 * @module actions/course_form
 */
export const SEND_COURSE_FORM = 'SEND_COURSE_FORM';

/**
 * Get erosketak.
 * @function sendCourseForm
 * @returns {Object} Get erosketak action.
 */
export function sendCourseForm(path, data, course) {
  return {
    type: SEND_COURSE_FORM,
    request: {
      op: 'post',
      path: `${path}/@courseform`,
      data: { data: data, course: course },
    },
  };
}
